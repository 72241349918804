import { configure } from "mobx"
import {
    getLocalStorage,
    getAppName,
    writeAppConfig,
    readAppConfig
} from '../utils/storagehelper';
import AppMessageHandler from './messagehandler';
import AppGameLoader from './loader';
import LayoutManager from './layoutmanager';
import AppState from './stores/appstate';
import createMessage from './messages/index';
import {
    getXpForLevel,
    replace1k,
    translateKey
} from '../utils/helper';

configure({
    useProxies: "ifavailable"
})

const wait = (ms: number) => new Promise(
    (res, rej) => setTimeout(
        () => rej(new Error(`timed out after ${ms} ms`)),
        ms
    )
);

class GameUserXP {
    public maxLevel: number;
    public levelList: any;

    constructor() {
        this.maxLevel = 1;
        this.levelList = [];
    }

    onHandleXP(xpObject: any) {
        const useXPList = xpObject || {};
        this.levelList = [];
        let currentXP = 0;
        this.maxLevel = useXPList.max || 250;

        for (let iLvl = 0; iLvl < this.maxLevel; iLvl++) {
            currentXP = currentXP + getXpForLevel(iLvl + 1, useXPList.x, useXPList.y, useXPList.z)
            this.levelList.push(currentXP);
        }
    }

    getLevel(experience: number): number {
        if (this.levelList.length) {
            let foundLevel = 0;
            this.levelList.forEach((xp: number, i: number) => {
                if (experience >= xp) {
                    foundLevel = i + 2;
                }
            })
            if (foundLevel !== 0) {
                return foundLevel;
            }
        }
        return 1;
    }

    getLevelExperience(experience: number): any {
        if (this.levelList.length) {
            let foundLevel = -1;

            this.levelList.forEach((xp: number, i: number) => {
                if (experience >= xp) {
                    foundLevel = i;
                }
            })
            const useSubtract = foundLevel === -1 ? 0 : this.levelList[foundLevel];
            const useMax = this.levelList.length >= foundLevel + 2 ?
                this.levelList[foundLevel + 1] :
                experience;

            return [
                experience - useSubtract,
                useMax - useSubtract
            ]

        }
        return [experience, experience]
    }
}

class GamePublicConfig {
    public buyInConfig: any;

    constructor() {
        this.buyInConfig = [];
    }

    public onHandleData(obj: any): void {
        if (obj?.buyin) {
            this.buyInConfig = obj.buyin.split(',').map((val: any) => parseInt(val, 10))
        }
    }

    public valueString(lvl: number): string {
        if (lvl <= this.buyInConfig.length - 1) {
            return translateKey('text.lobby.blindinfo', {}, [
                ['$sb', replace1k(this.buyIn[lvl].sb)],
                ['$bb', replace1k(this.buyIn[lvl].bb)],
                ['$min', replace1k(this.buyIn[lvl].min)],
                ['$max', replace1k(this.buyIn[lvl].max)]
            ])
        }
        return '-';
    }

    get buyIn() {
        return this.buyInConfig.map((data: any) => ({
            min: data,
            max: data * 10,
            sb: data / 20,
            bb: (data / 20) * 2
        }));
    }
}
class CouchgamesSdk {
    public messageHandler: AppMessageHandler;
    public layoutManager: LayoutManager;
    public gameLoader: AppGameLoader;
    public appState: AppState;
    public appConfig: any;
    public xp: GameUserXP;
    public appPublic: GamePublicConfig;

    constructor() {
        //@ts-ignore
        console.log(window?.sdk ? 'SDK YES' : 'SDK NO')
        //@ts-ignore
        window.sdk = this;
        console.log('### <<< CREATE SDK >>> ####')
        this.messageHandler = new AppMessageHandler(this, { url: '' });
        this.layoutManager = new LayoutManager();
        this.gameLoader = new AppGameLoader(this, this.messageHandler)
        this.appState = new AppState(this);
        this.xp = new GameUserXP();
        this.appPublic = new GamePublicConfig();
    }

    // the fetch could remain unchanged
    // ...but it helps to add the "signal" property to abort the request early
    //   const abortController = new AbortController();
    //   const fetchData = fetch(
    //     'http://url',
    //     {
    //       method:"POST",
    //       signal: abortController.signal
    //     }
    //   );

    public blobToFile(theBlob: any, fileName: any) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    public async uploadFile(api: string, path: string, fileBase64: any): Promise<any> {
        let useUrl = '';

        if (api === 'account') {
            useUrl = this.getAccountUrl(path);
        } else if (api === 'api') {
            useUrl = this.getApiUrl(path);
        } else if (api === 'custom') {
            useUrl = path;
        }
        const base64Response = await fetch(fileBase64);
        const blob = await base64Response.blob();

        const formData = new FormData();

        formData.append('File', this.blobToFile(blob, "avatar.jpg"));
        formData.append('UserToken', this.appState?.user?.token || '');

        return new Promise((resolve, reject) => {
            const abortController = new AbortController();
            const fetchData = fetch(useUrl, {
                method: 'POST',
                signal: abortController.signal,
                // headers: { 'Content-Type': 'application/json' },
                body: formData

            });

            const fetchOrTimeout = Promise.race([fetchData, wait(parseInt(`${process.env.REACT_APP_FETCH_TIMEOUT}`, 10))]);

            fetchOrTimeout
                .then(async (response: any) => {
                    resolve({
                        json: await response.json(),
                        status: response.status
                    })
                })
                .catch((error: any) => {
                    abortController.abort()
                    resolve(undefined)
                });
        })

    }

    public async fetchApi(api: string, path: string, data: any = {}, method: string = 'POST'): Promise<any> {
        let useUrl = '';

        if (api === 'account') {
            useUrl = this.getAccountUrl(path);
        } else if (api === 'api') {
            useUrl = this.getApiUrl(path);
        }

        const isGet = method === 'GET';

        if (isGet) {
            const reqQuery = Object.entries({
                ...data,
                service: process.env.REACT_APP_GAMESERVICE
            }).map((c, i) => `${i > 0 ? '&' : ''}${c[0]}=${c[1]}`).join('')
            useUrl = `${useUrl}?${reqQuery}`
        }

        return new Promise((resolve, reject) => {
            const abortController = new AbortController();
            const fetchData = fetch(useUrl, {
                method,
                signal: abortController.signal,
                headers: { 'Content-Type': 'application/json' },
                body: isGet ? undefined : JSON.stringify({
                    ...data,
                    service: process.env.REACT_APP_GAMESERVICE
                })
            });

            const fetchOrTimeout = Promise.race([fetchData, wait(parseInt(`${process.env.REACT_APP_FETCH_TIMEOUT}`, 10))]);

            fetchOrTimeout
                .then(async (response: any) => resolve({
                    json: await response.json(),
                    status: response.status
                }))
                .catch((error: any) => {
                    console.log('abort', error)
                    abortController.abort()
                    resolve(undefined)
                });
        })

    }

    public getAccountUrl(path: string): string {
        return `${this.appConfig.accountUrl}${path}`;
    }

    public getApiUrl(path: string): string {
        return `${this.appConfig.apiUrl}${path}`;
    }

    public getMessageList(): any {
        return createMessage(this)
    }

    // public sendMessage(type: number, data: any = {}): boolean {
    //     return sendMessage(this, type, data);
    // }

    public init() {
        this.gameLoader.startLoading(200);
    }

    public onPauseMobile() {
        this.messageHandler.onPause();
        try {
            this.appState?.currentSession?.onPause();
        } catch (e) { }
    }

    public onResumeMobile() {
        console.log('[GAMELOADER] ResumeMobile')
        this.gameLoader.forceStartLoading();
    }

    public removeFromStorage(key: string, forceLocalstorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;
        console.log('REMOVE FROM STORAGE', key, useKey)
        if (getLocalStorage(forceLocalstorage)) {
            getLocalStorage(forceLocalstorage).removeItem(btoa(useKey));
        }
    }

    // The user storage
    public async loadFromUserStorage(key: string, forceLocalstorage: boolean = false) {
        if (process.env.REACT_APP_MOBILE !== "false") {
            // go the new way
            const data: any = await readAppConfig();

            if (data) {
                let rData:any = null;
                try {
                    rData = JSON.parse(data);
                } catch (e) {
                }
                return rData;
            }

        }

        return this.loadFromStorage(key, forceLocalstorage)
    }

    public async saveToUserStorage(key: string, value: any, forceLocalstorage: boolean = false) {
        if (process.env.REACT_APP_MOBILE !== "false") {
            // go the new way
            await writeAppConfig(JSON.stringify(value));
        } else {
            this.saveInStorage(key, value, forceLocalstorage)
        }
        return true;
    }

    public loadFromStorage(key: string, forceLocalstorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;

        if (getLocalStorage(forceLocalstorage)) {
            const data = getLocalStorage(forceLocalstorage).getItem(btoa(useKey));
            if (data) {
                try {
                    if (typeof data === 'boolean') return data;
                    if (data === 'false') return false;
                    return JSON.parse(atob(data));
                } catch (e) {
                    return atob(data);
                }
            }
        }
        return undefined;
    }

    public saveInStorage(key: string, value: any, forceLocalstorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;
        console.log('SAVE', useKey)
        if (getLocalStorage(forceLocalstorage)) {
            if (typeof value === 'object') {
                getLocalStorage(forceLocalstorage).setItem(btoa(useKey), btoa(JSON.stringify(value)));
            } else if (typeof value === 'string') {
                getLocalStorage(forceLocalstorage).setItem(btoa(useKey), btoa(value));
            } else if (typeof value === 'boolean') {
                //@ts-ignore
                getLocalStorage(forceLocalstorage).setItem(btoa(useKey), value);
            }
        }
    }
}

let CGSdk: CouchgamesSdk | undefined = undefined;

const getSdk = () => {
    if (!CGSdk) {
        CGSdk = new CouchgamesSdk();
        CGSdk.init();
    }

    return CGSdk;
}

export default getSdk;
export {
    CouchgamesSdk
}