import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react';
import {
    OptionTextbox
} from '../../../controls';
import GameOverlaySaveBar from '../GameOverlaySaveBar';


const BlindSetting = observer(({
    sdk,
    onClose
}: any) => {
    const [smallBlind, setSmallBlind] = useState(sdk?.appState?.currentSession?.currentMatch?.smallBlind || 0);
    const [bigBlind, setBigBlind] = useState(sdk?.appState?.currentSession?.currentMatch?.bigBlind || 0);

    return <>
        <OptionTextbox
            id={'config-smallblind'}
            label={'checkbox.lobby.smallblind'}
            value={smallBlind}
            inputType={'number'}
            containerOption={{
                margin: [16, 0, 16, 140],
                float: 'left'
            }}
            onInput={(txt: any) => setSmallBlind(txt)}
        />
        <OptionTextbox
            id={'config-bigblind'}
            label={'checkbox.lobby.bigblind'}
            value={bigBlind}
            inputType={'number'}
            containerOption={{
                margin: [16, 0, 0, 140],
                float: 'left'
            }}
            onInput={(txt: any) => setBigBlind(txt)}
        />
        <GameOverlaySaveBar
            onSave={() => {
                if(sdk?.appState?.currentSession?.currentMatch) {
                    sdk.appState.currentSession.currentMatch.adminSaveBlinds(smallBlind, bigBlind);
                }
                onClose()
            }}
            onCancel={() => {
                onClose()
            }}
            containerOption={{
                margin: [300, 0, 0, 0]
            }}
        />
    </>
});

export default BlindSetting;