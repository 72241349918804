import {
    observer
} from 'mobx-react-lite';
import {
    getCardSource,
    getCardBack
} from '../../../../utils/cardhelper';
import {
    ImageComponent
} from '../../../controls';

const CommunityCard = observer(({
    round,
    dealerCard,
    gameSession,
    cardOption,
    microcard = false,
    cardWidth = 128,
    marginTop = 35,
    marginRight = 6,
    marginLeft = 0,
    deck,
    onClick = null
}: any) => {
    const cardVisible = round?.dealerHand?.length >= dealerCard;
    const cardShadowEffect = [0, 0, 8, 2, '#F8FF4A'];
    const roundMissdeal = round?.roundMissdeal || false;
    const featureRabbitHunt = !cardVisible && round?.finished && gameSession?.isRabbitHunt;
    const roundRabbitCard = round?.rabbitCards?.length === 5 ? round.rabbitCards[dealerCard - 1] : null;
    const cardMissdeal = dealerCard > 3 && !cardVisible && roundMissdeal;
    const useCardRanks = round?.rankCard || [] || undefined;
    const showRank = !!useCardRanks.find((r: any) => {
        return r.suit === round?.dealerHand?.[dealerCard - 1]?.suit &&
            r.value === round?.dealerHand?.[dealerCard - 1]?.value
    });

    if (cardVisible) {
        return <ImageComponent
            className={'slide-left'}
            // className={roundMissdeal ? 'slide-left2' : 'slide-left'}
            id={`card-${dealerCard}-${roundMissdeal ? 'miss' : 'full'}`}
            imageOption={{
                margin: [marginTop, marginRight, 0, marginLeft],
                widthPx: cardWidth,
                heightToWidth: 0.649,
                float: 'left',
                filterShadow: useCardRanks && showRank ? cardShadowEffect : undefined,
                opacity: useCardRanks?.length && !showRank ? 0.6 : undefined,
                zIndex: 15,
                position: 'relative',
                ...(cardOption)
            }}
            onClick={() => onClick ? onClick() : null}
            source={getCardSource(round.dealerHand[dealerCard - 1].suit, round.dealerHand[dealerCard - 1].value, deck, microcard)}
        />;
    }

    if (featureRabbitHunt) {
        return <ImageComponent
            imageOption={{
                margin: [marginTop, marginRight, 0, marginLeft],
                widthPx: cardWidth,
                heightToWidth: 0.649,
                float: 'left',
                opacity: 0.6,
                zIndex: 14,
                position: 'relative',
                ...(cardOption)
            }}
            onClick={() => {
                if(!roundRabbitCard) {
                    if(dealerCard <= 3) {
                        gameSession?.rabbitHunt(1)
                    } else if(dealerCard <= 4) {
                        gameSession?.rabbitHunt(2)
                    } else {
                        gameSession?.rabbitHunt(3)
                    }
                }
            }}
            source={roundRabbitCard ? 
                getCardSource(roundRabbitCard.suit, roundRabbitCard.value, deck, microcard):
                getCardBack()}
        />
    }

    return cardMissdeal ?
        <ImageComponent
            imageOption={{
                margin: [marginTop, marginRight, 0, marginLeft],
                widthPx: cardWidth,
                heightToWidth: 0.649,
                float: 'left',
                opacity: 1.0,
                zIndex: 14,
                position: 'relative',
                ...(cardOption)
            }}
            onClick={() => onClick ? onClick() : null}
            source={getCardBack()}
        />
        : null;
})

export default CommunityCard;