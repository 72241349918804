import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    Icon
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper'

const TutorialDragAndDrop = observer(({
    gameSession,
    appState
}: any) => {
    const visible = gameSession?.ui?.buttonMulti === 2 && appState?.tutorialShow('dragchip');

    return visible ? <>
        <Container
            containerOption={{
                position: 'absolute',
                widthPx: 280,
                textAlign: 'center',
                bottom: 300,
                right: 0,
                fontSize: 28,
                zIndex: 80,
                color: '#fff',
                fontWeight: 'bold'
            }}
        >
           {translateKey('tutorial.dragndrop')}
        </Container>
        <Icon
            iconOption={{
                position: 'absolute',
                bottom: 128,
                right: 128,
                widthPx: 64,
                heightPx: 64,
                zIndex: 80
            }}
            source={`./data/ui/tap.png`}
            // source={`./data/icon/point.svg`}
            className={'tutorialMoveChip'}
        />
    </> : null;
})

export default TutorialDragAndDrop;

