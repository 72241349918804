
import {
    observer
} from 'mobx-react-lite';
import {
    List,
    Header,
    Checkbox,
    OptionTextbox,
    RadioButton,
    TextField,
    InputSlider,
    Label,
    Icon,
    Container,
    IconButton
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper'

const LobbyCardSelection = observer(({
    sdk,
    item,
    lobbyStore
}: any) => {
    return <>

        <RadioButton
            id={`tableasset-deck-${item.id}`}
            // label={item.data[4]}
            fixSelected={lobbyStore.globalDeck === item.id}
            containerOption={{
                float: 'left',
                margin: [8, 0, 0, 32],
                lineHeight: 48,
                heightPx: 48
            }}
            buttonOption={{
                widthPx: 32,
                heightPx: 32,
                margin: [8, 0, 0, 0]
            }}
            labelOption={{
                fontSize: 22
            }}
        >
            <Icon
                source={item.icon}
                iconOption={{
                    float: 'left',
                    widthPx: 180,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
            />
        </RadioButton>
    </>
});

const CheckboxWithHelp = observer(({
    sdk,
    store,
    id,
    storeProperty,
    label,
    widthIncrease,
    value,
    tutorial,
    disabled
}: any) => {
    return <>
        <Checkbox
            id={id}
            value={value}
            store={store}
            disableClick={disabled}
            storeProperty={storeProperty}
            label={label}
            containerOption={{
                float: 'left',
                widthPx: 440 + (widthIncrease || 0)
            }}
            labelOption={{
                widthPx: 310
            }}
        >
            <IconButton
                source={'./data/icon/help1.svg'}
                buttonOption={{
                    widthPx: 48,
                    heightPx: 48,
                    float: 'left',
                    margin: [0, 0, 0, 0]
                }}
                onClick={() => sdk.appState.showTutorialBox(tutorial)}
            />
        </Checkbox>
    </>
})

const LobbyMenuSettings = observer(({
    sdk
}: any) => {
    const useStyles = sdk.layoutManager.styles
    const propsSubContainer = useStyles({
        width: '100%',
        float: 'left'
    })
    const lobbyStore = sdk.appState.currentSession.roomLobby;
    const isPremium = !!sdk.appState.user.premium;
    const blindDisabled = lobbyStore.flagDisableBlinds === 1 && lobbyStore.isDigitalCardMode;

    return <div
        id={'lobbyarea-roomsetting'}
        style={useStyles({
            widthPx: 980,
            heightPx: 412,
            overflowY: 'auto',
            overflowX: 'hidden',
            margin: [0, 0, 0, 14]
        })}
    >
        <div style={propsSubContainer}>
            <div
                style={useStyles({
                    widthPx: 952,
                    heightPx: lobbyStore.dealerModeAllowed ? 120 : 80,
                    float: 'left',
                    margin: [16, 16, 0, 0]
                })}
            >
                <CheckboxWithHelp
                    sdk={sdk}
                    id={'room-private'}
                    store={lobbyStore?.isPublicAllowed ? lobbyStore : null}
                    storeProperty={'flagPrivate'}
                    label={'checkbox.lobby.private'}
                    widthIncrease={340}
                    tutorial={'tutorial.privategame'}
                    value={1}
                />
                {lobbyStore.dealerModeAllowed ? (
                    <>
                        <CheckboxWithHelp
                            sdk={sdk}
                            id={'room-dealer'}
                            store={lobbyStore}
                            storeProperty={'flagDealerMode'}
                            label={'checkbox.lobby.flagIamDealer'}
                            // widthIncrease={340}
                            tutorial={'tutorial.iamDealer'}
                            disabled={sdk.appState.currentSession?.sessionRunning}
                        />
                        {lobbyStore?.flagDealerMode === 1 ? (
                            <>
                                <Container
                                    containerOption={{
                                        widthPx: 160,
                                        heightPx: 48,
                                        lineHeight: 48,
                                        float: 'left',
                                        color: '#c1c1c1',
                                        fontSize: 20,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {translateKey('text.lobby.dealer.authcode')}
                                </Container>
                                <TextField
                                    id={`room-dealer-pin`}
                                    store={lobbyStore}
                                    inputType={'number'}
                                    maxLength={8}
                                    inputOption={{
                                        float: 'left',
                                        widthPx: 160,
                                        margin: [0, 0, 0, 16],
                                        heightPx: 20,
                                        borderSize: 1,
                                        border: 'solid #fff'
                                    }}
                                    disabled={sdk.appState.currentSession?.sessionRunning}
                                    storeProperty={'dealerModePin'}
                                    placeholder={translateKey('input.lobby.dealer.authcode')}
                                    onManipulateInput={(code: any) => {
                                        return code.replace(/[^0-9]/g, '').slice(0, 8);
                                    }}
                                />
                                <IconButton
                                    source={'./data/icon/help1.svg'}
                                    buttonOption={{
                                        widthPx: 48,
                                        heightPx: 48,
                                        float: 'left',
                                        margin: [0, 0, 0, 16]
                                    }}
                                    onClick={() => sdk.appState.showTutorialBox('tutorial.feature.dealerauthcode')}
                                />
                            </>
                        ) : null}

                    </>
                ) : null}

            </div>
        </div>
        {!lobbyStore.privateGame ? (
            <>
                <Header
                    id={'head-gamesetting'}
                    label={'text.lobby.head.publicsetting'}
                    headOption={{
                        widthPx: 640
                    }}
                />
                <div style={propsSubContainer}>
                    <div
                        style={useStyles({
                            widthPx: 952,
                            heightPx: 80,
                            float: 'left',
                            margin: [16, 16, 0, 0]
                        })}
                    >
                        <CheckboxWithHelp
                            id={'feature-flagBigTable'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagBigTable'}
                            label={'checkbox.lobby.bigtable'}
                            tutorial={'tutorial.feature.bigtable'}
                        />
                    </div>
                    <div
                        style={useStyles({
                            widthPx: 952,
                            heightPx: 180,
                            float: 'left',
                            margin: [0, 16, 0, 16]
                        })}
                    >
                        <Label
                            labelOption={{
                                widthPx: 800
                            }}
                            value={'Buyin'}
                        />
                        <InputSlider
                            sliderMin={0}
                            sliderMax={sdk.appPublic.buyIn.length - 1}
                            store={lobbyStore}
                            storeProperty={'pokerPublicBuyin'}
                        />
                        <Label
                            labelOption={{
                                widthPx: 800
                            }}
                            value={sdk.appPublic.valueString(lobbyStore.pokerPublicBuyin)}
                        />
                    </div>

                </div>
            </>
        ) : null}
        {lobbyStore.privateGame ? (
            <>
                {!isPremium ? (
                    <div style={propsSubContainer}>
                        <div
                            style={useStyles({
                                widthPx: 900,
                                heightPx: 48,
                                lineHeight: 48,
                                float: 'left',
                                margin: [0, 16, 0, 16],
                                borderSize: 3,
                                whiteSpace: 'nowrap',
                                fontSize: 20,
                                fontWeight: 'bold',
                                border: 'dotted #fff',
                                bgColor: '#69140a',

                            })}
                            onClick={() => sdk.appState.showMessagebox(
                                'vipinfo',
                                'tutorial.30.minutes.limitation',
                                () => null,
                                null,
                                true,
                                [],
                                false,
                                {
                                    fontSize: 22,
                                    openLocation: 'session'
                                },
                                'expire'
                            )}
                        >
                            <Icon
                                source={'./data/icon/vip.svg'}
                                iconOption={{
                                    float: 'left',
                                    widthPx: 40,
                                    heightPx: 40,
                                    margin: [4, 4, 0, 4]
                                }}
                            ></Icon>
                            <div dangerouslySetInnerHTML={{
                                __html: translateKey('30.minutes.limitation')
                            }}></div>
                        </div>
                    </div>
                ) : null}
                <Header
                    id={'head-gamesetting'}
                    label={'text.lobby.head.gamesetting'}
                    headOption={{
                        widthPx: 640
                    }}
                />
                <div style={propsSubContainer}>
                    <div
                        id={'content-gamesetting-left'}
                        style={useStyles({
                            widthPx: 460,
                            heightPx: 240,
                            fontSize: 28,
                            float: 'left',
                            margin: [16, 4, 0, 4],
                        })}
                    >
                        <CheckboxWithHelp
                            id={'feature-flagRandomSeat'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagRandomSeat'}
                            label={'checkbox.lobby.randomseat'}
                            tutorial={'tutorial.feature.randomseatorder'}
                        />
                        <CheckboxWithHelp
                            id={'feature-flagAllowAfterJoin'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagAllowAfterJoin'}
                            label={'checkbox.lobby.flagallowafterjoin'}
                            tutorial={'tutorial.feature.flagAllowAfterJoin'}
                        />
                        {lobbyStore.isDigitalCardMode ? (
                            <CheckboxWithHelp
                                id={'feature-flagWinDetection'}
                                sdk={sdk}
                                store={lobbyStore}
                                storeProperty={'flagWinDetection'}
                                label={'checkbox.lobby.flagWinDetection'}
                                tutorial={'tutorial.feature.whowins'}
                            />
                        ) : null}
                        {!lobbyStore.isDigitalCardMode ? (
                            <CheckboxWithHelp
                                id={'feature-flagDefaultShowHand'}
                                sdk={sdk}
                                store={lobbyStore}
                                storeProperty={'flagDefaultShowHand'}
                                label={'checkbox.lobby.flagDefaultShowHand'}
                                tutorial={'tutorial.feature.flagDefaultShowHand'}
                            />
                        ) : null}
                        {lobbyStore.isDigitalCardMode ? (
                            <CheckboxWithHelp
                                id={'feature-flagUiTable'}
                                sdk={sdk}
                                store={lobbyStore}
                                storeProperty={'flagUiTable'}
                                label={'checkbox.lobby.flagUiTable'}
                                tutorial={'tutorial.feature.flagUiTable'}
                            />
                        ) : null}
                    </div>
                    <div
                        id={'content-gamesetting-right'}
                        style={useStyles({
                            widthPx: 460,
                            heightPx: 200,
                            fontSize: 28,
                            float: 'left',
                            margin: [16, 0, 0, 4]
                        })}
                    >
                        <CheckboxWithHelp
                            id={'feature-flagAllowShowFold'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagAllowShowFold'}
                            label={'checkbox.lobby.flagAllowShowFold'}
                            tutorial={'tutorial.feature.whathadi'}
                        />
                        <CheckboxWithHelp
                            id={'feature-flagRabbitCards'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagRabbitCards'}
                            label={'checkbox.lobby.flagRabbitCards'}
                            tutorial={'tutorial.feature.flagRabbitCards'}
                        />
                        <CheckboxWithHelp
                            id={'feature-flagAutoRevealAll'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagAutoRevealAll'}
                            label={'checkbox.lobby.flagAutoRevealAll'}
                            tutorial={'tutorial.feature.revealall'}
                        />
                        {(!lobbyStore.flagDealerMode && !lobbyStore.isDigitalCardMode) ? (
                            <CheckboxWithHelp
                                id={'feature-flagAutoAllInShowdown'}
                                sdk={sdk}
                                store={lobbyStore}
                                storeProperty={'flagAutoAllInShowdown'}
                                label={'checkbox.lobby.flagAutoAllInShowdown'}
                                tutorial={'tutorial.feature.flagAutoAllInShowdown'}
                            />
                        ) : null}
                        {(lobbyStore.isDigitalCardMode) ? (
                            <CheckboxWithHelp
                                id={'feature-flagNextPointer'}
                                sdk={sdk}
                                store={lobbyStore}
                                storeProperty={'flagNextPointer'}
                                label={'checkbox.lobby.flagNextPointer'}
                                tutorial={'tutorial.feature.flagNextPointer'}
                            />
                        ) : null}
                    </div>
                    {lobbyStore.supportAutostart ? (
                        <div
                            style={useStyles({
                                widthPx: 952,
                                heightPx: 180,
                                float: 'left',
                                margin: [0, 16, 0, 16]
                            })}
                        >
                            <Label
                                labelOption={{
                                    widthPx: 800
                                }}
                                value={translateKey('text.lobby.autostart.title')}
                            />
                            <InputSlider
                                sliderMin={0}
                                sliderMax={30}
                                store={lobbyStore}
                                storeProperty={'autoStart'}
                            />
                            <Label
                                labelOption={{
                                    widthPx: 800
                                }}
                                value={lobbyStore.autoStartValue}
                            />
                        </div>
                    ) : null}

                    <div
                        style={useStyles({
                            widthPx: 952,
                            heightPx: 64,
                            float: 'left',
                            margin: [32, 16, 32, 0]
                        })}
                    >
                        <CheckboxWithHelp
                            id={'feature-flagWaitingArea'}
                            sdk={sdk}
                            store={lobbyStore}
                            storeProperty={'flagWaitingArea'}
                            label={'checkbox.lobby.flagWaitingArea'}
                            tutorial={'tutorial.feature.flagWaitingArea'}
                            disabled={sdk.appState.currentSession?.sessionRunning}
                        />
                    </div>

                </div>
                {lobbyStore.supportBuyin ?
                    (
                        <>
                            <Header
                                id={'head-buyinsetting'}
                                label={'text.lobby.head.buyinsetting'}
                                headOption={{
                                    widthPx: 640
                                }}
                            />
                            <div style={propsSubContainer}>
                                <div
                                    style={useStyles({
                                        widthPx: 952,
                                        heightPx: lobbyStore.flagDisableBuyIn ? 200 : 312,
                                        float: 'left',
                                        margin: [16, 16, 0, 0]
                                    })}
                                >

                                    <CheckboxWithHelp
                                        id={'feat-buyinrange'}
                                        sdk={sdk}
                                        widthIncrease={300}
                                        store={lobbyStore}
                                        storeProperty={'buyInRange'}
                                        label={'checkbox.lobby.buyinrange'}
                                        tutorial={'tutorial.feature.buyinrange'}
                                    />
                                    <CheckboxWithHelp
                                        id={'feat-buyinconfirm'}
                                        sdk={sdk}
                                        widthIncrease={300}
                                        store={lobbyStore}
                                        storeProperty={'buyInConfirm'}
                                        label={'checkbox.lobby.buyinconfirm'}
                                        tutorial={'tutorial.feature.buyinconfirm'}
                                    />
                                    {lobbyStore?.buyInRange ? (
                                        <>
                                            <OptionTextbox
                                                id={'config-buyinmin'}
                                                label={'checkbox.lobby.buyinmin'}
                                                store={lobbyStore}
                                                storeProperty={'buyInMin'}
                                                containerOption={{
                                                    margin: [16, 0, 0, 0],
                                                    float: 'left'
                                                }}
                                            />
                                            <OptionTextbox
                                                id={'config-buyinmax'}
                                                label={'checkbox.lobby.buyinmax'}
                                                store={lobbyStore}
                                                storeProperty={'buyInMax'}
                                                containerOption={{
                                                    margin: [16, 0, 0, 32],
                                                    float: 'left'
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <OptionTextbox
                                            id={'config-buyintotal'}
                                            label={'checkbox.lobby.buyintotal'}
                                            store={lobbyStore}
                                            storeProperty={'buyInTotal'}
                                            containerOption={{
                                                margin: [16, 0, 0, 0],
                                                float: 'left'
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    ) : <div style={propsSubContainer}>
                        <div
                            style={useStyles({
                                widthPx: 952,
                                heightPx: 120,
                                float: 'left',
                                margin: [16, 16, 0, 0]
                            })}
                        >
                            {!lobbyStore.flagDealerMode ? (
                                <CheckboxWithHelp
                                    id={'feat-buyinconfirm'}
                                    sdk={sdk}
                                    widthIncrease={300}
                                    store={lobbyStore}
                                    storeProperty={'buyInConfirm'}
                                    label={'checkbox.lobby.sitdownconfirm'}
                                    tutorial={'tutorial.feature.sitdownconfirm'}
                                />) : null}
                            <CheckboxWithHelp
                                id={'feat-blindnotice'}
                                sdk={sdk}
                                widthIncrease={300}
                                store={lobbyStore}
                                storeProperty={'flagShowBlindNotice'}
                                label={'checkbox.lobby.flagShowBlindNotice'}
                                tutorial={'tutorial.feature.flagShowBlindNotice'}
                            />
                        </div>
                    </div>
                }
                <Header
                    id={'head-assetsettings'}
                    label={'text.lobby.head.assetsetting'}
                    headOption={{
                        widthPx: 640
                    }}
                />
                <div style={propsSubContainer}>
                    <div
                        style={useStyles({
                            widthPx: 952,
                            heightPx: lobbyStore.flagGlobalDeck === 1 ? 256 : 128,
                            float: 'left',
                            margin: [16, 16, 0, 0]
                        })}
                    >
                        <CheckboxWithHelp
                            id={'feature-flagForceDeck'}
                            sdk={sdk}
                            store={lobbyStore}
                            widthIncrease={360}
                            storeProperty={'flagGlobalDeck'}
                            label={'checkbox.lobby.tableForceDeck'}
                            tutorial={'tutorial.feature.flagGlobalDeck'}
                        />
                        {lobbyStore.flagGlobalDeck === 1 ? <Container
                            containerOption={{
                                heightPx: 160,
                                overflowY: 'auto',
                                float: 'left'
                            }}>
                            <List
                                vertical={true}
                                clickIds={sdk.appState.user.deckList.filter((item: any) => item.selectable).map((item: any) => item.id)}
                                components={sdk.appState.user.deckList.filter((item: any) => item.selectable).map((item: any) => <LobbyCardSelection sdk={sdk} lobbyStore={lobbyStore} item={item} />)}
                                containerOption={{}}
                                listOption={{
                                    heightPx: 80,
                                    lineHeight: 64,
                                    widthPx: 420,
                                    float: 'left',
                                    margin: [0, 16, 0, 16]
                                }}
                                onClick={(itemName: any) => {
                                    lobbyStore.updateValue('globalDeck', itemName)
                                }}
                            />
                        </Container> : null}
                    </div>
                </div>
                <Header
                    id={'head-blindsetting'}
                    label={'text.lobby.head.blindsetting'}
                    headOption={{
                        widthPx: 640
                    }}
                />
                <div style={propsSubContainer}>
                    <div
                        style={useStyles({
                            widthPx: 952,
                            heightPx: 468,
                            float: 'left',
                            margin: [16, 16, 0, 0]
                        })}
                    >
                        {lobbyStore.isDigitalCardMode ? (
                            <CheckboxWithHelp
                                id={'feature-flagDisableBlinds'}
                                sdk={sdk}
                                store={lobbyStore}
                                widthIncrease={340}
                                storeProperty={'flagDisableBlinds'}
                                label={'checkbox.lobby.flagDisableBlinds'}
                                tutorial={'tutorial.feature.flagDisableBlinds'}
                            />
                        ) : null}
                        {!blindDisabled ? (
                            <CheckboxWithHelp
                                id={'feature-pokerclock'}
                                sdk={sdk}
                                store={lobbyStore}
                                widthIncrease={340}
                                storeProperty={'pokerClock'}
                                label={'checkbox.lobby.pokerblindraise'}
                                tutorial={'tutorial.feature.pokerclock'}
                            />
                        ) : null}
                        {blindDisabled ?
                            null :
                            (
                                lobbyStore.pokerClock ?
                                    <>
                                        <div
                                            style={useStyles({
                                                widthPx: 192,
                                                heightPx: 64,
                                                fontColor: '#fff',
                                                lineHeight: 64,
                                                fontSize: 24,
                                                fontWeight: 'bold',
                                                float: 'left'
                                            })}
                                        >
                                            {translateKey('text.lobby.timeunit')}
                                        </div>
                                        <RadioButton
                                            id={'pokerclock-unit-min'}
                                            label={'text.lobby.timeunit.minutes'}
                                            value={1}
                                            store={lobbyStore}
                                            storeProperty={'pokerClockUnit'}
                                            containerOption={{
                                                float: 'left'
                                            }}
                                        />
                                        <RadioButton
                                            id={'pockerclock-unit-round'}
                                            label={'text.lobby.timeunit.rounds'}
                                            value={0}
                                            store={lobbyStore}
                                            storeProperty={'pokerClockUnit'}
                                            containerOption={{
                                                float: 'left'
                                            }}
                                        />
                                        <List
                                            id={'pokerclock-table'}
                                            components={[
                                                ...Array(lobbyStore.pokerClockLevel).fill('0').map((level: any, lvl: number) => {
                                                    return <>
                                                        <div
                                                            style={useStyles({
                                                                widthPx: 80,
                                                                heightPx: 80,
                                                                fontColor: '#fff',
                                                                lineHeight: 80,
                                                                fontSize: 20,
                                                                fontWeight: 'bold',
                                                                float: 'left'
                                                            })}
                                                        >
                                                            {translateKey('text.lobby.blindraise.level', {}, [
                                                                ['$level', `${(lvl + 1)}`]
                                                            ])}
                                                        </div>
                                                        <TextField
                                                            id={`pokerclock-sb-${lvl}`}
                                                            store={lobbyStore}
                                                            inputType={'number'}
                                                            inputOption={{
                                                                widthPx: 160,
                                                                margin: [0, 16, 0, 0],
                                                                heightPx: 24,
                                                                borderSize: 1,
                                                                border: 'solid #fff'
                                                            }}
                                                            storeProperty={['pokerClockSmallBlind', lvl]}
                                                            placeholder={translateKey('input.lobby.blindraise.placeholder.smallblind')}
                                                        />
                                                        <TextField
                                                            id={`pokerclock-bb-${lvl}`}
                                                            inputType={'number'}
                                                            inputOption={{
                                                                widthPx: 160,
                                                                margin: [0, 16, 0, 0],
                                                                heightPx: 24,
                                                                borderSize: 1,
                                                                border: 'solid #fff'
                                                            }}
                                                            store={lobbyStore}
                                                            storeProperty={['pokerClockBigBlind', lvl]}
                                                            placeholder={translateKey('input.lobby.blindraise.placeholder.bigblind')}
                                                        />
                                                        {lvl !== lobbyStore.pokerClockLevel - 1 ? (
                                                            <TextField
                                                                id={`pokerclock-time-${lvl}`}
                                                                inputType={'number'}
                                                                inputOption={{
                                                                    widthPx: 160,
                                                                    margin: [0, 16, 0, 0],
                                                                    heightPx: 24,
                                                                    borderSize: 1,
                                                                    border: 'solid #fff'
                                                                }}
                                                                store={lobbyStore}
                                                                storeProperty={['pokerClockTime', lvl]}
                                                                placeholder={translateKey(lobbyStore.clockUnitMinute ? 'text.lobby.timeunit.minutes' : 'text.lobby.timeunit.rounds')}
                                                            />
                                                        ) : null}
                                                    </>
                                                }),
                                                <>
                                                    <button
                                                        id={`pokerclock-addlevel`}
                                                        className={'mainbutton_green_noborder'}
                                                        style={useStyles({
                                                            widthPx: 192,
                                                            textAlign: 'center',
                                                            padding: [16, 12, 16, 12],
                                                            fontSize: 32,
                                                            fontWeight: 'bold',
                                                            lineHeight: 32,
                                                            borderRadius: 8,
                                                            whiteSpace: 'nowrap'
                                                        })}
                                                        disabled={lobbyStore.pokerClockLevel > 14}
                                                        onClick={() => lobbyStore.updateValue('pokerClockLevel', lobbyStore.pokerClockLevel + 1)}
                                                    >
                                                        {translateKey('button.lobby.pokerclock.add')}
                                                    </button>
                                                    <button
                                                        id={`pokerclock-dellevel`}
                                                        className={'mainbutton_red'}
                                                        style={useStyles({
                                                            widthPx: 192,
                                                            textAlign: 'center',
                                                            padding: [16, 12, 16, 12],
                                                            margin: [0, 0, 0, 16],
                                                            fontSize: 32,
                                                            fontWeight: 'bold',
                                                            lineHeight: 32,
                                                            borderRadius: 8,
                                                            whiteSpace: 'nowrap'
                                                        })}
                                                        disabled={lobbyStore.pokerClockLevel <= 1}
                                                        onClick={() => lobbyStore.updateValue('pokerClockLevel', lobbyStore.pokerClockLevel - 1)}
                                                    >
                                                        {translateKey('button.lobby.pokerclock.delete')}
                                                    </button>
                                                </>
                                            ]}
                                            containerOption={{
                                                className: 'settinglist',
                                                widthPx: 940,
                                                heightPx: (lobbyStore.pokerClockLevel + 1) * 80,
                                                margin: [0, 0, 0, 20],
                                                padding: [0, 0, 0, 0],
                                                float: 'left',
                                                overflowX: 'hidden',
                                                height: '100%'
                                            }}
                                            listOption={{
                                                position: 'relative',
                                                widthPx: 960,
                                                heightPx: 80,
                                                lineHeight: 80,
                                                border: 10,
                                                color: '#fff',
                                                fontSize: 28
                                            }}
                                        />
                                    </>
                                    : (
                                        <>
                                            <OptionTextbox
                                                id={'config-smallblind'}
                                                label={'checkbox.lobby.smallblind'}
                                                store={lobbyStore}
                                                storeProperty={'smallBlind'}
                                                containerOption={{
                                                    margin: [16, 0, 0, 0],
                                                    float: 'left'
                                                }}
                                            />
                                            <OptionTextbox
                                                id={'config-bigblind'}
                                                label={'checkbox.lobby.bigblind'}
                                                store={lobbyStore}
                                                storeProperty={'bigBlind'}
                                                containerOption={{
                                                    margin: [16, 0, 0, 32],
                                                    float: 'left'
                                                }}
                                            />
                                        </>
                                    )
                            )}

                    </div>
                </div>
            </>
        ) : null}

    </div>
});

export default LobbyMenuSettings;