import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import getSdk from '..';
import {
    GAMEMODE
} from '../../constants/gamemode';
import {
    numberInvalid,
    translateKey,
    generatePin
} from '../../utils/helper';

export default class LobbyState {

    public currentTab: number; // The current Tab
    public tabModeDisabled: number;
    public mode: number;
    public defaultMode: number;

    public gameMode: number; // Cashgame = 0 , Tournament = 1

    public flagPrivateDefault: number;
    public flagPokerClock: number;
    public flagRandomSeat: number;
    public flagAllowAfterJoin: number;
    public flagWinDetection: number;
    public flagAutoRevealAll: number;
    public flagAllowShowFold: number;
    public flagAutoAllInShowdown: number;
    public flagDisableBlinds: number;
    public flagDisableBuyIn: number;
    public flagShowBlindNotice: number;
    public flagPrivate: number;
    public flagBigTable: number;
    public flagDefaultShowHand: number;
    public flagRabbitCards: number;
    public flagNextPointer: number; // Hybrid Only -> NextFeature
    public flagUiTable: number; // Hybrid Only -> Hide Ui Table

    public flagDealerMode: number;
    public dealerModePin: string;

    public flagGlobalDeck: number;

    public autoStart: number;
    public flagWaitingArea: number;

    public buyInRange: boolean;
    public buyInConfirm: boolean;
    public buyInTotal: number;
    public buyInMin: number;
    public buyInMax: number;

    public pokerClock: number;
    public pokerClockUnit: number;
    public pokerClockLevel: number;
    public pokerClockSmallBlind: Array<number>;
    public pokerClockBigBlind: Array<number>;
    public pokerClockTime: Array<number>;
    public pokerPublicBuyin: number;

    public globalDeck: string | undefined;

    public smallBlind: number;
    public bigBlind: number;
    public aiPlayer: number;

    public rebuy: number;

    constructor(privateDefault: number = 0, defaultMode: number = GAMEMODE.NOT_SELECTED) {
        makeObservable(this, {
            currentTab: observable,
            tabModeDisabled: observable,
            mode: observable,
            flagRandomSeat: observable,
            flagAllowAfterJoin: observable,
            flagDealerMode: observable,
            dealerModePin: observable,
            flagGlobalDeck: observable,
            flagWaitingArea: observable,
            flagRabbitCards: observable,
            defaultMode: observable,
            flagWinDetection: observable,
            flagUiTable: observable,
            flagBigTable: observable,
            flagShowBlindNotice: observable,
            flagAutoRevealAll: observable,
            flagDefaultShowHand: observable,
            autoStart: observable,
            globalDeck: observable,
            flagNextPointer: observable,
            flagAutoAllInShowdown: observable,
            flagDisableBlinds: observable,
            flagDisableBuyIn: observable,
            flagPrivateDefault: observable,
            flagAllowShowFold: observable,
            flagPrivate: observable,
            pokerClock: observable,
            pokerClockUnit: observable,
            pokerClockLevel: observable,
            gameMode: observable,
            smallBlind: observable,
            bigBlind: observable,
            rebuy: observable,
            pokerPublicBuyin: observable,
            pokerClockSmallBlind: observable,
            pokerClockBigBlind: observable,
            pokerClockTime: observable,
            buyInRange: observable,
            buyInConfirm: observable,
            buyInMin: observable,
            buyInMax: observable,
            buyInTotal: observable,
            updateValue: action,
            loadFromCache: action,
            reset: action,
            updateArrayValue: action,
            setMode: action,
            isFullDigitalMode: computed,
            supportAutostart: computed,
            supportBuyin: computed,
            isNoModeSelected: computed,
            isDigitalCardMode: computed,
            dealerModeAllowed: computed,
            clockUnitMinute: computed,
            autoStartValue: computed,
            validate: computed
        });
        this.currentTab = defaultMode === GAMEMODE.MODE_100_DIGITAL_ALL ? 1 : 0;
        this.tabModeDisabled = defaultMode === GAMEMODE.MODE_100_DIGITAL_ALL ? 1 : 0;
        this.flagPokerClock = 0;
        this.flagPrivateDefault = privateDefault;
        this.flagPrivate = privateDefault;
        this.flagRandomSeat = 1;
        this.flagShowBlindNotice = 1;
        this.flagAllowAfterJoin = 1;
        this.flagWaitingArea = 0;
        this.flagRabbitCards = 0;
        this.flagUiTable = 1;
        this.flagDefaultShowHand = 1;
        this.flagWinDetection = 1;
        this.flagAutoRevealAll = 0;
        this.flagNextPointer = 1;
        this.dealerModePin = generatePin(4);
        this.flagDealerMode = 0;
        this.autoStart = 5;
        this.flagAllowShowFold = 0;
        this.flagAutoAllInShowdown = defaultMode === GAMEMODE.MODE_100_DIGITAL_ALL ? 1 : 0;
        this.flagDisableBlinds = 0;
        this.flagDisableBuyIn = 0;
        this.pokerPublicBuyin = 0;
        this.flagBigTable = 0;
        this.smallBlind = 25;
        this.bigBlind = 50;
        this.rebuy = 500;
        this.gameMode = 0;
        this.aiPlayer = 0;
        this.buyInRange = false;
        this.flagGlobalDeck = 0;
        this.buyInConfirm = false;
        this.buyInMin = 500;
        this.buyInTotal = 500;
        this.buyInMax = 1000;
        this.globalDeck = 'poker.deck.2';
        this.defaultMode = defaultMode;
        this.mode = defaultMode;
        this.pokerClock = 0;
        this.pokerClockUnit = 1;
        this.pokerClockLevel = 1;
        this.pokerClockSmallBlind = Array(15).fill('');
        this.pokerClockBigBlind = Array(15).fill('');
        this.pokerClockTime = Array(15).fill('');
    }

    saveInCache(code: string | undefined): void {
        if (code) {
            getSdk().saveInStorage('lobby', {
                roomCode: code,
                currentTab: this.currentTab,
                tabModeDisabled: this.tabModeDisabled,
                mode: this.mode,
                defaultMode: this.defaultMode,
                gameMode: this.gameMode,
                flagPrivateDefault: this.flagPrivateDefault,
                flagPokerClock: this.flagPokerClock,
                flagRabbitCards: this.flagRabbitCards,
                flagRandomSeat: this.flagRandomSeat,
                flagAllowAfterJoin: this.flagAllowAfterJoin,
                flagWinDetection: this.flagWinDetection,
                flagAutoRevealAll: this.flagAutoRevealAll,
                flagAllowShowFold: this.flagAllowShowFold,
                flagNextPointer: this.flagNextPointer,
                flagUiTable: this.flagUiTable,
                flagDefaultShowHand: this.flagDefaultShowHand,
                flagAutoAllInShowdown: this.flagAutoAllInShowdown,
                flagDisableBlinds: this.flagDisableBlinds,
                flagDisableBuyIn: this.flagDisableBuyIn,
                flagShowBlindNotice: this.flagShowBlindNotice,
                flagPrivate: this.flagPrivate,
                flagBigTable: this.flagBigTable,
                flagDealerMode: this.flagDealerMode,
                dealerModePin: this.dealerModePin,
                flagGlobalDeck: this.flagGlobalDeck,
                autoStart: this.autoStart,
                flagWaitingArea: this.flagWaitingArea,
                buyInRange: this.buyInRange,
                buyInConfirm: this.buyInConfirm,
                buyInTotal: this.buyInTotal,
                buyInMin: this.buyInMin,
                buyInMax: this.buyInMax,
                pokerClock: this.pokerClock,
                pokerClockUnit: this.pokerClockUnit,
                pokerClockLevel: this.pokerClockLevel,
                pokerClockSmallBlind: this.pokerClockSmallBlind,
                pokerClockBigBlind: this.pokerClockBigBlind,
                pokerClockTime: this.pokerClockTime,
                pokerPublicBuyin: this.pokerPublicBuyin,
                globalDeck: this.globalDeck,
                smallBlind: this.smallBlind,
                bigBlind: this.bigBlind,
                aiPlayer: this.aiPlayer,
                rebuy: this.rebuy
            });
        }
    }

    loadFromCache(code: string): void {
        const readLobbyCache = getSdk().loadFromStorage('lobby');

        if (readLobbyCache?.roomCode === code) {
            this.currentTab = readLobbyCache.currentTab;
            this.tabModeDisabled = readLobbyCache.tabModeDisabled;
            this.mode = readLobbyCache.mode;
            this.defaultMode = readLobbyCache.defaultMode;
            this.gameMode = readLobbyCache.gameMode;
            this.flagPrivateDefault = readLobbyCache.flagPrivateDefault;
            this.flagPokerClock = readLobbyCache.flagPokerClock;
            this.flagRandomSeat = readLobbyCache.flagRandomSeat;
            this.flagAllowAfterJoin = readLobbyCache.flagAllowAfterJoin;
            this.flagWinDetection = readLobbyCache.flagWinDetection;
            this.flagAutoRevealAll = readLobbyCache.flagAutoRevealAll;
            this.flagAllowShowFold = readLobbyCache.flagAllowShowFold;
            this.flagNextPointer = readLobbyCache.flagNextPointer;
            this.flagDefaultShowHand = readLobbyCache.flagDefaultShowHand;
            this.flagAutoAllInShowdown = readLobbyCache.flagAutoAllInShowdown;
            this.flagDisableBlinds = readLobbyCache.flagDisableBlinds;
            this.flagRabbitCards = readLobbyCache.flagRabbitCards;
            this.flagDisableBuyIn = readLobbyCache.flagDisableBuyIn;
            this.flagShowBlindNotice = readLobbyCache.flagShowBlindNotice;
            this.flagPrivate = readLobbyCache.flagPrivate;
            this.flagUiTable = readLobbyCache.flagUiTable;
            this.flagBigTable = readLobbyCache.flagBigTable;
            this.flagDealerMode = readLobbyCache.flagDealerMode;
            this.dealerModePin = readLobbyCache.dealerModePin;
            this.flagGlobalDeck = readLobbyCache.flagGlobalDeck;
            this.autoStart = readLobbyCache.autoStart;
            this.flagWaitingArea = readLobbyCache.flagWaitingArea;
            this.buyInRange = readLobbyCache.buyInRange;
            this.buyInConfirm = readLobbyCache.buyInConfirm;
            this.buyInTotal = readLobbyCache.buyInTotal;
            this.buyInMin = readLobbyCache.buyInMin;
            this.buyInMax = readLobbyCache.buyInMax;
            this.pokerClock = readLobbyCache.pokerClock;
            this.pokerClockUnit = readLobbyCache.pokerClockUnit;
            this.pokerClockLevel = readLobbyCache.pokerClockLevel;
            this.pokerClockSmallBlind = readLobbyCache.pokerClockSmallBlind;
            this.pokerClockBigBlind = readLobbyCache.pokerClockBigBlind;
            this.pokerClockTime = readLobbyCache.pokerClockTime;
            this.pokerPublicBuyin = readLobbyCache.pokerPublicBuyin;
            this.globalDeck = readLobbyCache.globalDeck;
            this.smallBlind = readLobbyCache.smallBlind;
            this.bigBlind = readLobbyCache.bigBlind;
            this.aiPlayer = readLobbyCache.aiPlayer;
            this.rebuy = readLobbyCache.rebuy;
        }
    }

    reset(useMode: any = null) {
        const is100Digital = useMode === GAMEMODE.MODE_100_DIGITAL_ALL;

        this.globalDeck = 'poker.deck.2'
        this.flagPokerClock = 0;
        this.flagPrivate = this.flagPrivateDefault;
        this.flagDealerMode = 0;
        this.dealerModePin = generatePin(4);
        this.flagRandomSeat = 1;
        this.flagDefaultShowHand = 1;
        this.flagBigTable = 0;
        this.flagUiTable = 1;
        this.flagNextPointer = 1;
        this.flagAllowAfterJoin = 1;
        this.flagWinDetection = 1;
        this.flagAutoRevealAll = 0;
        this.flagRabbitCards = 0;
        this.autoStart = 5;
        this.flagAllowShowFold = 0;
        this.flagAutoAllInShowdown = is100Digital ? 1 : 0;
        this.flagDisableBlinds = 0;
        this.flagWaitingArea = 0;
        this.flagShowBlindNotice = 1;
        this.flagDisableBuyIn = 0;
        this.pokerPublicBuyin = 0;
        this.smallBlind = 25;
        this.bigBlind = 50;
        this.rebuy = 500;
        this.gameMode = 0;
        this.aiPlayer = 0;
        this.buyInRange = false;
        this.buyInConfirm = false;
        this.buyInMin = 500;
        this.buyInTotal = 500;
        this.buyInMax = 1000;
        this.mode = this.defaultMode;
        this.pokerClock = 0;
        this.pokerClockUnit = 1;
        this.pokerClockLevel = 1;
        this.pokerClockSmallBlind = Array(15).fill('');
        this.pokerClockBigBlind = Array(15).fill('');
        this.pokerClockTime = Array(15).fill('');
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    updateArrayValue(valueName: string, valuePos: number, value: any): void {
        // @ts-ignore
        this[valueName][valuePos] = value;
    }

    /**
     * Here we will set the default options when starting a new mode
     */
    setMode(mode: number): void {
        this.reset(mode);
        this.mode = mode;
        this.currentTab = 1; // Goto the next tab
    }

    get handMode() {
        if (this.isDigitalCardMode) return 1;
        return 0
    }

    get isNoModeSelected() {
        return this.mode === GAMEMODE.NOT_SELECTED
    }
    get isFullDigitalMode() {
        return this.mode === GAMEMODE.MODE_100_DIGITAL_ALL
    }

    get isDigitalCardMode() {
        return this.mode === GAMEMODE.MODE_50_DIGITAL_CARD
    }

    get validate(): boolean {
        if (this.pokerClock === 1) {
            for (let check = 0; check < this.pokerClockLevel; check++) {
                if (numberInvalid(this.pokerClockSmallBlind[check]) || numberInvalid(this.pokerClockBigBlind[check]) || numberInvalid(this.pokerClockTime[check], check === this.pokerClockLevel - 1)) {
                    return false;
                    //@ts-ignore
                } else if (parseInt(this.pokerClockSmallBlind[check], 10) > parseInt(this.pokerClockBigBlind[check], 10)) {
                    return false;
                    //@ts-ignore
                } else if (parseInt(this.pokerClockTime[check], 10) > 99) {
                    // Max: 99 Minutes or 99 Rounds
                    return false;
                }
            }
        } else {
            if (numberInvalid(this.smallBlind) || numberInvalid(this.bigBlind)) {
                return false;
                //@ts-ignore
            } else if (parseInt(this.smallBlind, 10) > parseInt(this.bigBlind, 10)) {
                return false;
            }
        }

        if (this.isFullDigitalMode) {
            //@ts-ignore
            // if (numberInvalid(this.bankRoll) || parseInt(this.bankRoll, 10) <= 0) {
            //     return false;
            // }
        }
        if (this.buyInRange) {
            if (
                numberInvalid(this.buyInMin) ||
                numberInvalid(this.buyInMax) ||
                this.buyInMin <= 0 ||
                this.buyInMax < this.buyInMin
            ) {
                return false;
            }
        }
        // prüfe sb, bb,
        return true;
    }

    public buildBlindConfig(config: any) {
        let smallBlind = config.smallBlind;
        let bigBlind = config.bigBlind;
        let writeConfig: any = {}

        if (config?.pokerClock) {
            writeConfig = {
                increaseBlindUnit: config.pokerClockUnit,
                smallBlind: [],
                bigBlind: [],
                increaseBlind: []
            }

            for (let cBlind = 0; cBlind < config.pcLevel; cBlind++) {
                writeConfig.smallBlind.push(parseInt(config.pokerClockSmallBlind[cBlind] || '0', 10))
                writeConfig.bigBlind.push(parseInt(config.pokerClockBigBlind[cBlind] || '0', 10))
                writeConfig.increaseBlind.push(parseInt(config.pokerClockTime[cBlind] || '0', 10))
            }


        }
        return {
            smallBlind,
            bigBlind,
            ...writeConfig
        }
    }

    get autoStartValue() {
        if (this.autoStart === 0) {
            return translateKey('text.lobby.autostart.off', {});
        }

        return translateKey('text.lobby.autostart', {}, [
            ['$time', this.autoStart]
        ])
    }

    get clockUnitMinute() {
        return this.pokerClockUnit === 1;
    }

    // only allow public, when not digital card mode
    get isPublicAllowed() {
        return !this.isDigitalCardMode;
    };

    get privateGame() {
        if (this.isPublicAllowed) {
            return this.flagPrivate;
        }
        return 1;
    }

    get publicInfo() {
        if (this.flagPrivate === 0 && !this.isDigitalCardMode) {
            const usePublicData = getSdk().appPublic.buyIn[this.pokerPublicBuyin]
            return {
                maxPlayer: this.flagBigTable ? 10 : 5,
                sb: usePublicData.sb,
                bb: usePublicData.bb,
                buyinMin: usePublicData.min,
                buyinMax: usePublicData.max
            }
        }
        return undefined;
    }

    /**
     * supportAutostart
     * - not allowed in dealermode
     */
    get supportAutostart() {
        return this.isFullDigitalMode && !this.flagDealerMode;
    }

    get supportBuyin() {
        return this.isFullDigitalMode;
    }

    get dealerModeAllowed() {
        // return this.privateGame && this.isDigitalCardMode;
        return false;
    }

    // Check that windetection need to be enabled for 100% mode
    get config() {
        let buildConfig = {
            // bankRoll: this.bankRoll,
            flagSitdownVote: 0, // DEPRECATED
            flagAutoSitDown: this.flagRandomSeat,
            globalDeck: this.flagGlobalDeck === 1 ? this.globalDeck : undefined,
            paidToken: getSdk().appState.user?.data?.paidToken || undefined,
            flagAllowAfterJoin: this.flagAllowAfterJoin,
            flagAutoRevealAll: this.flagAutoRevealAll,
            flagDefaultShowHand: this.flagDefaultShowHand,
            flagAutoAllInShowdown: this.flagDealerMode ? 0 : this.flagAutoAllInShowdown,
            flagWinDetection: this.flagWinDetection,
            flagAllowShowFold: this.flagAllowShowFold,
            flagNextPointer: this.flagNextPointer,
            flagRabbitCards: this.flagRabbitCards,
            flagUiTable: this.flagUiTable,
            flagShowBlindNotice: 0,
            flagDisableBlinds: 0,
            flagDisableBuyIn: 0,
            gameMode: this.gameMode,
            handMode: this.handMode,
            autostartRound: this.flagDealerMode ? 0 : this.autoStart * 1000,
            aiPlayerMax: this.aiPlayer,
            pokerClock: this.pokerClock,
            buyIn: {
                mode: 'custom',
                confirm: this.flagDealerMode ? 1 : this.buyInConfirm,
                ...(this.isDigitalCardMode ? {
                    type: 'sitdown',
                    stack: 0
                } : {
                    type: 'buyin',
                    stack: this.buyInRange ? [
                        this.buyInMin,
                        this.buyInMax
                    ] : this.buyInTotal
                })
            }
        }

        // Check if all configflags are acceptable for the mode
        if (this.isFullDigitalMode) {
            buildConfig.flagWinDetection = 1;
        }
        // Blinds can only disabled in digital card mode
        if (this.isDigitalCardMode && this.flagDisableBlinds === 1) {
            buildConfig.flagDisableBlinds = 1;
        }

        if (this.isDigitalCardMode && this.flagShowBlindNotice === 1) {
            buildConfig.flagShowBlindNotice = 1;
        }

        // BuyIn can only disabled in digital card mode
        // if (this.isDigitalCardMode && this.flagDisableBuyIn === 1) {
        // buildConfig.flagDisableBuyIn = 1;
        // }

        if (!this.supportAutostart) {
            buildConfig.autostartRound = 0;
        }

        // Build the blinds
        return {
            ...buildConfig,
            ...this.buildBlindConfig({
                pokerClock: this.pokerClock,
                smallBlind: this.smallBlind,
                bigBlind: this.bigBlind,
                pokerClockUnit: this.pokerClockUnit,
                pcLevel: this.pokerClockLevel,
                pokerClockSmallBlind: this.pokerClockSmallBlind,
                pokerClockBigBlind: this.pokerClockBigBlind,
                pokerClockTime: this.pokerClockTime
            })
        };
    }
}