import { useState } from 'react';
import {
    observer
} from 'mobx-react-lite';
import {
    List,
    TextField,
    ActionButton,
    Container
} from '../controls'
import { translateKey } from '../../utils/helper';

const AccountRenameUser = observer(({
    sdk
}: any) => {
    const userName = sdk.appState?.user?.userData?.name;
    const [name, setName] = useState(userName);

    return <List
        id={'renamelist'}
        vertical={true}
        filterNullComponents={true}
        components={[
            <Container
                containerOption={{
                    widthPx: 480,
                    lineHeight: 32,
                    float: 'left',
                    color: '#fff',
                    margin: [0, 16, 0, 80],
                    borderSize: 3,
                    fontSize: 20,
                    fontWeight: 'bold',
                    border: 'dotted #fff',
                    bgColor: '#69140a',
                    whiteSpace: 'nowrap'
                }}
            >
                {translateKey('text.account.name.change.limitation')}
            </Container>,
            <TextField
                id={'input_name'}
                placeholder={'text.common.username'}
                maxLength={32}
                onInput={(name: string) => setName(name)}
                value={name}
                inputOption={{
                    widthPx: 480,
                    borderRadius: 24,
                    borderSize: 2,
                    border: 'solid #fff'
                }}
            />,
            null,
            <ActionButton
                id={'btn_rename'}
                translationKey={'button.account.rename'}
                actionColor={'green'}
                disabled={name === userName || name === ''}
                onClick={() => sdk.appState.user.renameUser(name)}
            />,
            // <ActionButton
            //     id={'btn_back'}
            //     translationKey={'button.account.back'}
            //     actionColor={'red'}
            //     onClick={() => null}
            // />
        ]}
        listOption={{
            widthPx: 640,
            heightPx: 120
        }}
        containerOption={{
            margin: [80, 0, 0, 175],
            float: 'left',
            widthPx: 640,
            heightPx: 120
        }}
    />
    // return <Container
    //     containerOption={{
    //         widthPx: 600,
    //         heightPx: 360,
    //         float: 'left',
    //         margin: [0, 0, 0, 100],
    //         bgColor: 'rgba(0, 0, 0, .3)'
    //     }}
    // >
    //     <TextField
    //         value={name}
    //         onInput={(name: string) => setName(name)}
    //         />
    // </Container>
});

export default AccountRenameUser;