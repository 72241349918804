import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../controls';
import {
    PokerDeck
} from '../../../constants/cards';

const SettingCards = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;

    return <List
        className={'settinglist'}
        containerOption={{
            widthPx: 930,
            heightPx: 600,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left'
        }
        }
        listOption={{
            widthPx: 930,
            heightPx: 100,
            lineHeight: 100,
            color: '#fff',
            fontSize: 28,
            borderSize: 2,
            borderBottom: `solid #fff`
        }}
        components={appState.user.deckList.filter((deck: any) => deck.selectable).map((deck: any) => {
            return <ConfiguratorItem
                label={deck.data[4]}
                onClick={() => appState.user.setCardDeck(deck.id)}
                iconImage={deck.icon}
                iconOption={{
                    widthPx: 180,
                    heightPx: 64,
                    margin: [16, 16, 0, 16]
                }}
                checkbox={true}
                checked={deck.checked}
            />
        })}
    // [
    //     ,
    //     <ConfiguratorItem
    //         label={'text.settings.cards.fourcolor'}
    //         onClick={() => appState.user.setCardDeck('poker.deck.3')}
    //         iconImage={PokerDeck['poker.deck.3'][2]}
    //         iconOption={{
    //             widthPx: 64,
    //             heightPx: 64,
    //             margin: [16, 16, 0, 16]
    //         }}
    //         checkbox={true}
    //     />
    // ]}
    />
});

export default SettingCards;