const PokerDeck: any = {
    'poker.deck.4': ['deck4', 'micro', './data/icon-deckdefault.png', true, 'text.settings.cards.deck4'],
    'poker.deck.2': ['deck2', 'micro', './data/icon-deckdefault.png', false, 'text.settings.cards.default'],
    'poker.deck.3': ['deck3', 'micro2', './data/icon-deckfourcolor.png', true, 'text.settings.cards.fourcolor'],
    'poker.deck.1': ['deck1', 'micro', './data/icon-deckdefault.png', true, 'text.settings.cards.deck1']
}

const POKERCARDVALUE: any = {
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE: '5',
    SIX: '6',
    SEVEN: '7',
    EIGHT: '8',
    NINE: '9',
    TEN: '10',
    JACK: 'Jack',
    QUEEN: 'Queen',
    KING: 'King',
    ACE: 'Ace'
};

const POKERCARDKEY: any = {
    '2': 'TWO',
    '3': 'THREE',
    '4': 'FOUR',
    '5': 'FIVE',
    '6': 'SIX',
    '7': 'SEVEN',
    '8': 'EIGHT',
    '9': 'NINE',
    '10': 'TEN',
    '11': 'JACK',
    '12': 'QUEEN',
    '13': 'KING',
    '14': 'ACE'
};

const POKERCARDSUITS: any = {
    CLUBS: 'Clubs', // Kreuz
    DIAMONDS: 'Diamonds', // Karo
    HEARTS: 'Hearts', // Herz
    SPADES: 'Spades' // Piek - highest
};

const POKERCARDSUITSKEY: any = {
    '1': 'CLUBS', // Kreuz
    '2': 'DIAMONDS', // Karo
    '3': 'HEARTS', // Herz
    '4': 'SPADES' // Piek - highest
};

/**
 * Convert the cardname to imagename
 * @param {*} cardName 
 * @param {*} defaultVal 
 * @returns 
 */
function StringToCardImage(cardName: string, defaultVal: any = undefined) {
    const splittedName = cardName.split('_');
    if (splittedName.length === 2) {
        return `${POKERCARDSUITS[splittedName[0]]}_${POKERCARDVALUE[splittedName[1]]}`
    }
    return defaultVal;
}

function CardValueToString(suit: string, value: number) {
    return `${POKERCARDSUITSKEY[`${suit}`]}_${POKERCARDKEY[`${value}`]}`
}


export {
    StringToCardImage,
    CardValueToString,
    PokerDeck
}