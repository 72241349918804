import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    Icon
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper'

const TutorialNext = observer(({
    gameSession,
    appState
}: any) => {
    const visible = gameSession?.ui?.buttonNextPointer === 2 && appState?.tutorialShow('nextbutton');

    return visible ? <>
        <Container
            containerOption={{
                position: 'absolute',
                widthPx: 280,
                textAlign: 'center',
                bottom: 520,
                right: 160,
                fontSize: 28,
                zIndex: 80,
                color: '#fff',
                fontWeight: 'bold'
            }}
        >
           {translateKey('advice.next.player')}
        </Container>
        <Icon
            iconOption={{
                position: 'absolute',
                bottom: 328,
                right: 270,
                widthPx: 64,
                heightPx: 64,
                zIndex: 80
            }}
            source={`./data/icon/arrowdown.svg`}
            // source={`./data/icon/point.svg`}
            className={'tutorialMoveNext'}
        />
    </> : null;
})

export default TutorialNext;

