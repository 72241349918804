import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import getSdk from '../../../api/index';
import {
    timestampSec,
    convertHMS,
    translateKey
} from '../../../utils/helper';

const CLOCKUNIT = {
    ROUNDCLOCK: 0,
    TIMECLOCK: 1
}


export default class GamePokerClock {

    public running: boolean;
    public clockTime: number | null;
    public clockTimeout: any;
    public clockLatestTimestamp: number;
    public clockUnit: number;
    public clockElapsed: number;
    public clockDuration: number;
    public clockLevel: number;
    public clockPlan: any;

    constructor(clockData: any) {
        makeObservable(this, {
            running: observable,
            clockTime: observable,
            clockLevel: observable,
            timeString: computed,
            updateTimer: action
        })

        this.running = clockData.running;
        this.clockDuration = clockData.duration;
        this.clockUnit = clockData.unit;
        this.clockLevel = clockData.level;
        this.clockLatestTimestamp = clockData.timestamp;
        this.clockPlan = clockData.plan;
        this.clockTime = 0;
        this.clockElapsed = clockData.elapsed;
        this.updateTimer();
    }

    update(clockData: any): void {
        // Update the values
        this.running = clockData.running;
        this.clockDuration = clockData.duration;
        this.clockLevel = clockData.level;
        this.clockLatestTimestamp = clockData.timestamp;
        this.clockPlan = clockData.plan;
        this.clockElapsed = clockData.elapsed;

        this.updateTimer(!this.running);
    }

    updateTimer(forceUpdate = false) {

        let lookForLevel = undefined;
        let elapsedTime = 0;

        // update the timer
        if (this.clockUnit === CLOCKUNIT.TIMECLOCK) {
            const currentServerTime = getSdk()?.appState?.currentSession?.currentServerTimeSec || timestampSec();
            elapsedTime = (currentServerTime - this.clockLatestTimestamp) + this.clockElapsed;

            // Look for the current level
            lookForLevel = (this.clockPlan || []).find((level: any) => {
                if (elapsedTime <= level.time) {
                    return level;
                }
                return undefined;
            })

            if (!lookForLevel) {
                lookForLevel = this.clockPlan[this.clockPlan.length - 1]
            }

            this.clockTime = lookForLevel.time - elapsedTime;
            this.clockLevel = lookForLevel.level;

        } else {
            this.clockTime = this.clockDuration;
        }

        if(this.clockTime < 0) {
            this.clockTime = 0;
        }

        // start a new timeout
        if (
            this.clockUnit === CLOCKUNIT.TIMECLOCK &&
            (!this.clockTimeout || forceUpdate)
        ) {
            // delete the old timeout
            if (this.clockTimeout) {
                clearTimeout(this.clockTimeout);
                this.clockTimeout = undefined;
            }

            if (this.running) {
                this.clockTimeout = setTimeout(() => {
                    this.updateTimer(true);
                }, 1000);
            }
        }
    }

    get timeString(): string {
        return this.clockUnit === CLOCKUNIT.ROUNDCLOCK ?
            `${this.clockTime} ${translateKey('text.lobby.timeunit.rounds')}` :
            `${convertHMS(`${this.clockTime}`, 'mm:ss')} ( ${this.clockLevel} )`

    }
}