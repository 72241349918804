import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import getSdk from '..';
import getCss from './csshelper'

const DEFAULT_CALCULATED_WIDTH = 1334;
const DEFAULT_CALCULATED_HEIGHT = 750;

const getWindow:any = () => {
    return window;
}

export default class LayoutManager {

    private initialRender: any;

    public width: number;
    public height: number;
    public scaleX: number;
    public scaleY: number;
    public offsetX: number;
    public offsetY: number;

    // Which resolution is used?
    public calcWidth: number;
    public calcHeight: number;

    public renderWidth: number;
    public renderHeight: number;

    public alignHorizontal: string;
    public alignVertical: string;

    public verticalCentered: boolean;

    constructor() {
        makeObservable(this, {
            width: observable,
            height: observable,
            renderWidth: observable,
            renderHeight: observable,
            offsetX: observable,
            offsetY: observable,
            verticalCentered: observable,
            scaleX: observable,
            scaleY: observable,
            handleResize: action,
            updateScreen: action,
            test: action,
            showVerticalCentered: action,
            all: computed,
            canvasMarginRight: computed,
            styles: computed,
            calculateCanvas: computed
        })

        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.renderWidth = 0;
        this.renderHeight = 0;
        this.scaleX = 1.0;
        this.scaleY = 1.0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.verticalCentered = false;
        this.calcWidth = DEFAULT_CALCULATED_WIDTH;
        this.calcHeight = DEFAULT_CALCULATED_HEIGHT;
        this.alignHorizontal = 'center'
        this.alignVertical = 'bottom';

        this.updateScreen()
        this.registerEvents();

        if(!this.initialRender) {
            this.initialRender = setTimeout(() => this.updateScreen(), 1500)
        }
    }

    get styles(): any {
        return (options: any) => getCss(options, this.scaleX, this.scaleY)
    }

    showVerticalCentered(state: boolean) {
        this.verticalCentered = state;
    }
    

    updateBackground() {
        // Save the latest background
        // const gameBackgroundType = GameSdk.options.backgroundType;
        // const background = gameBackgroundType === BACKGROUNDS.BACKGROUND_GAME ?
        // 'background.jpg' :
        // `background2.jpg`;
        document.body.style.backgroundImage = `url(./data/background1_b.jpg)`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundColor = '#000';
    }

    test() {
        this.width = 200;
    }

    get calculateCanvas() {
        return {
            width: `${this.renderWidth}px`,
            height: `${this.renderHeight}px`,
            margin: `${this.offsetY / (this.verticalCentered ? 2 : 1)}px 0 0 ${this.offsetX}px`
        }
    }

    handleResize(event: any): void {
        this.updateScreen();
    }

    updateScreen(target: any = undefined): void {

        const useTarget = target || getWindow();
        this.width = this.getInnerWidthTarget(useTarget.innerWidth);
        this.height = this.getInnerHeightTarget(useTarget.innerHeight);

        const ScreenScaleX = this.width / this.calcWidth;
        const ScreenScaleY = this.height / this.calcHeight;

        this.scaleX = 0;
        this.scaleY = 0;

        let UIOffX = 0;
        let UIOffY = 0;

        if (ScreenScaleX !== ScreenScaleY) {

            if (ScreenScaleX < ScreenScaleY) {
                this.scaleX = ScreenScaleX;
                this.scaleY = ScreenScaleX;
            } else if (ScreenScaleY < ScreenScaleX) {
                this.scaleX = ScreenScaleY;
                this.scaleY = ScreenScaleY;
            }

            this.renderWidth = Math.round(this.calcWidth * this.scaleX);
            this.renderHeight = Math.round(this.calcHeight * this.scaleY);

            const scX = Math.round(this.calcWidth * this.scaleX);
            const scY = Math.round(this.calcHeight * this.scaleY);

            UIOffX = Math.round(this.getAlignValue(this.width - scX));
            UIOffY = Math.round(this.getVAlignValue(this.height - scY));

        } else {

            this.scaleX = ScreenScaleX;
            this.scaleY = ScreenScaleY;

            this.renderWidth = this.calcWidth;
            this.renderHeight = this.calcHeight;

        }

        // INFO: Add a left border on iOS
        if (this.isIosMobile) {
            UIOffX = UIOffX + this.getScreenLeftBorder();
            UIOffY = UIOffY + this.getScreenTopBorder();
        }

        this.offsetX = UIOffX;
        this.offsetY = UIOffY;

        this.updateBackground();
    }

    getAlignValue(width: number): number {
        if (width > 0) {
            if (this.alignHorizontal === 'left') return 0;
            if (this.alignHorizontal === 'right') return width;
            return width / 2;
        }
        return 0;
    }

    getVAlignValue(height: number): number {
        if (height > 0) {
            if (this.alignVertical === 'top') return 0;
            if (this.alignVertical === 'bottom') return height;
            return height / 2;
        }
        return 0;
    }

    getInnerWidthTarget(width: number): number {
        if (!this.isIosMobile) {
            return width;
        }
        let leftBorder = 0;
        let rightBorder = 0;
        try {
            leftBorder = this.getScreenLeftBorder();
            rightBorder = this.getScreenRightBorder();
        } catch (e) {
        }
        return width - (leftBorder + rightBorder);
    }

    getInnerHeightTarget(height: number): number {
        if (!this.isIosMobile) {
            return height;
        }

        let totalBorder = 0;

        try {
            totalBorder += this.getScreenTopBorder();
            totalBorder += this.getScreenBottomBorder();
        } catch (e) {
        }

        return height - totalBorder;
    }

    getScreenLeftBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let leftBorder = 0;
        try {
            leftBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sal").replace('px', ''), 10);
        } catch(e) {
            leftBorder = 0;
        }
        return leftBorder;
    }

    getScreenRightBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let rightBorder = 0;
        try {
            rightBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sar").replace('px', ''), 10);
        } catch(e) {
            rightBorder = 0;
        }
        return rightBorder;
    }

    getScreenTopBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let topBorder = 0;
        try {
            topBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat").replace('px', ''), 10);
        } catch(e) {
            topBorder = 0;
        }
        return topBorder;
    }

    getScreenBottomBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let bottomBorder = 0;
        try {
            bottomBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab").replace('px', ''), 10);
        } catch(e) {
            bottomBorder = 0;
        }
        return bottomBorder;
    }

    get all() {
        return this.width;
    }

    get isIosMobile() {
        return process.env.REACT_APP_MOBILE_IOS === 'true';
    }

    get canvasMarginRight() {
        return parseInt((document?.getElementById('gamecanvas')?.style?.marginLeft || '0').replace('px', ''), 10);
    }

    registerEvents() {
        window.addEventListener('resize', (e) => this.handleResize(e));
        window.addEventListener('orientationchange', (e) => this.handleResize(e));
        
        // Mobile Listener
        document.addEventListener('pause', (e) => getSdk().onPauseMobile(), false);
        document.addEventListener('resume', (e) => getSdk().onResumeMobile(), false);

        if(process.env.REACT_APP_MOBILE === "false") {
            document.addEventListener('visibilitychange', () => document.visibilityState === 'visible' ? getSdk().onResumeMobile() : getSdk().onPauseMobile());
        }

        document.ontouchmove = event => {
            event.preventDefault();
        }
        //@ts-ignore
        // window.addEventListener('touchmove', (event) => event.scale !== 1 ? event.preventDefault() : null, { passive: false });
    }
}