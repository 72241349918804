import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../../controls';

const TrainerHelp = observer(({
    sdk,
    onNextItem
}: any) => {
    const gameSession = sdk?.appState?.currentSession;

    return <List
        id={'trainerlist'}
        className={'settinglist'}
        containerOption={{
            widthPx: 420,
            heightPx: 460,
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            float: 'right',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 420,
            heightPx: 64,
            lineHeight: 64,
            color: '#fff',
            fontSize: 24,
            borderSize: 2,
            borderBottom: `solid #fff`,
            whiteSpace: 'nowrap'
        }}
        components={[
            <ConfiguratorItem
                icon={'help1'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                label={'text.overlay.help.winninghand'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => gameSession.openOverlaySubmenu('helpwinninghand')}
            />,
            <ConfiguratorItem
                icon={'help1'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                label={'text.overlay.help.tutorial'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => sdk?.appState?.openTutorialDialog(false, true)}
            />
        ]}
    />
});

export default TrainerHelp;