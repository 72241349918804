import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ImageComponent
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper'
import {
    GAMEMODE
} from '../../../../constants/gamemode';

const LobbyMenuModeEntry = observer(({
    sdk,
    mode,
    onClick,
    disabled = false
}: any) => {
    const useStyles = sdk.layoutManager.styles;

    return <>
        <div
            style={useStyles({
                widthPx: 128,
                heightPx: 128,
                margin: [10, 16, 0, 8],
                float: 'left'
            })}
        >
            <ImageComponent
                id={`lobbymode-${mode}`}
                source={`./data/ui/mode${mode}.png`}
                imageOption={{
                    widthPx: 96,
                    heightPx: 96,
                    margin: [16, 0, 0, 16]
                }}
            />
        </div>
        <div style={useStyles({
            widthPx: 740,
            heightPx: 48,
            lineHeight: 48,
            color: '#fff',
            margin: [9, 0, 0, 0],
            float: 'left',
            textAlign: 'left',
            fontSize: 28,
            fontWeight: 'bold'
        })}>
            {translateKey(`text.lobby.mode.${mode}.head`)}
        </div>
        <div style={useStyles({
            widthPx: 740,
            heightPx: 40,
            lineHeight: 40,
            color: '#fff',
            margin: [4, 0, 0, 0],
            float: 'left',
            textAlign: 'left',
            fontSize: 24
        })}>
            {translateKey(`text.lobby.mode.${mode}.sub1`)}
        </div>
        <div style={useStyles({
            widthPx: 740,
            heightPx: 40,
            lineHeight: 40,
            color: '#fff',
            margin: [4, 0, 0, 0],
            float: 'left',
            textAlign: 'left',
            fontSize: 24
        })}>
            {translateKey(`text.lobby.mode.${mode}.sub2`)}
        </div>
        <button
            id={`lobbymode-${mode}-choose`}
            className={'mainbutton_green'}
            style={useStyles({
                position: 'absolute',
                right: 0,
                bottom: 0,
                textAlign: 'center',
                padding: [16, 16, 16, 16],
                fontSize: 24,
                fontWeight: 'bold',
                lineHeight: 32,
                widthPx: 320,
                borderRadius: 8,
                border: `solid`,
                borderSize: 1,
                margin: [0, 16, 16, 0]
            })}
            disabled={disabled}
            onClick={() => onClick ? onClick() : null}
        >
            {translateKey(`button.lobby.select`)}
        </button>
    </>;
});


const LobbyMenuMode = observer(({
    sdk,
    setTab
}: any) => {
    const lobbyStore = sdk.appState.currentSession.roomLobby;
    return <List
        id={'gamemode'}
        components={[
            <LobbyMenuModeEntry
                sdk={sdk}
                mode={1}
                onClick={() => {
                    if(!sdk.appState.currentSession?.running) {
                        lobbyStore.setMode(GAMEMODE.MODE_100_DIGITAL_ALL);
                    }
                }}
                disabled={lobbyStore.isFullDigitalMode}
            />,
            <LobbyMenuModeEntry
                sdk={sdk}
                mode={2}
                onClick={() => {
                    if(!sdk.appState.currentSession?.running) {
                        lobbyStore.setMode(GAMEMODE.MODE_50_DIGITAL_CARD)
                    }
                }}
                disabled={lobbyStore.isDigitalCardMode}
            />
        ]}
        listOption={{
            position: 'relative',
            widthPx: 950,
            heightPx: 148,
            color: '#fff',
            fontSize: 28,
            borderSize: 3,
            borderBottom: `solid #fff`
        }}
        containerOption={{
            widthPx: 1000,
            heightPx: 412,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
    />;
});

export default LobbyMenuMode;