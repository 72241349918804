import type AppMessageHandler from "../messagehandler";

export default function matchStart(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number
): boolean {
    messageHandler.sendMessage(1006, {
        roomToken,
        playerId
    })
    return true;
}