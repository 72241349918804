import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../../../utils/helper';
import {
    Container,
    ActionButton
} from '../../../controls';

const PokerTableSitdownInfo = observer(({
    appState,
    gameSession
}: any) => {
    const visible = !appState?.buyinDialog && gameSession.ui.buttonSingleBuyIn === 2 && !gameSession.tvMode;

    return visible ?
        <Container
            containerOption={{
                position: 'absolute',
                widthPx: 620,
                heightPx: 48,
                zIndex: 52,
                lineHeight: 48,
                left: 140,
                top: 200,
                fontSize: 28,
                color: '#dde4ff',
                fontWeight: 'bold',
                bgColor: '#051d78',
                borderRadius: 32,
                borderSize: 2,
                border: 'solid #dde4ff'
            }}
        >
           {translateKey('text.info.table.noseat')}
        </Container> : null;
});


export default PokerTableSitdownInfo;