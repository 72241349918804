import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import GameActorMatch from './gameactormatch';
import {
    MATCH_EVENT
} from '../../../constants/matchevent';

export default class GameActor {
    public id: number;
    public role: number;
    public name: string;
    public tv: boolean | null;
    public blindActor: boolean;
    public avatar: string | number | null;
    public active: boolean;
    public leaved: boolean;
    public me: boolean;
    public auth: string | null;
    public seat: number | null;
    public sitting: boolean;
    public idle: number;
    public waiting: boolean;
    public noresponse: boolean;

    // public me: boolean;

    public playing: boolean;
    public matchData: GameActorMatch | null;

    constructor(id: number, name: string, role: number, avatar: any, tv: boolean | null, active: boolean, blind: boolean) {
        makeObservable(this, {
            active: observable,
            leaved: observable,
            auth: observable,
            me: observable,
            sitting: observable,
            waiting: observable,
            idle: observable,
            noresponse: observable,
            seat: observable,
            update: action,
            updateAdminAuth: action,
            playing: observable,
            matchData: observable,
            initAsPlayer: action,
            isGameDealer: computed,
            idleTime: computed
        })
        this.id = id;
        this.role = role;
        this.name = name;
        this.auth = null;
        this.seat = null;
        this.sitting = false;
        this.waiting = false;
        this.noresponse = false;
        this.tv = tv;
        this.active = active;
        this.blindActor = blind;
        this.avatar = avatar;
        this.leaved = false;
        this.idle = 0;
        this.playing = false;
        this.me = false;
        this.matchData = null;
    }

    get isGameDealer() {
        return this.role === 5;
    }

    initAsPlayer(): void {
        this.me = true;
        console.log('=> init as player', this.name, this.me)
        // this.player = new GamePlayer();
    }

    update(playerInfo: any = undefined, leaved: boolean = false): any {
        const isSitting = this.sitting && (!!this.seat || this.seat === 0);

        if (playerInfo) {
            this.active = playerInfo.active;
            this.seat = playerInfo.seat === 0 ? 0 : playerInfo.seat || false;
            this.sitting = playerInfo.sitting || false;
            this.idle = playerInfo.idle || 0;
            this.waiting = this.seat !== null &&
                this.seat >= 0 &&
                !this.sitting;
            if(playerInfo.role === 5) {
                this.role = 5;
            }
        }
        this.leaved = leaved;

        if(!isSitting && this.sitting && (!!this.seat || this.seat === 0)) {
            return {
                id: MATCH_EVENT.NOW_SITTING
            };
        }
        return null
    }

    updateAdminAuth(auth: string | null): void {
        this.auth = auth;
    }

    updateMatch(matchData: any, playerHand: any, match: any): void {
        this.playing = matchData?.playing || false;
        this.noresponse = matchData?.noresponse || false;
        
        if (!this.matchData) {
            this.matchData = new GameActorMatch(this.id)
        }

        this.matchData?.update(matchData, playerHand, match?.round);
    }

    // Event is called when a new round status received
    onNewRoundStatus(sdk: any): void {
        if (this.matchData) {
            this.matchData.onNewRoundStatus(sdk)
        }
    }
    onYouAre(session: any): void {
        if (this.matchData) {
            this.matchData.onYouAre(session)
        }
    }

    get idleTime() {
        let t = null;
        if(this.idle > 0) {
            try {
                t = new Date(this.idle * 1000).toLocaleString()
            } catch(e) {}
        }

        return t ||  '-'
    }
}