import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../../utils/helper';
import {
    Container,
    ImageComponent,
    Checkbox
} from '../index';

const ConfiguratorItem = observer(({
    id,
    itemOption,
    label,
    labelSplitValue,
    onNextItem,
    openWebsite,
    onClick,
    checkbox,
    checked,
    icon,
    iconImage,
    iconOption
}: any) => {
    const propsContainer = {
        textAlign: 'left',
        float: 'left',
        widthPx: 920,
        heightPx: 100,
        ...(itemOption || {})
    };
    const heightFactor = propsContainer.heightPx !== 100 ?
        propsContainer.heightPx / 100 :
        1.0
    const chevronHeight = Math.round(40 * heightFactor);
    const chevronOption = {
        widthPx: 64,
        heightPx: chevronHeight,
        float: 'right',
        margin: [28 * heightFactor, 0, 0, 0]
    };

    let useItemLabel = label ? translateKey(label) : label;
    if (useItemLabel.includes('|')) {
        useItemLabel = useItemLabel.split('|')?.[labelSplitValue] || ''
    }

    return <Container
        id={id}
        containerOption={propsContainer}
        onClick={() => {
            if (onNextItem) {
                onNextItem()
            }
            if (onClick) {
                onClick()
            }
            if (openWebsite) {
                window.open(openWebsite);
            }
        }}
    >
        {(icon || iconImage) ? <ImageComponent
            imageOption={{
                float: 'left',
                widthPx: chevronHeight,
                heightPx: chevronHeight,
                margin: [(propsContainer.heightPx - chevronHeight) / 2, (propsContainer.heightPx - chevronHeight) / 2, (propsContainer.heightPx - chevronHeight) / 2, (propsContainer.heightPx - chevronHeight) / 2],
                ...(iconOption || {})
            }}
            source={iconImage ? iconImage : `./data/icon/${icon}.svg`}
        /> : null}
        {checkbox ? <Checkbox
            disableClick={true}
            containerOption={{
                float: 'left',
                widthPx: 24,
                heightPx: 24,
                margin: [(propsContainer.heightPx - 24) / 2, 16, 0, 0]
            }}
            buttonOption={{
                widthPx: 24,
                heightPx: 24
            }}
            value={checked ? 1 : 0}
        /> : null}
        {useItemLabel}
        {onNextItem ? <ImageComponent
            imageOption={chevronOption}
            source={'./data/ui/chevron.svg'}
        /> : null}
    </Container >
});

export default ConfiguratorItem;