import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../controls';

const SettingGame = observer(({
    sdk,
    onNextItem
}: any) => {
    const isTv = sdk?.appState?.currentSession?.tvMode;

    return <List
        className={'settinglist'}
        containerOption={{
            widthPx: 930,
            heightPx: 600,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left'
        }}
        listOption={{
            widthPx: 930,
            heightPx: 100,
            lineHeight: 100,
            color: '#fff',
            fontSize: 28,
            borderSize: 2,
            borderBottom: `solid #fff`
        }}
        components={[
            <ConfiguratorItem
                label={'text.headline.settings'}
                onNextItem={() => onNextItem('common')}
                icon={'setting'}
            />,
            ...(isTv ? [
                <ConfiguratorItem
                    label={'text.headline.screenlayout'}
                    onNextItem={() => onNextItem('gameviewlayout')}
                />,
            ] : []),
            ...(process.env.REACT_APP_GAMEWEBVIEW !== 'true' ? [<ConfiguratorItem
                label={'button.settings.device'}
                onNextItem={() => onNextItem('device')}
                icon={'client'}
            />] : []),
            ...(process.env.REACT_APP_GAMEWEBVIEW !== 'true' ? [<ConfiguratorItem
                label={'button.settings.roomsetting'}
                icon={'table'}
                onNextItem={() => {
                    if (sdk?.appState?.currentSession) {
                        sdk.appState.closeSetting();
                        sdk.appState.currentSession.openOverlay(
                            {},
                            'room',
                            'roomsetting'
                        )
                    }
                }}
            />] : []),
            ...(isTv && !sdk?.appState?.currentSession.admin ? [
                <ConfiguratorItem
                    label={'button.game.adminrequest'}
                    onClick={() => {
                        if (sdk?.appState?.currentSession) {
                            sdk.appState.currentSession.adminRequest()
                        }
                    }}
                />
            ] : []),
            // <ConfiguratorItem
            //     label={'button.settings.gamehistory'}
            //     onNextItem={() => onNextItem('gamehistory')}
            // />,
            <ConfiguratorItem
                label={'button.game.leave'}
                itemOption={{
                    color: '#D8282F'
                }}
                icon={'logout'}
                onClick={() => sdk.appState.leaveGame('settingclose')}
            />
        ]}
    />
});

export default SettingGame;