import {
    observer
  } from 'mobx-react-lite';
  import getSdk from '../../../api'
  
  const GifImage = observer(({
    id,
    imageOption,
    className,
    source,
    onMouseDown,
    onTouchStart,
    onClick,
    onMouseUp,
    draggable,
    imageRef
  }: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
  
    const propsImage = useStyles({
        widthPx: 160,
        heightPx: 160,
      ...(imageOption || {})
    });
  
    return <img
      id={id}
      src={`./data/gif/${source}.gif`}
      className={className}
      style={propsImage}
      alt={""}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onMouseUp}
      draggable={draggable}
      ref={imageRef}
    />
  });
  
  export default GifImage;