import {
    observer
} from 'mobx-react-lite';
import { useEffect } from 'react';
import {
    ActionButton,
    List,
    ImageComponent,
    IconButton
} from '../../controls'
import StatusBar from '../../account/StatusBar';
import {
    onRateApp,
    rateAppPossible
} from '../../../utils/helperfunction';

const MainMenu = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;
    let mainmenuButton = [];
    const btnOption = {
        padding: [0, 8, 0, 8],
        lineHeight: 48,
        widthPx: 256,
        textAlign: 'center'
    }
    useEffect(() => {
        if (appState?.user) {
            // only request every 5 minutes if necessaty
            appState.user.fetchUserWithOwnTime(300000)
        }
        // eslint-disable-next-line
    }, []);

    if (appState.mainmenuView === 0) {
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.quick'}
            translationKey={'button.mainmenu.quick'}
            actionColor={'green'}
            icon={'./data/icon/fast-delivery.svg'}
            buttonOption={btnOption}
            onClick={() => appState.joinQuickGame()}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.livepoker'}
            translationKey={'button.mainmenu.livepoker'}
            icon={'./data/icon/livepoker.svg'}
            enableDelay={false}
            actionColor={'black'}
            buttonOption={btnOption}
            onClick={() => appState.setMainmenuView(1)}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.onlinepoker'}
            translationKey={'button.mainmenu.onlinepoker'}
            icon={'./data/icon/onlinepoker.svg'}
            enableDelay={false}
            actionColor={'black'}
            buttonOption={btnOption}
            onClick={() => appState.setMainmenuView(2)}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.tv'}
            actionColor={'blue'}
            enableDelay={false}
            icon={'./data/icon/tvblack.svg'}
            buttonOption={btnOption}
            translationKey={'button.mainmenu.tv'}
            onClick={() => appState.openJoinMenuTv('tablet')}
        />)
    } else if (appState.mainmenuView === 1) {
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.back'}
            translationKey={'button.mainmenu.back'}
            actionColor={'red'}
            buttonOption={btnOption}
            icon={'./data/icon/backblack.svg'}
            enableDelay={false}
            onClick={() => appState.setMainmenuView(0)}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.create'}
            translationKey={'button.mainmenu.create'}
            buttonOption={btnOption}
            icon={'./data/icon/create.svg'}
            actionColor={'green'}
            enableDelay={false}
            onClick={() => appState.createNewGame()}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.join'}
            actionColor={'blue'}
            buttonOption={btnOption}
            icon={'./data/icon/join.svg'}
            enableDelay={false}
            translationKey={'button.mainmenu.join'}
            onClick={() => appState.openJoinMenu()}
        />)
    } else if (appState.mainmenuView === 2) {
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.back'}
            enableDelay={false}
            buttonOption={btnOption}
            icon={'./data/icon/backblack.svg'}
            translationKey={'button.mainmenu.back'}
            actionColor={'red'}
            onClick={() => appState.setMainmenuView(0)}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.create'}
            enableDelay={false}
            translationKey={'button.mainmenu.create'}
            actionColor={'green'}
            buttonOption={btnOption}
            icon={'./data/icon/create.svg'}
            onClick={() => appState.createNewGame()}
        />)
        mainmenuButton.push(<ActionButton
            id={'button.mainmenu.join'}
            enableDelay={false}
            actionColor={'blue'}
            buttonOption={btnOption}
            icon={'./data/icon/join.svg'}
            translationKey={'button.mainmenu.join'}
            onClick={() => appState.openJoinMenu()}
        />)
    }

    return <>
        <StatusBar sdk={sdk} />
        <ImageComponent
            id={'mainmenu_logo'}
            imageOption={{
                widthPx: 800,
                margin: [40, 0, 0, 64]
            }}
            className={'bounce-top'}
            source={'./data/gamelogo_optimized.png'}
            onClick={() => {
                try {
                    //@ts-ignore
                    document.getElementById('mainmenu_logo').classList.remove("bounce-top");

                } catch (e) { }
                setTimeout(() => {
                    try {
                        //@ts-ignore
                        document.getElementById('mainmenu_logo').classList.add("bounce-top");
                    } catch (e) { }
                }, 10)
            }}
        />
        <List
            id={'mainmenu_buttons'}
            components={mainmenuButton}
            listOption={{
                widthPx: 295
            }}
            containerOption={{
                margin: mainmenuButton.length === 4 ? [64, 0, 0, 85] : [64, 0, 0, 225],
                float: 'left',
                widthPx: 1334
            }}
        />
        <List
            id={'mainmenu_linklist'}
            components={[
                <IconButton
                    id={'btn_website'}
                    source={'./data/ui/website.png'}
                    openWebsite={process.env.REACT_APP_WEBSITE}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_tiktok'}
                    source={'./data/ui/tiktok.png'}
                    openWebsite={process.env.REACT_APP_TIKTOK}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_twitter'}
                    source={'./data/ui/twitter.png'}
                    openWebsite={process.env.REACT_APP_TWITTER}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_facebook'}
                    source={'./data/ui/facebook.png'}
                    openWebsite={process.env.REACT_APP_FACEBOOK}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_instagram'}
                    source={'./data/ui/instagram.png'}
                    openWebsite={process.env.REACT_APP_INSTAGRAM}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                rateAppPossible() ? <IconButton
                    id={'btn_rate'}
                    source={'./data/icon/star.svg'}
                    onClick={() => onRateApp()}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                /> : null,
                <IconButton
                    id={'btn_contact'}
                    source={'./data/icon/contact.svg'}
                    openWebsite={process.env.REACT_APP_SUPPORT}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_setting'}
                    source={'./data/ui/settings.svg'}
                    onClick={() => appState.openSetting('common')}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />
            ]}
            listOption={{
                widthPx: 80,
                heightPx: 80,
                lineHeight: 80
            }}
            containerOption={{
                float: 'left',
                widthPx: 1334,
                heightPx: 80,
                margin: [80, 0, 0, 0]
            }}
        />
    </>
});

export default MainMenu;