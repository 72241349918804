import type AppMessageHandler from "../messagehandler";

export default function roundNext(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number
): boolean {
    messageHandler.sendMessage(1021, {
        roomToken,
        playerId
    })
    return true;
}