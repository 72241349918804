import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../../controls/index';
import {
    translateKey
} from '../../../../../utils/helper'

const GameDigitalCardSessionLimit = observer(({
    sdk
}: any) => {
    const {
        appState
    } = sdk;
    const gameSession = appState?.currentSession;
    const demoTime = gameSession?.demo ? gameSession?.sessionClock?.timerDisplay : null;

    return demoTime ? <Container
        containerOption={{
            float: 'left',
            widthPx: 210,
            heightPx: 72,
            margin: [16, 10, 0, 5],
            borderRight: 'solid #879892',
            borderSize: 2
        }}
        onClick={() => {

            appState.showMessagebox('expiredemo', gameSession?.admin ?
                'dialog.vip.demo.extend' :
                'dialog.vip.demo.extend.player',
                () => null,
                null,
                true,
                [],
                false,
                {
                    fontSize: 24,
                    openLocation: 'session'
                },
                gameSession?.admin ? 'premium' : 'message'
            );

        }}
    >
        <Container
            containerOption={{
                widthPx: 200,
                heightPx: 32,
                fontSize: 22,
                fontWeight: 'bold',
                float: 'left',
                lineHeight: 32,
                color: '#fff',
                textAlign: 'center'
            }}
        >
            {translateKey('game.text.handview.demolimit')}
        </Container>
        <Container
            containerOption={{
                widthPx: 200,
                heightPx: 32,
                fontSize: 20,
                fontWeight: 'bold',
                float: 'left',
                lineHeight: 32,
                color: '#dd985d',
                textAlign: 'center'
            }}
        >
            {demoTime || ''}
        </Container>
    </Container> : null;
})


export default GameDigitalCardSessionLimit;