import { useEffect } from 'react';
import {
    observer
} from 'mobx-react-lite';
import PokerTableRoomCode from '../table/PokerTableRoomCode'
import PokerTableBlinds from '../table/PokerTableBlinds'
import PokerTableLogo from '../table/PokerTableLogo';
import CommunityCard from '../cards/CommunityCard';
import PokerWinnerBanner from '../table/PokerWinnerBanner';
import PokerTableDealerButton from '../table/PokerTableDealerButton'
import React from 'react';

import {
    Container,
    ActionButton,
    IconButton
} from '../../../controls';
import PokerTabletPlayer from './PokerTabletPlayer'
import PokerTablePot from '../table/PokerTablePot';

const PokerTabletView = observer(({
    sdk
}: any) => {

    useEffect(() => {
        if (sdk?.appState?.currentSession?.tablet === true) {
            sdk.layoutManager.showVerticalCentered(true)
        }
        return () => {
            sdk.layoutManager.showVerticalCentered(false)
        }
        // eslint-disable-next-line
    }, [sdk?.appState?.currentSession])

    const gameRound = sdk.appState.currentSession?.currentMatch?.round;
    const gameSession = sdk?.appState?.currentSession;
    const gameSeats = gameSession.seats;
    const cashEnabled = gameSession?.isDigitalCardMode ? false : true;
    const globalDealEnabled = (gameSession?.isDigitalCardMode && !gameSession?.dealerMode) ? true : false;
    let globalDealLabel = gameSession?.ui?.buttonDeal ?
        'game.button.handview.deal' : undefined;


    if (globalDealLabel === undefined && gameSession?.currentMatch?.round?.status === 3) {
        globalDealLabel = `game.button.handview.deal.showdown`;
    } else {
        if (gameSession?.currentMatch?.round?.status < 4) {
            globalDealLabel = `game.button.handview.deal.status.${gameSession?.currentMatch?.round?.status || 0}`
        } else {
            globalDealLabel = 'game.button.handview.deal';
        }

    }



    const middleHeight = cashEnabled ?
        314 :
        370;
    const topHeight = 189 + (370 - middleHeight) / 2;
    const bottomHeight = 189 + (370 - middleHeight) / 2;
    const communityCardWidth = cashEnabled ?
        192 :
        228

    return <>
        <Container
            id={'tabletview-top'}
            containerOption={{
                float: 'left',
                widthPx: 1290,
                heightPx: topHeight
            }}
        >
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 500,
                    heightPx: topHeight
                }}
            >
                <PokerTabletPlayer
                    pos={2}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'right'}
                />
                <PokerTabletPlayer
                    pos={1}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'right'}
                />
                <PokerTabletPlayer
                    pos={0}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'right'}
                />
                <IconButton
                    buttonOption={{
                        float: 'left',
                        widthPx: 40,
                        heightPx: 40,
                        border: 'none',
                        margin: [8, 0, 0, 0]
                    }}
                    source={'./data/ui/settings.svg'}
                    onClick={() => sdk.appState.openSetting('game')}
                />
            </Container>
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 290,
                    heightPx: topHeight
                }}
            >
                {globalDealEnabled ? (
                    <ActionButton
                        translationKey={globalDealLabel}
                        actionColor={'green'}
                        enableDelay={800}
                        buttonOption={{
                            widthPx: 240,
                            margin: [96, 0, 0, 0],
                            transform: 'rotate(180deg)'
                        }}
                        onClick={() => sdk.appState.currentSession?.currentMatch?.dealerGlobalDeal()}
                    />
                ) : null}
                {cashEnabled ? (
                    <PokerTablePot
                        gameSession={gameSession}
                        containerOption={{
                            position: 'relative',
                            widthPx: 160,
                            heightToWidth: 3.43,
                            lineHeight: 48,
                            bgImage: 'url(./data/ui/pot.svg)',
                            bgRepeat: 'no-repeat',
                            bgImageSize: 'contain',
                            color: '#B0C417',
                            fontSize: 20,
                            fontWeight: 'bold',
                            float: 'left',
                            left: undefined,
                            top: undefined,
                            margin: [!gameSession?.isDigitalCardMode ? 128 : 0, 0, 0, 64],
                            transform: 'rotate(180deg)'
                        }}
                    />
                ) : null}

            </Container>
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 500,
                    heightPx: topHeight
                }}
            >
                <PokerTabletPlayer
                    pos={3}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTabletPlayer
                    pos={4}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTableRoomCode
                    gameSession={gameSession}
                    smallLayout={true}
                    containerOption={{
                        float: 'right',
                        margin: [4, 0, 0, 0]
                    }}
                />
                <PokerTableBlinds
                    gameSession={gameSession}
                    containerOption={{
                        float: 'right',
                        margin: [8, 0, 0, 0]
                    }}
                />
            </Container>

        </Container>
        <Container
            id={'tabletview-middle'}
            containerOption={{
                float: 'left',
                widthPx: 1290,
                heightPx: middleHeight,
                position: 'relative'
            }}
        >
            <PokerTableLogo
                gameSession={gameSession}
                widthTable={1290}
                heightTable={370}
            />
            <React.Fragment key={'communitycard-area'}>
                <CommunityCard
                    round={gameRound}
                    dealerCard={1}
                    deck={gameSession.sessionDeck}
                    gameSession={gameSession}
                    cardWidth={communityCardWidth}
                    marginTop={10}
                    marginLeft={cashEnabled ? 120 : 60}
                    marginRight={cashEnabled ? 8 : 12}
                    onClick={() => gameSession?.adminMissdeal()}
                />
                <CommunityCard
                    round={gameRound}
                    dealerCard={2}
                    deck={gameSession.sessionDeck}
                    gameSession={gameSession}
                    cardWidth={communityCardWidth}
                    marginTop={10}
                    marginLeft={cashEnabled ? 8 : 0}
                    marginRight={cashEnabled ? 8 : 12}
                    onClick={() => gameSession?.adminMissdeal()}
                />
                <CommunityCard
                    round={gameRound}
                    dealerCard={3}
                    deck={gameSession.sessionDeck}
                    gameSession={gameSession}
                    cardWidth={communityCardWidth}
                    marginTop={10}
                    marginLeft={cashEnabled ? 8 : 0}
                    marginRight={cashEnabled ? 8 : 12}
                    onClick={() => gameSession?.adminMissdeal()}
                />
                <CommunityCard
                    round={gameRound}
                    dealerCard={4}
                    deck={gameSession.sessionDeck}
                    gameSession={gameSession}
                    cardWidth={communityCardWidth}
                    marginTop={10}
                    marginLeft={cashEnabled ? 8 : 0}
                    marginRight={cashEnabled ? 8 : 12}
                    onClick={() => gameSession?.adminMissdeal()}
                />
                <CommunityCard
                    round={gameRound}
                    dealerCard={5}
                    deck={gameSession.sessionDeck}
                    gameSession={gameSession}
                    cardWidth={communityCardWidth}
                    marginTop={10}
                    marginLeft={cashEnabled ? 8 : 0}
                    marginRight={0}
                    onClick={() => gameSession?.adminMissdeal()}
                />
            </React.Fragment>
            <PokerWinnerBanner bannerLayout={2} gameSession={gameSession} />
            <PokerTableDealerButton
                gameSession={gameSession}
                isTablet={true}
            />
        </Container>
        <Container
            id={'tabletview-bottom'}
            containerOption={{
                float: 'left',
                widthPx: 1290,
                heightPx: bottomHeight
            }}
        >
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 500,
                    heightPx: bottomHeight
                }}
            >
                <PokerTabletPlayer
                    pos={8}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'right'}
                    rotate={true}
                />
                <PokerTabletPlayer
                    pos={9}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'right'}
                    rotate={true}
                />
                <PokerTableBlinds
                    gameSession={gameSession}
                    containerOption={{
                        float: 'left',
                        transform: 'rotate(180deg)',
                        margin: [8, 0, 0, 0]
                    }}
                />
                <PokerTableRoomCode
                    gameSession={gameSession}
                    smallLayout={true}
                    containerOption={{
                        float: 'left',
                        transform: 'rotate(180deg)',
                        margin: [4, 0, 0, 0]
                    }}
                />
            </Container>
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 290,
                    heightPx: bottomHeight
                }}
            >
                {globalDealEnabled ? (
                    <ActionButton
                        translationKey={globalDealLabel}
                        enableDelay={800}
                        actionColor={'green'}
                        buttonOption={{
                            widthPx: 240,
                            margin: [48, 0, 0, 0]
                        }}
                        onClick={() => sdk.appState.currentSession?.currentMatch?.dealerGlobalDeal()}
                    />
                ) : null}
                {cashEnabled ? (
                    <PokerTablePot
                        gameSession={gameSession}
                        containerOption={{
                            position: 'relative',
                            widthPx: 160,
                            heightToWidth: 3.43,
                            lineHeight: 48,
                            bgImage: 'url(./data/ui/pot.svg)',
                            bgRepeat: 'no-repeat',
                            bgImageSize: 'contain',
                            color: '#B0C417',
                            fontSize: 20,
                            fontWeight: 'bold',
                            float: 'left',
                            left: undefined,
                            top: undefined,
                            margin: [32, 0, 0, 64]
                        }}
                    />
                ) : null}
            </Container>
            <Container
                containerOption={{
                    position: 'relative',
                    float: 'left',
                    widthPx: 500,
                    heightPx: bottomHeight
                }}
            >
                <PokerTabletPlayer
                    pos={7}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'left'}
                    rotate={true}
                />
                <PokerTabletPlayer
                    pos={6}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'left'}
                    rotate={true}
                />
                <PokerTabletPlayer
                    pos={5}
                    gameSeats={gameSeats}
                    gameSession={gameSession}
                    floating={'left'}
                    rotate={true}
                />
            </Container>

        </Container>
    </>
});

export default PokerTabletView;