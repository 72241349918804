import type AppMessageHandler from "../messagehandler";

export default function tableCreate(
    messageHandler: AppMessageHandler,
    roomToken: any,
    config: any = {}
): boolean {
    messageHandler.sendMessage(1005, {
        roomToken,
        config
    })
    return true;
}