import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import {
    convertNumberToString,
    translateKey
} from '../../../../utils/helper';

const PokerTablePot = observer(({
    gameSession,
    containerOption
}: any) => {
    const potVisible = !gameSession?.isDigitalCardMode || false;
    let renderPotInfo = convertNumberToString(gameSession?.currentMatch?.round?.pot || 0);
    
    if(!gameSession?.currentMatch?.isRunning) {
        renderPotInfo = translateKey('text.common.notstarted')
    } else if(gameSession?.currentMatch?.paused) {
        renderPotInfo = translateKey('text.common.paused')
    } 

    return potVisible ? <Container
        id={'table_pot'}
        containerOption={{
            position: 'absolute',
            left: 311,
            top: -20 , //,246,
            widthPx: 160,
            heightToWidth: 3.43,
            lineHeight: 48,
            bgImage: 'url(./data/ui/pot.svg)',
            bgRepeat: 'no-repeat',
            bgImageSize: 'contain',
            color: '#B0C417',
            fontSize: 20,
            fontWeight: 'bold',
            ...(containerOption || {})
        }}
    >
        {renderPotInfo}
    </Container> : null;
})

export default PokerTablePot;