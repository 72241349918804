import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../../controls';

const PlayerDetail = observer(({
    sdk,
    onNextItem
}: any) => {
    const gameSession = sdk?.appState?.currentSession;
    const useActor = gameSession?.overlay?.overlayObj || {};
    const buildItemList = [
        <ConfiguratorItem
            label={'text.overlay.admin.profile'}
            itemOption={{
                widthPx: 400,
                heightPx: 64,
                margin: [0, 0, 0, 16]
            }}
            icon={'name'}
            iconOption={{
                margin: [20, 8, 0, 0]
            }}
            onClick={() => {


                if (useActor?.me) {
                    sdk.appState.user.fetchUser()
                } else {
                    sdk.appState.user.fetchTmpUser(useActor);
                }

                sdk.appState.currentSession.closeOverlay();
                sdk.appState.openSetting('account', undefined, {
                    onlyRead: true,
                    me: useActor?.me || false
                });
            }}
        />
    ];
    if ((gameSession?.overlay?.overlayObj || {})?.me && gameSession?.ui?.buttonStandup === 2) {
        buildItemList.push(<ConfiguratorItem
            label={'button.game.menu.standup'}
            itemOption={{
                widthPx: 400,
                heightPx: 64,
                margin: [0, 0, 0, 16],
                color: 'red'
            }}
            icon={'logout'}
            iconOption={{
                margin: [20, 8, 0, 0]
            }}
            onClick={() => {
                if (
                    gameSession?.overlay?.overlayObj &&
                    gameSession?.currentMatch
                ) {
                    gameSession.standUp()
                    gameSession.closeOverlay();
                }
            }}
        />)
    }
    if (gameSession?.admin) {

        const hasVoting = gameSession?.voteList?.filter((vote: any) => (vote.type === 'buyin' || vote.type === 'sitdown') && vote.playerId === useActor?.id)

        if (!gameSession?.overlay?.overlayObj?.me) {
            buildItemList.push(<ConfiguratorItem
                label={'text.overlay.admin.kick'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => {
                    if (
                        gameSession?.overlay?.overlayObj &&
                        gameSession?.currentMatch
                    ) {
                        gameSession.currentMatch.adminKickPlayer(gameSession.overlay.overlayObj.id)
                    }
                }}
            />)
            buildItemList.push(<ConfiguratorItem
                label={'text.overlay.admin.forcestandup'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => {
                    if (
                        gameSession?.overlay?.overlayObj &&
                        gameSession?.currentMatch
                    ) {
                        gameSession.adminForcePlayerStandup(gameSession.overlay.overlayObj.id)
                    }
                }}
            />)

            if (gameSession?.currentMatch?.flagAllowSetDealer) {
                buildItemList.push(<ConfiguratorItem
                    label={'text.overlay.admin.setdealer'}
                    itemOption={{
                        widthPx: 400,
                        heightPx: 64,
                        margin: [0, 0, 0, 16]
                    }}
                    onClick={() => {
                        if (
                            gameSession?.overlay?.overlayObj &&
                            gameSession?.currentMatch
                        ) {
                            gameSession.currentMatch.adminSetDealer(gameSession.overlay.overlayObj.id)
                        }
                    }}
                />)
            }

            buildItemList.push(<ConfiguratorItem
                label={'text.overlay.admin.forcefold'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => {
                    if (
                        gameSession?.overlay?.overlayObj &&
                        gameSession?.currentMatch
                    ) {
                        gameSession.currentMatch.adminForcePlayerToFold(gameSession.overlay.overlayObj.id)
                    }
                }}
            />)
        }
        if (gameSession?.ui?.flagBankroll) {
            buildItemList.push(<ConfiguratorItem
                label={'text.overlay.admin.setbankroll'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={'casino-chip'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => gameSession.openOverlaySubmenu('editbankroll')}
            />)
        }

        if (hasVoting.length === 1) {
            buildItemList.push(<ConfiguratorItem
                label={'text.overlay.admin.sitdown.accept'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                icon={'accept'}
                onClick={() => {
                    sdk.getMessageList().adminRequestResult(hasVoting[0].id, true)
                }}
            />)
            buildItemList.push(<ConfiguratorItem
                label={'text.overlay.admin.sitdown.decline'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                icon={'decline'}
                onClick={() => {
                    sdk.getMessageList().adminRequestResult(hasVoting[0].id, false)
                }}
            />)
        }

    }

    return <List
        id={'playersetting'}
        className={'settinglist'}
        containerOption={{
            widthPx: 420,
            heightPx: 460,
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            float: 'right',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 420,
            heightPx: 64,
            lineHeight: 64,
            color: '#fff',
            fontSize: 24,
            borderSize: 2,
            borderBottom: `solid #fff`,
            whiteSpace: 'nowrap'
        }}
        components={buildItemList}
    />
});

export default PlayerDetail;