import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ActionButton
} from '../../../controls';

const PokerTableGlobalBuyIn = observer(({
    appState,
    gameSession
}: any) => {
    const visible = !appState?.buyinDialog && gameSession.ui.buttonGlobalBuyIn === 2 && !gameSession.tvMode && !gameSession.currentActor.isGameDealer;

    return visible ?
        <Container
            containerOption={{
                position: 'absolute',
                widthPx: 640,
                heightPx: 96,
                zIndex: 52,
                left: 140,
                top: 70
            }}
        >
            <ActionButton
                id={'btn_globalbuyin'}
                actionColor={'green'}
                translationKey={gameSession.sitdownEnabled ? 'text.game.button.global.sitdown' : 'text.game.button.global.buyin'}
                buttonOption={{
                    widthPx: 480,
                    heightToWidth: 8.24,
                    lineHeight: 16,
                    fontSize: 28,
                    position: 'relative',
                    margin: [80, 0, 0, 0]
                }}
                onClick={() => gameSession.joinMatch(null)}
            />
        </Container> : null;
});


export default PokerTableGlobalBuyIn;