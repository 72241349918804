import {
    observer
} from 'mobx-react-lite';
import {
    getCardSource,
    getCardBack
} from '../../../../utils/cardhelper';
import {
    ImageComponent
} from '../../../controls';

const PokerCardMicro = ({
    cardRevealed = true,
    cardWidth,
    card,
    deck,
    cardOption
}: any) => {
    return <ImageComponent
        imageOption={{
            widthPx: cardWidth,
            heightToWidth: 0.649,
            float: 'left',
            ...(cardOption || {})
        }}
        source={cardRevealed && card ?
            getCardSource(card.suit, card.value, deck, true) :
            getCardBack()
        }
    />;
}
const PokerCard = observer(({
    id,
    card,
    cardRevealed,
    cardWidth = 128,
    microcard = false,
    cardOption,
    onMouseDown,
    onMouseUp,
    draggable,
    cardRef,
    glow,
    glowSize,
    deck
}: any) => {
    const isSmallGlow = glowSize === 'small'
    return <ImageComponent
        id={id}
        imageOption={{
            widthPx: cardWidth,
            heightToWidth: 0.649,
            float: 'left',
            filterShadow: glow ? [0, 0, isSmallGlow ? 2 : 10, 2, '#F8FF4A'] : undefined,
            // boxShadow: glow ? [0, 0, 8, 2, '#F8FF4A'] : undefined,
            ...(cardOption || {})
        }}
        source={cardRevealed && card ?
            getCardSource(card.suit, card.value, deck, microcard) :
            getCardBack()
        }
        onMouseDown={(e: any) => {
            if (onMouseDown) {
                try {
                    onMouseDown(e)
                } catch (err: any) {
                    console.log(err)
                }
            }
        }}
        onTouchStart={(e: any) => {
            onMouseDown(e)
        }}
        onMouseUp={onMouseUp}
        draggable={draggable}
        imageRef={cardRef}
    />;
})

export default PokerCard;
export {
    PokerCardMicro
}