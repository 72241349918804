import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper';

const PokerTableRoomCode = observer(({
    gameSession,
    containerOption,
    smallLayout = false
}: any) => {
    const visible = gameSession.tableConfig.flagHideRoomCode === 0;
    const demoTime = gameSession?.demo ? gameSession?.sessionClock?.timerDisplay : null;
    const demoTimeBorder = gameSession?.demo && gameSession?.sessionClock?.lessThan5Minutes ? true : false
    const votingNotice = (gameSession?.voteList || []);

    return visible ? <Container
        containerOption={{
            bgColor: '#000',
            widthPx: 192,
            heightPx: 88 - (smallLayout ? 40 : 0),
            lineHeight: 80,
            color: '#fff',
            margin: [32, 0, 0, 64],
            borderRadius: 20,
            ...(containerOption || {}),
            ...((votingNotice.length && gameSession.admin) || ((gameSession.admin || gameSession.tablet || gameSession.tv) && demoTimeBorder) ? {
                borderSize: 2,
                border: 'solid #e2574c'
            } : {})
        }}
        onClick={() => {
            gameSession.openOverlay(
                {},
                'room',
                'roomsetting'
            )
        }}
    >
        {!smallLayout ? (
            <Container
                containerOption={{
                    widthPx: 192,
                    heightPx: 40,
                    lineHeight: 40,
                    color: '#fff',
                    fontWeight: 500,
                    fontSize: 20,
                    borderBottom: 'solid rgb(200, 200, 200,0.4)',
                    borderSize: 2
                }}>
                {translateKey('text.common.roomcode', { upperSize: true })}
            </Container>
        ) : null}
        <Container
            containerOption={{
                widthPx: 192,
                heightPx: 40,
                lineHeight: 40,
                fontWeight: 'bold',
                fontSize: 26,
                color: '#fff',
                opacity: 0.7
            }}>
            {gameSession?.roomCode ? gameSession.roomCode : null}{demoTime ? ` / ${demoTime}` : null}
        </Container>
    </Container> : null;
});

export default PokerTableRoomCode;