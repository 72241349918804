import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../utils/helper';
import {
    Container,
    IconButton,
    ImageComponent,
    ActionButton,
    Icon
} from '../controls/index';

const ExpireComponent = observer(({ sdk }: any) => {
    const {
        appState,
        layoutManager
    } = sdk;
    const dialogOpen = appState?.currentSession?.demoExpired && !appState?.gamestore;
    const gameCreator = appState?.currentSession?.admin;

    return dialogOpen ? <div
        id={appState?.rotatedView ? 'gamedialog_rotated' : 'gamedialog'}
        onClick={() => null}
        style={appState?.rotatedView ? {
            transform: 'rotate(-90deg)'
        } : {}}
    >
        <div
            id={'gamedialog_content'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'dialog_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    bgColor: '#ff1e1e',
                    margin: [(750 - 362) / 2, 0, 0, (1334 - 640) / 2]
                }}
            >
                <Container
                    id={'dialog_message'}
                    containerOption={{
                        widthPx: 620,
                        heightPx: 220,
                        color: '#fff',
                        // fontSize: 24,
                        fontSize: 26,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        role: 'alert'
                    }}
                >
                    {translateKey(gameCreator ?
                        'dialog.gameover.demo.creator' :
                        'dialog.gameover.demo.player'
                    )}
                </Container>
                {gameCreator ? (
                    <ActionButton
                        actionColor={'green'}
                        buttonOption={{
                            float: 'left',
                            widthPx: 260,
                            margin: [0, 16, 0, 64],
                            padding: undefined,
                            lineHeight: 62
                        }}
                        onClick={() => appState.openGameStore({
                            view: 'vip'
                        })}
                    >
                        <Icon
                            source={'./data/icon/vip.svg'}
                            iconOption={{
                                widthPx: 48,
                                heightPx: 48,
                                float: 'left'
                            }}
                        />Premium
                    </ActionButton>
                ) : null}
                <ActionButton
                    actionColor={'red'}
                    buttonOption={{
                        widthPx: 260,
                        float: 'left',
                        fontSize: 22,
                        margin: [0, 16, 0, 16],
                        padding: undefined,
                        lineHeight: 62
                    }}
                    onClick={() => appState.leaveGame()}
                >
                    <Icon
                        source={'./data/icon/logout.svg'}
                        iconOption={{
                            widthPx: 48,
                            heightPx: 48,
                            float: 'left'
                        }}
                    />
                    {translateKey('button.game.leave')}
                </ActionButton>
            </Container>
        </div>
    </div> : null;
});

export default ExpireComponent;