const MATCH_ROLE = {
    NONE: 0,
    DEALER: 1,
    SMALLBLIND: 2,
    BIGBLIND: 3,
    DEALER_BIGBLIND: 4,
    DEALER_SMALLBLIND: 5
};

export {
    MATCH_ROLE
}