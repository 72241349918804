import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import {
    currentLanguage,
    emailIsValid,
    translateKey
} from '../../utils/helper';
import type {
    CouchgamesSdk
} from '../index';

export default class AppAccount {
    public sdk: CouchgamesSdk;
    public accountMail: string;
    public accountToken: string;

    public formActivationCode: string;
    public formMail1: string;
    public formMail2: string;
    public formStep: number;
    public formAgb: number;
    public formNewsletter: number;

    constructor(sdk: CouchgamesSdk) {
        this.sdk = sdk;

        makeObservable(this, {
            accountMail: observable,
            accountToken: observable,
            formActivationCode: observable,
            formMail1: observable,
            formMail2: observable,
            formStep: observable,
            formAgb: observable,
            formNewsletter: observable,
            loggedIn: computed,
            mailFormValid: computed,
            resetAccount: action,
            leaveRegistration: action,
            leaveActivation: action,
            setFormStep: action,
            resetForm: action,
            login: action,
            register: action,
            activate: action,
            load: action,
            updateValue: action
        });
        this.accountMail = '';
        this.accountToken = '';
        this.formActivationCode = '';
        this.formMail1 = '';
        this.formMail2 = '';
        this.formAgb = 0;
        this.formNewsletter = 0;
        this.formStep = 0;
    }

    public updateValue(valueName: string, value: any) {
        // @ts-ignore
        this[valueName] = value;
    }

    public resetForm(): void {
        this.formActivationCode = '';
        this.formMail1 = '';
        this.formMail2 = '';
        this.formStep = 0;
        this.formAgb = 0;
        this.formNewsletter = 0;
    }

    public resetAccount(): void {
        this.accountMail = '';
        this.accountToken = '';
    }

    public leaveRegistration(): void {
        this.formStep = 0;
        this.formMail2 = '';
        this.formAgb = 0;
        this.formNewsletter = 0;
    }

    public leaveActivation(): void {
        this.formStep = 0;
        this.formMail1 = '';
        this.formAgb = 0;
        this.formNewsletter = 0;
        this.formActivationCode = '';
    }

    public setFormStep(step: number): void {
        this.formStep = step;
    }

    public async login(): Promise<boolean> {
        const useSpinner = this.sdk.appState.createSpinner();

        const fetchedData = await this.sdk.fetchApi('account', 'login', {
            mail: this.formMail1
        })

        useSpinner.close();

        if (fetchedData?.json?.response === 'success') {
            this.setFormStep(1);
            this.sdk.appState.showMessagebox('dialog.account.login.waitactivation', 'dialog.account.login.waitactivation', null, null, true, [])
            return true;
        } else if (fetchedData?.json?.response === 'error') {
            this.sdk.appState.showErrorMessage('loginerror', translateKey('dialog.account.login.error', {}, [
                ['$error', fetchedData?.json?.errorName || '-']
            ]), true)
        } else {
            this.sdk.appState.showErrorMessage('loginerror', translateKey('dialog.account.login.error', {}, [
                ['$error', '?']
            ]), true)
        }
        return false;
    }

    public async register(): Promise<any> {

        // Validate the fields
        if (!emailIsValid(this.formMail1) || !emailIsValid(this.formMail2)) {
            return {
                error: true,
                errorMessage: 'dialog.account.invalid.mail'
            };
        } else if (this.formMail1 !== this.formMail2) {
            return {
                error: true,
                errorMessage: 'dialog.account.notsame.mail'
            };
        } else if (this.formAgb === 0) {
            return {
                error: true,
                errorMessage: 'dialog.account.notselected.agb'
            };
        }
        const useSpinner = this.sdk.appState.createSpinner();

        const regData = await this.sdk.fetchApi('account', 'register', {
            mail: this.formMail1,
            newsletter: this.formNewsletter,
            term: this.formAgb
        });

        useSpinner.close();
        if (regData.status === 200) {
            this.setFormStep(1);
            this.sdk.appState.showMessagebox('dialog.account.register.waitactivation', 'dialog.account.register.waitactivation', null, null, true, [])
            return true;
        }

        return {
            error: true,
            errorMessage: 'dialog.account.registration.failed'
        };
    }

    public async activate(disableSpinner:boolean = false): Promise<string | undefined> {
        const useSpinner = disableSpinner ? null : this.sdk.appState.createSpinner();
        const fetchedData = await this.sdk.fetchApi('account', 'login', {
            mail: this.formMail1,
            activationcode: this.formActivationCode,
            language: currentLanguage()
        })

        if(useSpinner !== null) {
            useSpinner.close();
        }

        if (fetchedData?.json?.response === 'success') {
            this.accountMail = this.formMail1;
            this.accountToken = fetchedData?.json?.token;
            this.resetForm();
            this.sdk.appState.showMessagebox('dialog.account.login.success', 'dialog.account.login.success', null, null, true, [])
            this.sdk.appState?.user?.saveUser();
            return fetchedData?.json?.token;
        } else if (fetchedData?.json?.response === 'error') {
            this.sdk.appState.showErrorMessage('loginerror', translateKey('dialog.account.login.error', {}, [
                ['$error', fetchedData?.json?.errorName || '-']
            ]), true)
        } else {
            this.sdk.appState.showErrorMessage('loginerror', translateKey('dialog.account.login.error', {}, [
                ['$error', '?']
            ]), true)
        }

        return undefined;
    }

    get loggedIn() {
        return !!this.accountToken;
    }

    get mailFormValid() {
        return emailIsValid(this.formMail1);
    }

    get store() {
        return {
            mail: this.accountMail,
            token: this.accountToken
        }
    }

    public load(data: any) {
        this.accountMail = data?.mail || undefined;
        this.accountToken = data?.token || undefined;
    }
}