import {
    observer
} from 'mobx-react-lite';
import PokerHand from '../../hand/PokerHand';
import PokerHandStatic from '../../hand/PokerHandStatic';
import PokerHandStaticFold from '../../hand/PokerHandStaticFold';

const GameDigitalHand = observer(({
    sdk,
    gameSession,
    containerStatic,
    containerHand,
    rotated = false,
    hugeOverlappedView = false
}: any) => {
    const allowShowFold = gameSession?.tableConfig?.flagAllowShowFold === 1; // Was hatte ich
    const showStaticFoldedHand = (gameSession?.currentActor?.matchData?.folded && allowShowFold);
    const showStaticHand = (gameSession?.currentActor?.matchData?.handRevealed === 3 && !gameSession?.currentActor?.matchData?.folded);

    // The folded hand with feature 
    if (showStaticFoldedHand) {
        return <PokerHandStaticFold
            cardWidth={hugeOverlappedView ? 440 : 340}
            hugeOverlappedView={hugeOverlappedView}
            gameSession={gameSession}
            containerOption={{
                margin: [20, 0, 0, 20],
                ...(containerStatic || {})
            }}
        />
    }

    // gameSession?.currentActor?.matchData?.folded
    return showStaticHand ? <PokerHandStatic
        cardWidth={hugeOverlappedView ? 440 : 340}
        hugeOverlappedView={hugeOverlappedView}
        gameSession={gameSession}
        containerOption={{
            margin: [20, 0, 0, 20],
            ...(containerStatic || {})
        }}
    /> : <PokerHand
        cardWidth={340}
        rotated={rotated}
        gameSession={gameSession}
        containerOption={{
            margin: [20, 0, 0, 128],
            ...(containerHand || {})
        }}
    />;
})


export default GameDigitalHand;