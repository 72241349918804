import type AppMessageHandler from "../messagehandler";

export default function matchLeave(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number
): boolean {
    messageHandler.sendMessage(1011, {
        roomToken,
        playerId,
        reason: 10
    })
    return true;
}