import type AppMessageHandler from "../messagehandler";

export default function tableSitDown(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    seat: number | null
): boolean {
    messageHandler.sendMessage(1016, {
        roomToken,
        playerId,
        seat
    })
    return true;
}