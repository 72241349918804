import React, { useState } from 'react';
import {
    observer
} from 'mobx-react-lite';
import { toJS } from 'mobx';
import PokerCard from '../cards/PokerCard';
// import {
//     getCardSource,
//     getCardBack
// } from '../../../../utils/cardhelper';
import {
    Container
} from '../../../controls';

const PokerHandStaticFold = observer(({
    gameSession,
    cardWidth = 160,
    containerOption,
    leftMargin = 0,
    hugeOverlappedView = false
}: any) => {
    const [revealed, setRevealed] = useState(false);
    const usePlayerHand = gameSession?.currentActor?.matchData?.hand || null;
    const handVisible = !!gameSession?.currentMatch?.round &&
        gameSession?.currentActor?.matchData?.joined &&
        usePlayerHand?.length;
    const cardWidthDiff = cardWidth / 160;


    let updatedContainerOption = [
        {}, {}
    ]
    if (hugeOverlappedView) {
        updatedContainerOption = [{
            position: 'absolute',
            left: 0,
            top: 0
        }, {
            position: 'absolute',
            left: 256,
            top: 96
        }]
    }
    return handVisible ? <Container
        containerOption={{
            position: 'relative',
            margin: [5, 10, 0, 8 + leftMargin],
            widthPx: cardWidth * 2 + 48,
            heightPx: 256 * cardWidthDiff,
            zIndex: 1,
            ...(containerOption || {})
        }}
    >
        {(usePlayerHand || []).map((card: any, i: number) => {
            return <PokerCard
                key={`cardstatic-${i}`}
                cardWidth={cardWidth}
                revealOnClick={true}
                card={toJS(card)}
                deck={gameSession.sessionDeck}
                draggable={false}
                cardRevealed={revealed}
                onMouseDown={() => setRevealed(true)}
                onMouseUp={() => setRevealed(false)}
                cardOption={{
                    margin: [2, 0, 0, 16],
                    draggable: false,
                    float: 'left',
                    ...(updatedContainerOption[i] || {})
                    // left: i * (cardWidth * cardWidthDiff)
                }}
            />
        })}
    </Container> : null;
})

export default PokerHandStaticFold;