import Resizer from "react-image-file-resizer";
import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react'
import {
    // List,
    // ConfiguratorItem,
    Container,
    ActionButton,
    InputFile,
    ImageComponent,
    IconButton
    // TextField,
    // ProgressBar
} from '../../components/controls';

const AccountAvatarUpload = observer(({
    sdk,
    onNextItem,
    handleOnBack
}: any) => {
    const [avatar, setAvatar]: any = useState(sdk.loadFromStorage('customavatar', true) || null);

    const openFilePicker = () => {
        //@ts-ignore
        navigator.camera.getPicture((fileUri: any) => {
            const buildFile = `data:image/jpeg;base64,${fileUri}`;
            // save this image to the localstorage
            sdk.saveInStorage('customavatar', buildFile, true);

            // Set the avatar in the state
            setAvatar(buildFile);
        }, (e: any) => {
        }, {
            quality: 70,
            allowEdit: process.env.REACT_APP_MOBILE_IOS === 'true',
            //@ts-ignore
            destinationType: Camera.DestinationType.DATA_URL,
            //@ts-ignore
            sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
            //@ts-ignore
            encodingType: Camera.EncodingType.JPEG,
            targetWidth: 256,
            targetHeight: 256
        });
    }

    const resizeFile = (file: any) => new Promise((resolve) => {
        Resizer.imageFileResizer(file, 256, 1024, 'JPEG', 80, 0, (uri: any) => {
            resolve(uri);
        }, 'base64');
    })

    const uploadFile = () => {
        sdk.uploadFile('custom', 'https://media.couchgames.wtf/poker/', avatar).then((upload: any) => {
            if (upload?.json?.result === 'ok') {
                sdk.appState.user.fetchUser(false).then(() => {
                    handleOnBack();
                    sdk.appState.showMessagebox(
                        'dialog.avatar.feedback',
                        'dialog.avatar.upload.success',
                        null,
                        null,
                        true,
                        []
                    )
                })
            } else {
                sdk.appState.showMessagebox(
                    'dialog.avatar.feedback',
                    'dialog.avatar.upload.failed',
                    null,
                    null,
                    true,
                    [],
                    false,
                    {},
                    'warning'
                )
            }
        })
    }

    const changeAvatarImage = async (event: any) => {
        //@ts-ignore
        const file = event.target.files[0];
        const image = await resizeFile(file);

        if (image) {
            // save this image to the localstorage
            sdk.saveInStorage('customavatar', image, true);
            setAvatar(image);
        }
    }

    return <>
        <Container
            containerOption={{
                widthPx: 800,
                heightPx: 380,
                margin: [32, 0, 0, 96]
            }}
        >
            {process.env.REACT_APP_MOBILE === 'true' ? (
                <IconButton
                    source={avatar ? avatar : './data/avatar/0.jpg'}
                    buttonOption={{
                        widthPx: 256,
                        heightPx: 256,
                        borderRadiusPercent: 50
                    }}
                    onClick={openFilePicker}
                />
            ) : (
                <InputFile
                    id={'avatarupload'}
                    onChange={changeAvatarImage}
                >
                    <ImageComponent
                        source={avatar ? avatar : './data/avatar/0.jpg'}
                        imageOption={{
                            widthPx: 256,
                            heightPx: 256,
                            borderRadiusPercent: 50
                        }}
                    />
                </InputFile>
            )}

        </Container>
        <Container
            containerOption={{
                widthPx: 800,
                heightPx: 192,
                margin: [0, 0, 0, 96]
            }}
        >
            <ActionButton
                actionColor={'red'}
                buttonOption={{
                    widthPx: 256
                }}
                onClick={() => handleOnBack()}
                translationKey={'button.avatar.back'}
            />
            <ActionButton
                actionColor={'green'}
                buttonOption={{
                    widthPx: 256,
                    margin: [0, 0, 0, 32]
                }}
                disabled={!avatar}
                translationKey={'button.avatar.save'}
                onClick={() => uploadFile()}
            />
        </Container>
    </>
});

export default AccountAvatarUpload;