import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../utils/helper';
import {
    Container,
    Icon
} from '../controls'

const StatusBar = observer(({ sdk }: any) => {
    const user = sdk.appState.user;
    const bonusAvailable =
        sdk.appState.user?.userBoni?.getBoniTime('moneyAd') === 0 ||
        sdk.appState.user?.userBoni?.getBoniTime('moneyDaily') === 0
    const userName = user?.userData?.name || '';

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 64,
            float: 'left',
            bgColor: 'rgba(0, 0, 0, .4)',
            color: '#fff',
            fontSize: 28,
            borderRadius: 16
        }}
    >
        <Container
            containerOption={{
                widthPx: 240,
                heightPx: 60,
                lineHeight: 60,
                margin: [2, 8, 0, 0],
                float: 'left',
                fontWeight: 'bold',
                textAlign: 'left',
                color: '#fff'
            }}
            className={bonusAvailable ? 'heartbeat' : undefined}
            onClick={() => sdk.appState.openGameStore({
                view: 'credit'
            })}
        >
            <Icon
                source={'./data/ui/chip.png'}
                iconOption={{
                    widthPx: 40,
                    heightPx: 40,
                    float: 'left',
                    margin: [10, 8, 0, 8]
                }}
            />
            {user?.userData?.creditLabel || 0}
        </Container>
        <Container
            containerOption={{
                widthPx: 240,
                heightPx: 60,
                lineHeight: 60,
                margin: [2, 8, 0, 0],
                float: 'left',
                fontWeight: 'bold',
                textAlign: 'left',
                color: '#fff'
            }}
            onClick={() => sdk.appState.openGameStore({
                view: 'coin'
            })}
        >
            <Icon
                source={'./data/ui/coin.png'}
                iconOption={{
                    widthPx: 40,
                    heightPx: 40,
                    float: 'left',
                    margin: [10, 8, 0, 8]
                }}
            />
            {user?.userData?.coinLabel || 0}
        </Container>
        <Container
            containerOption={{
                widthPx: 720,
                heightPx: 60,
                lineHeight: 60,
                margin: [2, 8, 0, 0],
                float: 'right',
                textAlign: 'right'
            }}
            onClick={() => {
                sdk.appState.user.fetchUser();
                sdk.appState.openSetting('account');
            }}
        >
            <Icon
                source={sdk.appState.user.premium ? './data/icon/vip.svg' : './data/ui/user.svg'}
                iconOption={{
                    widthPx: 32,
                    heightPx: 32,
                    float: 'right',
                    margin: [14, 8, 0, 8]
                }}
            />
            <Container
                containerOption={{
                    fontSize: 26,
                    float: 'right'
                }}
            >
                {userName}
            </Container>
            {userName.length === 13 && !isNaN(userName.slice(6)) ? (
                <Container
                    containerOption={{
                        fontSize: 20,
                        float: 'right',
                        margin: [0, 8, 0, 0]
                    }}
                >
                    <span>{translateKey('text.changenamehere')}</span>{' '}
                </Container>
            ) : null}


        </Container>
    </Container>
});

export default StatusBar;