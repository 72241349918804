import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../../controls/index';
import {
    translateKey
} from '../../../../../utils/helper'

const GameDigitalCardClock = observer(({
    sdk
}: any) => {
    const {
        appState
    } = sdk;
    const currentSession = appState?.currentSession;
    
    return currentSession?.currentMatch?.clock ? <Container
        containerOption={{
            float: 'left',
            widthPx: 210,
            heightPx: 72,
            margin: [16, 10, 0, 5],
            borderRight: 'solid #879892',
            borderSize: 2
        }}
    >
        <Container
            containerOption={{
                widthPx: 200,
                heightPx: 32,
                fontSize: 22,
                fontWeight: 'bold',
                float: 'left',
                lineHeight: 32,
                color: '#fff',
                textAlign: 'center'
            }}
        >
            {translateKey('game.text.handview.nextblinds')}
        </Container>
        <Container
            containerOption={{
                widthPx: 200,
                heightPx: 32,
                fontSize: 20,
                fontWeight: 'bold',
                float: 'left',
                lineHeight: 32,
                color: '#dd985d',
                textAlign: 'center'
            }}
        >
            {currentSession?.currentMatch?.clock?.timeString || ''}
        </Container>
    </Container> : null;
})


export default GameDigitalCardClock;