

// }
// dealerGlobalDeal
import {
    observer
} from 'mobx-react-lite';
import {
    ActionButtonRound
} from '../../../controls';

const GameDealerButton = observer(({
    gameSession
}: any) => {

    let globalDealLabel = gameSession?.ui?.buttonDeal ?
        'game.button.handview.deal' : undefined;


    if (globalDealLabel === undefined &&  gameSession?.currentMatch?.round?.status === 3) {
        globalDealLabel = `game.button.handview.deal.showdown`;
    } else {
        if (gameSession?.currentMatch?.round?.status < 4) {
            globalDealLabel = `game.button.handview.deal.status.${gameSession?.currentMatch?.round?.status || 0}`
        } else {
            globalDealLabel = 'game.button.handview.deal';
        }

    }

    return <ActionButtonRound
        translationKey={globalDealLabel}
        buttonOption={{
            position: 'absolute',
            right: 32,
            bottom: 32,
            zIndex: 1,
            widthPx: 96,
            heightPx: 96,
            margin: [0, 18, 0, 0],
            fontSize: 24
        }}
        actionColor={'green'}
        buttonVisibility={gameSession?.ui?.buttonDealer}
        onClick={() => gameSession?.currentMatch?.dealerGlobalDeal()}
    />
});


export default GameDealerButton;