import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react';
import {
    OptionTextbox
} from '../../../controls';
import GameOverlaySaveBar from '../GameOverlaySaveBar';


const ClockConnect = observer(({
    sdk,
    onClose
}: any) => {
    const [clockCode, setClockCode] = useState('');

    return <>
        <OptionTextbox
            id={'config-clockcode'}
            label={'text.common.clockcode'}
            value={clockCode}
            placeholder={'code'}
            maxLength={4}
            upperCase={true}
            containerOption={{
                margin: [16, 0, 16, 140],
                float: 'left'
            }}
            onInput={(txt: any) => setClockCode(txt)}
        />

        <GameOverlaySaveBar
            onSave={() => {
                if(sdk?.appState?.currentSession) {
                    sdk.appState.currentSession.adminConnectClock(clockCode)
                }
                // Send the message
                onClose()
            }}
            onCancel={() => {
                onClose()
            }}
            containerOption={{
                margin: [300, 0, 0, 0]
            }}
        />
    </>
});

export default ClockConnect;