import type AppMessageHandler from "../messagehandler";

export default function adminConnectClock(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    code: string
): boolean {
    messageHandler.sendMessage(1090, {
        roomToken,
        playerId,
        roomCode: code
    })
    return true;
}