import {
    observer
} from 'mobx-react-lite';
import {
    useState
} from 'react';
import { translateKey } from '../../../utils/helper';
import {
    ActionButton,
    ImageComponent,
    List,
    TextField,
    RadioButton,
    Checkbox,
    Container
} from '../../controls'

const JoinMenu = observer(({
    sdk,
    tv = false,
    embedded = false
}: any) => {
    const {
        appState
    } = sdk;
    const [device, setDevice] = useState(tv ? appState.tvdevice || 'tablet' : undefined);
    const [dealer, setDealer] = useState(0);
    const [dealerPin, setDealerPin] = useState('');
    const tvImageWidth = tv ? 252 * 1.2 : 0;

    return <>
        {tv ? (
            <ImageComponent
                imageOption={{
                    widthPx: tvImageWidth,
                    heightPx: 189 * 1.2,
                    float: 'left',
                    margin: [256, 0, 0, 64],
                    borderSize: 2,
                    border: 'solid #fff'
                }}
                source={`./data/tutorial/game-${device}.jpg`}
            />
        ) : null}
        <List
            id={'joinlist'}
            vertical={true}
            filterNullComponents={true}
            components={[
                <TextField
                    id={'input_roomcode'}
                    placeholder={'text.common.roomcode'}
                    maxLength={4}
                    upperCase={true}
                    store={appState}
                    storeProperty={'roomCode'}
                    inputOption={{
                        widthPx: embedded ? 480 : 240,
                        borderRadius: 24,
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                />,
                dealer ?
                    <TextField
                        id={'input_dealercode'}
                        placeholder={'text.common.dealercode'}
                        maxLength={8}
                        inputType={'number'}
                        value={dealerPin}
                        onManipulateInput={(code: string) => code.replace(/[^0-9]/g, '').slice(0, 8)}
                        onInput={(v: string) => setDealerPin(v)}
                        upperCase={true}
                    />
                    : null,
                tv ? <>
                    <RadioButton
                        id={'radio_tablemode'}
                        label={'checkbox.tvmode.tablet'}
                        value={'tablet'}
                        selected={device === 'tablet'}
                        onSelect={(v: string) => setDevice(v)}
                    />
                    <RadioButton
                        id={'radio_tvmode'}
                        label={'checkbox.tvmode.tv'}
                        value={'tv'}
                        selected={device === 'tv'}
                        onSelect={(v: string) => setDevice(v)}
                    />
                </> : null, 
                // <Checkbox
                //     id={'join-dealer'}
                //     value={dealer}
                //     label={'checkbox.join.dealer'}
                //     containerOption={{
                //         float: 'left',
                //         widthPx: 440
                //     }}
                //     labelOption={{
                //         widthPx: 310
                //     }}
                //     onChange={() => setDealer(dealer ? 0 : 1)}
                // />,
                <ActionButton
                    id={'btn_join'}
                    translationKey={'button.mainmenu.join'}
                    actionColor={'green'}
                    buttonOption={{
                        margin: [32, 32, 0, 0],
                        padding: [0, 8, 0, 8],
                        lineHeight: 48,
                        widthPx: 256,
                        textAlign: 'left'
                    }}
                    icon={'./data/icon/join.svg'}
                    onClick={() => appState.joinGame(device || undefined, undefined, dealer ? dealerPin : null)}
                />,
                !embedded ? <ActionButton
                    id={'btn_back'}
                    translationKey={'button.back'}
                    actionColor={'red'}
                    buttonOption={{
                        margin: [32, 32, 0, 0],
                        padding: [0, 8, 0, 8],
                        lineHeight: 48,
                        widthPx: 256,
                        textAlign: 'left'
                    }}
                    icon={'./data/icon/backblack.svg'}
                    onClick={() => appState.openMainMenu()}
                /> : null
            ]}
            listOption={{
                widthPx: embedded ? 640 : 350,
                heightPx: 112
            }}
            containerOption={{
                margin: embedded ? [96, 0, 0, 175] : [160, 0, 0, tv ? 96 : 500],
                // bgColor: embedded ? '#000' : undefined,
                float: 'left',
                widthPx: embedded ? 640 : 350,
                heightPx: embedded ? 240 : 120
            }}
        />
        {tv ? (
            <Container
                containerOption={{
                    widthPx: tvImageWidth,
                    heightPx: 392,
                    float: 'left',
                    margin: [220, 0, 0, 64],
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 24
                }}
                source={`./data/tutorial/game-${device}.jpg`}
            >
                <div dangerouslySetInnerHTML={{
                    __html: translateKey('tutorial.whatistv')
                }} />
            </Container>
        ) : null}
    </>
});

export default JoinMenu;