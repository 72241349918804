const getLocalStorage = (forceLocalstorage: boolean = false): Storage => {
    // Mobile Games only use the localStorage
    if (process.env.REACT_APP_MOBILE !== "false" || forceLocalstorage) {
        return localStorage;
    }
    // this is only for the websessions
    return sessionStorage || localStorage;
}

const writeAppConfig = (data: string) => {
    return new Promise((resolve, reject) => {
        //@ts-ignore
        window.resolveLocalFileSystemURL(cordova.file.dataDirectory , (dirpar: any) => {
            dirpar.getDirectory('bulletspokercache', { create: true }, (dir: any) => {
                dir.getFile('user', { create: true, exclusive: false }, (fileEntry: any) => {
                    fileEntry.createWriter((fileWriter: any) => {
                        fileWriter.onwriteend = resolve
                        fileWriter.onerror = reject
                        fileWriter.write(new Blob([data], { type: 'text/plain' }));
                    });
                }, reject);
            }, reject);
        }, reject);
    });
}

const readAppConfig = () => {
    return new Promise((resolve, reject) => {
        //@ts-ignore
        window.resolveLocalFileSystemURL(cordova.file.dataDirectory , (dirpar: any) => {
            dirpar.getDirectory('bulletspokercache', { create: true }, (dir: any) => {
                dir.getFile('user', { create: true, exclusive: false }, (fileEntry: any) => {
                    fileEntry.file((file: any) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            console.log('LOADEND', reader)
                            resolve(reader.result)
                        };
                        reader.onerror = reject;
                        reader.readAsText(file);
                    });
                }, reject);
            }, reject);
        }, reject);
    });
}

const getAppName = (): string => {
    return process.env.REACT_APP_GAMESERVICE || '';
}

export {
    getLocalStorage,
    getAppName,
    writeAppConfig,
    readAppConfig
}