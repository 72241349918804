import {
    observer
} from 'mobx-react-lite';
import PokerCard from '../cards/PokerCard';
import {
    Container,
    ActionButton,
    ActionButtonRound
} from '../../../controls';

const PokerHandButtons = observer(({
    gameSession
}: any) => {
    const usePlayerHand = gameSession?.currentActor?.matchData?.hand || null;
    const handVisible = !!gameSession?.currentMatch?.round &&
        gameSession?.currentActor?.matchData?.joined &&
        usePlayerHand?.length &&
        !gameSession?.currentActor?.matchData?.folded && 
        !gameSession?.currentMatch?.round?.allInShowdown;
    const isCurrentPlayer = (!gameSession?.isDigitalCardMode &&
        gameSession?.currentMatch?.round?.currentPlayer === gameSession?.currentActor?.id) || (gameSession?.isDigitalCardMode && gameSession?.currentActor?.playing);
    const foldPossible = handVisible;
    const checkPossible = (isCurrentPlayer && gameSession?.ui?.buttonCheck === 2) || !isCurrentPlayer;
    const showPreButtons = !isCurrentPlayer;

    const showReveal = gameSession?.currentMatch?.round?.finished;
    const showRevealStatus = gameSession?.currentActor?.matchData?.handRevealed;
    const open = gameSession?.currentActor?.matchData?.showHand || false;

    return handVisible ?
        <Container
            containerOption={{
                position: 'absolute',
                margin: [5, 10, 0, 0],
                left: 0,
                bottom: 0,
                widthPx: 220,
                heightPx: 192,
                zIndex: 1
            }}
        >
            {showReveal ?
                <>
                    <ActionButtonRound
                        id={'left-reveal'}
                        actionColor={'blue'}
                        buttonOption={{
                            margin: [4, 0, 0, 18],
                            widthPx: 80,
                            heightPx: 80,
                            float: 'left'
                        }}
                        onClick={() => gameSession?.currentMatch?.playerReveal(1)}
                        buttonVisibility={(showRevealStatus === 1 || showRevealStatus === 3) ? 1: 2}
                    >
                        <PokerCard
                            key={`reveal-left-hand`}
                            cardRevealed={true}
                            card={(open || showRevealStatus === 1 || showRevealStatus === 3) ? usePlayerHand[0] : ''}
                            deck={gameSession.sessionDeck}
                            cardWidth={32}
                            microcard={true}
                            glow={false}
                            glowSize={'small'}
                            cardOption={{
                                margin: [0, 0, 0, 16]
                            }}
                        />
                    </ActionButtonRound>
                    <ActionButtonRound
                        actionColor={'blue'}
                        id={'right-reveal'}
                        buttonOption={{
                            margin: [4, 16, 0, 18],
                            widthPx: 80,
                            heightPx: 80,
                            float: 'right'
                        }}
                        onClick={() => gameSession?.currentMatch?.playerReveal(2)}
                        buttonVisibility={(showRevealStatus === 2 || showRevealStatus === 3) ? 1: 2}
                    >
                        <PokerCard
                            key={`reveal-left-hand`}
                            cardRevealed={true}
                            card={(open || showRevealStatus === 2 || showRevealStatus === 3) ? usePlayerHand[1] : ''}
                            deck={gameSession.sessionDeck}
                            cardWidth={32}
                            microcard={true}
                            glow={false}
                            glowSize={'small'}
                            cardOption={{
                                margin: [0, 0, 0, 16]
                            }}
                        />
                    </ActionButtonRound>
                    <ActionButton
                        actionColor={'green'}
                        translationKey={'button.game.reveal.all'}
                        buttonOption={{
                            widthPx: 192,
                            margin: [32, 0, 0, 0],
                            padding: [0, 8, 0, 8],
                            lineHeight: 48
                        }}
                        disabled={showRevealStatus === 3}
                        onClick={() => gameSession?.currentMatch?.playerReveal(3)}
                    />
                </>
                : (
                    <>
                        <ActionButton
                            actionColor={showPreButtons ? 'black' : 'red'}
                            buttonOption={{
                                widthPx: 192,
                                margin: [0, 0, 0, 0],
                                padding: [0, 8, 0, 8],
                                lineHeight: 48
                            }}
                            icon={showPreButtons ? `./data/ui/radiobutton${gameSession?.currentActor?.matchData?.prefolded ? 1 : 0}.svg` : undefined}
                            disabled={!foldPossible}
                            onClick={() => showPreButtons ?
                                gameSession.currentMatch.playerPreFold() :
                                gameSession.currentMatch.playerFold()
                            }
                        >
                            Fold
                        </ActionButton>
                        <ActionButton
                            actionColor={showPreButtons ? 'black' : 'green'}
                            buttonOption={{
                                widthPx: 192,
                                margin: [32, 0, 0, 0],
                                padding: [0, 8, 0, 8],
                                lineHeight: 48
                            }}
                            icon={showPreButtons ? `./data/ui/radiobutton${gameSession?.currentActor?.matchData?.prechecked ? 1 : 0}.svg` : undefined}
                            disabled={!checkPossible}
                            onClick={() => showPreButtons ?
                                gameSession.currentMatch.playerPreCheck() :
                                gameSession.currentMatch.playerCheck()
                            }
                        >
                            Check
                        </ActionButton>
                    </>
                )}

        </Container> : null
})

export default PokerHandButtons;