import {
    observer
} from 'mobx-react-lite';
import React from 'react';
import {
    List,
    Container,
    Icon,
    IconButton
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper';
import PokerCard from '../../ingame/cards/PokerCard';

const listWinningHands = [
    {
        cards: [
            [4, 10],
            [4, 11],
            [4, 12],
            [4, 13],
            [4, 14]
        ]
    },
    {
        cards: [
            [3, 4],
            [3, 5],
            [3, 6],
            [3, 7],
            [3, 8]
        ]
    },
    {
        cards: [
            [1, 12],
            [2, 12],
            [3, 12],
            [4, 12],
            null
        ]
    },
    {
        cards: [
            [2, 13],
            [2, 13],
            [1, 3],
            [2, 3],
            [3, 3]
        ]
    },
    {
        cards: [
            [2, 12],
            [2, 14],
            [2, 5],
            [2, 7],
            [2, 9]
        ]
    },
    {
        cards: [
            [2, 13],
            [4, 12],
            [1, 11],
            [2, 10],
            [3, 9]
        ]
    },
    {
        cards: [
            [4, 7],
            [2, 7],
            [3, 7],
            null,
            null
        ]
    },
    {
        cards: [
            [4, 9],
            [2, 9],
            [3, 13],
            [4, 13],
            null
        ]
    },
    {
        cards: [
            [4, 6],
            [2, 6],
            null,
            null,
            null
        ]
    },
    {
        cards: [
            [2, 11],
            null,
            null,
            null,
            null
        ]
    }
]
const WinnerHand = ({ sdk, hand, j }: any) => {

    return <>
        {hand.cards.map((card: any, i: number) =>
        (
            <PokerCard
                key={`pokercard-${j}-${i}`}
                cardRevealed={card === null ? false : true}
                card={card ? {
                    suit: card[0],
                    value: card[1]
                } : null}
                deck={sdk.appState.currentSession.sessionDeck}
                cardWidth={48}
                microcard={card === null ? false : true}
                cardOption={{
                    margin: [10, 0, 0, 6],
                    float: 'left'
                }}
            />
        )
        )}
        {translateKey(`text.player.hand.${10 - j}`)}
    </>
}
const TrainerWinningHand = observer(({
    sdk,
    onClose
}: any) => {

    return <List
        id={'winninghand'}
        vertical={true}
        filterNullComponents={true}
        components={listWinningHands.map((hand: any, j: number) => <WinnerHand sdk={sdk} j={j} hand={hand} />)}
        listOption={{
            widthPx: 600,
            heightPx: 96,
            borderSize: 3,
            borderBottom: `solid #fff`
        }}
        containerOption={{
            margin: [0, 0, 0, 0],
            float: 'left',
            widthPx: 620,
            heightPx: 660,
            whiteSpace: 'nowrap',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
    />
});

export default TrainerWinningHand;