import { useEffect } from 'react'
import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    IconButton
} from '../../../controls';
import PokerTable from './PokerTable';
import PokerTablePlayer from './PokerTablePlayer';
import PokerWinnerBanner from './PokerWinnerBanner';
import PokerTableGlobalBuyIn from './PokerTableGlobalBuyIn';
import PokerTableSitdownInfo from './PokerTableSitdownInfo';
import PokerTableRoomCode from './PokerTableRoomCode';
import PokerTableBlinds from './PokerTableBlinds';
import PokerTableShare from './PokerTableShare';
import PokerTableIamBack from './PokerTableIamBack';

const PokerTableView = observer(({
    sdk
}: any) => {
    const gameRound = sdk.appState.currentSession?.currentMatch?.round;
    const gameSession = sdk?.appState?.currentSession;

    useEffect(() => {
        if (sdk?.appState?.currentSession?.tv === true) {
            sdk.layoutManager.showVerticalCentered(true)
        }
        return () => {
            sdk.layoutManager.showVerticalCentered(false)
        }
        // eslint-disable-next-line
    }, [sdk?.appState?.currentSession])

    return gameSession ? <>
        {/* LEFT */}
        <Container
            id={'left'}
            containerOption={{
                float: 'left',
                widthPx: 220,
                heightPx: 750
            }}
        >
            <Container
                id={'left-top'}
                containerOption={{
                    float: 'left',
                    widthPx: 220,
                    heightPx: 200
                }}
            >
                <PokerTableBlinds
                    gameSession={gameSession}
                />
                <PokerTableShare
                    gameSession={gameSession}
                />
            </Container>
            <Container
                id={'left-middle'}
                containerOption={{
                    float: 'left',
                    widthPx: 220,
                    heightPx: 350
                }}
            >
                <PokerTablePlayer
                    pos={9}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
            </Container>
            <Container
                id={'left-bottom'}
                containerOption={{
                    float: 'left',
                    widthPx: 220,
                    heightPx: 200
                }}
            >
            </Container>
        </Container>
        {/* CENTER */}
        <Container
            id={'center'}
            containerOption={{
                float: 'left',
                widthPx: 890,
                heightPx: 750
            }}
        >
            <Container
                id={'center-top'}
                containerOption={{
                    float: 'left',
                    widthPx: 890,
                    heightPx: 220
                }}
            >
                <PokerTablePlayer
                    pos={0}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTablePlayer
                    pos={1}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTablePlayer
                    pos={2}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTablePlayer
                    pos={3}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
            </Container>
            <Container
                id={'center-middle'}
                containerOption={{
                    float: 'left',
                    position: 'relative',
                    widthPx: 890,
                    heightPx: 310,
                    // margin: [0, 0, 0, 80]
                }}
            >
                <PokerTable sdk={sdk} round={gameRound} gameSession={gameSession} />
                <PokerWinnerBanner bannerLayout={1} gameSession={gameSession} />
                <PokerTableGlobalBuyIn gameSession={gameSession} appState={sdk.appState} />
                <PokerTableSitdownInfo gameSession={gameSession} appState={sdk.appState} />
                <PokerTableIamBack gameSession={gameSession} appState={sdk.appState} />
            </Container>
            <Container
                id={'center-bottom'}
                containerOption={{
                    float: 'left',
                    widthPx: 890,
                    heightPx: 220
                }}
            >
                <PokerTablePlayer
                    pos={8}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTablePlayer
                    pos={7}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTablePlayer
                    pos={6}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
                <PokerTablePlayer
                    pos={5}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
            </Container>
        </Container>
        {/* RIGHT */}
        <Container
            id={'right'}
            containerOption={{
                float: 'left',
                widthPx: 220,
                heightPx: 750
            }}
        >
            <Container
                id={'right-top'}
                containerOption={{
                    float: 'left',
                    widthPx: 220,
                    heightPx: 200
                }}
            >
                <PokerTableRoomCode
                    gameSession={gameSession}
                    containerOption={{
                        margin: [32, 0, 0, 24]
                    }}
                />
                <IconButton
                    buttonOption={{
                        widthPx: 40,
                        heightPx: 40,
                        border: 'none',
                        margin: [16, 16, 0, 0],
                        float: 'right'
                    }}
                    source={'./data/ui/settings.svg'}
                    onClick={() => sdk.appState.openSetting('game')}
                />

                <IconButton
                    buttonOption={{
                        widthPx: 40,
                        heightPx: 40,
                        border: 'none',
                        margin: [16, 16, 0, 0],
                        float: 'right'
                    }}
                    source={'./data/icon/help.svg'}
                    onClick={() => {
                        gameSession.openOverlay(
                            {},
                            'trainer',
                            'pokertrainer'
                        )
                    }}
                />
                {gameSession?.admin ? (
                    <IconButton
                        buttonOption={{
                            widthPx: 60,
                            heightPx: 40,
                            border: 'none',
                            margin: [16, 16, 0, 0],
                            float: 'right'
                        }}
                        source={'./data/icon/admin.svg'}
                        onClick={() => gameSession.openOverlay(
                            {},
                            'room',
                            'roomsetting'
                        )}
                    />
                ) : null}

            </Container>
            <Container
                id={'right-middle'}
                containerOption={{
                    float: 'left',
                    widthPx: 220,
                    heightPx: 350
                }}
            >
                <PokerTablePlayer
                    pos={4}
                    // gameSeats={gameSeats}
                    gameSession={gameSession}
                />
            </Container>
            <Container
                id={'right-bottom'}
                containerOption={{
                    float: 'left',
                    widthPx: 220,
                    heightPx: 200
                }}
            >
            </Container>
        </Container>
    </> : null
});

export default PokerTableView;