import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../controls';
import {
    openAboutUs,
    openImprint,
    openSupport,
    onRateApp,
    rateAppPossible
} from '../../../utils/helperfunction';

const SettingCommon = observer(({
    sdk,
    onNextItem
}: any) => {
    // const {
    //     appState,
    //     gameLoader
    // } = sdk;

    return <List
        className={'settinglist'}
        containerOption={{
            widthPx: 930,
            heightPx: 600,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 930,
            heightPx: 100,
            lineHeight: 100,
            color: '#fff',
            fontSize: 28,
            borderSize: 2,
            borderBottom: `solid #fff`
        }}
        components={[
            <ConfiguratorItem
                label={'text.common.language'}
                onNextItem={() => onNextItem('language')}
                icon={'language'}
            />,
            ...(process.env.REACT_APP_GAMEWEBVIEW !== 'true' ? [<ConfiguratorItem
                label={'button.settings.device'}
                onNextItem={() => onNextItem('device')}
                icon={'client'}
            />] : []),
            ...(process.env.REACT_APP_GAMEWEBVIEW !== 'true' ? [<ConfiguratorItem
                label={'button.settings.cards'}
                onNextItem={() => onNextItem('cards')}
                icon={'poker-cards'}
            />] : []),
            ...(rateAppPossible() ? [
                <ConfiguratorItem
                label={'text.common.rateapp'}
                onClick={() => onRateApp()}
                icon={'rate'}
            />
            ] : []),
            <ConfiguratorItem
                label={'text.common.contactandsupport'}
                onClick={() => openSupport()}
                icon={'contact'}
            />,
            <ConfiguratorItem
                label={'text.common.imprint'}
                onClick={() => openImprint()}
                icon={'law'}
            />,
            <ConfiguratorItem
                label={'text.common.aboutus'}
                onClick={() => openAboutUs()}
                icon={'info'}
            />
        ]}
    />
});

export default SettingCommon;