import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const InputSlider = observer(({
    id,
    sliderMin,
    sliderMax,
    inputOption,
    onChange,
    children,
    store,
    storeProperty,
    value
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    let useValue = value;

    if (store && storeProperty) {
        if (Array.isArray(storeProperty)) {
            useValue = store[storeProperty[0]][storeProperty[1]];
        } else {
            useValue = store[storeProperty];
        }
    }

    const propsInput = useStyles({
        float: 'left',
        widthPx: 500,
        borderRadius: 30,
        heightPx: 28,
        margin: [8, 0, 8, 0],
        ...(inputOption || {})
    });

    return <input
        id={id}
        min={sliderMin}
        max={sliderMax}
        type={'range'}
        value={useValue}
        className={'gameslider'}
        style={propsInput}
        onInput={(event: any) => {
            //@ts-ignore
            let useInput = parseInt(event.target.value || 0, 10);
            // Update the statepath
            if (store && storeProperty) {
                if (Array.isArray(storeProperty)) {
                    if (store?.updateArrayValue) {
                        store.updateArrayValue(storeProperty[0], storeProperty[1], useInput)
                    }
                } else {
                    if (store?.updateValue) {
                        store.updateValue(storeProperty, useInput)
                    }
                }
            }
            if(onChange) {
                onChange(useInput)
            }
        }}
    />
});

export default InputSlider;