import {
    observer
} from 'mobx-react-lite';
import {
    ImageComponent
} from '../../../controls';

const PokerTableLogo = observer(({
    gameSession,
    widthTable,
    heightTable,
    widthLogo = 760,
    heightLogo = 210
}: any) => {
    const left = (widthTable - widthLogo) / 2;
    const top = (heightTable - heightLogo) / 2;

    return <ImageComponent
        imageOption={{
            position: 'absolute',
            widthPx: widthLogo,
            left,
            top,
            zIndex: 10
        }}
        source={'./data/ui/table_couchgames.png'}
    />
})

export default PokerTableLogo;