function triggerEvent(event: string, data:any) {
    window.dispatchEvent(new CustomEvent(event, {
        detail: data
    }))
}

function subscribeEvent(event: string, eventCallback: any) {
    window.addEventListener(event, eventCallback)
    return {
        unsubscribe: () => window.removeEventListener(event, eventCallback)
    }
}
export {
    triggerEvent,
    subscribeEvent
}