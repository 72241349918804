import type AppMessageHandler from "../messagehandler";
import type AppUser from "../stores/user";

export default function roomJoin(
    messageHandler: AppMessageHandler,
    user: AppUser | undefined,
    roomCode: string | undefined,
    tvMode: string | undefined,
    rejoinRoomToken: string | undefined = undefined,
    referred: boolean = false,
    quickgame: boolean = false,
    dealer: string | null = null
): boolean {
    if(quickgame && (user?.data?.credits || 0) === 0) {
        return false;
    }

    messageHandler.sendMessage(1001, {
        userToken: user?.token,
        playerName: user?.userData?.name || '',
        playerAvatar: user?.userData?.avatar || 0,
        clientToken: messageHandler.createClientToken(),
        blindmode: user?.blindMode || false,
        referred,
        tvMode: tvMode ? true : false,
        tvInfo: typeof tvMode === 'string' ? tvMode : '',
        rejoin: rejoinRoomToken ? true : false,
        rejoinRoomToken,
        roomCode,
        ...(quickgame ? {
            quickgame: {
                filterCash: user?.data?.credits || 0
            }
        }: {}),
        ...(dealer !== null ? {
            dealerMode: true,
            dealerPin: dealer
        }: {})
    })
    return true;
}