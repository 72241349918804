import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import GamePokerClock from './gamepokerclock';
import GameRound from './gameround';
import GamePokerTimer from './gamepokertimer';
import getSdk from '../../../api/index';
import {
    numberInvalid
} from '../../../utils/helper';
import {
    MATCH_EVENT
} from '../../../constants/matchevent';
import {
    MATCH_ROLE
} from '../../../constants/matchrole';

export default class GameMatch {
    public id: number;
    public handMode: number;
    public round: GameRound | null;
    public smallBlind: number;
    public bigBlind: number;
    public status: number;
    public dealer: number | null;
    public dealerMissing: number;
    public lastYouAre: string;
    public running: boolean;

    public positionD: number;
    public positionBB: number;
    public positionSB: number;

    public nextVotes: any;
    public clock: GamePokerClock | null;
    public roundTimer: GamePokerTimer | null;
    public paused: any;

    get isPause() {
        return this.running && this.paused;
    }

    get isRunning() {
        return this.running;
    }

    constructor(matchData: any) {
        makeObservable(this, {
            round: observable,
            smallBlind: observable,
            bigBlind: observable,
            running: observable,
            status: observable,
            paused: observable,
            nextVotes: observable,
            clock: observable,
            roundTimer: observable,
            dealerMissing: observable,
            positionD: observable,
            positionBB: observable,
            positionSB: observable,
            isPause: computed,
            isRunning: computed,
            isDigitalCardMode: computed,
            isShowDown: computed,
            update: action,
            destroyAutostartTimer: action,
            flagAllowSwitchWinDetection: computed,
            isMissdealPossible: computed,
            preFlop: computed,
            flagAllowSetDealer: computed
        })

        this.handMode = matchData.handmode || 0;
        this.id = matchData.id || 0;
        this.positionD = -1;
        this.positionSB = -1;
        this.positionBB = -1;
        this.smallBlind = matchData.smallBlind || 0;
        this.bigBlind = matchData.bigBlind || 0;
        this.status = matchData.status || 0;
        this.lastYouAre = '';
        this.dealerMissing = 0;
        this.running = false;
        if (matchData.pause) {
            this.paused = matchData.pause;
        } else {
            this.paused = null;
        }
        this.dealer = matchData.dealer || null;
        this.nextVotes = matchData.nextVotes || [];
        this.round = matchData.round ? new GameRound(matchData.round) : null
        this.clock = matchData.clock ? new GamePokerClock(matchData.clock) : null;
        this.roundTimer = null;
    }

    getSeatRole(seat: number): number {
        if (seat !== -1) {
            if (this.positionD === seat && this.positionSB === seat) {
                return MATCH_ROLE.DEALER_SMALLBLIND;
            }
            if (this.positionD === seat && this.positionBB === seat) {
                return MATCH_ROLE.DEALER_BIGBLIND;
            }
            if (this.positionD === seat) {
                return MATCH_ROLE.DEALER;
            }
            if (this.positionBB === seat) {
                return MATCH_ROLE.BIGBLIND;
            }
            if (this.positionSB === seat) {
                return MATCH_ROLE.SMALLBLIND;
            }
        }
        return MATCH_ROLE.NONE;
    }

    destroy(): void {
        if (this.round) {
            this.round.destroy();
        }
        this.round = null;
        this.destroyAutostartTimer();
    }

    createSimpleState(): any {
        return {
            round: this.round ? {
                id: this.round.id,
                status: this.round.status,
                betCount: this.round.betCount,
                winner: this.round.winner,
                currentPlayer: this.round.currentPlayer,
                nextPointer: this.round.nextPointer?.playerId,
                lastYouAre: this.lastYouAre
            } : null,
            smallBlind: this.smallBlind,
            bigBlind: this.bigBlind
        }
    }

    destroyAutostartTimer() {
        if (this.roundTimer) {
            this.roundTimer.destroy();
            this.roundTimer = null;
        }
    }

    // Update the matchinformation. Check if we have a new round
    update(matchData: any): any {
        const oldState = this.createSimpleState();

        this.handMode = matchData.handmode || 0;
        this.id = matchData.id || 0;
        this.smallBlind = matchData.smallBlind || 0;
        this.bigBlind = matchData.bigBlind || 0;
        this.status = matchData.status || 0;
        this.running = matchData.running || false;
        this.dealer = matchData.dealer || null;
        this.nextVotes = matchData.nextVotes || [];
        this.dealerMissing = matchData.dealerMissing || 0;

        this.positionD = matchData?.playerPosition?.d === 0 ? 0 : (matchData?.playerPosition?.d || -1);
        this.positionSB = matchData?.playerPosition?.sb === 0 ? 0 : (matchData?.playerPosition?.sb || -1);
        this.positionBB = matchData?.playerPosition?.bb === 0 ? 0 : (matchData?.playerPosition?.bb || -1);

        if (matchData.autostartTimer) {
            if (!this.roundTimer) {
                this.roundTimer = new GamePokerTimer();
            }
            this.roundTimer.update(matchData.autostartTimer.remaining)
        } else {
            this.destroyAutostartTimer();
        }


        if (matchData.pause) {
            this.paused = matchData.pause;
        } else {
            this.paused = null;
        }

        if (matchData.clock) {
            this.clock?.update(matchData.clock)
        }
        if (matchData.round) {
            if (this.round) {
                this.round.update(matchData.round)
            } else {
                this.round = new GameRound(matchData.round)
            }
        } else {
            if (this.round) {
                this.round = null;
            }
        }

        return this.compareState(oldState, this.createSimpleState());
    }

    handleActorJoin(): void {
        this.lastYouAre = '';
    }

    compareState(oldState: any, newState: any): any {
        const events: any = [];

        // Check if something changed
        if (JSON.stringify(oldState) !== JSON.stringify(newState)) {

            // Do we have a new blind?
            if (
                oldState.smallBlind !== newState.smallBlind ||
                oldState.bigBlind !== newState.bigBlind
            ) {
                // We have a new Round Event
                events.push({
                    id: MATCH_EVENT.NEW_BLINDS,
                    value: {
                        smallBlind: newState.smallBlind,
                        bigBlind: newState.bigBlind
                    }
                })
            }

            // Do we have a new round?
            if (newState?.round?.id > (oldState?.round?.id || 0) && newState?.round?.status === 0) {
                // We have a new Round Event
                events.push({
                    id: MATCH_EVENT.NEW_ROUND,
                    value: newState.round.id
                })
            } else if (
                newState?.round?.nextPointer !== null &&
                newState?.round?.nextPointer === getSdk()?.appState?.currentSession?.currentActor?.id &&
                newState?.round?.nextPointer !== oldState?.round?.nextPointer
            ) {
                events.push({
                    id: MATCH_EVENT.YOURTURN,
                    value: 0
                })
            }

            // Do we have a new round status?
            if (newState?.round?.id !== undefined && (newState?.round?.status !== oldState?.round?.status || newState?.round?.id > (oldState?.round?.id || 0))) {
                // We have a new Round Event Status
                events.push({
                    id: MATCH_EVENT.NEW_ROUND_STATUS,
                    value: newState.round.id
                })
            }

            // Do we have a new winner?
            if (this.isDigitalCardMode) {
                if ((oldState?.round?.winner || []).length === 0) {
                    if ((newState?.round?.winner || []).length > 1) {
                        events.push({
                            id: MATCH_EVENT.SPLITPOT
                        })
                    } else if ((newState?.round?.winner || []).length === 1) {
                        events.push({
                            id: MATCH_EVENT.MATCHWINNER,
                            value: newState.round.winner[0]
                        })
                    }
                }
            }
        }

        if (
            !this.isDigitalCardMode &&
            newState?.round?.currentPlayer === getSdk()?.appState?.currentSession?.currentActor?.id &&
            this.lastYouAre !== `${newState?.round?.id}-${newState?.round?.status}-${newState?.round?.betCount}`
        ) {
            this.lastYouAre = `${newState?.round?.id}-${newState?.round?.status}-${newState?.round?.betCount}`
            events.push({
                id: MATCH_EVENT.YOU_ARE
            })

        }

        return events;
    }

    playerCheck(): void {
        getSdk().getMessageList().check();
    }
    playerCall(): void {
        getSdk().getMessageList().call();
    }
    playerBet(value: number): void {
        getSdk().getMessageList().bet(value);
    }
    playerRaise(value: number): void {
        getSdk().getMessageList().raise(value);
    }
    playerAllIn(): void {
        if (this.isDigitalCardMode && this.round?.hasNextPointer) {
            getSdk().appState.showSecurityBox('dialog.game.security.allin', () => getSdk().getMessageList().allin(), null)
            return;
        }
        getSdk().getMessageList().allin();
    }
    playerDeal(force: boolean = false): void {
        if (this.round?.hasNextPointer && !force && !this.isShowDown) {
            if (!this.round?.isNextPointerReady) {
                const that = this;
                getSdk().appState.showSecurityBox('dialog.security.really.deal', () => that.playerDeal(true), null)
                return;
            }
        }
        getSdk().getMessageList().deal();
    }

    dealerNextRound(): void {
        getSdk().getMessageList().nextRound();
    }

    dealerGlobalDeal(force: boolean = false): void {
        if (this.round?.hasNextPointer && !force && !this.isShowDown) {
            if (!this.round?.isNextPointerReady) {
                const that = this;
                getSdk().appState.showSecurityBox('dialog.security.really.deal', () => that.dealerGlobalDeal(true), null)
                return;
            }
        }
        if (this.status === 0 || (this.status === 1 && !this.round) || this.round?.finished) {
            getSdk().getMessageList().globalNextRound();
        } else {
            getSdk().getMessageList().globalDeal();
        }
    }

    playerReveal(val: number): void {
        if (!this.round?.roundEndPause && (this.isDigitalCardMode || (this.round?.finished || this.round?.allInShowdown))) {
            if (!this.isDigitalCardMode) {
                getSdk().getMessageList().reveal(val)
            } else {
                getSdk().appState.showSecurityBox(
                    val === 3 ?
                        'dialog.game.security.reveal' :
                        'dialog.game.security.revealOne',
                    () => getSdk().getMessageList().reveal(val), 'dummyRevealDisabled')
            }
            // Check if the player is allowed to reveal

        }
    }

    playerIsBack(): void {
        getSdk().getMessageList().iAmBack()
    }

    playerPreFold(): void {
        if (this.round?.roundEndPause) return;
        if (!this.isDigitalCardMode) {
            const canPrefold = getSdk().appState.currentSession?.currentActor?.playing;
            if (canPrefold) {
                getSdk().getMessageList().prefold()
            }
        }
    }

    playerPreCheck(): void {
        if (this.round?.roundEndPause) return;
        if (!this.isDigitalCardMode) {
            const canPrecheck = getSdk().appState.currentSession?.currentActor?.playing;
            if (canPrecheck) {
                getSdk().getMessageList().precheck()
            }
        }
    }

    playerFold(): boolean {
        // 1. Condition: The round is not in pause
        if (this.round?.roundEndPause) return false;
        // 2. Condition: If 100% mode check if the user is playing or is currently active
        if (!this.isDigitalCardMode) {
            const isPlayerTurn = this.round?.currentPlayer === getSdk().appState.currentSession?.currentActor?.id;
            const canPrefold = getSdk().appState.currentSession?.currentActor?.playing && getSdk().appState.currentSession?.currentActor?.matchData?.prefolded === false;
            if (!isPlayerTurn && !canPrefold) return false;
        }
        if (getSdk().appState.currentSession?.currentActor?.matchData?.winner) return false;

        if (this.isDigitalCardMode && this.round?.hasNextPointer) {
            // Is next feature?
            if (!getSdk().appState.currentSession?.currentActor?.matchData?.pointerTurn) {
                getSdk().appState.showErrorMessage(
                    'nofoldpossible',
                    'dialog.fold.yourturn',
                    true
                );
                return false;
            }
        }

        getSdk().appState.showSecurityBox('dialog.game.security.fold', () => getSdk().getMessageList().fold(), 'dummyFoldDisabled')

        return true;
    }

    adminPlacePlayer(playerId: number, seat: number): boolean {
        getSdk().getMessageList().adminEdit('placeplayer', {
            playerId,
            seat
        });
        return true;
    }

    adminKickPlayer(playerId: number): boolean {
        getSdk().getMessageList().adminEdit('kick', {
            playerId
        });
        return true;
    }

    adminSetDealer(playerId: number): boolean {
        getSdk().getMessageList().adminEdit('setdealer', {
            playerId
        });
        return true;
    }

    adminForcePlayerToFold(playerId: number): boolean {
        getSdk().getMessageList().adminEdit('forcefold', {
            playerId
        });
        return true;
    }

    adminSaveBankroll(playerId: number, bankRoll: number): boolean {
        getSdk().getMessageList().adminEdit('bankroll', {
            playerId,
            bankRoll
        });
        return true;
    }

    adminChangeClockRunning(running: boolean): boolean {
        getSdk().getMessageList().adminEdit('clockrunning', {
            running
        });
        return true;
    }

    adminChangeWinDetection(): boolean {
        getSdk().getMessageList().adminEdit('windetection', {});
        return true;
    }

    adminChangeTableUi(): boolean {
        getSdk().getMessageList().adminEdit('uitable', {});
        return true;
    }

    adminSaveBlinds(usb: number, ubb: number): boolean {
        //@ts-ignore
        if (!numberInvalid(usb) && !numberInvalid(ubb)) {
            //@ts-ignore
            const sb = parseInt(usb, 10);
            //@ts-ignore
            const bb = parseInt(ubb, 10);

            if (sb > 0 && sb < bb) {
                getSdk().getMessageList().adminEdit('blindsetting', {
                    smallBlind: sb,
                    bigBlind: bb,
                    level: ((this.clock?.clockLevel || 1) - 1) || 0
                });
                return true;
            }
        }
        return false;
    }

    get isDigitalCardMode() {
        return this.handMode === 1;
    }

    get isShowDown() {
        return this.round?.status === 4;
    }

    get isMissdealPossible() {
        if (this.round) {
            return this.round?.status > 0 && this.round?.status < 4;
        }
        return false;
    }

    get flagAllowSetDealer() {
        return this.handMode === 1 && this.dealer !== null;
    }

    get flagAllowSwitchWinDetection() {
        return this.handMode === 1;
    }

    get preFlop() {
        return this.round?.status === 0 || false;
    }

}