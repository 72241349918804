import {
    observer
} from 'mobx-react-lite';
import SettingCommon from './common/SettingCommon';
import SettingLanguage from './common/SettingLanguage';
import SettingDevice from './common/SettingDevice';
import SettingGame from './game/SettingGame';
import SettingCards from './common/SettingCards';
import SettingTvLayout from './game/SettingTvLayout';
import AccountDetails from '../account/AccountDetails';
import AccountAvatarUpload from '../account/AccountAvatarUpload';
import AccountAvatarSelect from '../account/AccountAvatarSelect';
import AccountRenameUser from '../account/AccountRenameUser';
import AccountSetup from '../account/AccountSetup';
import GameHistory from './history/GameHistory';
import {
    Configurator
} from '../controls';
import { translateKey } from '../../utils/helper';

const Setting = observer(({ sdk }: any) => {
    const {
        appState,
        gameLoader
    } = sdk;
    const currentSetting = appState?.settingView || null;
    const currentSettingOption = appState?.settingOption || {};
    const settingOpen = !gameLoader.isActive && currentSetting !== null;
    const headline = translateKey(`text.settings.head.${currentSetting}`, {
        default: ' ',
        upperSize: true
    });

    const handleOnBack = () => {
        appState.openPreviousSetting();
    }

    const handleOnNext = (data: any) => {
        appState.openSetting(data);
    }

    return settingOpen ? (
        <Configurator
            title={headline}
            onBack={handleOnBack}
            disableBackButton={currentSetting === 'account-connect-user' &&  appState?.user.account.formStep === 3}
            containerOption={{
                position: 'relative'
            }}
        >
            {currentSetting === 'gamehistory' ? <GameHistory sdk={sdk} onNextItem={handleOnNext} /> : null}
            {currentSetting === 'common' ? <SettingCommon sdk={sdk} onNextItem={handleOnNext} /> : null}
            {currentSetting === 'language' ? <SettingLanguage sdk={sdk} onNextItem={handleOnNext} /> : null}
            {currentSetting === 'device' ? <SettingDevice sdk={sdk} onNextItem={handleOnNext} /> : null}
            {currentSetting === 'game' ? <SettingGame sdk={sdk} onNextItem={handleOnNext} /> : null}
            {currentSetting === 'cards' ? <SettingCards sdk={sdk} onNextItem={handleOnNext} /> : null}
            {currentSetting === 'account' ? <AccountDetails sdk={sdk} onNextItem={handleOnNext} options={currentSettingOption} /> : null}
            {currentSetting === 'account-select-avatar' ? <AccountAvatarSelect sdk={sdk} onNextItem={handleOnNext} handleOnBack={handleOnBack}  /> : null}
            {currentSetting === 'account-upload-avatar' ? <AccountAvatarUpload sdk={sdk} onNextItem={handleOnNext} handleOnBack={handleOnBack}  /> : null}
            {currentSetting === 'account-rename-user' ? <AccountRenameUser sdk={sdk} onNextItem={handleOnNext} handleOnBack={handleOnBack}  /> : null}
            {currentSetting === 'account-connect-user' ? <AccountSetup sdk={sdk} onNextItem={handleOnNext} handleOnBack={handleOnBack} setupOption={{
                margin: [72,0,0,240]
            }} /> : null}
            {currentSetting === 'gameviewlayout' ? <SettingTvLayout sdk={sdk} onNextItem={handleOnNext}/> : null}
        </Configurator>
    ) : null

});

export default Setting;