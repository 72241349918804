import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'
import { useDoubleTap } from '../../hooks/doubleTap';

const Container = observer(({
    id,
    containerOption,
    children,
    onClick,
    className,
    containerRef,
    draggable,
    onDragStart,
    onDragEnd,
    onDrop,
    onDoubleTap,
    onMouseDown,
    onMouseMove
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsContainer = useStyles({
        ...(containerOption || {})
    })

    const bindDoubleTap = useDoubleTap(onDoubleTap ? () => {
        onDoubleTap();
      } : null)

    return <div
        id={id ? `${id}-container` : undefined}
        style={propsContainer}
        className={className}
        onClick={(event: any) => onClick ? onClick(event) : null}
        ref={containerRef}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDrop={onDrop}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onTouchStart={onMouseDown}
        onDragOver={(event:any) =>
            event.preventDefault()
        }
        {...bindDoubleTap}
    >
        {children}
    </div>
});

export default Container;