import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import PokerTablePlayerAvatar from '../player/PlayerAvatar';
import PokerTablePlayerBet from '../player/PlayerBet';
import PokerTablePlayerHand from '../player/PlayerHand';
import PokerTablePlayerBankroll from '../player/PlayerBankroll';
import PokerTablePlayerName from '../player/PlayerName';
import PokerTablePlayerKrone from '../player/PlayerKrone';
import PokerTablePlayerEmpty from '../player/PlayerEmpty';
import React from 'react';

const PokerTabletPlayer = observer(({
    pos,
    gameSeats,
    gameSession,
    floating = 'left',
    rotate = false
}: any) => {
    const player = gameSeats[pos];
    const seatVisible = !!player || false;
    const cashEnabled = gameSession?.isDigitalCardMode ? false : true;
    const isCurrentPlayer = (!gameSession?.isDigitalCardMode &&
        gameSession?.currentMatch?.round?.currentPlayer === player?.id) || (gameSession?.isDigitalCardMode && player?.matchData?.pointerTurn);
    
    // const emptyPlayerVisible = gameSession?.tableConfig?.flagAutoSitDown === 0;
    const emptyPlayerVisible = true;

    return seatVisible ? <Container
        id={`seat-${pos}`}
        className={isCurrentPlayer ? 'placer-active' : undefined}
        containerOption={{
            position: 'relative',
            float: floating,
            widthPx: 128,
            heightPx: cashEnabled ? 216 : 188,
            margin: [0, 3, 0, 3],
            opacity: 1.0,
            transform: rotate ? 'rotate(180deg)' : undefined,
            borderRadius: isCurrentPlayer ? 16 : undefined
            // bgColor: isCurrentPlayer ? 'rgba(0,0,0,0.25)' : undefined,
        }}
        onClick={() => {
            if (seatVisible) {
                gameSession.openOverlay(
                    player,
                    'player',
                    'playerdetail'
                )
            }
        }}
    >
        <PokerTablePlayerAvatar
            pos={pos}
            player={player}
            gameSession={gameSession}
            isTablet={true}
            containerOption={{
                widthPx: 112,
                heightPx: 128,
                margin: [8, 8, 0, 0]
            }}
            avatarBackgroundOption={{
                top: 11,
                left: 11,
                widthPx: 90,
                heightPx: 90
            }}
            iconPosition={'top'}
        >
            <PokerTablePlayerHand
                pos={pos}
                player={player}
                containerOption={{
                    position: 'absolute',
                    bottom: 0,
                    widthPx: 108,
                    heightPx: 80
                }}
                gameSession={gameSession}
                cardWidth={44}
                cardOption={{
                    margin: [8, 0, 0, 4]
                }}
            />
        </PokerTablePlayerAvatar>
        {/* <PokerTablePlayerBubble
            pos={pos}
            player={player}
            gameSession={gameSession}
            bubbleOption={{
                top: 12
            }}
        /> */}
        <PokerTablePlayerKrone
            pos={pos}
            player={player}
            imageOption={{
                top: 0,
                right: undefined,
                left: 32
            }}
        />

        <PokerTablePlayerName
            pos={pos}
            player={player}
            containerOption={{
                widthPx: 128,
                textAlign: 'center'
            }}
        />
        {cashEnabled ? <React.Fragment key={`pos-cash-${pos}`}>
            <PokerTablePlayerBankroll
                pos={pos}
                player={player}
                gameSession={gameSession}
                containerOption={{
                    widthPx: 128,
                    textAlign: 'center',
                    fontSize: 16
                }}
            />
            <PokerTablePlayerBet
                pos={pos}
                player={player}
                visibleTop={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                origPos={1}
                position={'top'}
                containerOption={{
                    widthPx: 128,
                    heightPx: 28,
                    margin: [0, 0, 0, 0]
                }}
                chipOption={{
                    widthPx: 20,
                    heightPx: 20,
                    margin: [4, 0, 0, 4]
                }}
                betOption={{
                    widthPx: 100
                }}
            />
        </React.Fragment> : null}
    </Container> : <Container
        id={`seat-empty-${pos}`}
        containerOption={{
            position: 'relative',
            float: floating,
            widthPx: 128,
            heightPx: cashEnabled ? 216 : 188,
            margin: [0, 3, 0, 3]
        }}
    >
        {emptyPlayerVisible ? (
            <PokerTablePlayerEmpty
                pos={pos}
                containerOption={{
                    transform: rotate ? 'rotate(180deg)' : undefined,
                    margin: [rotate ? 80 : 0, 0, 0, 16]
                }}
                gameSession={gameSession}
            />
        ) : null}

    </Container>;
})

export default PokerTabletPlayer;