import {
    ActionButton,
    Container
} from '../../controls';

const GameOverlaySaveBar = ({
    onSave,
    onCancel,
    containerOption
}: any) => {
    return <Container
        containerOption={{
            width: '100%',
            heightPx: 80,
            float: 'right',
            ...(containerOption || {})
        }}
    >
        <ActionButton
            actionColor={'green'}
            translationKey={'button.save'}
            buttonOption={{
                widthPx: 192,
                float: 'right',
                margin: [12, 16, 0, 0],
                padding: [0, 0, 0, 0]
            }}
            onClick={onSave}
        />
        <ActionButton
            actionColor={'red'}
            buttonOption={{
                widthPx: 192,
                float: 'right',
                margin: [12, 32, 0, 16],
                padding: [0, 0, 0, 0]
            }}
            translationKey={'button.cancel'}
            onClick={onCancel}
        />
    </Container>
}

export default GameOverlaySaveBar;