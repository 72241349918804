import { useState } from 'react';
import {
    observer
} from 'mobx-react-lite';
import {
    List,
    Container,
    IconButton,
    ImageComponent,
    ActionButton,
    ProgressBar,
    Icon
} from '../../components/controls';
import {
    STATISTIC_LIST
} from '../../constants/statistic';
import {
    translateKey,
    convertNumberToString
} from '../../utils/helper';

const AccountDetails = observer(({
    sdk,
    onNextItem,
    options = {}
}: any) => {

    const [manageView, setManageView] = useState(false);

    const buildStatistic = (name: string, statistic: any) => {
        const entries: any = [];
        entries.push([
            'HEAD',
            translateKey(`text.statistic.head.${name}`, {
                upperSize: true
            })
        ])
        Object.entries(STATISTIC_LIST).forEach(([key, value]: any) => {
            const statTitle = `${translateKey(`text.statistic.${key}`)}:`;
            let statValue = `${statistic[key] || value.default || '-'}`;
            if (value?.txtWithValue && statistic[key]) {
                statValue = translateKey(`${value?.txtWithValue}${statistic[key]}`)
            }
            entries.push([
                statTitle,
                statValue
            ])

        })
        return entries;
    }

    const onlyRead = options?.onlyRead || false;
    const isMe = options?.me === undefined ? true : options?.me || false;
    const user = isMe ?
        sdk.appState.user.userData :
        sdk.appState.user.userTmp;
    const userNextLevel = user.levelXp;
    const userStatistic = user.statistic.map((x: any) => buildStatistic(x[0], x[1])).reduce((a: any, c: any) => [
        ...a,
        ...c
    ])
    const playerInfo: any = [];
    const isPremiumUser = isMe && user.premium;

    playerInfo.push(<>
        <span style={{
            color: '#fff'
        }}>
            {translateKey('text.statistic.playerSince', {}, [
                ['%date', user.userCreated || '?']
            ])}
        </span>
    </>)

    if (isMe && user.premium) {
        playerInfo.push(<Container
            containerOption={{
                float: 'left',
                widthPx: 900,
                fontSize: 22,
                color: '#fff',
                margin: [0, 0, 0, 10],
                textAlign: 'center'
            }}>
            {user.premiumExpire}
        </Container>)
    }

    if (!isPremiumUser) {
        playerInfo.push(<></>)
        playerInfo.push(<></>)
        playerInfo.push(<>
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 900,
                    fontSize: 22,
                    color: '#fff',
                    margin: [0, 0, 0, 10],
                    textAlign: 'center'
                }}>
                {translateKey('text.vip.statistic')}
            </Container>
        </>)
        playerInfo.push(<ActionButton
            actionColor={'green'}
            buttonOption={{
                float: 'left',
                widthPx: 192,
                margin: [0, 75, 0, 370],
                padding: undefined,
                lineHeight: 48
            }}
            onClick={() => sdk.appState.openGameStore({
                view: 'vip',
                from: 'account'
            })}
        >
            <Icon
                source={'./data/icon/vip.svg'}
                iconOption={{
                    widthPx: 40,
                    heightPx: 40,
                    float: 'left'
                }}
            />
            {translateKey('text.vip.button')}
        </ActionButton >)
    }
    // Wichtig: Hier kann man seinen eigenen Account ansehen, als auch den Account von dritten Spielern
    return <>
        <Container
            id={'account-headarea'}
            containerOption={{
                margin: [0, 0, 0, 5],
                widthPx: 970,
                heightPx: 64,
                bgColor: '#2f3030',
                borderBottom: 'solid #242424',
                borderSize: 4,
                float: 'left'
            }}
        >
            <Container
                containerOption={{
                    margin: [0, 0, 0, 0],
                    widthPx: 400,
                    heightPx: 64,
                    float: 'left'
                }}
            >
                <Container
                    id={'account_name'}
                    className={'gameinput'}
                    containerOption={{
                        padding: [8, 48, 8, 48],
                        margin: [8, 0, 0, 32],
                        fontSize: 30,
                        fontWeight: 'bold',
                        lineHeight: 40,
                        widthPx: 240,
                        heightPx: 40
                    }}
                    onClick={(e: any) => {
                        if (!onlyRead) {
                            onNextItem('account-rename-user')
                        }
                    }}
                >
                    {user.name}
                </Container>
            </Container>
            <Container
                containerOption={{
                    margin: [0, 0, 0, 168],
                    widthPx: 400,
                    heightPx: 64,
                    float: 'left'
                }}
            >
                <Container
                    id={'account_credits'}
                    className={'gameinput'}
                    containerOption={{
                        padding: [8, 48, 8, 16],
                        margin: [4, 0, 0, 32],
                        fontSize: 30,
                        lineHeight: 40,
                        widthPx: 256,
                        heightPx: 40,
                        fontWeight: 'bold'
                    }}
                    onClick={(e: any) => {
                        sdk.appState.openGameStore({
                            view: 'credit',
                            from: 'account'
                        });
                    }}
                >
                    <ImageComponent
                        source={'./data/waiting.png'}
                        imageOption={{
                            widthPx: 32,
                            heightPx: 32,
                            float: 'left',
                            margin: [6, 0, 0, 0]
                        }}
                    />
                    {convertNumberToString(user.credits, false)}
                </Container>
            </Container>
        </Container>
        <Container
            id={'account-subheadarea'}
            containerOption={{
                margin: [0, 0, 0, 5],
                widthPx: 970,
                heightPx: 52,
                bgColor: '#505050',
                // borderBottom: 'solid #163683',
                // borderSize: 2,
                float: 'left'
            }}
        >
            <Container
                id={'account_level'}
                className={'gameinput'}
                containerOption={{
                    padding: [8, 48, 8, 16],
                    margin: [4, 0, 0, 32],
                    fontSize: 26,
                    fontWeight: 'bold',
                    lineHeight: 32,
                    widthPx: 80,
                    heightPx: 32,
                    float: 'left',
                    borderRadius: 16
                }}
            >
                <Icon
                    source={'./data/icon/level.svg'}
                    iconOption={{
                        widthPx: 38,
                        heightPx: 16,
                        float: 'left',
                        bgImageSize: 'contain',
                        margin: [12, 0, 0, 0]
                    }}
                />
                {user.level}
            </Container>
            <ProgressBar
                containerOption={{
                    margin: [0, 0, 0, 32],
                    float: 'left'
                }}
                width={480}
                height={44}
                range={userNextLevel[1]}
                value={userNextLevel[0]}
                icon={'./data/icon/experience.svg'}
            />
            {/* <Icon
                source={'./data/icon/premium.svg'}
                iconOption={{
                    widthPx: 32,
                    heightPx: 32,
                    float: 'left',
                    margin: [10, 4, 0, 12]
                }}
            /> */}
        </Container>
        <IconButton
            buttonOption={{
                position: 'absolute',
                left: 415,
                top: 0,
                widthPx: 160,
                heightPx: 160,
                zIndex: 5,
                borderRadiusPercent: user.avatarImage.circle ? '50' : undefined,
            }}
            source={user.avatarImage.src}
            onClick={() => {
                if (!onlyRead) {
                    onNextItem('account-select-avatar');
                }
            }}
        // onClick={() => onNextItem('account-select-avatar')}
        />
        <Icon
            source={'./data/icon/camera.svg'}
            iconOption={{
                position: 'absolute',
                left: 440,
                top: 120,
                widthPx: 48,
                heightPx: 38,
                zIndex: 6
            }}
        />
        {isMe && user.premium ? (
            <Icon
                iconOption={{
                    position: 'absolute',
                    left: 532,
                    top: 96,
                    widthPx: 64,
                    heightPx: 64,
                    zIndex: 6
                }}
                source={'./data/icon/vip.svg'}
            />
        ) : null}
        <Container
            containerOption={{
                widthPx: 980,
                heightPx: 420,
                float: 'left',
                borderBottom: 'solid grey',
                borderSize: 2
            }}
        >
            {(manageView && !onlyRead) ?
                <Container
                    containerOption={{
                        widthPx: 640,
                        heightPx: 240,
                        margin: [48, 0, 0, 170]
                    }}>
                    <Container
                        containerOption={{
                            heightPx: 48,
                            lineHeight: 48,
                            fontSize: 28,
                            fontWeight: 'bold',
                            color: '#f2a462'
                        }}
                    >
                        {translateKey('button.account.manage', {
                            upperSize: true
                        })}
                    </Container>
                    <ActionButton
                        id={'btn_delete'}
                        buttonOption={{
                            padding: [0, 0, 0, 0],
                            float: 'left',
                            margin: [48, 0, 0, 200],
                            heightToWidth: 4.2
                        }}
                        enableDelay={100}
                        translationKey={'button.account.delete'}
                        actionColor={'red'}
                        onClick={() => sdk.appState.showMessagebox('dialog.delete.user', 'dialog.account.delete', () => {
                            sdk.appState.user.deleteUserFromApi().then((result: any) => {
                                if (result === true) {
                                    sdk.appState.removeUser();
                                    sdk.appState.showMessagebox('dialog.account.result', 'dialog.account.delete.success', null, null, true)
                                }
                                sdk.appState.showMessagebox('dialog.account.result', 'dialog.account.delete.failed', null, null, true)

                            });
                        }, () => null, false, [], false, {
                            fontSize: 28
                        }, 'warning')}
                    />
                    <ActionButton
                        id={'btn_change'}
                        buttonOption={{
                            padding: [0, 0, 0, 0],
                            float: 'left',
                            margin: [48, 0, 0, 200],
                            heightToWidth: 4.2
                        }}
                        enableDelay={100}
                        translationKey={'button.settings.rename'}
                        actionColor={'green'}
                        onClick={() => onNextItem('account-rename-user')}
                    />
                </Container>
                : (
                    <List
                        id={'statistic'}
                        containerOption={{
                            widthPx: 950,
                            heightPx: 400,
                            float: 'left',
                            margin: [0, 0, 0, 10],
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                        listOption={{
                            widthPx: 940,
                            heightPx: 48,
                            lineHeight: 48,
                            fontSize: 28,
                            fontWeight: 'bold',
                            color: '#f5861f'
                        }}
                        components={!isPremiumUser ? [
                            ...playerInfo
                        ] : [
                            ...playerInfo,
                            ...userStatistic.map((comp: any) => {
                                if (comp[0] === 'HEAD') {
                                    return <>{comp[1]}</>
                                }
                                return <>
                                    <Container
                                        containerOption={{
                                            float: 'left',
                                            widthPx: 450,
                                            fontSize: 22,
                                            color: '#fff',
                                            margin: [0, 0, 0, 10],
                                            textAlign: 'right'
                                        }}>
                                        {comp[0]}
                                    </Container>
                                    <Container
                                        containerOption={{
                                            float: 'left',
                                            widthPx: 450,
                                            fontSize: 22,
                                            color: '#fff',
                                            margin: [0, 0, 0, 20],
                                            textAlign: 'left',
                                        }}>
                                        {comp[1]}
                                    </Container>
                                </>

                            })
                        ]}
                    />
                )}
        </Container>
        <Container
            containerOption={{
                widthPx: 980,
                heightPx: 78,
                float: 'left',
                borderSize: 2
            }}
        >
            {!onlyRead ? (
                <>
                    <ActionButton
                        id={'btn_manage'}
                        buttonOption={{
                            padding: [0, 0, 0, 0],
                            float: 'left',
                            margin: [12, 0, 0, 16],
                            heightToWidth: 4.2
                        }}
                        enableDelay={100}
                        translationKey={manageView ? 'button.back' : 'button.account.manage'}
                        actionColor={'red'}
                        onClick={() => setManageView(!manageView)}
                    />
                    {sdk.appState.user.hasAccount ? (
                        <ActionButton
                            id={'btn_logout'}
                            buttonOption={{
                                widthPx: 192,
                                padding: [0, 0, 0, 0],
                                float: 'right',
                                margin: [8, 16, 0, 0]
                            }}
                            translationKey={'button.account.logout'}
                            actionColor={'red'}
                            onClick={() => sdk.appState.showMessagebox('dialog.logout', 'dialog.logout.warning', () => {
                                sdk.appState.user.logoutUser().then((result: any) => {
                                    console.log('logoutUserr ', result)
                                    if (result === true) {
                                        sdk.appState.removeUser();
                                    }
                                });
                            }, () => null, false)}
                        />
                    ) : (
                        <ActionButton
                            id={'btn_connect'}
                            buttonOption={{
                                widthPx: 390,
                                heightToWidth: 6.2,
                                padding: [0, 0, 0, 0],
                                float: 'right',
                                margin: [8, 16, 0, 0]
                            }}
                            translationKey={'button.account.connect'}
                            actionColor={'green'}
                            onClick={() => onNextItem('account-connect-user')}
                        />
                    )}
                </>
            ) : null}
        </Container>
    </>
});

export default AccountDetails;

// const SettingGame = observer(({
//     sdk,
//     onNextItem
// }: any) => {
//     return <List
//         className={'settinglist'}
//         containerOption={{
//             widthPx: 930,
//             heightPx: 600,
//             margin: [0, 0, 0, 20],
//             padding: [0, 0, 0, 0],
//             float: 'left'
//         }}
//         listOption={{
//             widthPx: 930,
//             heightPx: 100,
//             lineHeight: 100,
//             color: '#fff',
//             fontSize: 28,
//             borderSize: 2,
//             borderBottom: `solid #fff`
//         }}
//         components={[
//             <ConfiguratorItem
//                 label={'text.headline.settings'}
//                 onNextItem={() => onNextItem('common')}
//             />,
//             <ConfiguratorItem
//                 label={'button.settings.device'}
//                 onNextItem={() => onNextItem('device')}
//             />,
//             <ConfiguratorItem
//                 label={'button.game.leave'}
//                 itemOption={{
//                     color: '#D8282F'
//                 }}
//                 onClick={() => sdk.appState.leaveGame()}
//             />
//         ]}
//     />
// });

// export default SettingGame;