//PROJECT GENERATED Couchgames Poker
//DATE Sun Apr 02 2023 07:06:54 GMT+0200 (Mitteleuropäische Sommerzeit) 

 const TRANSLATIONS:any = {
  "text.game.player.sets.big.blind": {
    "de": "$player Big Blind $bigblind_value",
    "en": "$player big blind $bigblind_value",
    "es": "$player big blind $bigblind_value"
  },
  "text.game.dealer.gives.pocket.cards": {
    "de": "Dealer gibt Karten",
    "en": "Dealer deals cards",
    "es": "El repartidor da cartas"
  },
  "text.game.dealer.deals.flop": {
    "de": "Flop:",
    "en": "Flop:",
    "es": "Flop:"
  },
  "text.game.dealer.deals.turn": {
    "de": "Turn:",
    "en": "Turn:",
    "es": "Turn:"
  },
  "text.game.dealer.deals.river": {
    "de": "River:",
    "en": "River:",
    "es": "River:"
  },
  "text.game.player.sets.small.blind": {
    "de": "$player Small Blind $smallblind_value",
    "en": "$player small blind $smallblind_value",
    "es": "$player small blind $smallblind_value"
  },
  "text.game.player.bets": {
    "de": "$player setzt $bet_value",
    "en": "$player bet $bet_value",
    "es": "$player apuesta $bet_value"
  },
  "text.game.player.calls": {
    "de": "$player call $total",
    "en": "$player call $total",
    "es": "$player va por $total"
  },
  "text.game.player.raises": {
    "de": "$player erhöht auf $total",
    "en": "$player raise to $total",
    "es": "$player increment a $total"
  },
  "text.game.player.allin": {
    "de": "$player all in $total",
    "en": "$player all in $total",
    "es": "$player all in $total"
  },
  "text.game.player.folds": {
    "de": "$player fold",
    "en": "$player fold",
    "es": "$player retira"
  },
  "text.game.player.reveals": {
    "de": "$player zeigt",
    "en": "$player reveals",
    "es": "$player revela"
  },
  "text.game.player.wins": {
    "de": "$player gewinnt $win_value",
    "en": "$player wins $win_value",
    "es": "$player gana $win_value"
  },
  "text.game.all.in": {
    "de": "all in",
    "en": "all in",
    "es": "all in"
  },
  "text.game.next.blind.in": {
    "de": "Nächster Blind in",
    "en": "next blind in",
    "es": "proximos ciegos en"
  },
  "text.game.total": {
    "de": "Konto",
    "en": "bankroll",
    "es": "Cuenta"
  },
  "button.game.show.table": {
    "de": "zum Tisch",
    "en": "show table",
    "es": "Ver tabla"
  },
  "button.game.show.log": {
    "de": "Zeige Log",
    "en": "show log",
    "es": "Ver registro"
  },
  "button.game.fold": {
    "de": "passen",
    "en": "fold",
    "es": "retirar"
  },
  "button.game.check": {
    "de": "check",
    "en": "check",
    "es": "pasar"
  },
  "button.game.call": {
    "de": "call",
    "en": "call",
    "es": "igualar"
  },
  "button.game.bet": {
    "de": "bet",
    "en": "bet",
    "es": "apostar"
  },
  "button.game.raise": {
    "de": "raise",
    "en": "raise",
    "es": "subir"
  },
  "button.game.stand.up": {
    "de": "aufstehen",
    "en": "stand up",
    "es": "Retirar"
  },
  "text.game.player.checks": {
    "de": "$player checkt",
    "en": "$player checks",
    "es": "$player pasa"
  },
  "button.game.hide.log": {
    "de": "Log verbergen",
    "en": "hide log",
    "es": "Ocultar el registro"
  },
  "button.game.dealer.deal": {
    "de": "Karten geben",
    "en": "deal cards",
    "es": "Repartir cartas"
  },
  "button.game.allin": {
    "de": "All in",
    "en": "all in",
    "es": "all in"
  },
  "button.mainmenu.create": {
    "de": "Erstellen",
    "en": "create",
    "es": "Abrir partida"
  },
  "button.mainmenu.join": {
    "de": "Beitreten",
    "en": "Join",
    "es": "Ùnete"
  },
  "button.mainmenu.tv": {
    "de": "TV",
    "en": "tv",
    "es": "TV"
  },
  "button.back": {
    "de": "Zurück",
    "en": "back",
    "es": "Atrás"
  },
  "button.start": {
    "de": "Start",
    "en": "start",
    "es": "Iniciar"
  },
  "text.common.roomcode": {
    "de": "Tischcode",
    "en": "Table code",
    "es": "Código"
  },
  "text.common.playername": {
    "de": "Spielername",
    "en": "playername",
    "es": "Nombre del jugador"
  },
  "text.lobby.gamesetting": {
    "de": "Optionen",
    "en": "gamesettting",
    "es": "Ajustes Partida"
  },
  "text.lobby.blindsetting": {
    "de": "Blind Optionen",
    "en": "blindsetting",
    "es": "Ajustes ciegas"
  },
  "text.lobby.connectedplayer": {
    "de": "Verbundene Spieler",
    "en": "connected player",
    "es": "Jugadores conectados"
  },
  "checkbox.lobby.randomseat": {
    "de": "Zufällige Sitzordnung",
    "en": "random seat",
    "es": "Asiento aleatorio"
  },
  "input.lobby.bankroll": {
    "de": "Konto",
    "en": "bankroll",
    "es": "Cuenta"
  },
  "checkbox.lobby.smallblind": {
    "de": "small blind",
    "en": "small blind",
    "es": "ciega pequeña"
  },
  "checkbox.lobby.bigblind": {
    "de": "big blind",
    "en": "big blind",
    "es": "ciega grande"
  },
  "checkbox.lobby.digitalchip": {
    "de": "digitale chips",
    "en": "digital chips",
    "es": "Fichas digitales"
  },
  "button.game.deal": {
    "de": "deal",
    "en": "deal",
    "es": "Repartir"
  },
  "text.game.pot": {
    "de": "Pot",
    "en": "pot",
    "es": "Bote"
  },
  "text.game.player.bankrupt": {
    "de": "$player ist bankrott",
    "en": "$player is out of money",
    "es": "$player se queda sin dinero"
  },
  "text.game.match.winner": {
    "de": "$player gewinnt das Spiel",
    "en": "$player wins the match",
    "es": "$player gana el partido"
  },
  "button.game.showdown": {
    "de": "showdown",
    "en": "showdown",
    "es": "showdown"
  },
  "checkbox.lobby.flagonlycards": {
    "de": "Nur digitale Karten",
    "en": "Only digital cards",
    "es": "Sólo cartas digitales"
  },
  "checkbox.lobby.flagallowafterjoin": {
    "de": "Beitritt nach Spielstart erlauben",
    "en": "allow to join after game start",
    "es": "Unirse a un juego en curso"
  },
  "checkbox.tvmode.tablet": {
    "de": "Tablet",
    "en": "Tablet",
    "es": "Tabla"
  },
  "checkbox.tvmode.tv": {
    "de": "TV",
    "en": "Tv",
    "es": "TV"
  },
  "text.settings.german": {
    "de": "Deutsch",
    "en": "German",
    "es": "Alemán"
  },
  "text.settings.english": {
    "de": "Englisch",
    "en": "English",
    "es": "Inglés"
  },
  "text.headline.language": {
    "de": "Sprachauswahl",
    "en": "Choose Language",
    "es": "Elija el idioma"
  },
  "text.headline.settings": {
    "de": "Optionen",
    "en": "Settings",
    "es": "Opciones"
  },
  "text.common.imprint": {
    "de": "Impressum",
    "en": "Imprint",
    "es": "Aviso legal"
  },
  "text.common.language": {
    "de": "Sprache",
    "en": "Language",
    "es": "Idioma"
  },
  "text.common.aboutus": {
    "de": "Über uns",
    "en": "About us",
    "es": "Sobre nosotros"
  },
  "text.headline.gamesetting": {
    "de": "Spieloptionen",
    "en": "Gamesettings",
    "es": "Opciones de juego"
  },
  "button.game.leave": {
    "de": "Spiel verlassen",
    "en": "Leave game",
    "es": "Salir del juego"
  },
  "text.headline.screenlayout": {
    "de": "Bildschirm Layout",
    "en": "Screen layout",
    "es": "Diseño de pantalla"
  },
  "dialog.voting.sitdown": {
    "de": "$player möchte sich hinsetzen",
    "en": "$player wants to sitdown",
    "es": "$player quiere sentarse"
  },
  "button.create": {
    "de": "Erstelle Spiel",
    "en": "Create game",
    "es": "Crear juego"
  },
  "text.settings.history": {
    "de": "Komplette Spielhistorie",
    "en": "Full Gamelog",
    "es": "Historial completo del partido"
  },
  "text.settings.admin": {
    "de": "Spieloptionen ( Admin )",
    "en": "Game settings ( Admin )",
    "es": "Opciones de juego ( Admin )"
  },
  "text.headline.adminsettings": {
    "de": "Admin Bereich",
    "en": "Admin area",
    "es": "Área de administración"
  },
  "button.settings.admin.closeroom": {
    "de": "Tisch schließen ( Jetzt offen = Andere Spieler können beitreten )",
    "en": "Close table ( Now open = other players can join )",
    "es": "Cerrar mesa ( Ahora abierta = otros jugadores pueden unirse )"
  },
  "button.settings.admin.openroom": {
    "de": "Tisch öffnen ( Jetzt geschlossen = Niemand kann dem Spiel beitreten )",
    "en": "Open table ( Now closed = No one can join the game )",
    "es": "Mesa abierta ( Ahora cerrada = Nadie puede unirse al juego )"
  },
  "text.player.hand.5": {
    "de": "Straße",
    "en": "Straight",
    "es": "Escalera"
  },
  "text.player.hand.1": {
    "de": "Highcard",
    "en": "Highcard",
    "es": "Carta alta"
  },
  "text.player.hand.2": {
    "de": "Paar",
    "en": "Pair",
    "es": "Pareja"
  },
  "text.player.hand.3": {
    "de": "Two Pair",
    "en": "Two Pair",
    "es": "Doble Pareja"
  },
  "text.player.hand.4": {
    "de": "Drilling",
    "en": "Three of a Kind",
    "es": "Trio"
  },
  "text.player.hand.6": {
    "de": "Flush",
    "en": "Flush",
    "es": "Color"
  },
  "text.player.hand.7": {
    "de": "Full House",
    "en": "Full House",
    "es": "Full"
  },
  "text.player.hand.8": {
    "de": "Vierling",
    "en": "Quads",
    "es": "Poker"
  },
  "text.player.hand.9": {
    "de": "Straight Flush",
    "en": "Straight Flush",
    "es": "Escalera de color"
  },
  "text.player.hand.10": {
    "de": "Royal Flush",
    "en": "Royal Flush",
    "es": "Escalera real"
  },
  "text.game.history.newround": {
    "de": "Starte neue Runde $round ( $time )",
    "en": "Start round $round ( $time )",
    "es": "Iniciar nuevo turno $round ( $time )"
  },
  "button.mainmenu.multiplayer": {
    "de": "Mehrspieler",
    "en": "multiplayer",
    "es": "Multijugador"
  },
  "button.account.guest": {
    "de": "Als Gast spielen",
    "en": "play as guest",
    "es": "Jugar sin cuenta"
  },
  "text.cards.symbol": {
    "de": "Kreuz|Karo|Herz|Pik",
    "en": "Clubs|Diamonds|Hearts|Spades",
    "es": "Tréboles|Rombos|Corazones|Picas"
  },
  "text.cards.value": {
    "de": "Zwei|Drei|Vier|Fünf|Sechs|Sieben|Acht|Neun|Zehn|Bube|Dame|König|Ass",
    "en": "Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Jack|Queen|King|Ace",
    "es": "Dos|Tres|Cuatro|Cinco|Seis|Siete|Ocho|Nueve|Diez|Jack|Dama|Rey|As"
  },
  "button.game.leave.table": {
    "de": "Tisch verlassen",
    "en": "Leave Table",
    "es": "Salir de la mesa"
  },
  "text.gameloader.connecting": {
    "de": "Verbinde...",
    "en": "Connecting...",
    "es": "Conectando..."
  },
  "text.gameloader.timeout": {
    "de": "Zeit überschritten",
    "en": "Timeout",
    "es": "Tiempo excedido"
  },
  "text.gameloader.unavailable": {
    "de": "Nicht verfügbar",
    "en": "unavailable",
    "es": "No disponible"
  },
  "text.gameloader.unauthorized": {
    "de": "Nicht authentifiziert",
    "en": "unauthorized",
    "es": "No autentificado"
  },
  "text.gameloader.error": {
    "de": "Fehler",
    "en": "error",
    "es": "Error"
  },
  "button.gameloader.reconnect": {
    "de": "Verbinden",
    "en": "Reconnect",
    "es": "Conectando"
  },
  "txt.game.help.title": {
    "de": "Hilfe",
    "en": "Help",
    "es": "Ayuda"
  },
  "checkbox.lobby.flagAutoRevealAll": {
    "de": "Alle Karten am Ende aufdecken",
    "en": "Auto reveal all cards",
    "es": "Voltea todas las cartas al final"
  },
  "text.game.player.callraise": {
    "de": "$player geht mit und erhöht um $raise_value",
    "en": "$player calls and raises by $raise_value",
    "es": "$player va con y sube por $raise_value"
  },
  "checkbox.lobby.flagAutoAllInShowdown": {
    "de": "Automatischer Showdown",
    "en": "Auto AllIn Showdown",
    "es": "Showdown automático"
  },
  "button.game.menu.standup": {
    "de": "Auf- stehen",
    "en": "stand up",
    "es": "Retirar"
  },
  "button.game.menu.sitdown": {
    "de": "Hinsetzen",
    "en": "sitdown",
    "es": "Siéntate"
  },
  "button.game.menu.revealall": {
    "de": "Aufdecken",
    "en": "reveal both",
    "es": "Reveal"
  },
  "button.game.menu.newmatch": {
    "de": "Neues Spiel",
    "en": "new match",
    "es": "Nueva partida"
  },
  "button.game.menu.help": {
    "de": "Hilfe",
    "en": "help",
    "es": "Ayuda"
  },
  "button.game.reveal": {
    "de": "Aufdecken",
    "en": "reveal card",
    "es": "Reveal"
  },
  "text.game.player.hand.blinds": {
    "de": "Blinds: $smallblind / $bigblind",
    "en": "Blinds: $smallblind / $bigblind",
    "es": "Ciegas: $smallblind / $bigblind"
  },
  "checkbox.lobby.pokerblindraise": {
    "de": "Aktiviere Poker Clock",
    "en": "Activate Poker Clock",
    "es": "Activar Poker Clock"
  },
  "text.lobby.timeunit": {
    "de": "Zeiteinheit",
    "en": "timeunit",
    "es": "Unidad de tiempo"
  },
  "text.lobby.timeunit.rounds": {
    "de": "Runden",
    "en": "rounds",
    "es": "Turno"
  },
  "text.lobby.timeunit.minutes": {
    "de": "Minuten",
    "en": "minutes",
    "es": "Minutos"
  },
  "text.lobby.blindraise.level": {
    "de": "Lvl ( $level )",
    "en": "Lvl ( $level )",
    "es": "Nivel($level)"
  },
  "input.lobby.blindraise.placeholder.smallblind": {
    "de": "Small blind",
    "en": "small blind",
    "es": "ciega pequeña"
  },
  "input.lobby.blindraise.placeholder.bigblind": {
    "de": "Big blind",
    "en": "big blind",
    "es": "ciega grande"
  },
  "text.lobby.playerlist.level": {
    "de": "Lvl $xp",
    "en": "Lvl $xp",
    "es": "Nivel $xp"
  },
  "button.game.league.waiting.leave": {
    "de": "Verlassen",
    "en": "leave",
    "es": "Salir"
  },
  "text.game.banner.blindincrease": {
    "de": "Blind erhöht $smallblind / $bigblind",
    "en": "blind increases $smallblind / $bigblind",
    "es": "La ciega sube $smallblind / $bigblind"
  },
  "text.game.player.hand.blindnext.rounds": {
    "de": "$rounds Runden",
    "en": "$rounds rounds",
    "es": "$rounds turnos"
  },
  "text.format.timer.moreoneday": {
    "de": "> 1 Tag",
    "en": "> 1 day",
    "es": "> 1 dia"
  },
  "text.game.player.hand.blindnext.time": {
    "de": "Blinderhöhung in mm:ss Minuten",
    "en": "next blind increase mm:ss minutes",
    "es": "Aumento ciego en mm:ss minutos"
  },
  "button.account.rename": {
    "de": "Umbenennen",
    "en": "Rename",
    "es": "Cambia nombre"
  },
  "dialog.error.renameuser": {
    "de": "Spieler konnte nicht umbenannt werden, weil es den Namen bereits gibt",
    "en": "The player could not renamed because already exist",
    "es": "No se ha podido cambiar el nombre del jugador porque el nombre ya existe"
  },
  "text.game.match.sitdown": {
    "de": "$player setzt sich",
    "en": "$player sitdown",
    "es": "$player se sienta"
  },
  "text.game.match.standup": {
    "de": "$player steht auf",
    "en": "$player standup",
    "es": "$player se levanta"
  },
  "text.game.player.reraises": {
    "de": "$player erhöht auf $total",
    "en": "$player reraise to $total",
    "es": "$player sube a $total"
  },
  "button.game.reraise": {
    "de": "reraise",
    "en": "reraise",
    "es": "reraise"
  },
  "button.game.menu.iamback": {
    "de": "Bin da",
    "en": "I am back",
    "es": "He vuelto"
  },
  "dialog.logout.warning": {
    "de": "Achtung: Du verlierst deinen User wenn du dich ausloggst und ihn nicht mit deinen Account verbunden hast. Wirklich ausloggen?",
    "en": "Attention: You will lose your user if you log out and do not connect it to your account. Really log out?",
    "es": "Atención: perderás tu usuario si te desconectas y no lo has conectado a tu cuenta. ¿Realmente cerrar la sesión?"
  },
  "text.leaguestatistic": {
    "de": "Online Multiplayer Statistiken",
    "en": "Online Multiplayer Statistics",
    "es": "Estadísticas multijugador online"
  },
  "text.multiplayerstatistic": {
    "de": "Multiplayer Statistiken",
    "en": "Multiplayer statistics",
    "es": "Estadísticas multijugador"
  },
  "text.account.connect": {
    "de": "Mit Account verbinden",
    "en": "Connect with your account",
    "es": "Conéctate a la cuenta"
  },
  "button.settings.rename": {
    "de": "Spieler umbennen",
    "en": "Rename player",
    "es": "Cambiar nombre"
  },
  "text.game.player.join": {
    "de": "$player kommt an den Tisch",
    "en": "$player comes to the table",
    "es": "$jugador viene a la mesa"
  },
  "text.game.player.leave": {
    "de": "$player verlässt den Tisch",
    "en": "$player leaves the table",
    "es": "$jugador abandona la mesa"
  },
  "text.inappstore.favorite": {
    "de": "Am Beliebtesten",
    "en": "Most popular",
    "es": "Lo más popular"
  },
  "text.inappstore.bestvalue": {
    "de": "Bestes Verhältnis",
    "en": "Best value",
    "es": "Mejor opción"
  },
  "button.inappstore.buy": {
    "de": "Kaufen",
    "en": "Purchase",
    "es": "Comprar"
  },
  "text.headline.admin.blindsetting": {
    "de": "Blind ändern",
    "en": "Blindsettings",
    "es": "Cambia ciegas"
  },
  "button.save": {
    "de": "Speichern",
    "en": "Save",
    "es": "Guardar"
  },
  "text.spinner.pleasewait": {
    "de": "Bitte warten...",
    "en": "Please wait...",
    "es": "Porfavor espera..."
  },
  "text.spinner.account.login": {
    "de": "Login Benutzer",
    "en": "Login user",
    "es": "Iniciar sesión"
  },
  "text.spinner.account.logout": {
    "de": "Logout Benutzer",
    "en": "Logout user...",
    "es": "Desconnectar"
  },
  "text.spinner.account.activate": {
    "de": "Aktiviere...",
    "en": "Activate...",
    "es": "Activando..."
  },
  "text.spinner.account.register": {
    "de": "Registriere...",
    "en": "Register...",
    "es": "Registrando..."
  },
  "text.inappstore.watchvideo": {
    "de": "Video ansehen",
    "en": "Watch video",
    "es": "Ver video"
  },
  "text.inappstore.free": {
    "de": "Gratis",
    "en": "free",
    "es": "Gratis"
  },
  "text.inappstore.dailybonus": {
    "de": "Täglicher\nBonus",
    "en": "Daily\nBonus",
    "es": "Bono\nDiario"
  },
  "text.inappstore.adbonus": {
    "de": "Werbe\nBonus",
    "en": "Advertisement\nBonus",
    "es": "Bono\nanuncio"
  },
  "blind.poker.button.inappstore": {
    "de": "Shop|Öffne den In App Shop für kostenlose Boni und mehr",
    "en": "Shop|Open the appstore to get free bonus and more",
    "es": "Tienda|Abre la tienda de la aplicación para obtener bonos gratuitos y más"
  },
  "text.account.experience": {
    "de": "Erfahrung:$xp Nächste Stufe: $nextxp",
    "en": "Experience:$xp Next level: $nextxp",
    "es": "Experiencia:$xp Próximo nivel: $nextxp"
  },
  "text.game.roundtype": {
    "de": "Preflop|Flop|Turn|River|Showdown",
    "en": "Preflop|Flop|Turn|River|Showdown",
    "es": "Preflop|Flop|Turn|River|Showdown"
  },
  "text.settings.vibration.on": {
    "de": "Deaktiviere Vibration ( aktiviert )",
    "en": "Disable vibration ( enabled )",
    "es": "Desactivar vibración ( activada )"
  },
  "text.settings.vibration.off": {
    "de": "Aktiviere Vibration ( deaktiviert )",
    "en": "Enable vibration ( disabled )",
    "es": "Activar la vibración ( desactivada )"
  },
  "text.statistic.fold": {
    "de": "Fold",
    "en": "Fold hands",
    "es": "Abandonar"
  },
  "text.statistic.handType": {
    "de": "Beste Hand",
    "en": "Best hand",
    "es": "mejor mano"
  },
  "text.statistic.playedHand": {
    "de": "Gespielte Hände",
    "en": "Played hand",
    "es": "Manos jugadas"
  },
  "text.statistic.roundWon": {
    "de": "Gewonnene Hände",
    "en": "Won hands",
    "es": "Manos ganadas"
  },
  "text.statistic.cashWon": {
    "de": "Gewonnenes Spielgeld",
    "en": "Cash won",
    "es": "Fichas ganadas"
  },
  "text.statistic.cashHighestWin": {
    "de": "Höchster Gewinn",
    "en": "Highest win",
    "es": "Máximo beneficio"
  },
  "text.statistic.playerSince": {
    "de": "Spieler seit: %date",
    "en": "Player since: %date",
    "es": "Jugador desde: %date"
  },
  "text.lobby.mode.1.head": {
    "de": "Voll digital ( 100% )",
    "en": "Fully digital ( 100% )",
    "es": "Totalmente digital ( 100% )"
  },
  "text.lobby.mode.1.sub1": {
    "de": "Spiele mit digitalen Karten und",
    "en": "Play with digital cards and",
    "es": "Juega con tarjetas digitales y"
  },
  "text.lobby.mode.1.sub2": {
    "de": "digitalen Chips",
    "en": "with digital chips",
    "es": "chips digitales"
  },
  "text.lobby.mode.2.head": {
    "de": "Digitale Karten",
    "en": "Only digital cards",
    "es": "Cartas digitales"
  },
  "text.lobby.mode.2.sub1": {
    "de": "Spiele nur mit digitalen Karten und",
    "en": "Play only with digital cards and",
    "es": "Juega sólo con tarjetas digitales y"
  },
  "text.lobby.mode.2.sub2": {
    "de": "mit deinen eigenen Chips",
    "en": "with your own chips",
    "es": "con sus propios chips"
  },
  "button.mainmenu.singleplayer": {
    "de": "Einzelspieler",
    "en": "Singleplayer",
    "es": "Contra bots"
  },
  "dialog.fold": {
    "de": "Möchtest du wirklich folden?",
    "en": "Do you really want to fold?",
    "es": "¿Realmente quieres abandonar?"
  },
  "dialog.standup": {
    "de": "Möchtest du wirklich vom Tisch aufstehen?",
    "en": "Do you really want to standup?",
    "es": "¿Realmente quieres levantarte de la mesa?"
  },
  "dialog.sitdown": {
    "de": "Du sitzt nicht am Tisch. Willst du dich hinsetzen?",
    "en": "You are not sitting. Do you want to sitdown?",
    "es": "No estás sentado en la mesa. ¿Quieres sentarte?"
  },
  "dialog.noseat": {
    "de": "Du sitzt nicht am Tisch. Wähle einen Sitzplatz!",
    "en": "You are not sitting. Select a seat!",
    "es": "No estás sentado en la mesa. ¡Elige un asiento!"
  },
  "checkbox.lobby.flagWinDetection": {
    "de": "Gewinnermittlung",
    "en": "Determine winner",
    "es": "Señalar ganador"
  },
  "input.lobby.rebuy": {
    "de": "Rebuy",
    "en": "Rebuy",
    "es": "recompra"
  },
  "button.settings.admin.windetection.enable": {
    "de": "Aktiviere die Gewinnerermittlung",
    "en": "Enable windetection ( Disabled )",
    "es": "Activar aviso del ganador"
  },
  "button.settings.admin.windetection.disable": {
    "de": "Deaktiviere die Gewinnerermittlung",
    "en": "Disable windetection ( Enabled)",
    "es": "Desactivar aviso del ganador"
  },
  "text.game.playerhand.winner": {
    "de": "Du hast gewonnen: $handtype",
    "en": "You win: $handtype",
    "es": "Has ganado: $handtype"
  },
  "text.game.playerhand.splitpot": {
    "de": "Du gewinnst splitpot: $handtype",
    "en": "You win splitpot: $handtype",
    "es": "Ganas el splitpot: $handtype"
  },
  "text.game.playerhand.handtype": {
    "de": "Deine Hand: $handtype",
    "en": "Your hand: $handtype",
    "es": "Tu mano: $handtype"
  },
  "button.account.back": {
    "de": "Abbrechen",
    "en": "cancel",
    "es": "Cancelar"
  },
  "checkbox.lobby.flagAllowShowFold": {
    "de": "Was hatte ich",
    "en": "Show folded cards",
    "es": "¿Qué tenía?"
  },
  "dialog.reveal": {
    "de": "Möchtest du wirklich alle Karten aufdecken?",
    "en": "Do you want to reveal all your cards?",
    "es": "¿Realmente quieres revelar todas las cartas?"
  },
  "text.singleplayer.1": {
    "de": "Zwei Bots|Spiele ein schnelles Spiel|gegen zwei Bots",
    "en": "Two bots|Play a quick game|against two bots",
    "es": "Dos bots|Juega una partida rápida|contra dos bots"
  },
  "dialog.voting.adminrequest": {
    "de": "Möchtest du dem Tablet oder TV die Admin-Rechte übergeben?",
    "en": "Do you want to give the tablet or TV admin rights?",
    "es": "¿Quieres dar a la tableta o al televisor derechos de administrador?"
  },
  "tutorial.feature.whathadi": {
    "de": "Karten noch sehen, auch wenn sie gefoldet sind.",
    "en": "Cards can still be seen, even if they are folded.",
    "es": "Seguir viendo cartas aunque estén tiradas"
  },
  "tutorial.feature.revealall": {
    "de": "Am Ende einer Runde werden alle Karten von allen Spielern aufgedeckt.",
    "en": "At the end of a round, all cards are revealed by all players.",
    "es": "Al final de un turno, todos los jugadores dan la vuelta a todas las cartas."
  },
  "tutorial.feature.whowins": {
    "de": "Das Spiel erklärt den Gewinner und die Gewinnerhand.",
    "en": "The game declares the winner and the winning hand.",
    "es": "El juego declara el ganador y la mano ganadora."
  },
  "tutorial.aiplayer": {
    "de": "Diese Funktion fügt Computer-Spieler dem Spiel hinzu.",
    "en": "This function adds computer players to the game.",
    "es": "Esta función añade jugadores AI al juego."
  },
  "tutorial.refresh": {
    "de": "Hier aktualisierst du den Tisch, wenn sich mal etwas aufgehängt haben sollte.",
    "en": "Here you can update the table if something has hung up.",
    "es": "Aquí puedes actualizar la mesa si algo se ha colgado."
  },
  "tutorial.reset": {
    "de": "Achtung: Hier setzt du den kompletten Tisch zurück. Manche Spieler müssen sich evt. neu verbinden.",
    "en": "Attention: Here you reset the entire table. Some players may have to reconnect.",
    "es": "Atención: Aquí se restablece toda la mesa. Es posible que algunos jugadores tengan que volver a conectarse."
  },
  "text.share.invite": {
    "de": "Spiele bullets poker|${name} hat dich eingeladen bullets poker zu spielen. Lade das Spiel und tritt dem Tisch ${code} bei",
    "en": "Play bullets poker|${name} has invited you to play bullets poker. Download the game and join the table ${code}.",
    "es": "Juega al bullets poker|${nombre} te ha invitado a jugar a bullets poker. Carga el juego y une la tabla ${code}."
  },
  "text.common.share": {
    "de": "Teilen",
    "en": "Share",
    "es": "compartir"
  },
  "text.game.bubble.yourturn": {
    "de": "Du bist",
    "en": "You are",
    "es": "Te toca"
  },
  "text.lobby.tab.modus": {
    "de": "Modus",
    "en": "Mode",
    "es": "Modo"
  },
  "text.lobby.tab.setting": {
    "de": "Spiel Einstellungen",
    "en": "Game setting",
    "es": "Ajustes partida"
  },
  "text.lobby.tab.waitingarea": {
    "de": "Wartebereich",
    "en": "Waitingarea",
    "es": "Zona de espera"
  },
  "button.lobby.select": {
    "de": "Wählen",
    "en": "Choose",
    "es": "Elige"
  },
  "text.lobby.help.tab.1": {
    "de": "1. Wähle den Spielmodus",
    "en": "1. Choose your game mode",
    "es": "1. elige el modo de la partida"
  },
  "text.lobby.help.tab.2": {
    "de": "2. Spiel Einstellungen konfigurieren",
    "en": "2. Setup your game",
    "es": "2. Configurar los ajustes de la partida"
  },
  "text.lobby.help.tab.3": {
    "de": "Alle wartende Spieler in diesem Raum",
    "en": "See all waiting players",
    "es": "Jugadores en esta mesa"
  },
  "radiobutton.lobby.cashgame": {
    "de": "Cashgame",
    "en": "Cashgame",
    "es": "Cashgame"
  },
  "text.lobby.head.gamesetting": {
    "de": "Spiel Einstellungen",
    "en": "Game settings",
    "es": "Ajustes de partida"
  },
  "text.lobby.head.blindsetting": {
    "de": "Blind Einstellungen",
    "en": "Blind settings",
    "es": "Ajustes ciegas"
  },
  "button.lobby.pokerclock.add": {
    "de": "Hinzufügen",
    "en": "Add",
    "es": "Añadir"
  },
  "button.lobby.pokerclock.delete": {
    "de": "Entfernen",
    "en": "Remove",
    "es": "Quitar"
  },
  "button.lobby.close": {
    "de": "Schließen",
    "en": "Close",
    "es": "Cerrar"
  },
  "button.lobby.start": {
    "de": "Start",
    "en": "start",
    "es": "Iniciar"
  },
  "button.lobby.create": {
    "de": "Erstellen",
    "en": "Create",
    "es": "Crear"
  },
  "button.mainmenu.quick": {
    "de": "Schnelles Spiel",
    "en": "Quick game",
    "es": "Partida rapida"
  },
  "text.settings.spain": {
    "de": "Spanisch",
    "en": "Spanish",
    "es": "Español"
  },
  "button.settings.device": {
    "de": "Clienteinstellungen",
    "en": "Clientsettings",
    "es": "Ajustes"
  },
  "game.button.handview.table": {
    "de": "TABLE",
    "en": "TABLE",
    "es": "MESA"
  },
  "game.button.handview.newgame": {
    "de": "NEW GAME",
    "en": "NEW GAME",
    "es": "NUEVA PARTIDA"
  },
  "game.button.handview.deal": {
    "de": "DEAL",
    "en": "DEAL",
    "es": "DAR"
  },
  "game.button.handview.deal.status.0": {
    "de": "DEAL FLOP",
    "en": "DEAL FLOP",
    "es": "DAR FLOP"
  },
  "game.button.handview.deal.status.1": {
    "de": "DEAL TURN",
    "en": "DEAL TURN",
    "es": "DAR TURN"
  },
  "game.button.handview.deal.status.2": {
    "de": "DEAL RIVER",
    "en": "DEAL RIVER",
    "es": "DAR RIVER"
  },
  "game.button.handview.standup": {
    "de": "STAND UP",
    "en": "STAND UP",
    "es": "LEVÁNTATE"
  },
  "game.button.handview.deal.showdown": {
    "de": "SHOW DOWN",
    "en": "SHOW DOWN",
    "es": "SHOW DOWN"
  },
  "game.text.handview.blinds": {
    "de": "Blinds",
    "en": "Blinds",
    "es": "Ciegas"
  },
  "game.text.handview.folded": {
    "de": "Folded",
    "en": "Folded",
    "es": "Folded"
  },
  "game.note.handview.smallblind": {
    "de": "Du bist small blind. Setze $smallblind_value",
    "en": "You are small blind. Bet $smallblind_value",
    "es": "Tú eres la ciega pequeña. Pon $smallblind_value"
  },
  "game.note.handview.bigblind": {
    "de": "Du bist big blind. Setze $bigblind_value",
    "en": "You are big blind. Bet $bigblind_value",
    "es": "Tú eres la ciega grande. Pon $bigblind_value"
  },
  "checkbox.lobby.flagDisableBlinds": {
    "de": "Deaktiviere Blinds",
    "en": "Disable blinds",
    "es": "Desactivar ciegas"
  },
  "button.lobby.leave": {
    "de": "Verlassen",
    "en": "Leave",
    "es": "Salir"
  },
  "game.text.handview.nextblinds": {
    "de": "Nächste Stufe",
    "en": "Next blinds",
    "es": "Siguiente nivel"
  },
  "text.settings.reveal.sensibility": {
    "de": "Karten - Aufdecken Sensibilität (schwach)|Karten - Aufdecken Sensibilität (normal)|Karten - Aufdecken Sensibilität (stark)",
    "en": "Cards - reveal sensibility (weak)|Cards - reveal sensibility (medium)|Cards - reveal sensibility (strong)",
    "es": "Cartas - Revelar Sensibilidad (Débil)|Cartas - Revelar Sensibilidad (Normal)|Cartas - Revelar Sensibilidad (Fuerte)"
  },
  "text.settings.fold.sensibility": {
    "de": "Karten - Folden Sensibilität (schwach)|Karten  - Folden Sensibilität (normal)|Karten - Folden Sensibilität (stark)",
    "en": "Cards - fold sensibility (weak)|Cards - fold sensibility (medium)|Cards - fold sensibility (strong)",
    "es": "Cartas - retirar sensibilidad (débil)|Cartas - retirar sensibilidad (normal)|Cartas - retirar sensibilidad (fuerte)"
  },
  "text.overlay.admin.blindsetting": {
    "de": "Blind ändern",
    "en": "edti blinds",
    "es": "Cambiar ciegas"
  },
  "text.overlay.admin.clock.pause": {
    "de": "Pokeruhr pausieren",
    "en": "pause pokerclock",
    "es": "Pausar pokerclock"
  },
  "text.overlay.admin.clock.resume": {
    "de": "Pokeruhr starten",
    "en": "resume pokerclock",
    "es": "Iniciar pokerclock"
  },
  "text.overlay.admin.smallblind": {
    "de": "Small Blind: $value",
    "en": "smallblind: $value",
    "es": "Ciega pequeña: $value"
  },
  "text.overlay.admin.bigblind": {
    "de": "Big Blind: $value",
    "en": "bigblind: $value",
    "es": "Ciega grande: $value"
  },
  "button.cancel": {
    "de": "Abbrechen",
    "en": "cancel",
    "es": "Cancelar"
  },
  "dialog.leave.reason.kick": {
    "de": "Du wurdest vom Spiel ausgeschlossen.",
    "en": "You was kicked from the game.",
    "es": "Has sido expulsado del juego."
  },
  "dialog.leave.reason.cleanup": {
    "de": "Das Spiel wurde vom Server beendet",
    "en": "The game was closed by the server",
    "es": "La partida en este servidor ha finalizado"
  },
  "dialog.admin.access.received": {
    "de": "Du hast Administrationsrechte erhalten",
    "en": "You got admin access",
    "es": "Te han dado derechos de administración"
  },
  "text.common.clockformat": {
    "de": "$hour:$min Uhr",
    "en": "$hour:$min",
    "es": "$hour:$min"
  },
  "text.game.player.bankroll.update": {
    "de": "$player - Bankroll angepasst auf $value",
    "en": "$player - Bankroll updated to $value",
    "es": "$player - Saldo de la cuenta ajustado a $value"
  },
  "text.game.player.kicked": {
    "de": "$player wurde gekickt",
    "en": "$player kicked",
    "es": "$player fue expulsado"
  },
  "text.player.winner.log": {
    "de": "hat $type",
    "en": "has $type",
    "es": "tiene $type"
  },
  "text.game.player.revealed": {
    "de": "$player hat aufgedeckt",
    "en": "$player revealed",
    "es": "El jugador enseña las cartas"
  },
  "header.account.step.0": {
    "de": "couchgames.wtf Account",
    "en": "couchgames.wtf Account",
    "es": "couchgames.wtf Cuenta"
  },
  "button.account.login": {
    "de": "Login",
    "en": "Login",
    "es": "Iniciar sesión"
  },
  "button.account.register": {
    "de": "Registrieren",
    "en": "Register",
    "es": "Regístrate"
  },
  "button.switch.accessbility": {
    "de": "Wechsel zum barrierefreien Modus",
    "en": "Switch to Accessible Mode",
    "es": "Pasar al modo accesible"
  },
  "account.activationcode": {
    "de": "Code hier einfügen",
    "en": "Insert code here",
    "es": "Inserte el código aquí"
  },
  "button.account.activate": {
    "de": "Login",
    "en": "Login",
    "es": "Iniciar sesión"
  },
  "button.account.agb": {
    "de": "Akzeptiere AGB",
    "en": "Accept T&C",
    "es": "Acepta las condiciones generales"
  },
  "button.account.newsletter": {
    "de": "Anmeldung zur Newsletter",
    "en": "Newsletter registration",
    "es": "Inscripción en el boletín"
  },
  "header.account.step.2": {
    "de": "Gib deine eMail Adresse an",
    "en": "Enter your email address",
    "es": "Introduzca aquí el correo electrónico"
  },
  "header.account.step.1": {
    "de": "Du hast einen Code per Mail bekommen",
    "en": "You have received a code by mail",
    "es": "Has recibido un código por correo"
  },
  "button.settings.rommsetting": {
    "de": "Tisch Einstellungen",
    "en": "Table settings",
    "es": "Ajustes de la mesa"
  },
  "text.overlay.admin.close.room": {
    "de": "Schließe Tisch",
    "en": "Close table",
    "es": "Cierra la mesa"
  },
  "text.overlay.admin.hideroomcode": {
    "de": "Blende den Tischcode aus",
    "en": "Hide the table code",
    "es": "Esconder el código de la mesa"
  },
  "text.overlay.admin.disable.windetection": {
    "de": "Siegererkennung deaktivieren",
    "en": "Deactivate winner detection",
    "es": "Desactivar la identificación del ganador"
  },
  "text.overlay.admin.enable.windetection": {
    "de": "Siegererkennung aktivieren",
    "en": "Activate winner detection",
    "es": "Activar la identificación del ganador"
  },
  "text.overlay.history.show": {
    "de": "Zeige die Historie",
    "en": "Show history",
    "es": "Mostrar la historia"
  },
  "text.overlay.admin.open.room": {
    "de": "Öffne Tisch",
    "en": "Open table",
    "es": "Abre la mesa"
  },
  "text.overlay.admin.showroomcode": {
    "de": "Blende den Tischcode ein",
    "en": "Show the table code",
    "es": "Mostrar el código de la mesa"
  },
  "text.statistic.head.gamepublic": {
    "de": "Öffentliche Spiele",
    "en": "Public Games",
    "es": "Partida publica"
  },
  "text.statistic.head.gameprivate": {
    "de": "Private Spiele",
    "en": "Private Games",
    "es": "Partida privada"
  },
  "dialog.account.registration.failed": {
    "de": "Registrierung fehlgeschlagen",
    "en": "Registration failed",
    "es": "Registro fallido"
  },
  "dialog.account.notselected.agb": {
    "de": "Bitte AGB akzeptieren",
    "en": "Please accept GTC",
    "es": "Por favor, acepte las condiciones generales"
  },
  "dialog.account.notsame.mail": {
    "de": "Unterschiedliche mail Adressen",
    "en": "Different mail addresses",
    "es": "Diferentes direcciones de correo"
  },
  "dialog.account.invalid.mail": {
    "de": "Ungültige eMail",
    "en": "Invalid e-mail",
    "es": "Correo electrónico inválido"
  },
  "button.account.logout": {
    "de": "Ausloggen",
    "en": "Logout",
    "es": "Cerrar la sesión"
  },
  "text.common.email": {
    "de": "E-Mail Adresse",
    "en": "email address",
    "es": "correo electrónico"
  },
  "button.account.connect": {
    "de": "Verbinden mit CouchGames.wtf",
    "en": "Connect with CouchGames.wtf",
    "es": "Conecta con CouchGames.wtf"
  },
  "text.overlay.admin.profile": {
    "de": "Zeige Profil",
    "en": "Show Profile",
    "es": "Ver perfil"
  },
  "text.overlay.admin.kick": {
    "de": "Spieler kicken",
    "en": "kick player",
    "es": "Quitar al jugador"
  },
  "text.overlay.admin.forcestandup": {
    "de": "Standup erzwingen",
    "en": "force standup",
    "es": "Obligar a levantarse de la mesa"
  },
  "text.overlay.admin.setdealer": {
    "de": "Als dealer bestimmen",
    "en": "set dealer",
    "es": "Elegir como dealer"
  },
  "text.overlay.admin.forceplay": {
    "de": "Erzwinge nächste Aktion",
    "en": "force play action",
    "es": "Forzar la siguiente acción"
  },
  "text.overlay.admin.setbankroll": {
    "de": "Bankroll ändern",
    "en": "change bankroll",
    "es": "Modificar el saldo de la cuenta"
  },
  "text.overlay.admin.resetmatch": {
    "de": "Spiel zurücksetzen",
    "en": "reset match",
    "es": "Reiniciar el partido"
  },
  "dialog.admin.resetmatch": {
    "de": "Spiel wirklich zurücksetzen? Der aktuelle Spielstand geht hierbei verloren",
    "en": "Do you really want to reset the current match? All your gameprogress will be lost",
    "es": "¿De verdad quieres reiniciar el juego? Se perderá la puntuación actual"
  },
  "game.note.handview.dealer.bigblind": {
    "de": "Du bist Dealer und Big Blind. Setze $bigblind_value",
    "en": "You are dealer and big blind. Bet $bigblind_value",
    "es": "Eres el dealer y la ciega grande. Fijar $bigblind_value"
  },
  "game.note.handview.dealer": {
    "de": "Du bist Dealer",
    "en": "You are dealer",
    "es": "Tú eres dealer"
  },
  "text.settings.head.gamehistory": {
    "de": "Spielhistorie",
    "en": "Gamehistory",
    "es": "Historia del juego"
  },
  "text.settings.head.common": {
    "de": "Einstellungen",
    "en": "Settings",
    "es": "Opciones"
  },
  "text.settings.head.language": {
    "de": "Sprachauswahl",
    "en": "Language Selector",
    "es": "Seleccióna idioma"
  },
  "text.settings.head.device": {
    "de": "Clienteinstellungen",
    "en": "Clientsettings",
    "es": "Configuración del cliente"
  },
  "text.settings.head.game": {
    "de": "Spieleinstellungen",
    "en": "Gamesetting",
    "es": "Configuración del juego"
  },
  "text.settings.head.account-select-avatar": {
    "de": "Avatarauswahl",
    "en": "Your avatar",
    "es": "Selección de avatar"
  },
  "text.settings.head.account-upload-avatar": {
    "de": "Avatar Upload",
    "en": "Upload avatar",
    "es": "Subir avatar"
  },
  "text.settings.head.account-rename-user": {
    "de": "Spieler umbennen",
    "en": "Rename User",
    "es": "Renombrar jugador"
  },
  "text.settings.head.account-connect-user": {
    "de": "Couchgames.wtf Account",
    "en": "Couchgames.wtf Account",
    "es": "Cuenta Couchgames.wtf"
  },
  "text.settings.head.gameviewlayout": {
    "de": "Tv Layout",
    "en": "Tv Layout",
    "es": "TV Diseño"
  },
  "dialog.avatarupload.waiting": {
    "de": "Dein Profilbild wird derzeit noch geprüft. Bitte warte und schaue zu einem späteren Zeitpunkt vorbei",
    "en": "Your profile picture is currently in review. Please wait",
    "es": "Tu foto de perfil aún está en revisión. Por favor, espere y vuelva a comprobarlo más adelante"
  },
  "dialog.avatarupload.declined": {
    "de": "Dein Profilbild entspricht nicht unseren Communityregeln. Bitte lade ein neues hoch",
    "en": "Your profile picture does not comply with our community rules. Please upload a new one",
    "es": "Tu foto de perfil no cumple las normas de nuestra comunidad. Por favor, suba uno nuevo"
  },
  "dialog.avatarupload.delete": {
    "de": "Willst du ein neues Profilbild hochladen? Dein altes Profilbild wird sofort gelöscht",
    "en": "Do you want to upload your profile picture? Your old one will be deleted directly",
    "es": "¿Quieres subir una nueva foto de perfil? Tu antigua foto de perfil se eliminará inmediatamente"
  },
  "dialog.buyin.range": {
    "de": "In das Spiel einkaufen mit $buyinmin bis $buyinmax",
    "en": "Buy-in with $buyinmin up to $buyinmax",
    "es": "Entra en el juego con $buyinmin a $buyinmax"
  },
  "checkbox.lobby.private": {
    "de": "Privater Tisch",
    "en": "Private table",
    "es": "Mesa privada"
  },
  "text.lobby.head.publicsetting": {
    "de": "Öffentliche Einstellungen",
    "en": "public settings",
    "es": "Opciones públicas"
  },
  "text.lobby.head.buyinsetting": {
    "de": "Buy-in Einstellungen",
    "en": "buy-in settings",
    "es": "Ajustes de Buy-In"
  },
  "checkbox.lobby.buyintotal": {
    "de": "Buy-in",
    "en": "buy-in",
    "es": "buy-in"
  },
  "checkbox.lobby.buyinmin": {
    "de": "Buy-in - Minimum",
    "en": "buy-in - minimum",
    "es": "Buy-in - mínimo"
  },
  "checkbox.lobby.buyinmax": {
    "de": "Buy-in - Maximum",
    "en": "buy-in - maximum",
    "es": "Buy-in - nmáximo"
  },
  "checkbox.lobby.buyinrange": {
    "de": "Buy-in Bereich",
    "en": "buy-in range",
    "es": "Zona de buy-in"
  },
  "checkbox.lobby.buyinconfirm": {
    "de": "Buy-in Bestätigung",
    "en": "buy-in confimation",
    "es": "Buy-in confirmación"
  },
  "dialog.buyin": {
    "de": "Buy-in mit $buyinmin",
    "en": "Buy-in with $buyinmin",
    "es": "Buy-in con $buyinmin"
  },
  "text.overlay.admin.allow.buyin": {
    "de": "Erlaube buy-in",
    "en": "Allow player buy-in",
    "es": "Permitir buy-in"
  },
  "text.overlay.admin.forbid.buyin": {
    "de": "Verbiete den buy-in",
    "en": "Forbid buy-in",
    "es": "Prohibir el buy-in"
  },
  "dialog.buyin.disabled": {
    "de": "Buy-in in das laufende Spiel ist deaktiviert. Der Spieladmin kann es aktivieren.",
    "en": "Buy-in to the game in progress is disabled. The game admin can activate it.",
    "es": "El buy-in al juego en curso está desactivada. El administrador del juego puede activarlo."
  },
  "text.voting.adminrequest.title": {
    "de": "Admin Rechte Anfrage",
    "en": "admin request",
    "es": "Solicitar derechos de administrador"
  },
  "snackbar.voting.request": {
    "de": "Neue Anfragen",
    "en": "New requests",
    "es": "Nuevos pedidos"
  },
  "text.voting.adminrequest.subtitle": {
    "de": "Jemand fordert Admin-Rechte",
    "en": "Someone demands admin rights",
    "es": "Alguien exige derechos de administrador"
  },
  "text.common.waiting": {
    "de": "Warte",
    "en": "waiting",
    "es": "Esperando"
  },
  "text.common.paused": {
    "de": "pausiert",
    "en": "paused",
    "es": "pausa"
  },
  "text.game.button.global.buyin": {
    "de": "Buy-in",
    "en": "buy-in",
    "es": "buy-in"
  },
  "text.overlay.admin.roomrequest": {
    "de": "Anfragen",
    "en": "Requests",
    "es": "Pedidos"
  },
  "button.settings.roomsetting": {
    "de": "Tischeinstellungen",
    "en": "Table settings",
    "es": "Configuración de la mesa"
  },
  "text.lobby.autostart": {
    "de": "Nächster Rundenstart nach $time Sekunden",
    "en": "Next round start after $time seconds",
    "es": "El siguiente turno comienza después de $time segundos"
  },
  "text.game.player.buyin": {
    "de": "$player buy-in $value",
    "en": "$player buy-in $value",
    "es": "$player buy-in $value"
  },
  "text.joinmenu.tab.public": {
    "de": "Öffentlicher Tisch",
    "en": "public table",
    "es": "Mesa publica"
  },
  "text.joinmenu.tab.private": {
    "de": "Privater Tisch",
    "en": "private table",
    "es": "Mesa privada"
  },
  "text.lobby.serverlist.noroom": {
    "de": "Keine Tische gefunden. Eventuell Verbindungsprobleme.",
    "en": "No tables found. Possibly connection problems.",
    "es": "No se han encontrado mesas. Posiblemente problemas de conexión."
  },
  "text.lobby.serverlist.entry": {
    "de": "Buy-in: $min / $max Blinds: $sb / $bb",
    "en": "Buy-in: $min / $max Blinds: $sb / $bb",
    "es": "Buy-in: $min / $max ciegas: $sb / $bb"
  },
  "dialog.user.credits.notenough": {
    "de": "Du hast nicht genug Spielchips. Du kannst im Shop dir mehr Spielchips holen",
    "en": "You have not enough chips. Get more chips in the shop",
    "es": "No tienes suficientes fichas. Puedes conseguir más fichas en la tienda"
  },
  "checkbox.lobby.sitdownconfirm": {
    "de": "Teilnahme Bestätigung",
    "en": "Sitdown confirm",
    "es": "Aceptar participación"
  },
  "text.voting.buyin": {
    "de": "Buy-in - $value",
    "en": "buy-in - $value",
    "es": "buy-in - $value"
  },
  "text.voting.sitdown": {
    "de": "Hinsetzen erlauben?",
    "en": "Allow to sit down?",
    "es": "¿Permitir sentarse?"
  },
  "dialog.sitdown.disabled": {
    "de": "Buy-in in das laufende Spiel ist deaktiviert. Der Spieladmin kann es aktivieren.",
    "en": "The sitdown is currently disabled. Please ask your gameadmin",
    "es": "La opcion de compra en el juego está desactivada. El administrador del juego en curso puede activarlo."
  },
  "text.game.button.global.sitdown": {
    "de": "Hinsetzen",
    "en": "sitdown",
    "es": "Sentarme"
  },
  "checkbox.lobby.flagShowBlindNotice": {
    "de": "Hinweise für SB / BB / Dealer",
    "en": "Hints for sb/bb/dealer",
    "es": "Notas para cp / cg / repartidor"
  },
  "dialog.expire.demo.creator": {
    "de": "Die kostenlose Spielzeit endet in 5 Minuten. Hole dir jetzt einen VIP-Pass (mindestens 24h) um weiter zu spielen, oder ein VIP-Spieler eröffnet einen neuen Tisch. Alle anderen Spieler können kostenlos mitspielen.",
    "en": "The free play time ends in 5 minutes. Get a VIP Pass now (minimum 24h) to continue playing, or a VIP-player opens a new table. All other players can play for free.",
    "es": "El tiempo de juego gratuito termina en 5 minutos. Consigue ahora un Pase VIP (mínimo 24h) para seguir jugando, o un jugador VIP abre una nueva mesa. Todos los demás jugadores pueden jugar gratis."
  },
  "dialog.expire.30minutes": {
    "de": "Der Tisch wird in 30 Minuten geschlossen. Wollt Ihr noch weiter spielen?",
    "en": "The table will be closed in 30 minutes. Do you still want to play?",
    "es": "La mesa se cerrará en 30 minutos. ¿Todavía queréis jugar?"
  },
  "snackbar.notice.matchwinner": {
    "de": "$player gewinnt",
    "en": "$player wins",
    "es": "$player gana"
  },
  "snackbar.notice.splitpot": {
    "de": "Splitpot",
    "en": "splitpot",
    "es": "bote dividido"
  },
  "tutorial.privategame": {
    "de": "Der Tisch taucht nicht in der öffentlichen Tisch-Liste auf. Teile den Tischcode mit deinen Freunden. \n",
    "en": "The table does not appear in the public table list. Share the table code with your friends. \n",
    "es": "La mesa no aparece en la lista de mesas públicas. Comparte el código de la mesa con tus amigos."
  },
  "text.overlay.admin.forbid.sitdown": {
    "de": "Verbiete den Sitdown",
    "en": "Forbid sitdown",
    "es": "Prohibir sentarse"
  },
  "text.overlay.admin.allow.sitdown": {
    "de": "Erlaube Sitdown",
    "en": "Allow player sitdown",
    "es": "Permitir sentarse"
  },
  "text.info.table.noseat": {
    "de": "Du sitzt nicht am Tisch. Wähle einen Platz",
    "en": "You are not sitting. Choose a seat!",
    "es": "No estás sentado a la mesa. Elija un asiento"
  },
  "tutorial.feature.randomseatorder": {
    "de": "Alle Spieler werden in zufälliger Reihenfolge an den Tisch gesetzt.",
    "en": "All players are seated at the table in random order.",
    "es": "Todos los jugadores se sientan a la mesa en orden aleatorio."
  },
  "text.vip.button": {
    "de": "VIP",
    "en": "VIP",
    "es": "VIP"
  },
  "text.vip.statistic": {
    "de": "Statistiken sind nur für VIP-Spieler verfügbar",
    "en": "Statistics are only available for VIP-players\n",
    "es": "Las estadísticas sólo están disponibles para los jugadores VIP"
  },
  "dialog.vip.avatar": {
    "de": "Du benötigst VIP, um dein einen eigenen Avatar hoch zu laden",
    "en": "You need to have VIP, to upload your own avatar",
    "es": "Necesitas VIP para subir tu propio avatar"
  },
  "text.lobby.blindinfo": {
    "de": "buy-in: $min - $max / Blinds: $sb/$bb",
    "en": "buy-in: $min - $max / Blinds: $sb/$bb",
    "es": "buy-in: $min - $max / ciegas: $sb/$bb"
  },
  "text.lobby.autostart.off": {
    "de": "Deaktiviert",
    "en": "Off",
    "es": "desactivado"
  },
  "poker.vip.1": {
    "de": "24 Stunden VIP ( Tagespass )",
    "en": "24 hours VIP ( daily pass )",
    "es": "24 horas VIP ( Pase de un día )"
  },
  "poker.vip.30": {
    "de": "1 Monat VIP ( 30 Tage )",
    "en": "1 month VIP ( 30 days )",
    "es": "1 mes VIP ( 30 días )"
  },
  "poker.vip.360": {
    "de": "1 Jahr VIP ( 360 Tage )",
    "en": "1 year VIP ( 360 days )",
    "es": "1 año VIP ( 360 días )"
  },
  "button.avatar.back": {
    "de": "Zurück",
    "en": "Back",
    "es": "Atras"
  },
  "button.avatar.save": {
    "de": "Speichern",
    "en": "Save",
    "es": "Guardar"
  },
  "text.userdetail.vip.expire": {
    "de": "VIP läuft aus am $date",
    "en": "VIP expires on $date",
    "es": "VIP caduca en $date"
  },
  "30.minutes.limitation": {
    "de": "Kostenloses Probespielen für 30 Minuten. <u>Mehr lesen</u>",
    "en": "Free trial for 30 minutes. <u>Read more</u>",
    "es": "Prueba gratuita durante 30 minutos. <u>Seguir leyendo</u>"
  },
  "tutorial.30.minutes.limitation": {
    "de": "Während der kostenlosen Spielzeit von 30 Minuten könnt Ihr die Limitierung aufheben, in dem der Spielersteller einen VIP-Pass kauft (mindestens 24 Stunden) und weiterspielen. Alle anderen können kostenlos mitspielen. Neben dem Tischcode findet Ihr den Countdown.",
    "en": "During the free play time of 30 minutes, you can remove the limitation by purchasing a VIP pass (minimum 24 hours) and continue playing. Everyone else can play for free. You will find the countdown next to the table code.",
    "es": "Durante el tiempo de juego gratuito de 30 minutos, puedes eliminar la limitación comprando un pase VIP (mínimo 24 horas) y seguir jugando. Todos los demás pueden jugar gratis. Junto al código de la tabla encontrará el tiempo restante."
  },
  "checkbox.lobby.flagIamDealer": {
    "de": "Tisch als Dealer erstellen",
    "en": "Create table as dealer",
    "es": "Crear tabla como dealer"
  },
  "tutorial.iamDealer": {
    "de": "Als Dealer hast du die volle Kontroller über das Spiel, erhältst jedoch keine Karten und kannst nicht mitspielen",
    "en": "As the dealer, you have full control over the game, but you do not receive any cards and cannot play.",
    "es": "Como dealer, tienes el control total del juego, pero no recibes cartas y no puedes jugar."
  },
  "dialog.vip.avatar.granted": {
    "de": "Dein Avatar wurde genehmigt. Du kannst ihn nun aktivieren",
    "en": "Your avatar has been approved. You can now activate it",
    "es": "Tu avatar ha sido aprobado. Ya puedes activarlo"
  },
  "text.lobby.autostart.title": {
    "de": "Automatischer Rundenstart",
    "en": "Automatic round start",
    "es": "Siguiente turno"
  },
  "snackbar.shop.vip.extended": {
    "de": "VIP verlängert",
    "en": "VIP extended",
    "es": "VIP extendido"
  },
  "snackbar.shop.asset": {
    "de": "Asset gekauft",
    "en": "Asset purchased",
    "es": "Artículo comprado"
  },
  "button.settings.cards": {
    "de": "Kartendeck auswählen",
    "en": "Select cards",
    "es": "Seleccionar baraja"
  },
  "text.settings.cards.default": {
    "de": "Standard Karten",
    "en": "Default cards",
    "es": "Baraja estándar"
  },
  "text.settings.cards.fourcolor": {
    "de": "4 farbige Karten",
    "en": "4 coloured cards",
    "es": "Tarjetas de 4 colores"
  },
  "text.lobby.head.assetsetting": {
    "de": "Spielkarten",
    "en": "Playing cards",
    "es": "Barajas de cartas"
  },
  "checkbox.lobby.tableForceDeck": {
    "de": "Gleiches Kartendesign für alle",
    "en": "Same cards design for all",
    "es": "Diseño de tarjetas para todos"
  },
  "text.settings.cards.deck1": {
    "de": "Modern 1",
    "en": "Modern 1",
    "es": "Moderno 1"
  },
  "text.overlay.admin.help": {
    "de": "Hilfe",
    "en": "help",
    "es": "Ayuda"
  },
  "text.overlay.help.winninghand": {
    "de": "Pokerblätter",
    "en": "Poker hands",
    "es": "Manos de poker"
  },
  "text.overlay.sub.title.helpwinninghand": {
    "de": "Pokerblätter",
    "en": "Poker hands",
    "es": "Manos de poker"
  },
  "text.overlay.sub.title.roomrequest": {
    "de": "Alle Anfragen",
    "en": "All request",
    "es": "Todos los pedidos"
  },
  "text.overlay.sub.title.editblind": {
    "de": "Ändere die Blinds zur nächsten Runde",
    "en": "Change the blinds to the next round",
    "es": "Cambiar las ciegas al proximo turno"
  },
  "text.overlay.sub.title.editbankroll": {
    "de": "Kontostand ändern",
    "en": "Change bankroll",
    "es": "Modificar el saldo"
  },
  "tutorial.feature.flagAllowAfterJoin": {
    "de": "Spieler dürfen dem gestarteten Spiel beitreten",
    "en": "Players are allowed to join the current game later",
    "es": "Los jugadores pueden unirse a la partida iniciada"
  },
  "tutorial.feature.flagAutoAllInShowdown": {
    "de": "Wenn alle Spieler All-in sind und keine Aktionen mehr möglich sind, werden die Karten automatisch aufgedeckt.",
    "en": "When all players are all-in and no more actions are possible, the cards are automatically turned over.",
    "es": "Cuando todos los jugadores están all-in y no hay más acciones posibles, las cartas se dan la vuelta automáticamente."
  },
  "tutorial.feature.buyinrange": {
    "de": "Spieler können selber entscheiden mit wie vielen Chips sie an den Tisch gehen.",
    "en": "Players can decide for themselves how many chips they want to bring to the table.",
    "es": "Los jugadores pueden decidir por sí mismos cuántas fichas quieren llevar a la mesa."
  },
  "tutorial.feature.buyinconfirm": {
    "de": "Der Spieladmin muss bestätigen, ob sich jemand einkaufen darf",
    "en": "The game admin must confirm if a player is allowed to buy-in",
    "es": "El administrador del juego debe confirmar si un jugador está autorizado a hacer un buy-in"
  },
  "tutorial.feature.sitdownconfirm": {
    "de": "Der Spieladmin muss bestätigen, ob ein Spieler sich hinsetzen darf",
    "en": "The game admin must confirm if a player is allowed to sit down",
    "es": "El administrador del partido debe confirmar si un jugador puede sentarse"
  },
  "tutorial.feature.flagShowBlindNotice": {
    "de": "Spieler bekommen Hinweise wenn sie Dealer, Small Blind oder Big Blind sind und wie viel sie setzen müssen als Blind.",
    "en": "Players get hints when they are dealer, small blind or big blind and how much they have to bet as blind.",
    "es": "Los jugadores reciben indicios de si son dealer, ciega pequeña o ciega grande y de cuánto tienen que apostar como ciega."
  },
  "tutorial.feature.flagGlobalDeck": {
    "de": "Wähle ein einheitliches Kartendeck für alle Spieler aus. Alle Spieler haben dann das selbe Deck, auch wenn sie es nicht gekauft haben.",
    "en": "Select a uniform pack of cards for all players. All players then have the same deck, even if they did not buy it.",
    "es": "Selecciona una baraja uniforme para todos los jugadores. Todos los jugadores tienen la misma baraja, aunque no la hayan comprado."
  },
  "tutorial.feature.flagDisableBlinds": {
    "de": "Deaktiviere die Blind-Funktion im Spiel. Es werden keine Blinds mehr angezeigt und die Poker Clock kann auch nicht verwendet werden.",
    "en": "Deactivate the blind function in the game. Blinds are no longer displayed and the poker clock cannot be used.",
    "es": "Desactiva la función ciega en el juego. Ya no se visualizan las ciegas y no se puede utilizar el reloj de poker."
  },
  "tutorial.feature.pokerclock": {
    "de": "Konfiguriere die Blinderhöhungen nach Minuten oder Runden. Klicke auf Hinzufügen um mehrere Level zu konfigurieren. Das Spiel weißt euch auf die Erhöhungen hin. ",
    "en": "Configure the blind increases by minutes or rounds. Click Add to configure multiple levels. The game will alert you to the increases. ",
    "es": "Configure las subidas ciegas por minutos o turno. Haga clic en Añadir para configurar varios niveles. El juego te avisará de los aumentos."
  },
  "snackbar.demo.upgraded": {
    "de": "Zeitlimit aufgehoben",
    "en": "Time limit removed",
    "es": "Tiempo inlimitado activado"
  },
  "tutorial.whatistv": {
    "de": "Der TV Modus wird genutzt um die community Karten auf dem Tisch darzustellen: Entweder auf einem Tablet in der Mitte des Tisches, oder in jedem Browser am PC, Notebook oder auch SmartTV. Für den App-freien Zugriff öffne deinen Browser und surfe auf <b><u>tv.bullets.poker</b></u>",
    "en": "The TV mode is used to display the community cards on the table: Either on a tablet in the middle of the table, or in any browser on a PC, notebook or even SmartTV. For app-free access, open your browser and surf to <b><u>tv.bullets.poker</b></u>",
    "es": "El modo TV se utiliza para mostrar las cartas comunitarias en la mesa: Ya sea en una tableta en medio de la mesa, o en cualquier navegador de un PC, portátil o incluso SmartTV. Para acceder sin necesidad de aplicación, abre tu navegador y navega hasta <b><u>tv.bullets.poker</b></u>"
  },
  "dialog.vip.demo.extend": {
    "de": "Um die 30-Minuten Limitierung aufzuheben, kannst du die hier ein VIP Pass kaufen. Alle anderen spielen kostenlos mit.",
    "en": "To remove the 30-minute limit, you can buy a VIP pass here. Everyone else plays for free.",
    "es": "Para eliminar el límite de 30 minutos, puede comprar un pase VIP aquí. Todos los demás juegan gratis."
  },
  "text.overlay.admin.demo.extend": {
    "de": "Limitierung aufheben",
    "en": "Cancel limitation",
    "es": "Cancelar limitación"
  },
  "text.inappstore.tab.chips": {
    "de": "Spielchips",
    "en": "chips",
    "es": "fichas"
  },
  "text.inappstore.tab.coins": {
    "de": "Coins",
    "en": "coins",
    "es": "Monedas"
  },
  "text.inappstore.tab.assets": {
    "de": "Anpassungen",
    "en": "Cosmetics",
    "es": "Cosméticos"
  },
  "text.inappstore.tab.vip": {
    "de": "VIP",
    "en": "VIP",
    "es": "VIP"
  },
  "dialog.user.coins.notenough": {
    "de": "Du hast nicht genug Coins. Du kannst im Shop dir mehr Coins holen",
    "en": "You have not enough coins. Get more coins in the shop",
    "es": "No tienes suficientes monedas. Puedes conseguir más monedas en la tienda"
  },
  "dialog.expire.demo.player": {
    "de": "Die kostenlose Spielzeit endet in 5 Minuten. Um weiter zu spielen muss der Tischersteller ein VIP-Pass (mindestens 24h) holen, oder ein VIP-Spieler eröffnet einen neuen Tisch. Alle anderen Spieler können kostenlos mitspielen.",
    "en": "The free play time ends in 5 minutes. To continue playing, the table creator must get a VIP pass (at least 24h) or a VIP player opens a new table. All other players can play for free.",
    "es": "El tiempo de juego libre termina en 5 minutos. Para seguir jugando, el creador de la mesa debe conseguir un pase VIP (al menos 24h) o un jugador VIP abre una nueva mesa. Todos los demás jugadores pueden jugar gratis."
  },
  "dialog.gameover.demo.creator": {
    "de": "Die kostenlose Spielzeit ist vorbei. Hole dir jetzt einen VIP-Pass (mindestens 24h) um weiter zu spielen, oder ein VIP-Spieler eröffnet einen neuen Tisch. Alle anderen Spieler können kostenlos mitspielen.",
    "en": "The free play time is over. Get a VIP Pass now (minimum 24h) to continue playing, or a VIP-player opens a new table. All other players can play for free.",
    "es": "Se acabó el tiempo de juego gratuito. Consigue ahora un pase VIP (mínimo 24h) para seguir jugando, o un jugador VIP abre una nueva mesa. Todos los demás jugadores pueden jugar gratis."
  },
  "dialog.gameover.demo.player": {
    "de": "Die kostenlose Spielzeit ist vorbei. Um weiter zu spielen muss der Tischersteller ein VIP-Pass (mindestens 24h) holen, oder ein VIP-Spieler eröffnet einen neuen Tisch. Alle anderen Spieler können kostenlos mitspielen.",
    "en": "The free play time is over. To continue playing, the table creator must get a VIP pass (at least 24h) or a VIP player opens a new table. All other players can play for free.",
    "es": "Se acabó el tiempo de juego gratuito. Para seguir jugando, el creador de la mesa debe conseguir un pase VIP (al menos 24h) o un jugador VIP abre una nueva mesa. Todos los demás jugadores pueden jugar gratis."
  },
  "text.settings.cards.deck4": {
    "de": "Sehr große Symbole",
    "en": "Very large symbols",
    "es": "Símbolos muy grandes"
  },
  "snackbar.shop.vip.buy": {
    "de": "VIP gekauft",
    "en": "VIP bought",
    "es": "VIP comprado"
  },
  "text.inappstore.freetrial": {
    "de": "Kostenlos testen",
    "en": "Free trial",
    "es": "Prueba gratuita"
  },
  "dialog.inappstore.freetrial": {
    "de": "Probiere das Kartendeck für 1 Stunde kostenlos aus. Nur einmal einlösbar.",
    "en": "Try the deck for free for 1 hour. Redeemable only once.",
    "es": "Pruebe la baraja gratis durante 1 hora. Canjeable una sola vez."
  },
  "dialog.game.security.fold": {
    "de": "Willst du wirklich folden?",
    "en": "Do you really want to fold?",
    "es": "¿De verdad quieres retirarte?"
  },
  "dialog.game.security.dontshowagain": {
    "de": "Nicht wieder anzeigen",
    "en": "Do not show again",
    "es": "No volver a mostrar"
  },
  "dialog.game.security.reveal": {
    "de": "Möchtest du deine Karten wirklich aufdecken?",
    "en": "Do you really want to reveal your cards?",
    "es": "¿De verdad quieres revelar tus cartas?"
  },
  "text.settings.security.fold": {
    "de": "Fold Sicherheitsabfrage (an)|Fold Sicherheitsabfrage (aus)",
    "en": "fold security (enabled)|fold security (disabled)",
    "es": "Aviso de confirmación de retirada (activado)|aviso de confirmación de retirada (desactivado)"
  },
  "text.settings.security.reveal": {
    "de": "Reveal Sicherheitsabfrage (an)|Reveal Sicherheitsabfrage (aus)",
    "en": "reveal security (enabled)|reveal security (disabled)",
    "es": "Mostrar mensaje de revelar cartas (activado)| Mostrar mensaje de revelar cartas (desactivado)"
  },
  "snackbar.notice.adfree": {
    "de": "Jetzt werbefrei",
    "en": "now adfree",
    "es": "Ahora sin anuncios"
  },
  "text.overlay.buy.adfree": {
    "de": "Deaktiviere Werbung für alle",
    "en": "Disable Ads for all",
    "es": "Desactivar la publicidad para todos"
  },
  "dialog.table.upgrade.adfree": {
    "de": "Mache den Tisch für alle werbefrei.",
    "en": "Upgrade the table to be adfree for all",
    "es": "Haz que la mesa esté libre de anuncios para todos."
  },
  "button.game.adminrequest": {
    "de": "Adminrechte anfragen",
    "en": "Request admin",
    "es": "Pedir derechos de administrador"
  },
  "header.account.step.3": {
    "de": "Wähle deinen Account",
    "en": "Choose your account",
    "es": "Elige tu cuenta"
  },
  "checkbox.lobby.bigtable": {
    "de": "Großer Tisch",
    "en": "Big pokertable",
    "es": "Mesa grande"
  },
  "tutorial.feature.bigtable": {
    "de": "Erstelle einen großen Tisch mit 10 Spielern anstatt nur mit 5.",
    "en": "Create a large table with 10 players instead of only 5.",
    "es": "Crea una mesa grande con 10 jugadores en lugar de sólo 5."
  },
  "text.common.notstarted": {
    "de": "Inaktiv",
    "en": "Not started",
    "es": "En espera"
  },
  "tutorial.card.fold": {
    "de": "<u><b>Fold:</b></u>  Karten nach oben wischen",
    "en": "<u><b>Fold:</b></u> Swipe cards upwards",
    "es": "<u><b>Fold:</b></u>  Desliza hacia arriba"
  },
  "tutorial.card.revealone": {
    "de": "<u><b>Eine Karte aufdecken:</b></u> Nach rechts, oder links wischen",
    "en": "<u><b>Reveal individual cards:</b></u> Swipe right, or left",
    "es": "<u><b>Revela individualmente:</b></u> Desliza a la derecha o izquierda"
  },
  "tutorial.card.peek": {
    "de": "<u><b>Karten anschauen:</b></u> Ziehe etwas nach unten",
    "en": "<u><b>Look at the cards:</b></u> Drag something down",
    "es": "<u><b>Mira las cartas:</b></u> Arrastra hacia abajo"
  },
  "tutorial.bet": {
    "de": "<u><b>Call & Raise:</b></u> Wert einstellen und Chip in die Mitte ziehen",
    "en": "<u><b>Call & Raise:</b></u> Set value and move chip to the centre",
    "es": "<u><b>Call & Raise:</b></u> Ajusta el valor y arrastra el chip al centro"
  },
  "text.overlay.admin.seat.open": {
    "de": "Erlaube Sitzplatz",
    "en": "Allow seat",
    "es": "Permitir asiento"
  },
  "text.overlay.admin.seat.close": {
    "de": "Verbiete Sitzplatz",
    "en": "Disallow seat",
    "es": "Prohibir asiento"
  },
  "text.overlay.admin.place": {
    "de": "Platziere Spieler",
    "en": "Place player",
    "es": "Colocar jugador"
  },
  "text.overlay.sub.title.placeplayer": {
    "de": "Spieler am Tisch platzieren",
    "en": "Place player at the table",
    "es": "Colocar jugador en la mesa"
  },
  "text.overlay.admin.subhead.playerplace": {
    "de": "Platziere Spieler auf Platz $seat",
    "en": "Place player at seat $seat",
    "es": "Sentar jugador en el asiento $seat"
  },
  "text.overlay.admin.sitdown.accept": {
    "de": "Anfrage aktzeptieren",
    "en": "Accept request",
    "es": "Aceptar solicitud"
  },
  "text.overlay.admin.sitdown.decline": {
    "de": "Anfrage ablehnen",
    "en": "Decline request",
    "es": "Rechazar solicitud"
  },
  "dialog.leave.reason.closed": {
    "de": "Der Tisch wurde geschlossen",
    "en": "The table was closed",
    "es": "La mesa fue cerrado"
  },
  "dialog.leave.reason.noresponse": {
    "de": "Du warst inaktiv und wurdest vom Spiel entfernt",
    "en": "You are unresponsible and was removed by the game",
    "es": "Has estado inactivo y has sido expulsado del juego"
  },
  "text.overlay.admin.hideblindnotice": {
    "de": "Deaktiviere Blindhilfe",
    "en": "disable blinds help",
    "es": "Desactivar la ayuda ciegas"
  },
  "text.overlay.admin.showblindnotice": {
    "de": "Aktiviere Blindhilfe",
    "en": "enable blinds help",
    "es": "Activar la ayuda ciegas"
  },
  "tutorial.card.revealall": {
    "de": "<u><b>Karten aufdecken:</b></u> Halten und ganz nach unten ziehen",
    "en": "<u><b>Reveal cards:</b></u> Hold and pull all the way down",
    "es": "<u><b>Revela las cartas:</b></u> Sujeta y tira hasta el fondo"
  },
  "checkbox.lobby.flagBlindPro": {
    "de": "<Please add",
    "en": "<Please add",
    "es": "<Please add"
  },
  "tutorial.feature.flagBlindPro": {
    "de": "tutorial.feature.flagBlindPro",
    "en": "tutorial.feature.flagBlindPro",
    "es": "tutorial.feature.flagBlindPro"
  },
  "game.text.handview.waitnextround": {
    "de": "Warte bis die nächste Runde startet, oder du Karten bekommen darfst.",
    "en": "Wait until the next round starts or you are allowed to get cards.",
    "es": "Espera a que empiece el siguiente turno o a que se te permita conseguir cartas."
  },
  "button.mainmenu.back": {
    "de": "Zurück",
    "en": "back",
    "es": "Atrás"
  },
  "button.mainmenu.onlinepoker": {
    "de": "Online Poker",
    "en": "Online Poker",
    "es": "Online Poker"
  },
  "button.mainmenu.livepoker": {
    "de": "Live Poker",
    "en": "Live Poker",
    "es": "Live Poker"
  },
  "text.account.name.change.limitation": {
    "de": "! Namensänderungen sind nur alle 30 Tage möglich !",
    "en": "! You can only change your name every 30 days !",
    "es": "¡! Los cambios de nombre sólo son posibles cada 30 días."
  },
  "dialog.account.error.name.limitation": {
    "de": "Namensänderungen können nur alle 30 Tage vorgenommen werden",
    "en": "Name changes can only be made every 30 days",
    "es": "Los cambios de nombre sólo pueden hacerse cada 30 días"
  },
  "dialog.account.error.request": {
    "de": "Das hat nicht geklappt. Bitte versuche es später erneut",
    "en": "That did not work. Please try again later",
    "es": "Esto no funcionó. Vuelva a intentarlo más tarde"
  },
  "dialog.account.error.name.exist": {
    "de": "Dieser Name ist bereits vergeben. Bitte wähle einen anderen.",
    "en": "This name is already taken. Please choose another one.",
    "es": "Este nombre ya está ocupado. Por favor, elija otro."
  },
  "dialog.account.name.changed": {
    "de": "Du hast deinen Namen erfolgreich geändert. Dein neuer Name: $name",
    "en": "You have successfully changed your name. Your new name: $name",
    "es": "Has cambiado de nombre correctamente. Tu nuevo nombre: $name"
  },
  "tutorial.check": {
    "de": "<u><b>Checken:</b></u> Doppeltippen in die Mitte des Tisches",
    "en": "<u><b>Check:</b></u> Double tap in the centre of the table",
    "es": "<u><b>Pasar:</b></u> Toca dos vezes en el centro de la mesa"
  },
  "text.overlay.help.tutorial": {
    "de": "Tutorial anzeigen",
    "en": "show tutorial",
    "es": "Mostrar tutorial"
  },
  "text.overlay.admin.forcefold": {
    "de": "fold erzwingen",
    "en": "force fold",
    "es": "Retirada forzada"
  },
  "game.note.handview.dealer.smallblind": {
    "de": "Du bist Dealer und Small Blind. Setze $smallblind_value",
    "en": "You are dealer and small blind. Bet $smallblind_value",
    "es": "Tú eres la dealer y la ciega pequeña. Fijar $smallblind_value"
  },
  "checkbox.lobby.flagWaitingArea": {
    "de": "Tisch mit Wartebereich",
    "en": "Table with waiting area",
    "es": "Mesa con zona de espera"
  },
  "tutorial.feature.flagWaitingArea": {
    "de": "Selbst wenn der Tisch voll besetzt ist, können sich viele Spieler verbinden und Warten bis ein Platz frei wird. Sinnvoll für größere Turniere",
    "en": "Even if the table is full, many players can connect and wait for a seat to become available. Useful for larger tournaments",
    "es": "Aunque la mesa esté llena, muchos jugadores pueden conectarse y esperar a que quede un asiento libre. Útil para torneos grandes"
  },
  "dialog.gameover.waitforcreator.player": {
    "de": "Warte bis der Tischersteller den Tisch freigeschaltet hat.",
    "en": "Wait until the table maker has unlocked the table.",
    "es": "Espere hasta que el creador de la mesa haya desbloqueado la mesa."
  },
  "time.until.table.closes": {
    "de": "Noch mm:ss bis der Tisch geschlossen wird",
    "en": "Still mm:ss until the table is closed",
    "es": "Todavía mm:ss hasta que se cierre la mesa"
  },
  "game.text.handview.demolimit": {
    "de": "Zeitlimit",
    "en": "Trial limit",
    "es": "Límite de tiempo"
  },
  "text.overlay.sub.title.roomwaitarea": {
    "de": "Warteliste",
    "en": "Waiting list",
    "es": "Lista de espera"
  },
  "text.overlay.admin.waitingarea": {
    "de": "Wartebereich",
    "en": "Waiting area",
    "es": "Zona de espera"
  },
  "text.overlay.admin.subhead.waitarea": {
    "de": "Spieler im Wartebereich",
    "en": "Players in the waiting area",
    "es": "Jugadores en espera"
  },
  "dialog.account.login.error": {
    "de": "Login fehlgeschlagen ( $error )",
    "en": "Login failed( $error )",
    "es": "Login fallido ( $error )"
  },
  "dialog.account.login.waitactivation": {
    "de": "Checke deine eMails und gib den Aktivierungscode hier ein.",
    "en": "Check your emails and enter the activation code here.",
    "es": "Mira tu correo electrónico e introduce el código de activación aquí."
  },
  "dialog.account.login.success": {
    "de": "Du hast dich erfolgreich eingeloggt",
    "en": "You have signed in",
    "es": "Has iniciado sesión correctamente"
  },
  "dialog.account.register.waitactivation": {
    "de": "Du hast eine eMail mit dem Aktivierungscode erhalten.",
    "en": "You have received an email with the activation code.",
    "es": "Has recibido un correo electrónico con el código de activación."
  },
  "text.lobby.dealer.authcode": {
    "de": "Passwort:",
    "en": "Password:",
    "es": "contraseña:"
  },
  "input.lobby.dealer.authcode": {
    "de": "Passwort einfügen",
    "en": "Insert password",
    "es": "Insertar contraseña"
  },
  "tutorial.feature.dealerauthcode": {
    "de": "Merke dir dieses passwort gut, oder ersetze es durch einen eigenes. \nMit ihm kannst du dich immer wieder als Dealer an diesen Tisch setzen.",
    "en": "Remember this password well, or replace it with one of your own. \nWith it, you can always sit down at this table as a dealer.",
    "es": "Recuerda bien esta contraseña o sustitúyela por una propia. \nCon él, siempre puedes sentarte en esta mesa como dealer."
  },
  "checkbox.join.dealer": {
    "de": "Als Dealer beitreten",
    "en": "Join as dealer",
    "es": "Únete como dealer"
  },
  "text.common.dealercode": {
    "de": "Password",
    "en": "Password",
    "es": "Contraseña"
  },
  "text.overlay.admin.restartround": {
    "de": "Runde wiederholen",
    "en": "Repeat round",
    "es": "Repetir turno"
  },
  "dialog.admin.restartround": {
    "de": "Achtung: Willst du wirklich die Runde rückgängig machen und von vorne starten? Einsätze gehen zurück an die Spieler und neue Karten werden verteilt.",
    "en": "Caution: Do you really want to undo the round and start again? Bets go back to the players and new cards are distributed.",
    "es": "Atención: ¿realmente quieres deshacer la ronda y empezar de nuevo? Las apuestas vuelven a los jugadores y se distribuyen nuevas cartas."
  },
  "dialog.vip.demo.extend.player": {
    "de": "Um die 30-Minuten Limitierung aufzuheben, muss sich der Tischersteller VIP kaufen. Alle anderen spielen kostenlos mit.",
    "en": "To remove the 30-minute trial, the table creator must buy VIP. Everyone else plays for free.",
    "es": "Para eliminar el límite de 30 minutos, el creador de la mesa debe comprar VIP. Todos los demás juegan gratis."
  },
  "dialog.game.security.revealOne": {
    "de": "Möchtest du wirklich eine Karte aufdecken?",
    "en": "Do you really want to turn over a card?",
    "es": "¿De verdad quieres darle la vuelta a una carta?"
  },
  "multibutton.action.raise": {
    "de": "raise to",
    "en": "raise to",
    "es": "subir a"
  },
  "multibutton.action.reraise": {
    "de": "reraise to",
    "en": "reraise to",
    "es": "reraise a"
  },
  "multibutton.action.call": {
    "de": "call",
    "en": "call",
    "es": "igualar"
  },
  "multibutton.action.bet": {
    "de": "bet",
    "en": "bet",
    "es": "apostar"
  },
  "multibutton.action.allin": {
    "de": "All in",
    "en": "all in",
    "es": "all in"
  },
  "dialog.chip.range": {
    "de": "Setze den Chipwert von $min bis $max",
    "en": "Set your bet from $min to $max",
    "es": "Establece el valor de fichas de $min a $max"
  },
  "dialog.account.delete": {
    "de": "Hiermit löscht du deinen Account. Dein gesamter Spielfortschritt wird ebenfalls gelöscht. Alles was du gekauft hast wird ebenfalls gelöscht. Diesen Vorgang kann man nicht rückgängig machen.",
    "en": "This deletes your account. Your entire game progress will also be deleted. Everything you have bought will also be deleted. This process cannot be undone.",
    "es": "Esto borrará tu cuenta. También todo tu progreso en el juego incluido lo que hayas comprado. Este proceso no puede deshacerse."
  },
  "button.account.manage": {
    "de": "Account verwalten",
    "en": "Manage account",
    "es": "Administrar cuenta"
  },
  "button.account.delete": {
    "de": "Account löschen",
    "en": "Delete account",
    "es": "Eliminar cuenta"
  },
  "dialog.account.delete.success": {
    "de": "Dein Account wurde erfolgreich gelöscht",
    "en": "Your account was successfully deleted",
    "es": "Tu cuenta se ha eliminado"
  },
  "dialog.account.delete.failed": {
    "de": "Sorry, etwas ist schief gelaufen. Bitte versuche es später noch mal.",
    "en": "Sorry, something went wrong. Please try again later.",
    "es": "Lo sentimos, algo salió mal. Vuelva a intentarlo más tarde."
  },
  "text.overlay.menu": {
    "de": "Menü",
    "en": "Menu",
    "es": "Menu"
  },
  "snackbar.notice.autoaction": {
    "de": "Zeit abgelaufen",
    "en": "Time expired",
    "es": "Tiempo expirado"
  },
  "dialog.user.shop.noads": {
    "de": "Es kann gerade keine Werbung angezeigt werden. Versuche es später erneut.",
    "en": "No advertising can be displayed at the moment. Try again later.",
    "es": "De momento no se puede mostrar anuncios. Vuelva a intentarlo más tarde."
  },
  "dialog.avatar.upload.success": {
    "de": "Dein Avatar wurde hochgeladen. Es kann eine Weile dauern bis es freigegeben wurde.",
    "en": "You uploaded your avatar successfully. This can take some time until you can use it",
    "es": "Tu avatar ha sido subido. Puede pasar un tiempo antes de que se apruebe."
  },
  "dialog.avatar.upload.failed": {
    "de": "Es ist ein Fehler aufgetreten beim Upload deines Avatars. Bitte versuche es erneut oder wähle ein anderes Bild",
    "en": "There was an error while uploading your avatar. Please try again later or try again with another picture",
    "es": "Se ha producido un error al subir tu avatar. Por favor, inténtelo de nuevo o seleccione una imagen diferente."
  },
  "text.common.username": {
    "de": "Spielername",
    "en": "Playername",
    "es": "Nombre jugador"
  },
  "text.changenamehere": {
    "de": "Namen hier ändern ->",
    "en": "Change name here ->",
    "es": "Cambiar nombre aquí ->"
  },
  "dialog.buyin.notenough.buyinwith": {
    "de": "Buy-in mit $chips",
    "en": "Buy-in with $chips",
    "es": "Buy-in con  $chips"
  },
  "dialog.buyin.notenough.buychips": {
    "de": "Kaufe Chips",
    "en": "Buy chips",
    "es": "Comprar fichas"
  },
  "button.mainmenu.tablet": {
    "de": "Tablet",
    "en": "Tablet",
    "es": "Tableta"
  },
  "text.common.contactandsupport": {
    "de": "Kontakt & Support",
    "en": "Contact & Support",
    "es": "Contacto y soporte"
  },
  "text.dialog.tip": {
    "de": "Mit deinem Trinkgeld hilfst du uns die App weiter zu verbessern. Vielen Dank - dein Bullets Poker Team :)",
    "en": "With your tip you help us to improve the app. Thank you very much - your Bullets Poker Team :)",
    "es": "Con tu propina nos ayudas a mejorar la aplicación. Muchisimas gracias - tu equipo de Bullets Poker :)"
  },
  "text.dialog.tip.thanks": {
    "de": "Vielen Dank & auf dich, prost!",
    "en": "Thank you & cheers",
    "es": "Muchas gracias! Salud"
  },
  "checkbox.lobby.flagDefaultShowHand": {
    "de": "Eigene Karten offen anzeigen",
    "en": "Show own cards face up",
    "es": "Ver cartas propias boca arriba"
  },
  "tutorial.feature.flagDefaultShowHand": {
    "de": "Deaktiviere die Option, damit neue Karten verdeckt angezeigt werden. Tippe auf die Karten um sie zu drehen.",
    "en": "Deactivate the option so that new cards are displayed face down. Tap on the cards to rotate them.",
    "es": "Desactive la opción para que las nuevas cartas se vean de modo oculto. Pulsa sobre las tarjetas para girarlas."
  },
  "button.game.reveal.all": {
    "de": "Beide",
    "en": "All",
    "es": "Todas"
  },
  "text.common.rateapp": {
    "de": "Bitte bewerte Bullets Poker - Danke!",
    "en": "Please review Bullets Poker - Thank you!",
    "es": "Valora Bullets Poker - ¡Gracias!"
  },
  "tutorial.dragndrop": {
    "de": "ziehen & loslassen",
    "en": "drag and drop",
    "es": "arrastrar y soltar"
  },
  "button.start.playwithcg": {
    "de": "Mit CouchGames.wtf Account anmelden",
    "en": "Login with CouchGames.wtf Account",
    "es": "Iniciar sesión con cuenta de CouchGames.wtf"
  },
  "snackbar.invite": {
    "de": "Lade deine Freunde ein",
    "en": "Invite your friends",
    "es": "Invita a tus amigos"
  },
  "text.common.invite": {
    "de": "Spiel mit mir Bullets Poker|Hey du, lass uns zusammen bullets poker spielen! Mein Tisch-Code lautet: $tablecode Hier findest du den link zum download der App:",
    "en": "Player Bullets Poker|Hey you, let's play Bullets Poker together! My table code is: $tablecode. Here's the link to download the app:",
    "es": "Juega conmigo Bullets Poker|¡Hola tú! ¡Juguemos juntos a Bullets Poker! Mi código de mesa es: $tablecode. Aquí está el enlace para descargar la aplicación:"
  },
  "advice.next.player": {
    "de": "Fertig?",
    "en": "Done?",
    "es": "¿Listo?"
  },
  "text.game.playerhand.yourturn": {
    "de": "Du bist dran",
    "en": "Your turn",
    "es": "Te toca"
  },
  "dialog.game.security.allin": {
    "de": "Bist du wirklich all in?",
    "en": "Are you really all in?",
    "es": "¿Realmente estás all in?"
  },
  "dialog.fold.yourturn": {
    "de": "Du kannst erst folden, wenn du an der Reihe bist",
    "en": "You can only fold when it is your turn",
    "es": "Sólo puedes retirarte cuando es tu turno."
  },
  "dialog.security.really.deal": {
    "de": "Noch nicht alle Spieler waren an der Reihe. Möchtest du wirklich Karten geben?",
    "en": "Not all players have had their turn yet. Do you really want to deal?",
    "es": "No todos los jugadores han tenido aún su turno. ¿De verdad quieres dar cartas?"
  },
  "text.overlay.admin.missdeal": {
    "de": "Missdeal!",
    "en": "Missdeal!",
    "es": "¡Missdeal!"
  },
  "dialog.security.missdeal": {
    "de": "Willst du wirklich die letzten Communitykarten neugeben?",
    "en": "Do you really want to re-give the last community cards?",
    "es": "¿De verdad quieres volver a repartir las últimas cartas comunitarias?"
  },
  "text.settings.connectclock": {
    "de": "Mit Pokerclock verknüpfen",
    "en": "Connect to poker clock",
    "es": "Conexión con Pokerclock"
  },
  "tutorial.connectclock": {
    "de": "Verbinde diesen Tisch mit einer Pokerclock oder synchronisiere ihn mit einem anderen Tisch",
    "en": "Connect this table to a poker clock or synchronise it with another table",
    "es": "Conecta esta mesa a un reloj de póker o sincronízala con otra mesa"
  },
  "checkbox.lobby.flagUiTable": {
    "de": "Tisch mit TV/Tablet",
    "en": "Table with TV/tablet",
    "es": "Mesa con TV/tabla"
  },
  "tutorial.feature.flagUiTable": {
    "de": "Wenn mit zentralem Tablet oder TV gespielt wird, wird der Table Button ausgeblendet. Falls Ihr ohne Tablet oder TV spielt, solttet Ihr diese Option deaktvieren",
    "en": "If you are playing with a central tablet or TV, the table button is hidden. If you are playing without a tablet or TV, you should deactivate this option.",
    "es": "Si estás jugando con una tableta o TV central, el botón de la mesa está escondido. Si juegas sin tableta ni televisor, debes desactivar esta opción."
  },
  "checkbox.lobby.flagRabbitCards": {
    "de": "Rabbit Hunting",
    "en": "Rabbit hunting",
    "es": "Rabbit hunting"
  },
  "tutorial.feature.flagRabbitCards": {
    "de": "Rabbit Hunting ermöglicht es euch nach beenden einer Runde die Karten zu sehen, die gekommen wären, wenn weitergespielt werden würde.",
    "en": "Rabbit hunting allows you to see the cards that would have come if play had continued after a round has ended.",
    "es": "Rabbit hunting te permite ver las cartas que habrían salido si el juego hubiera continuado después de terminar una ronda."
  },
  "game.button.handview.allin": {
    "de": "All-In",
    "en": "All-In",
    "es": "All-In"
  },
  "checkbox.lobby.flagNextPointer": {
    "de": "Nächster Spieler Hinweise",
    "en": "Next player hints",
    "es": "Siguiente jugador indicios"
  },
  "tutorial.feature.flagNextPointer": {
    "de": "Nachdem du als Spieler eine Aktion durchgeführt hast (z.B. Call oder Raise), klicke auf \"Done\", damit der nächste Spieler einen Hinweis erhält, dass er an der Reihe ist.",
    "en": "After you as a player have performed an action (e.g. call or raise), click on \"Done\" so that the next player receives a hint that it is his turn.",
    "es": "Después de que tú, como jugador, hayas realizado una acción (por ejemplo, igualar o subir), haz clic en \"Hecho\" para que el siguiente jugador reciba una indicación de que es su turno."
  },
  "text.overlay.admin.disable.tablebutton": {
    "de": "Tisch Button ausblenden",
    "en": "Hide table button",
    "es": "Botón ocultar tabla"
  },
  "text.overlay.admin.enable.tablebutton": {
    "de": "Tisch button einblenden",
    "en": "Show table button",
    "es": "Botón Mostrar tabla"
  },
  "text.overlay.admin.connectclock": {
    "de": "Poker clock verbinden",
    "en": "Connect poker clock",
    "es": "Conectar reloj de poker"
  },
  "text.common.clockcode": {
    "de": "Clock Code",
    "en": "Clock Code",
    "es": "código reloj"
  },
  "snackbar.notice.clock.connected": {
    "de": "Pokerclock verbunden",
    "en": "Pokerclock connected",
    "es": "Reloj de póker conectado"
  },
  "text.overlay.sub.title.connectclock": {
    "de": "Verbinde deine globale Pokerclock",
    "en": "Connect a global pokerclock",
    "es": ""
  },
  "text.overlay.admin.disable.rabbithunt": {
    "de": "Deaktiviere Rabbit Hunting",
    "en": "Disable rabbit hunting",
    "es": "Desactiva rabbit hunting"
  },
  "text.overlay.admin.enable.rabbithunt": {
    "de": "Aktiviere Rabbit Hunting",
    "en": "Enable rabbit hunting",
    "es": "Activa rabbit hunting"
  },
  "UNKNOWN_ERROR": {
    "de": "10000 - Ein unbekannter Fehler ist aufgetreten",
    "en": "10000 - An unknown error has occurred",
    "es": "10000 - Se ha producido un error desconocido"
  },
  "ROOMCODE_WRONG": {
    "de": "10001 - Der Tischcode hat nicht das richtige Format",
    "en": "10001 - The table code is not in the correct format",
    "es": "10001 - El código de la mesa no tiene el formato correcto"
  },
  "ROOMCODE_NOTFREE": {
    "de": "10002 - Derzeit ist kein Tisch frei. Bitte versuche es später, oder erstelle einen Eigenen.",
    "en": "10002 - There is currently no table available. Please try later, or create your own. ",
    "es": "10002 - Actualmente no hay ninguna mesa libre. Por favor, inténtelo más tarde, o cree el suyo propio. "
  },
  "ROOMCODE_UKNOWN_ERROR": {
    "de": "10003 - Es gab ein Problem beim Empfang des Tischcodes",
    "en": "10003 - There was a problem receiving the table code",
    "es": "10003 - Ha habido un problema al recibir el código de la mesa"
  },
  "ROOMCODE_JOIN_NOT_ALLOWED": {
    "de": "10004 - Der Tisch ist geschlossen",
    "en": "10004 - The table is closed",
    "es": "10004 - La mesa está cerrada"
  },
  "ROOM_MAX_ACTOR_REACHED": {
    "de": "10010 - Der Tisch ist voll",
    "en": "10010 - The table is full",
    "es": "10010 - La mesa está llena"
  },
  "ACTOR_WRONG_TYPE": {
    "de": "10020 - Der Typ des Spielers ist ungültig",
    "en": "10020 - The type of the player is invalid",
    "es": "10020 - El tipo de jugador no es válido"
  },
  "ACTOR_ALREADY_LOGGEDIN": {
    "de": "10021 - Dieser Account ist bereits eingeloggt",
    "en": "10021 - This account is already logged in",
    "es": "10021 - Esta cuenta ya está conectada"
  },
  "ACTORNAME_SHORT": {
    "de": "10022 - Der Spielername ist zu kurz",
    "en": "10022 - The player name is too short",
    "es": "10022 - El nombre del jugador es demasiado corto"
  },
  "ACTORNAME_LONG": {
    "de": "10023 - Der Spielername ist zu lang",
    "en": "10023 - The player name is too long",
    "es": "10023 - El nombre del jugador es demasiado largo"
  },
  "ACTORNAME_FORMAT": {
    "de": "10024 - Der Spielername ist im falschen Format",
    "en": "10024 - The player name is in the wrong format",
    "es": "10024 - El nombre del jugador tiene un formato incorrecto"
  },
  "ACTORNAME_BLACKLIST": {
    "de": "10025 - Dieser Spielername ist nicht erlaubt",
    "en": "10025 - This player name is not allowed",
    "es": "10025 - Este nombre de jugador no está permitido"
  },
  "ACTOR_NOTCREATED": {
    "de": "10026 - Beim Anlegen eines neuen Spielers scheint ein Fehler aufgetreten zu sein.",
    "en": "10026 - An error seems to have occurred when creating a new player.",
    "es": "10026 - Parece que se ha producido un error al crear un nuevo jugador."
  },
  "ACTOR_INVALID_REJOINTOKEN": {
    "de": "10027 - Es scheint ein Problem beim Wiedereinstieg in die Sitzung zu geben (falscher oder fehlender Tisch Code)",
    "en": "10027 - There seems to be a problem re-entering the session (wrong or missing table code).",
    "es": "10027 - Parece que hay un problema al volver a entrar en la sesión (código de tabla erróneo o ausente)."
  },
  "ACTOR_INVALID_REJOIN": {
    "de": "10028 - Fehlende Informationen für die Wiederverbindung",
    "en": "10028 - Missing information for reconnection",
    "es": "10028 - Falta información para la reconexión"
  },
  "ACTOR_INVALID_REJOINAUTHTOKEN": {
    "de": "10029 - Ungültiger Rejoin-Authentifizierungscode",
    "en": "10029 - Invalid Rejoin auth code",
    "es": "10029 - Código de autenticación de reincorporación no válido"
  },
  "SERVICE_OLDVERSION": {
    "de": "10050 - Die verwendete Version ist zu alt. Bitte aktualisieren",
    "en": "10050 - The version used is too old. Please update",
    "es": "10050 - La versión que está utilizando es demasiado antigua. Por favor, actualice"
  },
  "SERVICE_DISABLED": {
    "de": "10051 - Der Dienst ist deaktiviert",
    "en": "10051 - The service is disabled",
    "es": "10051 - El servicio está desactivado"
  },
  "SERVICE_MAINTENANCE": {
    "de": "10052 - Der Dienst befindet sich zur Zeit in Wartung. Wir sind bald wieder da. ",
    "en": "10052 - The service is currently undergoing maintenance. We will be back soon. ",
    "es": "10052 - El servicio está actualmente en mantenimiento. Volveremos pronto. "
  },
  "SERVICE_UNAVAILABLE": {
    "de": "10053 - Es gab ein Problem beim Empfang der Daten",
    "en": "10053 - There was a problem receiving the data",
    "es": "10053 - Ha habido un problema al recibir los datos"
  },
  "SERVICE_NOFREE_HOSTCAPACITY": {
    "de": "10054 - Zur Zeit sind keine Kapazitäten frei, um deine Anfrage zu bearbeiten. Bitte versuche es später noch einmal.",
    "en": "10054 - There is currently no capacity available to process your request. Please try again later.",
    "es": "10054 - Actualmente no hay capacidad disponible para procesar su solicitud. Vuelva a intentarlo más tarde."
  },
  "SERVICE_HOST_MISSING": {
    "de": "10055 - Wir können die Hostinformationen nicht finden",
    "en": "10055 - We can't find the host information",
    "es": "10055 - No podemos encontrar la información del host"
  },
  "SESSION_NOT_AVAILABLE": {
    "de": "10056 - Tischcode falsch",
    "en": "10056 - Table code wrong",
    "es": "10056 - Código de tabla incorrecto"
  },
  "SESSION_COULDNOT_CREATED": {
    "de": "10057 - Die Sitzung konnte nicht erstellt werden",
    "en": "10057 - The session could not be created",
    "es": "10057 - No se ha podido crear la sesión"
  },
  "SESSION_ALREADY_EXIST": {
    "de": "10080 - Die Sitzung existiert bereits",
    "en": "10080 - The session already exists",
    "es": "10080 - La sesión ya existe"
  },
  "SESSION_NOT_STARTED": {
    "de": "10081 - Die Sitzung konnte nicht gestartet werden",
    "en": "10081 - The session could not be started",
    "es": "10081 - No se ha podido iniciar la sesión"
  },
  "ROUTE_WRONG_TYPE": {
    "de": "10090 - Wir können die Anfrage nicht beenden, weil eine falsche Route gewählt wurde",
    "en": "10090 - We cannot finish the request because a wrong route was selected",
    "es": "10090 - No podemos finalizar la solicitud porque se ha elegido una ruta incorrecta"
  },
  "ROUTE_WRONG_AUTHORIZATION": {
    "de": "10091 - Wir können die Anfrage nicht abschließen, weil die Berechtigung fehlt",
    "en": "10091 - We cannot finish the request because the authorization is missing",
    "es": "10091 - No podemos completar la solicitud porque falta la autorización"
  },
  "ROOMCODE_JOIN_WRONG_PASSWORD": {
    "de": "Passwort falsch",
    "en": "Password incorrect",
    "es": "Contraseña incorrecta"
  },
  "QUICKGAME_NO_SESSION": {
    "de": "Im Moment gibt es keine Spiele. Erstelle ein eigenes.",
    "en": "There are no games at the moment. Create your own.",
    "es": "De momento no hay partidos. Crea el tuyo propio."
  },
  "blind.all.colors": {
    "de": "schwarz|grün|weiß|blau|rot|gelb",
    "en": "black|green|white|blue|red|yellow"
  },
  "blind.all.button.options.fontsize": {
    "de": "Schriftgröße|Schriftgröße %fontsize von 5 ausgewählt, drücken zum ändern",
    "en": "Fontsize|Fontsize %fontsize selected from 5, press to change"
  },
  "blind.all.button.options.fontcolor": {
    "de": "Schriftfarbe|Schriftfarbe %fontcolor ausgewählt, drücke zum ändern",
    "en": "Fontcolor|Fontcolor %fontcolor selected, press to change"
  },
  "blind.all.button.options.bgcolor": {
    "de": "Hintergrundfarbe|Hintergrundfarbe %bgcolor ausgewählt, drücke zum ändern",
    "en": "Background color|Background color %bgcolor selected, press to change"
  },
  "blind.all.button.back": {
    "de": "Zurück|Zum vorherigen Menü",
    "en": "Back|Return to the previous menu"
  },
  "blind.all.button.normalmode": {
    "de": "Modus für Sehende|Wechsel zurück zum nicht barrierefreien Modus",
    "en": "Mode for sighted|Switch back to non-accessible mode"
  },
  "blind.all.button.startview.play": {
    "de": "Jetzt spielen|Starte ein Spiel im barrierefreien Modus",
    "en": "Play now|play the game in the accessibility mode"
  },
  "blind.all.button.menu.accessibility": {
    "de": "Barrierefreie Optionen|Wechsel zu den barrierefreien Einstellungen",
    "en": "Accessible options|Switch to the accessible settings"
  },
  "blind.all.checkbox.account.agb": {
    "de": "Agb Aktzeptiert|Agb verweigert|Ich habe die Agb gelesen und aktzeptiert|Ich habe die Agb gelesen und verweigert",
    "en": "Terms Accepted|Terms declined|I have read and accepted the terms and conditions|I have read and declined the terms and conditions"
  },
  "blind.all.checkbox.account.newsletter": {
    "de": "Newsletter aktzeptiert|Newsletter verweigert|Ich möchte den Newsletter erhalten|Ich möchte keinen Newsletter haben",
    "en": "Newsletter accepted|Newsletter declined|I want to subscribe to the newsletter|I do not want to receive your newsletter"
  },
  "blind.all.button.account.open_agb": {
    "de": "Öffne Agb|Öffne die Agb auf unserer Webseite",
    "en": "Open Terms|Open the terms and conditions on our website"
  },
  "blind.all.input.account.mail1": {
    "de": "Deine E-Mail Adresse|Gebe deine E-Mail Adresse zum Login oder Registrierung ein|Deine E-Mail Adresse: %input",
    "en": "E-Mail Adress|Enter your e-mail adress to login or register|Your mail adress: %input"
  },
  "blind.all.button.account.login": {
    "de": "Einloggen|Mit Account anmelden",
    "en": "Login|login with your account"
  },
  "blind.all.button.account.register": {
    "de": "Registrieren|Einen Couchgames Account erstellen",
    "en": "Register|Register an couchgames account"
  },
  "blind.all.button.account.activate": {
    "de": "Aktiviere|Drücke um deinen Couchgames Code zu aktivieren",
    "en": "Activate|Activate your couchgames code"
  },
  "blind.all.input.account.activationcode": {
    "de": "Aktivierungscode|Gebe den Aktivierungscode aus deiner E-Mail ein|Dein Aktivierungscode: %input",
    "en": "Activationcode|Enter your activationcode from your mail|Your activationcode: %input"
  },
  "blind.all.button.account.back": {
    "de": "Zurück|Zurück zur Accountübersicht",
    "en": "Back|go back to your accountoverview"
  },
  "blind.all.input.account.mail2": {
    "de": "Wiederhole E-Mail Adresse|Wiederhole deine E-Mail Adresse um dich zu registrieren|Deine wiederholte E-Mail Adresse: %input",
    "en": "E-Mail Adress|Enter again your mail adress to register|Your mail adress: %input"
  },
  "blind.all.button.start.play_as_guest": {
    "de": "Als Gast spielen|Als Gast spielen",
    "en": "play as guest|Play as guest"
  },
  "blind.all.button.mainmenu.multplayer": {
    "de": "Lokaler Mehrspieler|Spiele mit deinen Freunden lokal",
    "en": "Local Multiplayer|Play with your friends locally"
  },
  "blind.all.button.mainmenu.league": {
    "de": "Online Multiplayer|Spiele online gegen Spieler aus der ganzen Welt",
    "en": "Online Multiplayer|Play online with players around the world"
  },
  "blind.all.button.mainmenu.create": {
    "de": "Erstelle Spiel|Erstelle ein Spiel und lade deine Freunde ein mit dir zu spielen",
    "en": "create game|create a game and invite your friends to a match"
  },
  "blind.all.button.mainmenu.join": {
    "de": "Spiel beitreten|Einem Spiel beitreten",
    "en": "join game|join a game"
  },
  "blind.all.input.join.name": {
    "de": "Spielername|Gebe deinen Spielernamen ein|Dein Spielername: %input",
    "en": "playername|enter your playername|your playername: %input"
  },
  "blind.all.input.join.roomcode": {
    "de": "Raumcode|Gebe hier den 4stelligen Raumcode ein|Raumcode: %input",
    "en": "roomcode|enter your roomcode|entered roomode: %input"
  },
  "blind.all.button.join": {
    "de": "Beitreten|Trete dem Spiel bei",
    "en": "join game|Join the game"
  },
  "blind.all.button.create": {
    "de": "Erstellen|Erstelle ein Spiel",
    "en": "create game|Create a game"
  },
  "blind.all.input.user.username": {
    "de": "Spielername|Wähle einen Spielernamen|Dein Spielername: %input",
    "en": "playername|Choose a playername|Your name: %input"
  },
  "blind.all.button.user.rename": {
    "de": "Spieler umbennen|Spieler umbenennen",
    "en": "Rename player|rename the player"
  },
  "blind.poker.button.game.bet": {
    "de": "Setzen %money|%money setzen",
    "en": "bet %money|bet %money"
  },
  "blind.poker.button.game.raise": {
    "de": "Erhöhen %money|%money erhöhen",
    "en": "raise %money|raise %money"
  },
  "blind.poker.button.game.check": {
    "de": "Check|Checken",
    "en": "check|Check"
  },
  "blind.poker.button.game.call": {
    "de": "Mitgehen %money|Mit %money mitgehen",
    "en": "call %money|call %money"
  },
  "blind.poker.button.game.fold": {
    "de": "Passen|passen",
    "en": "fold|fold"
  },
  "blind.poker.button.game.deal": {
    "de": "Deal|dealen",
    "en": "deal|deal"
  },
  "blind.poker.button.game.showdown": {
    "de": "Showdown|Showdown",
    "en": "showdown|showdown"
  },
  "blind.poker.button.game.standup": {
    "de": "Aufstehen|Vom Tisch aufstehen",
    "en": "standup|standup from the table"
  },
  "blind.poker.button.game.sitdown": {
    "de": "Hinsetzen|Am Tisch hinsetzen",
    "en": "sitdown|sitdown to the table"
  },
  "blind.poker.button.game.newgame": {
    "de": "Neues Spiel|Ein neues Spiel starten",
    "en": "new game|start a new game"
  },
  "blind.poker.button.game.playerhand": {
    "de": "Spielerinformationen|Du spielst nicht|Keine Karten, Spielgeld $cash|Du hast aufgegeben, Spielgeld $cash|Du bist dran. Deine Karten $hand, Spielgeld $cash|Du wartest. Deine Karten $hand, Spielgeld $cash",
    "en": "Playerinformation|You are not playing|No cards, money $cash|You fold, money $cash|Your turn. Your cards $hand, money $cash|You are waiting. Your cards $hand, money $cash"
  },
  "blind.all.button.enableblindmode": {
    "de": "Aktiviere den barrierefreien Modus",
    "en": "Activate the accessible mode"
  },
  "blind.all.button.disableblindmode": {
    "de": "Deaktiviere den barrierefreien Modus",
    "en": "Deactivate the accessible mode"
  },
  "blind.all.button.links.website": {
    "de": "Couchgames Webseite|Besuche unsere Webseite",
    "en": "Website|visit our website"
  },
  "blind.all.button.links.facebook": {
    "de": "Facebook|Besuche unsere Facebook Seite",
    "en": "Facebook|visit our facebook page"
  },
  "blind.all.button.links.instagram": {
    "de": "Instagram|Besuche uns auf Instagram",
    "en": "Instagram|Visit us on instagram"
  },
  "blind.all.button.links.twitter": {
    "de": "Twitter|Folge uns auf Twitter",
    "en": "Twitter|Follow us on twitter"
  },
  "blind.all.button.links.tiktok": {
    "de": "Tiktok|Unsere Videos auf TikTok",
    "en": "Tiktok|Our TikTok Videos"
  },
  "blind.poker.history.smallblind": {
    "de": "$player Small Blind $smallblind_value",
    "en": "$player small blind $smallblind_value"
  },
  "blind.poker.history.bigblind": {
    "de": "$player Big Blind $bigblind_value",
    "en": "$player big blind $bigblind_value"
  },
  "blind.poker.history.fold": {
    "de": "$player fold",
    "en": "$player fold"
  },
  "blind.poker.history.bet": {
    "de": "$player setzt $bet_value",
    "en": "$player bet $bet_value"
  },
  "blind.poker.history.call": {
    "de": "$player call $total",
    "en": "$player call $total"
  },
  "blind.poker.history.raise": {
    "de": "$player erhöht auf $total",
    "en": "$player raise to $total"
  },
  "blind.poker.history.check": {
    "de": "$player checkt",
    "en": "$player checks"
  },
  "blind.poker.history.allin": {
    "de": "$player all in $allin_value",
    "en": "$player all in $allin_value"
  },
  "blind.poker.history.flop": {
    "de": "Flop: $card0, $card1, $card2",
    "en": "Flop: $card0, $card1, $card2"
  },
  "blind.poker.history.river": {
    "de": "River: $card0",
    "en": "River: $card0"
  },
  "blind.poker.history.turn": {
    "de": "Turn: $card0",
    "en": "Turn: $card0"
  },
  "blind.poker.button.game.leave": {
    "de": "Spiel verlassen|Verlasse das Spiel",
    "en": "leave|leave the current game"
  },
  "blind.poker.text.game.tableinfo": {
    "de": "Tischinformationen|Kein Spiel gestartet|Keine Runde gestartet|$roundtype, Pot: $pot, Smallblind: $smallblind Bigblind: $bigblind|$roundtype, Karten: $cards, Pot: $pot, Smallblind: $smallblind Bigblind: $bigblind",
    "en": "Table Info|No game started|No round started|$roundtype, pot: $pot, small blind: $smallblind bigblind: $bigblind|$roundtype, cards: $cards, pot: $pot, small blind: $smallblind bigblind: $bigblind"
  },
  "blind.poker.lobby.waitingplayer": {
    "de": "Spieler $playername wartet",
    "en": "player $playername waiting"
  },
  "blind.poker.lobby.waitinginfo": {
    "de": "Es sind $playercount Spieler anwesend",
    "en": "There are $playercount player available"
  },
  "blind.poker.checkbox.flagrandomseat": {
    "de": "Zufällige Sitzordnung an|Zufällige Sitzordnung aus|Aktiviere die zufällige Sitzordnung|Deaktiviere die zufällige Sitzordnung",
    "en": "Random seat on|Random seat off|Enable random seats|Disable random seats"
  },
  "blind.poker.checkbox.flagonlycards": {
    "de": "Nur digiale Karten an|Nur digitale Karten aus|Aktiviere nur digitale Karten|Deaktiviere nur digitale Karten",
    "en": "Only digital cards on| Only digital cards off|Enable only digital cards|Disable only digital cards"
  },
  "blind.poker.checkbox.flagafterjoin": {
    "de": "Beitritt nach Spielstart an|Beitritt nach Spielstart aus|Aktiviere den Beitritt nach Spielstart|Deaktiviere den Beitritt nach Spielstart",
    "en": "Allow afterjoin on|Allow afterjoin off|Allow player to join after the game was created|Disallow player to join after the game was created"
  },
  "blind.poker.checkbox.flagautoreveal": {
    "de": "Gewinner aufdecken an|Gewinner aufdecken aus|Aktiviere das automatische aufdecken von Gewinnern|Deaktiviere das automatische aufdecken von Gewinnern",
    "en": "Auto reveal on|Auto reveal off|Enable auto reveal all cards in showdown|Disable auto reveal all cards in showdown"
  },
  "blind.poker.checkbox.flagallinshowdown": {
    "de": "AllIn Showdown automatisch|AllIn Showdown manuell|Aktiviere den automatischen AllIn Showdown|Deaktiviere den automatischen Allin",
    "en": "Allin showdown automatic|Allin showdown manual|Enable automatic allin showdown|Disable automatic allin showdown"
  },
  "blind.poker.checkbox.flagaiplayer": {
    "de": "Bots aktiviert|Bots deaktiviert|Aktiviere Bots|Deaktiviere Bots",
    "en": "ai player on|ai player off|allow ai player|disable ai player"
  },
  "blind.poker.button.lobby.start": {
    "de": "Starte Spiel|Starte ein neues Spiel mit deiner Konfiguration",
    "en": "start game|start a new game with your settings"
  },
  "blind.all.input.lobby.roomcode": {
    "de": "Raumcode|Kein Raumcode|Der Code für diesen Raum lautet %input",
    "en": "Roomcode|No roomcode|The code for this room is %input"
  },
  "blind.poker.history.bankrupt": {
    "de": "$player ist bankrott",
    "en": "$player is out of money"
  },
  "blind.poker.history.sitdown": {
    "de": "$player sitzt nun am Tisch",
    "en": "$player sitdown"
  },
  "blind.poker.history.standup": {
    "de": "$player steht vom Tisch auf",
    "en": "$player standup"
  },
  "blind.poker.history.matchwinner": {
    "de": "$player gewinnt das Spiel",
    "en": "$player wins the match"
  },
  "blind.poker.history.winner0": {
    "de": "$player gewinnt $win_value",
    "en": "$player wins $win_value"
  },
  "blind.poker.history.winner1": {
    "de": "$player gewinnt mit $win_value with $card1 $card2",
    "en": "$player wins $win_value with $card1 $card2"
  },
  "blind.poker.history.bestfive": {
    "de": "$handrank mit $cards",
    "en": "$handrank with $cards "
  },
  "blind.poker.history.reraise": {
    "de": "$player erhöht noch mal auf $total",
    "en": "$player reraise to $total"
  },
  "blind.poker.history.join": {
    "de": "$player tritt dem Spiel bei",
    "en": "$player joins the game"
  },
  "blind.poker.history.leave": {
    "de": "$player verlässt den Raum",
    "en": "$player leaves the room"
  },
  "blind.poker.button.league.playleaguemode": {
    "de": "Spiele %name mit %player Spielern und einen Buyin von %buyin . Platz 1 gewinnt %place1 und Platz 2 gewinnt %place2",
    "en": "Play %name with %player players and pay buyin of %buyin. First place wins %place1 and second place wins %place2"
  },
  "blind.poker.button.league.playleaguemode.locked": {
    "de": "%name, freigeschaltet ab Level %level",
    "en": "%name, unlocked from level %level"
  },
  "blind.poker.button.league.playleaguemode2": {
    "de": "Spiele %name mit %player Spielern und einen Buyin von %buyin",
    "en": "Play %name with %player players and pay buyin of %buyin"
  },
  "blind.poker.text.league.waiting.player": {
    "de": "$playername wartet",
    "en": "$playername is waiting"
  },
  "blind.poker.text.league.waiting.leave": {
    "de": "Verlassen|Verlasse das Spiel",
    "en": "Leave|Leave the current match"
  },
  "blind.poker.league.waitinginfo": {
    "de": "Warte zum Starten von $name mit maximal $playermax Spielern",
    "en": "Waiting to start $name with a maximum of $playermax"
  },
  "blind.poker.text.account.chips": {
    "de": "$chips Chips|Du hast $chips zum Spielen",
    "en": "$chips Chips|You have $chips to play"
  },
  "blind.poker.button.inappstore.purchase": {
    "de": "Kaufe $item|Kaufe $item zum Preis von $price",
    "en": "Purchase $item|Purchase $item for the price $price"
  },
  "blind.poker.button.inappstore.dailybonus.get": {
    "de": "Tages Bonus|Hole dir jetzt Gratis deine $cash Chips ab",
    "en": "Dailybonus|Get your $cash Chips for free"
  },
  "blind.poker.button.inappstore.dailybonus.wait": {
    "de": "Tagesbonus|Warte noch $time Stunden to um deine $cash chips gratis abzuholen",
    "en": "Daily bonus|Wait $time hours to get your free $cash chips"
  },
  "blind.all.settings.about": {
    "de": "Über uns|Besuche unsere Webseite um mehr über uns zu erfahren",
    "en": "About|More informaton about us"
  },
  "blind.all.settings.imprint": {
    "de": "Impressum|Zeige couchgames.wtf Impressum",
    "en": "Imprint|Show couchgames.wtf Imprint"
  },
  "blind.all.button.gamesetting": {
    "de": "Spieleinstellungen|Passe deine Spieleinstellungen an",
    "en": "Gamesettings|Edit your gameconfig"
  },
  "blind.all.settings.language": {
    "de": "Sprache|Sprache wechseln",
    "en": "Language|Switch the language"
  },
  "blind.all.settings.language.switch": {
    "de": "Aktuelle Sprache $language|Wechsel zur nächsten Sprache. Aktuell gewählt: $language",
    "en": "Current language $language|Switch language. Current language: $language"
  },
  "blind.poker.text.account.info": {
    "de": "$name, Level $level ( $xp von $nextxp xp )|Hallo $name. Du bist Level $level und hast $xp Erfahrungspunkte. Zum levelaufstieg benötigst du insgesamt $nextxp Erfahrungspunkte",
    "en": "$name, Level $level ( $xp of $nextxp xp )|Hello $name. Your are level $level with $xp experience. To get up you need $nextxp experience."
  },
  "blind.poker.button.gameuser": {
    "de": "Dein Account|Sehe deine Account Einstellungen",
    "en": "Your account|See your account settings"
  },
  "blind.all.account.connected": {
    "de": "Account verbunden|Du bist mit deinem Account verbunden",
    "en": "Account connected|You are connected with your account"
  },
  "blind.all.account.connect": {
    "de": "Account verbinden|Verbinde dich mit deinen Couchgames Account",
    "en": "Connect Account|Connect this game with your couchgames account"
  },
  "blind.poker.text.game.tableinfo.nocards": {
    "de": "Keine",
    "en": "No cards"
  },
  "blind.poker.button.account.userdetails": {
    "de": "$name - $credits ($created)|Benutzer $name mit $credits Chips - Erstellt am $created",
    "en": "$name - $credits ($created)|Username $name with $credits chips ($created)"
  },
  "blind.poker.button.game.reveal": {
    "de": "Karten aufdecken|Zeige alle deine Karten",
    "en": "Reveal cards|Reveal all cards"
  },
  "blind.poker.button.game.bet.input": {
    "de": "Individualer Einsatz|Setze soviel wie du möchtest",
    "en": "Individual bet|Select your own bet"
  },
  "blind.poker.button.game.raise.input": {
    "de": "Individualer Erhöhung|Setze soviel wie du möchtest",
    "en": "Individual raise|Select your own raise"
  },
  "blind.all.input.game.bet": {
    "de": "Bet|Kein Bet eingegbeen|Dein Bet ist %input",
    "en": "Bet|No bet entered|The bet is %input"
  },
  "blind.all.input.game.raise": {
    "de": "Erhöhen|Keine Erhöhung eingegeben|Deine Erhöhung ist %input",
    "en": "Raise|No raise entered|The raise is %input"
  },
  "blind.poker.button.game.allin": {
    "de": "Allin|Gehe AllIn",
    "en": "Allin|All or nothing"
  },
  "blind.poker.text.game.tableinfo2": {
    "de": "Tischinformationen|Kein Spiel gestartet|Keine Runde gestartet|$roundtype, Smallblind: $smallblind Bigblind: $bigblind|$roundtype, Karten: $cards, Smallblind: $smallblind Bigblind: $bigblind",
    "en": "Table Info|No game started|No round started|$roundtype, small blind: $smallblind bigblind: $bigblind|$roundtype, cards: $cards, small blind: $smallblind bigblind: $bigblind"
  },
  "blind.poker.button.game.playerhand2": {
    "de": "Spielerinformationen|Du spielst nicht|Keine Karten|Du hast aufgegeben|Du bist dran. Deine Karten $hand|Du wartest. Deine Karten $hand",
    "en": "Playerinformation|You are not playing|No cards|You fold|Your turn. Your cards $hand|You are waiting. Your cards $hand"
  },
  "blind.poker.history.reveal": {
    "de": "$player deckt auf: $cards",
    "en": "$player reveal cards: $cards"
  },
  "blind.poker.button.game.playerhand3": {
    "de": "Deine Karten|Keine|$hand",
    "en": "Your cards|None|$hand"
  },
  "blind.poker.text.game.tableinfo3": {
    "de": "Community Karten|Keine|$cards",
    "en": "Community cards|None|$cards"
  },
  "blind.poker.text.game.tableinfo4": {
    "de": "Community Karten|Kein Spiel|Keine|$cards|$cards - Showdown|$cards - AllIn Showdown|Keine - AllIn Showdown|$cards - Warte auf andere Spieler",
    "en": "Community Karten|Kein Spiel|Keine|$cards|$cards - Showdown|$cards - AllIn Showdown|Keine - AllIn Showdown|$cards - Warte auf andere Spieler"
  },
  "blind.poker.text.game.tableinfo5": {
    "de": "Du sitzt nicht",
    "en": "You are not sitting"
  },
  "blind.poker.text.game.tableinfo6": {
    "de": "Keine",
    "en": "None"
  }
}

 export default TRANSLATIONS;