import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import GamePokerTimer from './gamepokertimer';

export default class GameRound {
    public id: number;
    public created: number;
    public status: number;
    public bet: number;
    public betCount: number;
    public highestBet: number;
    public lastRaise: number;
    public minimumBet: number;
    public minimumRaise: number;
    public pot: number;
    public finished: boolean;
    public nextPointer: any;
    public roundEndPause: boolean;
    public roundMissdeal: boolean;
    public roundQuit: boolean;
    public rankCard: Array<any>;
    public dealPushed: Array<any>;
    public rankCardType: Array<any>;
    public winner: Array<any>;
    public dealerHand: Array<any>;
    public rabbitCards: Array<any>;
    public currentPlayer: number;
    public allIn: any;
    public moveTimer: GamePokerTimer | null;

    constructor(roundData: any) {
        makeObservable(this, {
            status: observable,
            bet: observable,
            betCount: observable,
            moveTimer: observable,
            roundMissdeal: observable,
            dealPushed: observable,
            currentPlayer: observable,
            finished: observable,
            highestBet: observable,
            allIn: observable,
            lastRaise: observable,
            nextPointer: observable,
            minimumBet: observable,
            minimumRaise: observable,
            dealerHand: observable,
            rabbitCards: observable,
            pot: observable,
            rankCard: observable,
            rankCardType: observable,
            roundEndPause: observable,
            roundQuit: observable,
            winner: observable,
            update: action,
            destroyMoveTimer: action,
            allInShowdown: computed,
            hasNextPointer: computed,
            splitpot: computed
        })

        this.id = roundData?.id || 0;
        this.status = roundData?.status || 0;
        this.bet = roundData?.bet || 0;
        this.betCount = roundData?.betCount || 0;
        this.created = roundData?.created || 0;
        this.dealPushed = roundData?.dealPushed || 0;
        this.finished = roundData?.finished || 0;
        this.highestBet = roundData?.highestBet || 0;
        this.lastRaise = roundData?.lastRaise || 0;
        this.minimumBet = roundData?.minimumBet || 0;
        this.minimumRaise = roundData?.minimumRaise || 0;
        this.nextPointer = roundData?.nextPointer || null;
        this.pot = roundData?.pot || 0;
        this.rankCard = roundData?.rankCard || 0;
        this.rankCardType = roundData?.rankCardType || 0;
        this.roundEndPause = roundData?.roundEndPause || 0;
        this.roundQuit = roundData?.roundQuit || 0;
        this.roundMissdeal = roundData?.missdeal || false;
        this.winner = roundData?.winner || 0;
        this.dealerHand = roundData?.cards || [];
        this.rabbitCards = roundData?.rabbitCards || [];
        this.currentPlayer = roundData?.currentPlayer || 0;
        this.allIn = roundData?.allIn || undefined;
        this.moveTimer = null;

        if (roundData.moveTimer) {
            if (!this.moveTimer) {
                this.moveTimer = new GamePokerTimer();
            }
            this.moveTimer.update(roundData.moveTimer.remaining)
        }
    }

    get hasNextPointer() {
        return this.nextPointer !== null;
    }

    // The next pointer has a ready flag. If ready everyone pushed at least one time next / fold / allin
    get isNextPointerReady() {
        return this.nextPointer?.ready === true;
    }

    destroy(): void {
        this.destroyMoveTimer();
    }

    destroyMoveTimer() {
        if (this.moveTimer) {
            this.moveTimer.destroy();
            this.moveTimer = null;
        }
    }

    update(roundData: any): void {
        console.log('update Round', roundData)
        this.id = roundData?.id || 0;
        this.status = roundData?.status || 0;
        this.bet = roundData?.bet || 0;
        this.betCount = roundData?.betCount || 0;
        this.created = roundData?.created || 0;
        this.dealPushed = roundData?.dealPushed || 0;
        this.finished = roundData?.finished || 0;
        this.highestBet = roundData?.highestBet || 0;
        this.lastRaise = roundData?.lastRaise || 0;
        this.minimumBet = roundData?.minimumBet || 0;
        this.nextPointer = roundData?.nextPointer || null;
        this.minimumRaise = roundData?.minimumRaise || 0;
        this.pot = roundData?.pot || 0;
        this.rankCard = roundData?.rankCard || 0;
        this.rankCardType = roundData?.rankCardType || 0;
        this.roundEndPause = roundData?.roundEndPause || 0;
        this.roundQuit = roundData?.roundQuit || 0;
        this.roundMissdeal = roundData?.missdeal || false;
        this.winner = roundData?.winner || 0;
        this.dealerHand = roundData?.cards || [];
        this.rabbitCards = roundData?.rabbitCards || [];
        this.currentPlayer = roundData?.currentPlayer || 0;
        this.allIn = roundData?.allIn || undefined;

        if (roundData.moveTimer) {
            if (!this.moveTimer) {
                this.moveTimer = new GamePokerTimer();
            }
            this.moveTimer.update(roundData.moveTimer.remaining)
        } else {
            this.destroyMoveTimer();
        }
    }

    get allInDealVisible() {
        return this.allIn?.dealVisible || false
    }

    get allInShowdown() {
        return this.allIn?.showdown || false
    }

    get splitpot() {
        return this.winner?.length > 1 || false;
    }
}