import {
    observer
} from 'mobx-react-lite';
import {
    translateKey,
    convertNumberToString,
    convertHMS
} from '../../utils/helper';
import {
    Container,
    ImageComponent,
    ActionButton,
    Icon
} from '../controls';
import { GAMEPURCHASE_TYPE } from '../../constants/gameshop';

const GameShopBonusItem = observer(({ item, sdk }: any) => {
    const update = sdk.appState.user.userBoni.hasUpdate ? sdk.appState.user.userBoni.getBoniTime(item.bonusId) : 0;
    let showText = translateKey('text.format.timer.moreoneday');

    if (item.type === GAMEPURCHASE_TYPE.DAILYMONEYBONUS) showText = translateKey('text.inappstore.free')
    if (item.type === GAMEPURCHASE_TYPE.ADMONEYBONUS) showText = translateKey('text.inappstore.watchvideo')

    if (update > 0) {
        if (update < 86400) {
            showText = convertHMS(update, 'hh:mm:ss');
        }
    }
    return update > 0 ? <>{showText}</> : <>{showText}</>
});

const GameShopBonusInfo = observer(({ item, couponCredit, sdk }: any) => {
    if (
        item.type !== GAMEPURCHASE_TYPE.MONEY &&
        item.type !== GAMEPURCHASE_TYPE.DAILYMONEYBONUS &&
        item.type !== GAMEPURCHASE_TYPE.ADMONEYBONUS
    ) {
        return null;
    }
    const isPremium = !!sdk.appState.user.premium;
    let useValue = item.bonusId ? sdk.appState.user.userBoni.getBoniCash(item.bonusId) : item?.value;
    const useValueBonus = parseInt(`${couponCredit ? useValue * (couponCredit.multiplicator - 1.0) : 0}`, 10)

    return <>
        <Icon
            source={'./data/icon/vip.svg'}
            iconOption={{
                widthPx: 32,
                heightPx: 32,
                float: 'left',
                margin: [4, 0, 0, 0]
            }}
        />
        {!isPremium ? `10% Bonus` : `${convertNumberToString(useValueBonus)} mehr`}
    </>
});

const GameShopItem = observer(({ item, sdk, couponCredit }: any) => {

    const isItemPurchaseable = (item: any) => {
        if (item.type === GAMEPURCHASE_TYPE.ADMONEYBONUS || item.type === GAMEPURCHASE_TYPE.DAILYMONEYBONUS) {
            const update = sdk.appState.user?.userBoni?.hasUpdate ? sdk.appState.user.userBoni.getBoniTime(item.bonusId) : 0;

            if (update > 0) {
                return false;
            }
        }

        if (item.type === GAMEPURCHASE_TYPE.ADMONEYBONUS) {

            // if(sdk.appState?.ads?.hasRewardedAd) {
                // sdk.appState.ads.hasRewardedAd().then((result: any) => {
                //     console.log('ADREARD', result)
                //     if(result === true) {
                //         const adButton = document.getElementById('adreward-btn')
                //         if(adButton) {
                //             try {
                //                 console.log('AVAILABLE!')
                //                 setTimeout(() => {
                //                     //@ts-ignore
                //                     adButton.disabled = false;
                //                 }, 20)
                //             } catch(e) {}
                //         }
                //     }
                // })
            // }
            return true;
        }

        if (item.type === GAMEPURCHASE_TYPE.ASSETS) {
            return !item.purchased;
        }

        return true;
    }

    const renderItemInfo = () => {
        let useInfo = '';
        let infoBgColor = '#DC1717'

        if (item.type === GAMEPURCHASE_TYPE.DAILYMONEYBONUS) {
            useInfo = translateKey('text.inappstore.dailybonus')
        }
        if (item.type === GAMEPURCHASE_TYPE.ADMONEYBONUS) {
            useInfo = translateKey('text.inappstore.adbonus');
        }

        if (item.trial && !sdk.appState.user.data.hasTrialUsed(item.itemId)) {
            return <Container
                containerOption={{
                    widthPx: 170,
                    heightPx: 70,
                    margin: [20, 32, 0, 32],
                    float: 'left',
                    bgColor: '#25680a',
                    borderRadius: 16,
                    borderSize: 4,
                    lineHeight: 35,
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: 24,
                    border: 'solid #fff'
                }}
                onClick={() => {
                    sdk.appState.showMessagebox('freedemo', 'dialog.inappstore.freetrial', () => {
                        sdk.appState.shop.trialItem(item);
                    }, () => null, true)
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: translateKey('text.inappstore.freetrial').split(' ').join('<br />') }} ></div>
            </Container>
        }

        if (useInfo) {
            const infoLines = useInfo.replace('\n', ' ').split(' ')
            return <Container
                containerOption={{
                    widthPx: 170,
                    heightPx: 70,
                    lineHeight: 48,
                    margin: [20, 32, 0, 32],
                    float: 'left',
                    bgColor: infoBgColor,
                    borderRadius: 16,
                    borderSize: 4,
                    border: 'solid #fff'
                }}
            >
                {infoLines.map((line: string, i: number) => (
                    <Container
                        key={i}
                        containerOption={{
                            widthPx: 158,
                            float: 'left',
                            borderSize: 3,
                            textAlign: 'center',
                            heightPx: infoLines.length === 2 ? 32 : 64,
                            lineHeight: infoLines.length === 2 ? 32 : 64,
                            fontWeight: '500',
                            fontSize: 24,
                            margin: [2, 0, 0, 10],
                            color: '#fff'
                        }}
                    >
                        {line}
                    </Container>
                ))}
            </Container>
        }

        return <Container
            containerOption={{
                widthPx: 170,
                heightPx: 96,
                lineHeight: 48,
                margin: [2, 32, 0, 32],
                float: 'left'
            }}
        ></Container>;
    }

    const renderItemName = (item: any) => {
        let renderNameIcon = null;
        let useValue = item?.value;
        let useValueBonus = 0;

        if (item.type === GAMEPURCHASE_TYPE.PREMIUM) {
            return translateKey(item.itemId)
        }

        if (item.bonusId) {
            useValue = sdk.appState.user.userBoni.getBoniCash(item.bonusId)
        }

        if (item.type === GAMEPURCHASE_TYPE.MONEY || item.type === GAMEPURCHASE_TYPE.ADMONEYBONUS || item.type === GAMEPURCHASE_TYPE.DAILYMONEYBONUS) {
            renderNameIcon = './data/ui/chip.png'
            useValueBonus = parseInt(`${couponCredit ? useValue * (couponCredit.multiplicator - 1.0) : 0}`, 10)
            // useValue = useValue + useValueBonus;
        }
        if (item.type === GAMEPURCHASE_TYPE.COINS) {
            renderNameIcon = './data/ui/coin.png'
        }
        if (item.type === GAMEPURCHASE_TYPE.ASSETS) {
            console.log(item)
            return translateKey(item.translationKey);
        }

        if (renderNameIcon) {
            return <>
                {useValue ? convertNumberToString(useValue) : '-'}
                <ImageComponent
                    source={renderNameIcon}
                    imageOption={{
                        float: 'right',
                        widthPx: 40,
                        heightPx: 40,
                        margin: [8, 0, 0, 16]
                    }}
                />
            </>
        }
        return useValue ? convertNumberToString(useValue) : '-';
    }

    const renderPrice = () => {
        if (item.bonusId) {
            return <GameShopBonusItem sdk={sdk} item={item} />
        }

        // if (item.type === GAMEPURCHASE_TYPE.DAILYMONEYBONUS) return translateKey('text.inappstore.free')
        // if (item.type === GAMEPURCHASE_TYPE.ADMONEYBONUS) return translateKey('text.inappstore.watchvideo')
        let useValue = item?.price ?
            item.price :
            item.testPrice ? item.testPrice : 0;

        return item?.priceType === 'coin' ?
            (<>
                {useValue ?
                    convertNumberToString(useValue) :
                    `${useValue === 0 ? translateKey('text.inappstore.free') : '-'}`}
                <ImageComponent
                    source={'./data/ui/coin.png'}
                    imageOption={{
                        float: 'right',
                        widthPx: 32,
                        heightPx: 32,
                        margin: [0, 32, 0, 8]
                    }}
                />
            </>) :
            useValue;
    }


    return <>
        <ImageComponent
            imageOption={{
                widthPx: item.wide ? 180 : 80,
                heightPx: item.wide ? 64 : 80,
                margin: item.wide ?
                    [18, 16, 0, 64] :
                    [10, 16, 0, 64],
                float: 'left'
            }}
            onClick={() => item.wide ?
                sdk.appState.showPreviewBox(item.icon, 360, 128) :
                null}
            source={item.icon}
        />
        <Container
            containerOption={{
                widthPx: item.wide ? 400 : 500,
                heightPx: 96,
                margin: [2, 12, 0, 0],
                float: 'left'
            }}
        >
            <Container
                containerOption={{
                    widthPx: item.wide ? 145 : 245,
                    heightPx: 44,
                    margin: [0, 0, 2, 0],
                    float: 'left',
                    textAlign: 'left',
                    fontSize: 22,
                    lineHeight: 44,
                    fontWeight: '900',
                    color: '#FCAA67'
                }}
            >
                <GameShopBonusInfo sdk={sdk} item={item} couponCredit={couponCredit} />
            </Container>
            <Container
                containerOption={{
                    widthPx: 245,
                    heightPx: 26,
                    margin: [16, 0, 2, 0],
                    float: 'left',
                    textAlign: 'right',
                    fontSize: 22,
                    lineHeight: 26,
                    fontWeight: '900',
                    color: '#fff'
                }}
            >
                {translateKey('button.inappstore.buy')}
            </Container>
            <Container
                containerOption={{
                    widthPx: item.wide ? 392 : 492,
                    heightPx: 50,
                    margin: [2, 0, 0, 0],
                    float: 'left',
                    textAlign: 'right',
                    fontSize: 38,
                    lineHeight: 50,
                    fontWeight: 'bold',
                    color: '#FCAA67'
                }}
            >
                {renderItemName(item)}
            </Container>
        </Container>
        {renderItemInfo()}
        <Container
            containerOption={{
                widthPx: 192,
                heightPx: 96,
                margin: [2, 0, 0, 0],
                float: 'left'
            }}
        >
            <ActionButton
                id={'adreward-btn'}
                actionColor={item?.priceType === 'coin' ? 'yellow' : 'green'}
                buttonOption={{
                    textAlign: item?.priceType === 'coin' ? 'right' : 'center',
                    fontSize: 24,
                    fontWeight: 'bold',
                    widthPx: 188,
                    borderRadius: 8,
                    heightPx: 48,
                    // lineHeight: 48,
                    padding: undefined,
                    color: '#fff',
                    margin: [24, 0, 0, 0],
                    whiteSpace: 'nowrap'
                }}
                disabled={!isItemPurchaseable(item)}
                onClick={() => sdk.appState.shop.purchaseItem(item, couponCredit)}
            >
                {renderPrice()}

            </ActionButton>
        </Container>
    </>
})

export default GameShopItem;