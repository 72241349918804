import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import PokerCard from '../cards/PokerCard';

const PokerTablePlayerHand = observer(({
    pos,
    player,
    containerOption = {},
    cardOption = {},
    featHandReveal = false,
    cardWidth = 44,
    gameSession
}: any) => {
    const open = (!gameSession?.isDigitalCardMode && player?.matchData?.showHand) || false;
    const playerFolded = player?.matchData?.folded || false;
    const allowShowFold = !gameSession?.isDigitalCardMode &&
        gameSession?.tableConfig?.flagAllowShowFold === 1 &&
        gameSession?.currentActor?.id === player?.id; // Was hatte ich
    const playerRevealed = (open ? 3 : 0) || player?.matchData?.handRevealed;
    const playerHand = player?.matchData?.hand || [null, null];
    const playerWinner = player?.matchData?.winner || false;
    let playerHandTransform = undefined;

    if (playerWinner && playerRevealed === 3) {
        playerHandTransform = 'scale(1.5)';
    } else if (featHandReveal) {
        playerHandTransform = 'scale(1.2)';
    }

    return <Container
        id={`seat-${pos}-hand`}
        containerOption={{
            widthPx: 100,
            heightPx: 80,
            margin: [20, featHandReveal ? -4 : 0, 0, 0],
            textAlign: 'center',
            float: 'right',
            transform: playerHandTransform,
            ...(containerOption || {})
        }}
        onClick={(event: any) => {
            if (featHandReveal) {
                event.preventDefault();
                event.stopPropagation();
                if (player?.matchData) {
                    player.matchData.toggleShowHand();
                }
            }
        }}
    >
        {((!playerFolded || allowShowFold) && player.sitting) ? playerHand.map((card: any, i: number) => {
            return <PokerCard
                key={`seat-${pos}-hand-${i}`}
                cardRevealed={
                    playerRevealed === 3 ||
                    playerRevealed === i + 1
                }
                card={card}
                deck={gameSession.sessionDeck}
                cardWidth={cardWidth}
                microcard={true}
                glow={card?.glow || false}
                glowSize={'small'}
                cardOption={{
                    ...(cardOption || {}),
                    ...(playerFolded && allowShowFold ? {
                        opacity: 0.3
                    } : {})
                }}
            />
        }) : null}
    </Container>
});

export default PokerTablePlayerHand;