import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    Icon
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper';
import {
    canShare,
    onShare
} from '../../../../utils/helperfunction';

const PokerTableShare = observer(({
    gameSession
}: any) => {

    const visible = !gameSession?.tv && canShare();

    return visible ? <Container
        containerOption={{
            bgColor: '#000',
            widthPx: 192,
            heightPx: 48,
            fontSize: 22,
            fontWeight: 'bold',
            lineHeight: 48,
            color: '#2196f3',
            margin: [12, 0, 0, 0],
            borderCorner: [20, 20, 20, 20]
        }}
        onClick={() => onShare('invite', {
            playerName: gameSession?.currentActor?.name,
            roomCode: gameSession?.roomCode || ''
        })}
    >
        <Icon
            source={'./data/icon/share.svg'}
            iconOption={{
                float: 'left',
                widthPx: 32,
                heightPx: 32,
                margin: [8, 0, 0, 16]
            }}
        />
        {translateKey('text.common.share')}
    </Container> : null;
});

export default PokerTableShare;