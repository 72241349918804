import type AppMessageHandler from "../messagehandler";

export default function actionSend(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    action: number,
    value: number = 0
): boolean {
    messageHandler.sendMessage(1010, {
        roomToken,
        playerId,
        action,
        value
    })
    return true;
}