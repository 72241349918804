import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import { convertHMS } from '../../../utils/helper';

class GameSessionClock {
    public remainingTime: number;
    public timeOut: any;

    constructor() {
        makeObservable(this, {
            remainingTime: observable,
            update: action,
            updateTime: action,
            timerDisplay: computed,
            lessThan5Minutes: computed
        });
        this.remainingTime = 0;
        this.timeOut = null;
    }

    public update(time: number): void {
        this.remainingTime = time;
        this.createTimer();
    }

    public updateTime(): void {
        this.remainingTime -= 1;
        if (this.remainingTime < 0) {
            this.remainingTime = 0;
            if (this.timeOut) {
                clearTimeout(this.timeOut)
            }
        } else {
            this.createTimer();
        }
    }

    public createTimer(): void {
        if (this.timeOut) {
            clearTimeout(this.timeOut)
        }
        const that = this;
        this.timeOut = setTimeout(() => {
            that.updateTime()
        }, 1000)
    }

    public destroy(): void {
        if (this.timeOut) {
            clearTimeout(this.timeOut)
        }
    }

    get timerDisplay(): string {
        return convertHMS(`${this.remainingTime}`, this.remainingTime > 1800 ? 'hh:mm:ss' : 'mm:ss')
    }

    get lessThan5Minutes(): boolean {
        return this.remainingTime < 600;
    }
}

export default GameSessionClock;