import type AppMessageHandler from "../messagehandler";

export default function adminRequestResult(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    voteId: any,
    vote: boolean
): boolean {
    messageHandler.sendMessage(1081, {
        roomToken,
        playerId,
        voteId,
        vote
    })
    return true;
}