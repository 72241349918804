const WEBSOCKET_SETTINGS = {
    CONNECT_TIMEOUT: 7500,
    VALIDATION_TIMEOUT: 5000,
    RECONNECTION_ATTEMPTS: 3,
    RECONNECTION_INTERVALL1: 1000,
    RECONNECTION_INTERVALL2: 5000
};

export {
    WEBSOCKET_SETTINGS
}