const MOVE_SENSIBILITY = {
    WEAK: 0,
    DEFAULT: 1,
    STRONG: 2
};
// 0 = 100% / 1 = DigitalCard
const FOLD_SENSIBILITY_VALUE = [
    [1.25, 1.0, 0.75],
    [0.9, 0.75, 0.5]
];

const REVEAL_SENSIBILITY_VALUE = [
    [0.9, 0.6, 0.3],
    [0.9, 0.75, 0.5]
];


export {
    MOVE_SENSIBILITY,
    FOLD_SENSIBILITY_VALUE,
    REVEAL_SENSIBILITY_VALUE
}