import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ImageComponent
} from '../../../controls';
import {
    convertNumberToString
} from '../../../../utils/helper';

const getBetFontColor = (bet: number, roundBet: number, allIn: boolean = false) => {

    if (allIn) {
        return '#ff1e1e'
    } else if (bet === roundBet) {
        return '#48bf53';
    }

    return '#fff'
}

const PokerTablePlayerBet = observer(({
    player,
    visibleTop = [],
    visibleBottom = [],
    origPos,
    position,
    containerOption = {},
    chipOption = {},
    betOption = {},
    gameSession
}: any) => {
    const visible = ((visibleTop.includes(origPos) && position === 'top') ||
        (visibleBottom.includes(origPos) && position !== 'top'));
    const usePlayerBet = player?.matchData?.bet || 0;
    const useRoundBet = gameSession?.currentMatch?.round?.bet || 0;
    

    return visible ? <Container
        containerOption={{
            position: 'relative',
            widthPx: 160,
            heightPx: 32,
            float: 'left',
            color: 'green',
            fontWeight: 'bold',
            fontSize: 22,
            margin: [8, 0, 0, 48],
            zIndex: 2,
            ...(containerOption || {})
        }}
    >
        {usePlayerBet ? (
            <>
                {!player?.matchData?.isDealer ? (
                    <ImageComponent
                        imageOption={{
                            widthPx: 24,
                            heightPx: 24,
                            float: 'left',
                            margin: [4, 4, 0, 4],
                            ...(chipOption || {})
                        }}
                        source={'./data/ui/chip.png'}
                    />
                ) : null}
                <Container
                    containerOption={{
                        float: 'left',
                        widthPx: 110,
                        height: '100%',
                        color: getBetFontColor(usePlayerBet, useRoundBet, player?.matchData?.allIn),
                        fontWeight: 'bold',
                        fontSize: 22,
                        textShadow: 10,
                        textShadowColor: '#000',
                        lineHeight: 32,
                        ...(betOption || {})
                    }}
                >
                    {convertNumberToString(usePlayerBet)}
                </Container>
            </>
        ) : null}

    </Container> : null
});

export default PokerTablePlayerBet;