import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ImageComponent
} from '../../../../controls/index';

const GameDigitalTypeIcon = observer(({
    sdk,
    gameSession
}: any) => {
    let renderPlayerIcon = undefined;
    if (gameSession?.currentActor?.matchData?.isDealer && gameSession?.currentActor?.matchData?.isBigBlind) {
        renderPlayerIcon = 'dealer_bigblind.png'
    } else if (gameSession?.currentActor?.matchData?.isDealer && gameSession?.currentActor?.matchData?.isSmallBlind) {
        renderPlayerIcon = 'dealer_smallblind.png'
    } else if (gameSession?.currentActor?.matchData?.isDealer) {
        renderPlayerIcon = 'dealer.png'
    } else if (gameSession?.currentActor?.matchData?.isSmallBlind) {
        renderPlayerIcon = 'smallblind.svg'
    } else if (gameSession?.currentActor?.matchData?.isBigBlind) {
        renderPlayerIcon = 'bigblind.svg'
    }

    return renderPlayerIcon ? (
        <Container
            containerOption={{
                float: 'left',
                widthPx: 80,
                heightPx: 72,
                margin: [16, 10, 0, 5],
                borderRight: 'solid #879892',
                borderSize: 2,
                position: 'relative'
            }}
        >
            <ImageComponent
                imageOption={{
                    widthPx: 64,
                    heightToWidth: (renderPlayerIcon === 'dealer.png' || renderPlayerIcon === 'dealer_bigblind.png' || renderPlayerIcon === 'dealer_smallblind.png') ? 1 : 1.7,
                    position: 'absolute',
                    left: 0,
                    top: (renderPlayerIcon === 'dealer.png' || renderPlayerIcon === 'dealer_bigblind.png' || renderPlayerIcon === 'dealer_bigblind.png') ? 0 : 16
                }}
                source={`./data/ui/${renderPlayerIcon}`}
            />
        </Container>
    ) : null;
})


export default GameDigitalTypeIcon;