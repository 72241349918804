const GAMELOADER_STEP = {
    CHECK_WEBSOCKET_CONNECTION: 1,
    CHECK_CURRENT_GAMESESSION: 2,
    CHECK_RELOGIN: 3,
    PRE_FINISHED: 9,
    FINISHED: 10,
    ERROR: 11
};

export {
    GAMELOADER_STEP
}