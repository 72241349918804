const POKERCARDVALUE = {
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE: '5',
    SIX: '6',
    SEVEN: '7',
    EIGHT: '8',
    NINE: '9',
    TEN: '10',
    JACK: 'Jack',
    QUEEN: 'Queen',
    KING: 'King',
    ACE: 'Ace'
};

const POKERCARDKEY = {
    '2': 'TWO',
    '3': 'THREE',
    '4': 'FOUR',
    '5': 'FIVE',
    '6': 'SIX',
    '7': 'SEVEN',
    '8': 'EIGHT',
    '9': 'NINE',
    '10': 'TEN',
    '11': 'JACK',
    '12': 'QUEEN',
    '13': 'KING',
    '14': 'ACE'
};

const POKERCARDSUITS = {
    CLUBS: 'Clubs', // Kreuz
    DIAMONDS: 'Diamonds', // Karo
    HEARTS: 'Hearts', // Herz
    SPADES: 'Spades' // Piek - highest
};

const POKERCARDSUITSKEY = {
    '1': 'CLUBS', // Kreuz
    '2': 'DIAMONDS', // Karo
    '3': 'HEARTS', // Herz
    '4': 'SPADES' // Piek - highest
};

/**
 * Convert the cardname to imagename
 * @param {*} cardName 
 * @param {*} defaultVal 
 * @returns 
 */
function StringToCardImage(cardName: any, defaultVal: any = undefined) {
    const splittedName = cardName.split('_');
    if (splittedName.length === 2) {
        //@ts-ignore
        return `${POKERCARDSUITS[splittedName[0]]}_${POKERCARDVALUE[splittedName[1]]}`
    }
    return defaultVal;
}

function CardValueToString(suit: any, value: any) {
    //@ts-ignore
    return `${POKERCARDSUITSKEY[`${suit}`]}_${POKERCARDKEY[`${value}`]}`
}

function getCardBack(): string {
    return './data/decks/CardBack_1.png';
}
function getCardSource(suit: any, value: any, deck: any, microCard: boolean = false): any {
    const useCard = StringToCardImage(CardValueToString(suit, value), undefined);
    let useCardPath = `./data/decks/${microCard ? deck[1] : deck[0]}/${useCard}.svg`;
    if (!useCard) {
        useCardPath = getCardBack();
    }
    return useCardPath;
}
export {
    POKERCARDVALUE,
    POKERCARDKEY,
    POKERCARDSUITS,
    POKERCARDSUITSKEY,
    CardValueToString,
    StringToCardImage,
    getCardSource,
    getCardBack
}