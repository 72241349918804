import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import {
    MATCH_ROLE
} from '../../../constants/matchrole';
import type GameRound from '../session/gameround'
import type { CouchgamesSdk } from '../..';
import getSdk from '../..';

function checkGlow(communityCards: any, handCards: any, ranked: any, splitPot: boolean = false) {
    if (!handCards) return null;
    if (!ranked) return handCards;
    const nUseCommunity = communityCards || [];
    const nCards: any = [];
    handCards.forEach((card: any) => {
        if (card) {
            nCards.push({
                suit: card.suit,
                value: card.value,
                glow: ranked
                    .filter((c: any) => !nUseCommunity.find((community: any) => community.value === c.value && community.suit === c.suit)) // filter the card previously from the communitycards to get only the ranked cards for the hands
                    .find((c: any) => c.value === card.value && (c.suit === card.suit || c.suit === 0 || (splitPot && c.value === card.value))) ?
                    true :
                    false
            })
        } else {
            nCards.push(null)
        }
    })

    return nCards;
}

const getBetInformation = (round: GameRound, bet: number, bankroll: number) => {
    const roundBet = round?.bet || 0; // Tatsächliche Bet
    const roundBetCount = round?.betCount || 0;
    const roundMinRaise = round?.minimumRaise || 0;
    const payNeeded = roundBet - bet;

    return {
        betRound: roundBetCount,
        betMin: payNeeded,
        roundBet,
        betMax: bankroll,
        minRaise: roundMinRaise
    }
}

export default class GameActorMatch {
    public id: number;

    public latestRoundUpdate: string;
    public joined: boolean;
    public bankroll: number;
    public role: number;
    public folded: boolean;
    public assignable: boolean;
    public prefolded: boolean;
    public prechecked: boolean;
    public bet: number;
    public winner: boolean;
    public dealPushed: boolean;
    public handType: any;
    public turn: boolean;
    public pointerTurn: boolean; // The pointer shows on the player
    public hand: Array<any>;
    public handRevealed: number;
    public useBet: number;
    public useChipValue: number;
    public useChipBetValue: number;
    public useChipAction: string;
    public useBetPayNeeded: number;
    public useBetReRaise: boolean;
    public allIn: boolean;
    public sliderMin: number;
    public showHand: boolean;

    constructor(actorId: number) {
        makeObservable(this, {
            joined: observable,
            bankroll: observable,
            role: observable,
            folded: observable,
            prefolded: observable,
            prechecked: observable,
            showHand: observable,
            assignable: observable,
            bet: observable,
            useBet: observable,
            useChipValue: observable,
            useBetPayNeeded: observable,
            useChipBetValue: observable,
            useBetReRaise: observable,
            useChipAction: observable,
            allIn: observable,
            handType: observable,
            winner: observable,
            dealPushed: observable,
            turn: observable,
            pointerTurn: observable,
            hand: observable,
            handRevealed: observable,
            sliderMin: observable,
            update: action,
            toggleShowHand: action,
            updateMultiAction: action,
            updateSlider: action,
            updateBet: action,
            setBet: action,
            onNewRoundStatus: action,
            isDealer: computed,
            isSmallBlind: computed,
            isBigBlind: computed,
            betSliderAngle: computed,
            isRaiseChip: computed,
            useBetValue: computed,
            multiAction: computed
        })

        this.id = actorId;
        this.joined = false;
        this.bankroll = 0;
        this.sliderMin = 0;
        this.useChipBetValue = 0;
        this.role = MATCH_ROLE.NONE;
        this.assignable = false;
        this.folded = false;
        this.prefolded = false;
        this.prechecked = false;
        this.bet = 0;
        this.useBet = 0;
        this.useChipValue = 0;
        this.useBetPayNeeded = 0;
        this.showHand = false;
        this.useBetReRaise = false;
        this.winner = false;
        this.dealPushed = false;
        this.pointerTurn = false;
        this.turn = false;
        this.allIn = false;
        this.hand = [];
        this.handRevealed = 0;
        this.handType = 0;
        this.latestRoundUpdate = '';
        this.useChipAction = 'call';
    }

    toggleShowHand() {
        this.showHand = !this.showHand;
    }

    updateMultiAction(round: GameRound, ui: any): any {

        const betInfo = getBetInformation(round, this.bet, this.bankroll)
        let isAllIn = this.useChipAction === 'allin';
        let useAction = this.useChipAction;
        // let useText = 'call';
        let visible = true;
        let showValue = betInfo.betMin;
        // const isPlayerActive = this.id === round?.currentPlayer;

        // If we have a bet, a raise or a reraise show the full chip value
        // if(useAction === 'bet' || useAction === 'raise' || useAction === 'reraise' || useAction === 'allin') {
        //     showValue = this.useChipValue;
        // }
        // let isAllIn = false;
        // let visible = false;
        // let useAction = 'call';
        // let useText = 'call';
        // const isPlayerActive = this.id === round?.currentPlayer;

        // const betInfo = getBetInformation(round, this.bet, this.bankroll)
        // let useValue = betInfo.betMin > betInfo.betMax ? betInfo.betMax : betInfo.betMin;
        // if (useValue < 0) useValue = 0;


        // if (this.useBet < betInfo.betMin) {
        // this.useBet = betInfo.betMin;
        // if (this.useBet > betInfo.betMax) {
        // this.useBet = betInfo.betMax;
        // }
        // }

        // if (this.useBet > 0) {
        //     if (this.useBet <= betInfo.betMin) {
        //         // a call action
        //         if (this.useBet === 0) {
        //             useAction = 'call';
        //             useText = 'call';
        //             visible = true;
        //         } else if (betInfo.betMax > betInfo.betMin) {
        //             useAction = 'call';
        //             useText = 'call';
        //             if (betInfo.betMin - this.useBet === 0) {
        //                 visible = true;
        //             }
        //         } else {
        //             useAction = 'allin';
        //             useText = 'allin';
        //             isAllIn = true;
        //         }
        //         // Check for allIn
        //     } else if (this.useBet >= betInfo.betMax) {
        //         useAction = 'allin';
        //         useText = 'allin';
        //         isAllIn = true;

        //         // Otherwise it is a bet or a raise try
        //     } else {
        //         useAction = betInfo.betRound === 0 ? 'bet' : 'raise';
        //         useText = useAction;

        //         if (useAction === 'raise' && betInfo.betRound >= 2) {
        //             useText = 'reraise';
        //         }

        //         // Check if the button is visible
        //         visible = this.useRaiseBet >= betInfo.minRaise;
        //     }
        // } else {
        //     // disable button
        //     isAllIn = false;
        //     visible = false;
        // }
        // if (useAction === 'check' && ui?.buttonCheck === 2) {
        //     visible = true;
        // }

        return {
            action: useAction,
            text: useAction,
            roundBet: betInfo.roundBet || 0,
            showTotal: useAction === 'raise' || useAction === 'reraise' || useAction === 'bet',
            visible: visible || isAllIn,
            showValue
        }

    }

    get multiAction() {
        return {
            text: this.useChipAction,
            action: this.useChipAction,
            visible: true,
            minimum: this.useBetPayNeeded,
            maximum: this.bankroll,
            showValue: this.useChipAction === 'call' ?
                this.useBetPayNeeded :
                this.useChipValue
        }
    }

    // Here we say how many money we want to spent ()
    get useBetValue() {
        return this.useChipBetValue;
    }

    get useRaiseValue() {
        return this.useChipBetValue;
    }


    get isRaise() {
        const currentRoundBetCount = getSdk().appState?.currentSession?.currentMatch?.round?.betCount || 0;
        return currentRoundBetCount > 0;
    }

    get isRaiseChip() {
        const currentRound = getSdk().appState?.currentSession?.currentMatch?.round;
        const betCount = currentRound?.betCount || 0
        const roundStatus = currentRound?.status || 0

        return roundStatus === 0 ? betCount > 1 : betCount > 0;
    }

    onYouAre(session: any): void {
        this.useChipValue = 0;
        this.sliderMin = 0;

        // Try to get the next step
        if (session?.ui) {
            // if (sdk.appState.currentSession.ui.buttonCheck !== 2) {
            // Check is not possible , go to the next possible value
            const currentRound = session?.currentMatch?.round || undefined;

            if (currentRound) {

                // 0 ist minimum
                if (this.useChipValue < 0) {
                    this.useChipValue = 0;
                }

                const betInfo = getBetInformation(currentRound, this.bet, this.bankroll)

                const useBetMin = betInfo.betMin; // Dieser Wert muss mindestens genutzt werden
                const useBetMax = betInfo.betMax; // Dieser Wert muss mindestens genutzt werden
                const useMinRaise = betInfo.minRaise; // Wieviel muss mindestens erhöht werden?
                const isReRaise = betInfo.betRound >= 2;
                const useRoundBet = betInfo.roundBet;

                // the min pay needed
                this.useBetPayNeeded = betInfo.betMin;
                this.useChipBetValue = 0;
                this.useChipAction = 'call';
                this.useChipValue = useBetMin;

                if (this.useChipValue === 0) {
                    this.useChipValue = useRoundBet + useMinRaise;
                }

                // Es handelt sich um eine Call Action
                if (this.useChipValue <= useBetMin) {
                    this.useChipValue = useBetMin;
                    this.useChipAction = 'call';
                } else {
                    this.useChipAction = betInfo.betRound === 0 ?
                        'bet' :
                        isReRaise ? 'reraise' : 'raise';
                    if (this.useChipValue < useRoundBet + useMinRaise) {
                        this.useChipValue = useRoundBet + useMinRaise;
                    }

                    this.useChipBetValue = this.useChipValue - useRoundBet;
                }

                if (this.useChipValue >= useBetMax) {
                    this.useChipValue = useBetMax;
                    this.useChipAction = 'allin';
                }

                this.sliderMin = useBetMin;
                if (this.sliderMin > useBetMax) {
                    this.sliderMin = useBetMax;
                }
            }
        }

        console.log('ONYOUARE', this.useChipValue)
    }

    updateBet(round: GameRound, addValue: number): void {

        this.useChipValue += addValue;

        // 0 ist minimum
        if (this.useChipValue < 0) {
            this.useChipValue = 0;
        }

        const betInfo = getBetInformation(round, this.bet, this.bankroll)

        const useBetMin = betInfo.betMin; // Dieser Wert muss mindestens genutzt werden
        const useBetMax = betInfo.betMax; // Dieser Wert muss mindestens genutzt werden
        const useMinRaise = betInfo.minRaise; // Wieviel muss mindestens erhöht werden?
        const useRoundBet = betInfo.roundBet;
        const isReRaise = betInfo.betRound >= 2;

        // the min pay needed
        this.useBetPayNeeded = betInfo.betMin;
        this.useChipBetValue = 0;

        // Wir verringern den Betrag
        if (addValue < 0) {
            if (this.useChipValue < useRoundBet + useMinRaise) {
                this.useChipValue = useBetMin;
            }
        }

        // Es handelt sich um eine Call Action
        if (this.useChipValue <= useBetMin && useBetMin !== 0) {
            this.useChipValue = useBetMin;
            this.useChipAction = 'call';
        } else {
            this.useChipAction = betInfo.betRound === 0 ?
                'bet' :
                isReRaise ? 'reraise' : 'raise';
            if (this.useChipValue < useRoundBet + useMinRaise) {
                // if (addValue < 0) {
                //     this.useChipValue = useBetMin;
                //     this.useChipAction = 'call';
                // } else {
                this.useChipValue = useRoundBet + useMinRaise;
                // }
            }

            this.useChipBetValue = this.useChipValue - useRoundBet;
        }

        if (this.useChipValue >= useBetMax) {
            this.useChipValue = useBetMax;
            this.useChipAction = 'allin';
        }

        this.sliderMin = useBetMin;
        if (this.sliderMin > useBetMax) {
            this.sliderMin = useBetMax;
        }

    }

    setBet(bet: number): void {
        if (!isNaN(bet)) {
            const cRound = getSdk().appState?.currentSession?.currentMatch?.round;
            if (cRound) {
                if (this.id === cRound?.currentPlayer) {
                    this.useChipValue = bet;
                    this.updateBet(cRound, 0)
                }
            }
        }

    }

    setRaise(times: number): void {
        const cRound = getSdk().appState?.currentSession?.currentMatch?.round;

        if (cRound) {
            const useBetInfo = getBetInformation(cRound, this.bet, this.bankroll)
            const useMinRaise = useBetInfo.minRaise; // Wieviel muss mindestens erhöht werden?
            const useRoundBet = useBetInfo.roundBet;
            
            this.useChipValue = useRoundBet + useMinRaise * times;
            this.updateBet(cRound, 0)
        }
    }

    setPercentBet(percent: number, pot: number, bigBlind: number): void {

        let calcBet = Math.round(pot * (percent / 100))

        // Round the last sum

        if (calcBet > 100) {

            const ch = `${calcBet}`;
            const ch2 = Math.round(parseInt(ch[ch.length - 1], 10) / 10);

            if (ch2 === 1) {
                calcBet = calcBet - parseInt(ch[ch.length - 1], 10) + 10;
            } else {
                calcBet = calcBet - parseInt(ch[ch.length - 1], 10);
            }
        }

        // Minimum = BigBlind
        if (calcBet < bigBlind) {
            calcBet = bigBlind;
        }

        this.setBet(calcBet);
    }

    updateSlider(radien: number) {
        let useRadien = (radien + 100) - 325
        if (useRadien < 10) {
            useRadien = 0;
        } else if (useRadien > 80) {
            useRadien = 70
        } else {
            useRadien = useRadien - 10
        }

        this.setBet(Math.round((useRadien / 70) * this.bankroll));

    }

    onNewRoundStatus(sdk: CouchgamesSdk): void {
        const roundStatus = sdk?.appState?.currentSession?.currentMatch?.round?.status;
        if(roundStatus === 0) {
            this.showHand = sdk?.appState?.currentSession?.tableConfig?.flagDefaultShowHand === 0 ? false : true;
        }
    }



    update(matchData: any, playerHand: any, round: any): void {
        console.log(round)
        this.joined = !!matchData;
        this.bankroll = matchData?.cash || 0;

        if (matchData?.dealer === true) {
            this.role = MATCH_ROLE.DEALER;
            if (matchData?.isBigBlind === true) {
                this.role = MATCH_ROLE.DEALER_BIGBLIND;
            } else if (matchData?.isSmallBlind === true) {
                this.role = MATCH_ROLE.DEALER_SMALLBLIND;
            }
        } else if (matchData?.isSmallBlind === true) {
            this.role = MATCH_ROLE.SMALLBLIND;
        } else if (matchData?.isBigBlind === true) {
            this.role = MATCH_ROLE.BIGBLIND;
        } else {
            this.role = MATCH_ROLE.NONE;
        }
        this.handType = round?.hand?.find((pl: any) => pl[0] === this.id)?.[3] || 0;
        this.folded = matchData?.folded || false;
        this.prefolded = matchData?.prefolded || false;
        this.prechecked = matchData?.prechecked || false;
        this.bet = matchData?.bet || 0;
        this.assignable = matchData?.assignable || false;
        this.allIn = matchData?.allIn || false;
        this.winner = round?.winner?.find((id: number) => id === this.id) ? true : false;
        this.turn = (round?.currentPlayer === this.id && !round?.finished) || false;
        this.pointerTurn = (round?.nextPointer?.playerId === this.id && !round?.finished) || false;
        this.hand = checkGlow(
            round?.cards,
            playerHand?.hand,
            round?.rankCard,
            round?.winner?.length > 1
        );
        this.handRevealed = playerHand?.revealed || 0;
        this.dealPushed = round?.dealPushed?.find((pl: number) => pl === this.id) ? true : false;
    }

    get isDealer() {
        return this.role === MATCH_ROLE.DEALER || this.role === MATCH_ROLE.DEALER_BIGBLIND || this.role === MATCH_ROLE.DEALER_SMALLBLIND;
    }

    get isSmallBlind() {
        return this.role === MATCH_ROLE.SMALLBLIND || this.role === MATCH_ROLE.DEALER_SMALLBLIND;
    }

    get isBigBlind() {
        return this.role === MATCH_ROLE.BIGBLIND || this.role === MATCH_ROLE.DEALER_BIGBLIND;
    }

    get betSliderAngle() {
        let slMin = this.sliderMin;
        let slMax = this.bankroll - this.sliderMin;
        let slCurrent = this.useChipValue - this.sliderMin;
        if (slCurrent < 0) slCurrent = 0;
        if (slMin < 0) slMin = 0;
        if (slMax < 0) slMax = 0;
        if (slMax < slMin) slMax = slMin;

        let totalAngle = 325 + (slCurrent / slMax) * 70
        if (this.useChipAction === 'allin') {
            totalAngle = 325 + 70
        }
        return totalAngle > 360 ? totalAngle - 360 : totalAngle
    }
}