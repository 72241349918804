import { useEffect, useState } from 'react';
import {
    translateKey
} from '../../../utils/helper'
import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const DEFAULT_BUTTON_TIMEOUT = 800;

const ActionButtonRound = observer(({
    children,
    id,
    actionColor,
    actionType,
    className,
    labelValue,
    onClick,
    buttonOption,
    translationKey,
    enableDelay = DEFAULT_BUTTON_TIMEOUT,
    buttonVisibility
}: any) => {
    useEffect(() => {
        return () => {
            if (!!buttonDisabled) {
                clearTimeout(buttonDisabled)
            }
        }
    }, [])
    const visible = buttonVisibility === undefined ? 2 : buttonVisibility;
    const [buttonDisabled, setButtonDisabled] = useState(null);

    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const renderValue = translationKey ? translateKey(translationKey) : children;
    let useBorderColor = '#fff';

    if (actionColor === 'green') {
        useBorderColor = '#48bf53';
    } else if (actionColor === 'red') {
        useBorderColor = '#900D09'
    } else if (actionColor === 'blue') {
        useBorderColor = '#0096fe'
    } else if (actionColor === 'orange') {
        useBorderColor = '#df830b'
    } else if (actionColor === 'black') {
        useBorderColor = '#000'
    }

    return visible > 0 ? <button
        id={id}
        disabled={visible === 1 || !!buttonDisabled}
        onClick={(e) => {
            if (onClick) {
                onClick(e)
            }
            if(enableDelay) {
                const addTimeout = setTimeout(() => {
                    //@ts-ignore
                    setButtonDisabled(null);
                    // enableDelay()
                }, enableDelay)
    
                //@ts-ignore
                setButtonDisabled(addTimeout);
            }
        }}
        style={useStyles({
            textAlign: 'center',
            fontSize: 32,
            fontWeight: 'bold',
            float: 'right',
            widthPx: 140,
            heightPx: 140,
            outlineBorder: '#fff',
            outlineBorderSize: 8,
            bgColor: '#fff',
            padding: [0, 0, 0, 0],
            borderSize: 8,
            border: `solid ${useBorderColor}`,
            margin: [8, 12, 0, 12],
            ...(buttonOption || {})
        })}
        className={className ? className : 'button_circle_action'}
    >
        {labelValue ? (<>
            {renderValue}<br />
            <span style={useStyles({
                fontSize: 20
            })}>{labelValue}</span>
        </>) : (<>{renderValue}</>)}
    </button> : null;
});

export default ActionButtonRound;