const GAMEACTION = {
    ACTION_WINNER_TYPE: -96, // Show the winner type
    ACTION_ROUND_INFO: -97,
    ACTION_EMPTY_ROW: -98,
    ACTION_DEALER_DEALSCARD: -99,
    ACTION_SMALLBLIND: 1,
    ACTION_BIGBLIND: 2,
    ACTION_FOLD: 3, // Passen
    ACTION_BET: 4, // Bieten
    ACTION_CALL: 5, // Mitgehen
    ACTION_RAISE: 6, // Erhöhen
    ACTION_CHECK: 7, // Checken
    ACTION_ALLIN: 8, // ALLIN
    ACTION_WINNER: 10,
    ACTION_FLOP: 11,
    ACTION_TURN: 12,
    ACTION_RIVER: 13,
    ACTION_PLAYER_NOMONEY: 14, // The player has loose the game and leaves the game
    ACTION_DEAL: 15,
    ACTION_BANKRUPT: 16,
    ACTION_MATCH_WINNER: 17,
    ACTION_SITUP: 18, // Situp during a game
    ACTION_SITDOWN: 19, // Sitdown during a match
    ACTION_REVEALCARD: 20, // Reveal a card
    ACTION_NEWROUND: 21, // New round
    ACTION_ALLIN_DEAL: 23,
    ACTION_BEST_CARDS: 24,
    ACTION_RERAISE: 25,
    ACTION_BEBACK: 30, // The player is back
    ACTION_JOIN: 31,
    ACTION_LEAVE: 32,
    ACTION_BLIND_REVEALED: 33,
    ACTION_KICKED: 34,
    ACTION_BANNED: 35,
    ACTION_BANKROLL_UPDATE: 36,
    ACTION_BUYIN: 37,
    ACTION_PREFOLD: 40,
    ACTION_PRECHECK: 41,
    ACTION_NEXT: 50,
    ACTION_RABBITHUNT: 60
};

export {
    GAMEACTION
}