import roomCreate from "./RoomCreate";
import roomJoin from "./RoomJoin";
import roomPing from "./RoomPing";
import tableCreate from "./TableCreate";
import historyRequest from './HistoryRequest';
import adminRequest from "./AdminRequest";
// import adminOpenRoom from "./AdminOpenRoom";
// import adminSetDealer from "./AdminSetDealer";
// import adminResetRoom from "./AdminResetRoom";
import adminEditSetting from "./AdminEditSetting";
import adminRequestResult from "./AdminRequestResult";
import roundNext from './RoundNext';
// import roundVote from './RoundVote';
import roundNextGlobal from './RoundNextGlobal';
import matchStart from './MatchStart';
import matchLeave from './MatchLeave';
import actionSendGlobal from './ActionSendGlobal';
import actionSend from './ActionSend';
import tableSitDown from './TableSitDown';
import tableBuyIn from './TableBuyIn';
import tableStandUp from './TableStandUp';
import tableUpgrade from './TableUpgrade';
import tableUpgradeAdfree from './TableUpgradeAdfree';
import adminConnectClock from './AdminConnectClock';

import {
    GAMEACTION
} from '../../constants/gameaction';

import type { CouchgamesSdk } from "..";

export default function createMessage(sdk: CouchgamesSdk): any {
    const messageHandler = sdk.messageHandler;
    const user = sdk.appState.user;
    const session = sdk.appState.currentSession;
    const playerId = session?.currentActor?.id || 0;
    const playerAuth = session?.currentActor?.auth || '';

    return {
        upgradeAdFree: (token: string) => tableUpgradeAdfree(messageHandler, session?.roomToken, playerId, token),
        upgrade: (paidToken: string) => tableUpgrade(messageHandler, session?.roomToken, playerId, paidToken),
        fold: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_FOLD),
        iAmBack: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_BEBACK),
        adminHunt: (value: number) => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_RABBITHUNT, value),
        deal: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_DEAL),
        allInDeal: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_ALLIN_DEAL),
        call: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_CALL),
        nextPointer: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_NEXT),
        check: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_CHECK),
        allin: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_ALLIN),
        reveal: (reveal: number) => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_REVEALCARD, reveal),
        bet: (value: number) => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_BET, value),
        prefold: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_PREFOLD),
        adminConnectClock: (code: string) => adminConnectClock(messageHandler, session?.roomToken, playerId, code),
        precheck: () => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_PRECHECK),
        raise: (value: number) => actionSend(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_RAISE, value),
        standUp: () => tableStandUp(messageHandler, session?.roomToken, playerId),
        buyIn: (seat: number | null, value: number | undefined, creditToken: string | undefined) => tableBuyIn(messageHandler, session?.roomToken, playerId, seat, value, creditToken),
        createRoom: (publicData: any = undefined, dealer: boolean = false, dealerPin: string, waitingArea: boolean = false) => roomCreate(messageHandler, user, publicData, dealer, dealerPin, waitingArea),
        joinRoom: (code: string | undefined, tvMode: string | undefined, rejoinRoomToken: string | undefined, referred: boolean = false, quickgame: boolean = false, dealer: string | null = null) => roomJoin(messageHandler, user, code, tvMode, rejoinRoomToken, referred, quickgame, dealer),
        pingRoom: (actorId: number) => roomPing(messageHandler, session?.roomToken, actorId),
        leaveRoom: () => matchLeave(messageHandler, session?.roomToken, playerId),
        startMatch: () => matchStart(messageHandler, session?.roomToken, playerId),
        globalDeal: () => actionSendGlobal(messageHandler, session?.roomToken, playerId, GAMEACTION.ACTION_DEAL),
        globalNextRound: () => roundNextGlobal(messageHandler, session?.roomToken, playerId),
        nextRound: () => roundNext(messageHandler, session?.roomToken, playerId),
        sitDown: (seat: number | null) => tableSitDown(messageHandler, session?.roomToken, playerId, seat),
        createTable: (config: any) => tableCreate(messageHandler, session?.roomToken, config),
        adminEdit: (action: string, data: any = {}) => adminEditSetting(messageHandler, session?.roomToken, playerId, {
            action,
            auth: playerAuth,
            options: {
                ...data
            }
        }),
        requestHistory: () => historyRequest(messageHandler, session?.roomToken, playerId),
        adminRequest: () => adminRequest(messageHandler, session?.roomToken, playerId),
        adminRequestResult: (voteId: any, vote: boolean = true) => adminRequestResult(messageHandler, session?.roomToken, playerId, voteId, vote)
    }
}
//     switch (type) {
//         case 1005:
//             return 
//         case 1007:
//             return tableSitDown(messageHandler, session?.roomToken, data)
//         case 1010:
//             return actionSend(messageHandler, session?.roomToken, playerId, data);
//         case 1011: // TODO: CALLBACK RESET ??? 
//             return matchLeave(messageHandler, session?.roomToken, playerId);
//         case 1012:
//             return 
//         case 1013:
//             return historyRequest(messageHandler, session?.roomToken, playerId);
//         case 1022:
//             return roundNextGlobal(messageHandler, session?.roomToken, playerId);
//         case 1050:
//             return roundVote(messageHandler, session?.roomToken, playerId, data);
//         case 1060:
//             return adminOpenRoom(messageHandler, session?.roomToken, playerId, data);
//         case 1063:
//             return adminEditSetting(messageHandler, session?.roomToken, playerId, data);
//         case 1064:
//             return adminSetDealer(messageHandler, session?.roomToken, playerId, data);
//         case 1065:
//             return adminResetRoom(messageHandler, session?.roomToken, playerId);
//         case 1080:
//             return adminRequest(messageHandler, session?.roomToken, playerId);
//         case 1081:
//             return adminRequestResult(messageHandler, session?.roomToken, playerId, data);
//         default:
//     }

//     return false;
// }