const GAMEVIEW = {
    STARTVIEW: 0, // Here we will select a mode to use as a player ( Accessibility or Visible User )
    MAINMENU: 2, // The real mainmenu
    JOINMENU_ACTOR: 3,
    JOINMENU_TV: 4,
    CREATEGAME: 5,
    ACCOUNT_OVERVIEW: 9,
    // LEAGUE_OVERVIEW: 12,
    // LEAGUE_WAITAREA: 13,
    SINGLEPLAYER_OVERVIEW: 20,
    // BLINDMENU_ACCESSIBILITYSETTINGS: 80,
    // BLINDMENU_SETTINGS: 81,
    // BLINDMENU_INAPPSTORE: 82,
    // BLINDMENU_GAMESETTINGS: 83,
    SETTING: 90,
    GAME: 99
};

const POKERVIEW = {
    LOBBY: 0,
    TABLE: 1,
    HAND: 2
}

export {
    GAMEVIEW,
    POKERVIEW
}