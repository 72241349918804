import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import GameSession from './session/gamesession';
import AppOptions from './options';
import PublicList from './publiclist';
import AppUser from './user';
import {
    subscribeEvent,
} from '../events'
import {
    GAMEVIEW,
    POKERVIEW
} from '../../constants/gameViews'
import {
    TUTORIAL
} from '../../constants/tutorial';
import {
    canShare,
    onShare
} from '../../utils/helperfunction';
import { CouchgamesSdk } from '..';
import GameShop from './inapp/gameshop'
import GameAds from './ads/gameads';

const wait2 = (ms: number) => new Promise(
    (res, rej) => setTimeout(
        () => res(true),
        ms
    )
);

export default class AppState {

    public sdk: CouchgamesSdk;

    public currentSession: GameSession | undefined;
    public publicList: PublicList | undefined;
    public options: AppOptions | undefined;
    public user: AppUser | undefined;
    public shop: GameShop | undefined;
    public ads: GameAds | undefined;
    public view: number;
    public tvdevice: string | null;
    public subView: number;
    public mainmenuView: number;
    public gamestore: any;

    public settingView: string | null;
    public settingHistory: Array<string>;
    public settingOption: any;

    public currentDialog: any;
    public buyinDialog: any;
    public tutorialDialog: any;
    public dialogQueue: Array<any>;

    public roomCode: string; // The roomcode
    public joinDevice: number; // TV or TABLET
    public spinner: any;
    public digitalCardView: number;
    public snackbar: any;
    public cgMaskOpen: boolean;
    private lastAdIdused: any;
    private subscription: any;

    constructor(sdk: any) {
        makeObservable(this, {
            view: observable,
            subView: observable,
            mainmenuView: observable,
            gamestore: observable,
            currentSession: observable,
            settingView: observable,
            snackbar: observable,
            settingHistory: observable,
            spinner: observable,
            digitalCardView: observable,
            tvdevice: observable,
            roomCode: observable,
            joinDevice: observable,
            currentDialog: observable,
            buyinDialog: observable,
            cgMaskOpen: observable,
            tutorialDialog: observable,
            showSnackbarWarning: action,
            showSnackbarMoney: action,
            showSnackbar: action,
            showSnackbarDemo: action,
            showSnackbarCoin: action,
            hideSnackbar: action,
            createSpinner: action,
            closeSpinner: action,
            openMainMenu: action,
            setMainmenuView: action,
            openSetting: action,
            removeUser: action,
            closeSetting: action,
            updateValue: action,
            openJoinMenu: action,
            openTutorialDialogDigitalCard: action,
            openTutorialDialog100: action,
            switchDigitalCardView: action,
            joinGame: action,
            createNewGame: action,
            openJoinMenuTv: action,
            openDialog: action,
            openBuyInDialog: action,
            closeBuyInDialog: action,
            destroySession: action,
            openGameView: action,
            openCgMask: action,
            closeDialog: action,
            openGameStore: action,
            closeGameStore: action,
            closeTutorialDialog: action,
            rotatedView: computed,
            joinMenuTabDefault: computed
        });

        this.sdk = sdk;
        this.settingView = null;
        this.settingOption = null;
        this.settingHistory = [];
        this.snackbar = null;
        this.gamestore = null;
        this.cgMaskOpen = false;
        this.ads = undefined;

        this.view = GAMEVIEW.STARTVIEW;
        this.tvdevice = null;

        if (process.env.REACT_APP_GAMEWEBVIEW === 'true') {
            this.view = GAMEVIEW.MAINMENU;
        }

        this.subView = POKERVIEW.LOBBY;
        this.mainmenuView = 0;
        this.joinDevice = 0;
        this.roomCode = '';
        this.digitalCardView = 0;
        this.lastAdIdused = null;

        // dialogs
        this.currentDialog = undefined;
        this.buyinDialog = undefined;
        this.tutorialDialog = undefined;
        this.dialogQueue = [];

        this.options = new AppOptions(sdk);
        this.user = new AppUser(sdk);
        this.publicList = new PublicList(sdk);
        this.shop = new GameShop(sdk);
        if (process.env.REACT_APP_MOBILE === 'true') {
            this.ads = new GameAds();
        }
        this.subscription = subscribeEvent('message.handle', (msg: any) => this.handleGameMessage(msg?.detail?.type, msg?.detail?.rawMessage))
    }

    get joinMenuTabDefault() {
        return this.mainmenuView === 2 ? 0 : 1;
    }

    public openCgMask(): void {
        this.cgMaskOpen = true;
    }

    public createSpinner(timeout: number = 9000): any {
        this.closeSpinner();

        this.spinner = setTimeout(() => {
            this.closeSpinner();
        }, timeout)

        return {
            close: () => this.closeSpinner()
        }
    }

    public closeGameStore(): void {
        let useFrom = this.gamestore?.from
        this.gamestore = null;

        if (useFrom) {
            if (useFrom === 'session') {
                if (this.currentSession) {
                    this.view = GAMEVIEW.GAME;
                }
            } else {
                this.openSetting(useFrom)
            }

        }
    }

    public openGameStore(options: any = {}): void {
        this.gamestore = options;
        this.closeSetting();
    }

    public closeSpinner(): void {
        if (this.spinner) {
            clearTimeout(this.spinner);
        }
        this.spinner = undefined;
    }

    public switchDigitalCardView(): void {
        this.digitalCardView = 1 - this.digitalCardView;
    }

    handleGameMessage(type: number | undefined, message: any): void {
        if (!type) return;
        if (!message) return;

        switch (type) {
            case 3:
                if (message?.errorCause === 1001) {

                    if (this.currentSession?.rejoinCheck) {
                        this.currentSession.rejoinCheck.result = 404;
                    }

                    const {
                        errorId,
                        errorName,
                        errorCode
                    } = message;

                    if (errorId && errorName && errorCode) {
                        this.showErrorMessage(`error-${errorId}`, errorCode, true)
                    }
                }
                break;
            case 2199:
                if (process.env.REACT_APP_DEVMODE === 'true' && message?.reason !== 'noresponse' && message?.reason !== 'kick') {
                    console.log('DEVMODE -> DO NOT LEAVE THE GAME')
                } else {
                    this.leaveGame(message.reason);
                }
                break;
            case 2300:
                if (message?.adId && !this.user?.premium && !this.currentSession?.tvMode) {
                    this.showInterstitialAd(message.adId);
                }
                break;
            default:
        }
    }

    showSnackbarDemo(): void {
        if (this.snackbar === null) {
            this.snackbar = {
                type: 'demo'
            }
        }
    }

    showSnackbarCoin(coins: number, timeout: number = 3000): void {
        if (this.snackbar === null) {
            this.snackbar = {
                type: 'coins',
                timeout,
                coins
            }
            const that = this;
            setTimeout(() => that.hideSnackbar(), timeout)
        }
    }

    showSnackBarShare() {
        if (this.snackbar === null && canShare()) {
            this.snackbar = {
                type: 'invite',
                timeout: 4000,
                onClick: () => {
                    onShare('invite', { 
                        playerName: this.user?.userData?.name || '',
                        roomCode: this.currentSession?.roomCode || ''
                    })
                }
            }
            const that = this;
            setTimeout(() => that.hideSnackbar(), 4000)
        }
    }

    showSnackbarMoney(money: number, timeout: number = 3000): void {
        if (this.snackbar === null) {
            this.snackbar = {
                type: 'money',
                timeout,
                money
            }
            const that = this;
            setTimeout(() => that.hideSnackbar(), timeout)
        }
    }

    showSnackbar(message: string, replacements: any = [], timeout: number = 3000): void {
        if (this.snackbar === null) {
            this.snackbar = {
                message,
                type: 'info',
                timeout,
                replacements
            }
            const that = this;
            setTimeout(() => that.hideSnackbar(), timeout)
        }
    }

    showSnackbarWarning(message: string, onClick: any = undefined, timeout: number = 3000): void {
        if (this.snackbar === null) {
            this.snackbar = {
                message,
                onClick,
                type: 'warning',
                timeout,
                replacements: []
            }
            const that = this;
            setTimeout(() => that.hideSnackbar(), timeout)
        }
    }

    hideSnackbar(): void {
        this.snackbar = null;
    }

    showPremiumBox(feature: string, openLocation: string | null = 'account'): void {
        const buildDialog = {
            id: 'premiumbox',
            text: `dialog.vip.${feature}`,
            closeOutside: true,
            openLocation,
            config: {},
            type: 'premium',
            btnNo: null,
            btnYes: () => null,
            replacements: []
        }

        // Check if we already have a dialog
        if (this.currentDialog) {

            if (!this.dialogQueue.find(dialog => dialog.id === this.currentDialog.id)) {
                this.dialogQueue.push(buildDialog)
            }

            return;
        }

        // Open the dialog directly
        this.openDialog(buildDialog);

    }

    showInterstitialAd(adId: any) {
        if (this.ads && this.lastAdIdused !== adId) {
            this.lastAdIdused = adId;
            this.ads.showInterstitial();
        }
    }
    async showRewardAd() {
        console.log('showRewardAd')
        return new Promise((resolve: any) => {
            if (this.ads) {
                this.ads.showRewarded((result: any) => {
                    resolve(result);
                })
            } else {
                resolve(false);
            }
        })
    }

    showTutorialBox(text: string, replacements: any = []) {
        const buildDialog = {
            id: text,
            text,
            closeOutside: true,
            config: {},
            type: 'tutorial',
            btnNo: null,
            btnYes: null,
            replacements
        }

        if (this.currentDialog) {
            return;
        }

        // Open the dialog directly
        this.openDialog(buildDialog);
    }

    showSecurityBox(text: string, cbYes: any, storeProperty: string | null) {
        const buildDialog = {
            id: 'securitydialog',
            text,
            closeOutside: false,
            config: {},
            type: 'security',
            btnNo: () => null,
            storeProperty,
            btnYes: cbYes || null,
            replacements: []
        }

        // Check if we can do this directly 
        if (storeProperty !== null && this.options?.getValue(storeProperty) === 1) {
            if (cbYes) {
                cbYes();
            }
            return;
        }

        // Check if we already have a dialog
        if (this.currentDialog) {

            if (!this.dialogQueue.find(dialog => dialog.id === this.currentDialog.id)) {
                this.dialogQueue.push(buildDialog)
            }

            return;
        }

        // Open the dialog directly
        this.openDialog(buildDialog);
    }

    showPreviewBox(img: string, previewWidth: number | null = null, previewHeight: number | null = null,) {
        const buildDialog = {
            id: 'preview-image',
            text: '',
            closeOutside: true,
            preview: img,
            previewWidth,
            previewHeight,
            config: {},
            type: 'preview',
            btnNo: null,
            btnYes: null,
            replacements: []
        }
        if (this.currentDialog) {
            return;
        }
        // Open the dialog directly
        this.openDialog(buildDialog);

    }

    showMessagebox(id: string, text: string, cbYes: any, cbNo: any, closeOutside: boolean = false, replacements: any = [], overwrite: boolean = false, config: any = {}, type: string = 'message'): void {
        const buildDialog = {
            id,
            text,
            closeOutside,
            config,
            type,
            btnNo: cbNo || null,
            btnYes: cbYes || null,
            replacements,
            openLocation: config?.openLocation || undefined
        }

        // Check if we already have a dialog
        if (this.currentDialog) {

            if (overwrite) {
                this.dialogQueue = this.dialogQueue.filter(dialog => dialog.id !== id);
            }

            if (!this.dialogQueue.find(dialog => dialog.id === this.currentDialog.id)) {
                this.dialogQueue.push(buildDialog)
            }

            if (overwrite && this.currentDialog.id === id) {
                this.closeDialog();
            }
            return;
        }

        // Open the dialog directly
        this.openDialog(buildDialog);
    }

    showErrorMessage(id: string, text: string, closeOutside: boolean = false): void {
        const buildDialog = {
            id,
            text,
            closeOutside,
            type: 'error',
            btnNo: null,
            btnYes: null
        }

        // Check if we already have a dialog
        if (this.currentDialog) {
            if (!this.dialogQueue.find(dialog => dialog.id === this.currentDialog.id)) {
                this.dialogQueue.push(buildDialog)
            }
            return;
        }

        // Open the dialog directly
        this.openDialog(buildDialog);
    }

    tutorialShow(name: string) {
        return !this.options?.hasTutorialWatched(name)
    }

    tutorialCompleted(name: string) {
        this.options?.saveTutorialWatched(name)
    }

    openTutorialDialog(onlyWhenNotSeen: boolean = false, disableTimer: boolean = false): void {
        if (this.currentSession?.isDigitalCardMode) {
            this.openTutorialDialogDigitalCard(onlyWhenNotSeen, disableTimer);
            return;
        }
        // this.openTutorialDialog100(onlyWhenNotSeen, disableTimer);
    }

    openTutorialDialog100(onlyWhenNotSeen: boolean = false, disableTimer: boolean = false): void {
        if (onlyWhenNotSeen === true) {
            if (this.options?.hasTutorialWatched('mode_100pro')) {
                return;
            }
            this.options?.saveTutorialWatched('mode_100pro')
        }

        this.tutorialDialog = {
            tutorial: TUTORIAL.MODE_100PRO,
            disableTimer
        }
    }

    openTutorialDialogDigitalCard(onlyWhenNotSeen: boolean = false, disableTimer: boolean = false): void {
        if (onlyWhenNotSeen === true) {
            if (this.options?.hasTutorialWatched('mode_digital')) {
                return;
            }
            this.options?.saveTutorialWatched('mode_digital')
        }
        this.tutorialDialog = {
            tutorial: TUTORIAL.DIGITALCARD,
            disableTimer
        }
    }

    closeTutorialDialog(): void {
        this.tutorialDialog = undefined;
    }

    openChipStackDialog(from: number, to: number): void {
        this.buyinDialog = {
            stack: [from, to],
            seat: null,
            chipStack: true
        }
    }

    openBuyInDialog(seat: number | null, stack: number | Array<number>): void {
        this.buyinDialog = {
            stack,
            seat
        }
    }

    closeBuyInDialog(): void {
        this.buyinDialog = undefined;
    }

    openDialog(dialog: any): void {
        this.currentDialog = dialog;
    }

    closeDialog(): void {
        this.currentDialog = this.dialogQueue.shift();
    }

    updateValue(valueName: string, value: any) {
        // @ts-ignore
        this[valueName] = value;
    }

    updateRoomCode(code: string): void {
        this.roomCode = code;
    }

    openPreviousSetting(): void {
        if (this.settingHistory.length > 1) {
            const useHistory = this.settingHistory.slice(0, -1)
            if (useHistory.length) {
                this.openSetting(useHistory[useHistory.length - 1], useHistory)
                return;
            }
        }
        this.closeSetting();
    }

    openSetting(settingName: string, overwriteHistory: any = undefined, options: any = null): void {
        if (settingName !== this.settingView) {
            this.settingView = settingName;
            this.settingOption = options || {};
            if (overwriteHistory) {
                this.settingHistory = overwriteHistory;
            } else {
                this.settingHistory.push(settingName);
            }
        }
    }

    closeSetting(): void {
        this.settingView = null;
        this.settingOption = null;
        this.settingHistory = [];
    }

    get inDefaultView(): boolean {
        return this.view === GAMEVIEW.STARTVIEW;
    }

    removeUser(): void {
        this.view = GAMEVIEW.STARTVIEW;
        this.closeDialog();
        this.closeSetting();
        this.user?.deleteUser();
        this.user = undefined;
        this.user = new AppUser(this.sdk);
    }

    setMainmenuView(view: number) {
        this.mainmenuView = view;
    }

    openGameView(): void {
        this.view = GAMEVIEW.GAME;
        this.subView = this.currentSession?.checkView(this.subView) || this.subView;
    }

    openMainMenu(): void {
        this.view = GAMEVIEW.MAINMENU;
    }

    openJoinMenu(): void {
        this.view = GAMEVIEW.JOINMENU_ACTOR;
    }

    openGameHistory(): void {
        if (this.currentSession) {
            this.currentSession.closeOverlay()
        }
        this.sdk.getMessageList().requestHistory()
        this.openSetting('gamehistory')
    }

    openJoinMenuTv(device: string = 'tv'): void {
        this.view = GAMEVIEW.JOINMENU_TV;
        this.tvdevice = device;
    }

    destroySession(reason: string | undefined = undefined): void {
        console.log('DESTROY SESSION', reason)
        if (this.currentSession) {
            const shouldSendLeaveMessage: boolean = reason ?
                false :
                true;
            let shouldClearCache: boolean = true;
            if (reason === 'loader') {
                shouldClearCache = false;
            }
            this.currentSession.destroy(shouldSendLeaveMessage, shouldClearCache);
            this.currentSession = undefined;
        } else {
            if (reason !== 'loader') {
                this.sdk.removeFromStorage('lastgame');
            }
        }
    }

    async leaveGame(reason: string | undefined = undefined): Promise<void> {

        try {
            if (this.user) {
                this.user.resetLastUpdate();
            }
        } catch (e: any) { }

        

        if(reason === 'settingclose') {
            this.destroySession();

            const useSpinner = this.sdk.appState.createSpinner();
            await wait2(600)
            useSpinner.close();
        } else {
            this.destroySession(reason);
        }

        this.openMainMenu();
        this.closeSetting();

        if (reason === 'cleanup') {
            this.showMessagebox('kicked', 'dialog.leave.reason.cleanup', null, null, true)
        } else if (reason === 'destroy') {
            this.showMessagebox('kicked', 'dialog.leave.reason.closed', null, null, true)
        } else if (reason === 'kick') {
            this.showMessagebox('kicked', 'dialog.leave.reason.kick', null, null, true)
        } else if (reason === 'noresponse') {
            this.showMessagebox('kicked', 'dialog.leave.reason.noresponse', null, null, true)
        }
    }

    createNewGame(): void {
        this.destroySession();
        console.log('[SESSION] CREATE NEW SESSION ( GAME )')
        this.currentSession = new GameSession(
            this.sdk,
            this,
            undefined,
            true
        )

        this.view = GAMEVIEW.GAME;
        this.subView = POKERVIEW.LOBBY;

    }

    joinQuickGame(): void {
        this.destroySession();
        this.currentSession = new GameSession(this.sdk, this, undefined);
        this.currentSession.joinGame(undefined, false, true);
    }

    joinGame(tvMode: string | undefined = undefined, overwriteCode: string | undefined = undefined, dealer: string | null): void {
        console.log('[SESSION] JOINGAME / SESSION')
        this.destroySession();
        this.currentSession = new GameSession(this.sdk, this, overwriteCode || this.roomCode);
        this.currentSession.joinGame(tvMode ? tvMode : undefined, false, false, dealer !== null ? dealer : null);
    }

    tryGameRejoin(loadId: string): number {
        if (this.currentSession) {
            // Try to rejoin. If a rejoin is available try to create a new rejoin
            if (this.currentSession.getRejoinGame(loadId)) {
                return this.currentSession.getRejoinGame(loadId) || 100;
            }

            return this.currentSession.rejoinGame(loadId);
        }
        return 404;
    }

    checkGameSession(loadId: string): number {
        let useSession = undefined;
        // When we have a current connection check, return the result
        if (this.currentSession?.getConnectionCheck(loadId)) {
            return this.currentSession.getConnectionCheck(loadId) || 100;
        } else if (this.currentSession) {
            // this.currentSession;
        } else {
            this.destroySession('loader');
            useSession = this.sdk.loadFromStorage('lastgame');
        }

        // return this.currentSession.checkConnection(loadId, null);
        if (useSession) {
            if (!this.currentSession) {
                this.currentSession = new GameSession(this.sdk, this, useSession.code);
                this.currentSession.updateRoomInfo(useSession);
                return 401;
            }

        }

        if (this.currentSession?.sessionRunning) {
            console.log('CURRENT SESSION', this.currentSession)
            return this.currentSession.checkConnection(loadId, useSession?.actorId || null);
        }

        console.log('### GAMESESSION => return')
        return 404;
    }

    get rotatedView() {
        return this.currentSession?.isDigitalCardMode && this.digitalCardView === 1;
    }
}