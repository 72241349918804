import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'

class AppUserBoni {
    public serverTimestamp: number;
    public boniMap: Map<string, any>
    public timeOut: any;
    public waitTime: number;

    constructor() {
        makeObservable(this, {
            serverTimestamp: observable,
            boniMap: observable,
            waitTime: observable,
            update: action,
            updateBonusTimestamp: action,
            updateTime: action,
            hasUpdate: computed

        });
        this.boniMap = new Map();
        this.serverTimestamp = 0;
        this.waitTime = 0;
        this.timeOut = null;
    }

    updateBonusTimestamp(bonusId: string, bonusTimestamp: number): void {
        if (this.boniMap.has(bonusId)) {
            this.boniMap.set(bonusId, {
                ...this.boniMap.get(bonusId),
                lastUsed: bonusTimestamp
            })
        }
        this.serverTimestamp = bonusTimestamp;
        this.waitTime = 0;
    }

    update(data: any): void {
        if (data?.boni) {
            Object.keys(data.boni).forEach((bonusId: string) => {
                if (this.boniMap.has(bonusId)) {
                    if (JSON.stringify(this.boniMap.get(bonusId)) !== JSON.stringify(data.boni[bonusId])) {
                        this.boniMap.set(bonusId, data.boni[bonusId])
                    }
                } else {
                    this.boniMap.set(bonusId, data.boni[bonusId])
                }
            })
        }
        this.serverTimestamp = data?.timestamp || 0;
        this.waitTime = 0;

        this.createTimer();

        console.log(data?.boni)
    }

    createTimer(): void {
        if (this.timeOut) {
            clearTimeout(this.timeOut)
        }
        const that = this;
        this.timeOut = setTimeout(() => {
            that.updateTime()
        }, 1000)
    }

    updateTime(): void {
        this.waitTime += 1;
        this.createTimer();
    }

    getBoniCash(id: string): number {
        if (this.boniMap.has(id)) {
            return this.boniMap.get(id).cash;
        }
        return 0;
    }

    getBoniTime(id: string): number | null {
        let useBonus: any = null;

        if (this.boniMap.has(id)) {
            useBonus = this.boniMap.get(id);
        }

        if (useBonus) {
            const value = useBonus.time - (this.serverTimestamp - useBonus.lastUsed) - this.waitTime;
            return value < 0 ? 0 : value;
        }

        return null;
    }

    get hasUpdate() {
        return this.waitTime;
    }

}

export default AppUserBoni;