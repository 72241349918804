import {
    observer
} from 'mobx-react-lite';
import React from 'react';
import getSdk from '../../../../api';
import {
    Container,
    ActionButton
} from '../../../controls';
import CommunityCard from '../cards/CommunityCard';
import PokerTableLogo from './PokerTableLogo';
import PokerTablePot from './PokerTablePot';
import PokerTableDealerButton from './PokerTableDealerButton';

const PokerTable = observer(({
    round,
    gameSession
}: any) => {

    return <Container
        id={'table'}
        containerOption={{
            position: 'relative',
            widthPx: 780,
            heightPx: 270,
            bgImage: `url(./data/${!gameSession.dragging ? 'table.svg' : 'table_drop.png'})`,
            bgRepeat: 'no-repeat',
            bgImageSize: 'contain',
            margin: [16, 0, 0, 55]
        }}
        onDrop={(event: any) => {
            
            const useFunction = (event.dataTransfer.getData('text/plain') || '').split('|')
            if (useFunction?.length === 2) {
                switch (useFunction[1]) {
                    case 'check':
                        gameSession.currentMatch.playerCheck()
                        break;
                    case 'call':
                        gameSession.currentMatch.playerCall()
                        break;
                    case 'bet':
                        gameSession.currentMatch.playerBet(gameSession?.currentActor?.matchData?.useBetValue)
                        break;
                    case 'raise':
                        gameSession.currentMatch.playerRaise(gameSession?.currentActor?.matchData?.useRaiseValue)
                        break;
                    case 'reraise':
                        gameSession.currentMatch.playerRaise(gameSession?.currentActor?.matchData?.useRaiseValue)
                        break;
                    case 'allin':
                        gameSession.currentMatch.playerAllIn()
                        break;
                    case 'deal':
                        gameSession.currentMatch.dealerNextRound()
                        break;
                }

                try {
                    getSdk()?.appState?.tutorialCompleted('dragchip')
                } catch(e) {}
            }
        }}
        onDoubleTap={() => gameSession?.ui?.buttonCheck === 2 ? gameSession.currentMatch.playerCheck() : null}
    >
        <PokerTableLogo
            gameSession={gameSession}
            widthTable={780}
            heightTable={270}
        />
        <PokerTablePot gameSession={gameSession} />
        <CommunityCard round={round} deck={gameSession.sessionDeck} dealerCard={1} gameSession={gameSession} marginLeft={65} />
        <CommunityCard round={round} deck={gameSession.sessionDeck} dealerCard={2} gameSession={gameSession} />
        <CommunityCard round={round} deck={gameSession.sessionDeck} dealerCard={3} gameSession={gameSession} />
        <CommunityCard round={round} deck={gameSession.sessionDeck} dealerCard={4} gameSession={gameSession} />
        <CommunityCard round={round} deck={gameSession.sessionDeck} dealerCard={5} gameSession={gameSession} />
        <PokerTableDealerButton
            gameSession={gameSession}
        />
        {/* {gameSession.ui.buttonNewGame === 2 && gameSession.admin ?
            <ActionButton
                id={'btn_newgame'}
                actionColor={'green'}
                translationKey={'game.button.handview.newgame'}
                buttonOption={{
                    widthPx: 480,
                    heightToWidth: 8.24,
                    lineHeight: 16,
                    fontSize: 28,
                    zIndex: 12,
                    position: 'relative',
                    margin: [112, 0, 0, 0]
                }}
                onClick={() => getSdk().getMessageList().startMatch()}
            />
            : (
                <React.Fragment key={'communitycard-area'}>
             
                </React.Fragment>
            )} */}

    </Container>
})

export default PokerTable;