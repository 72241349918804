import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const ProgressBar = observer(({
    id,
    label,
    width,
    height,
    range,
    icon,
    value,
    containerOption,
    progressOption
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const useValue = value > range ? range : value
    const v = (useValue / range )

    const propsProgressBackground = useStyles({
        position: 'relative',
        bgColor: '#000000',
        widthPx: width,
        heightPx: height,
        borderSize: 4,
        borderRadius: 16,
        border: 'solid #272d33',
        ...(containerOption || {})
    });

    const propsProgressBar = useStyles({
        bgColor: `#f5861f`,
        widthPx: width * v,
        heightPx: height,
        borderSize: 4,
        borderRadius: 16,
        ...(progressOption || {})
    });

    const propsProgressBarText = useStyles({
        position: 'absolute',
        left: 0,
        top: 0,
        color: '#fff',
        widthPx: width,
        heightPx: height,
        lineHeight: height,
        fontSize: 28,
        fontWeight: 'bold',
        ...(progressOption || {})
    });

    const propsIcon = useStyles({
        position: 'absolute',
        left: 32,
        top: 8,
        widthPx: 32,
        heightPx: 32,
        float: 'left'
    })
    return <div style={propsProgressBackground}>
        <div style={propsProgressBar}></div>
        <div style={propsProgressBarText}>{label || `${useValue} / ${range}`}</div>
        {icon ? <img src={icon} style={propsIcon} alt={''} /> : null}
    </div>
});

export default ProgressBar;