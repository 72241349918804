import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    IconButton,
    ActionButtonRound
} from '../../../../controls/index';
import {
    translateKey
} from '../../../../../utils/helper'
import GameDigitalCardClock from './DigitalCardClock'
import GameDigitalCardSessionLimit from './DigitalCardSessionLimit'
import GameDigitalTypeIcon from './DigitalCardTypeIcon'
import GameDigitalHand from './DigitalCardHand'
import CommunityCard from '../../cards/CommunityCard';
import {
    POKERVIEW
} from '../../../../../constants/gameViews'

const GameDigitalCardPortrait = observer(({
    sdk,
    gameSession
}: any) => {
    const marginRight = sdk.layoutManager.canvasMarginRight;
    const showWinningHand = gameSession?.currentActor?.matchData?.handType &&
        gameSession?.currentActor?.matchData?.handRevealed === 3 &&
        !gameSession?.currentActor?.matchData?.folded;
    const showYourTurn = !showWinningHand && gameSession?.currentActor?.matchData?.pointerTurn;

    return <Container
        containerOption={{
            width: '100%',
            height: '100%',
            margin: [0, 0, 0, marginRight]
        }}
    >
        <Container
            className={'rotation-wrapper-outer'}
        >
            <Container
                className={'rotation-wrapper-inner'}
            >
                <Container
                    id={'content'}
                    className={'div-rotated'}
                    containerOption={{
                        widthPx: 750,
                        heightPx: 1350
                    }}
                >

                    <Container
                        id={'gameinfo'}
                        className={showYourTurn ? 'placer-active-digitalcard' : ''}
                        containerOption={{
                            width: '100%',
                            heightPx: 96,
                            margin: [0, 0, 0, 0],
                            bgColor: 'rgba(0, 0, 0, .7)',
                            borderRadius: 10,
                            float: 'left'
                        }}
                    >
                        <IconButton
                            buttonOption={{
                                widthPx: 48,
                                heightPx: 48,
                                border: 'none',
                                margin: [24, 0, 0, 16],
                                float: 'left'
                            }}
                            source={'./data/ui/settings.svg'}
                            onClick={() => sdk.appState.openSetting('game')}
                        />
                        {gameSession?.tableConfig?.flagDisableBlinds !== 1 ? (
                            <Container
                                containerOption={{
                                    float: 'left',
                                    widthPx: 210,
                                    heightPx: 72,
                                    margin: [16, 10, 0, 5],
                                    borderRight: 'solid #879892',
                                    borderSize: 2
                                }}
                            >
                                <Container
                                    containerOption={{
                                        widthPx: 200,
                                        heightPx: 32,
                                        fontSize: 22,
                                        fontWeight: 'bold',
                                        float: 'left',
                                        lineHeight: 32,
                                        color: '#fff',
                                        textAlign: 'center'
                                    }}
                                >
                                    {translateKey('game.text.handview.blinds')}
                                </Container>
                                <Container
                                    containerOption={{
                                        widthPx: 200,
                                        heightPx: 32,
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        float: 'left',
                                        lineHeight: 32,
                                        color: '#dd985d',
                                        textAlign: 'center'
                                    }}
                                >
                                    {`${gameSession?.currentMatch?.smallBlind || '-'} / ${gameSession?.currentMatch?.bigBlind || '-'}`}
                                </Container>
                            </Container>
                        ) : (
                            <Container
                                containerOption={{
                                    float: 'left',
                                    widthPx: 210,
                                    heightPx: 72,
                                    margin: [16, 10, 0, 5],
                                    borderRight: 'solid #879892',
                                    borderSize: 2
                                }}
                            >
                            </Container>
                        )}
                        <GameDigitalTypeIcon sdk={sdk} gameSession={gameSession} />
                        <Container
                            containerOption={{
                                float: 'right',
                                widthPx: 300,
                                heightPx: 72
                            }}
                        >
                            <CommunityCard
                                round={gameSession?.currentMatch?.round}
                                deck={gameSession.sessionDeck}
                                dealerCard={1}
                                gameSession={gameSession}
                                cardWidth={50}
                                microcard={true}
                                cardOption={{
                                    margin: [8, 3, 0, 3]
                                }}
                            />
                            <CommunityCard
                                round={gameSession?.currentMatch?.round}
                                deck={gameSession.sessionDeck}
                                dealerCard={2}
                                cardWidth={50}
                                gameSession={gameSession}
                                microcard={true}
                                cardOption={{
                                    margin: [8, 3, 0, 3]
                                }}
                            />
                            <CommunityCard
                                round={gameSession?.currentMatch?.round}
                                deck={gameSession.sessionDeck}
                                dealerCard={3}
                                cardWidth={50}
                                gameSession={gameSession}
                                microcard={true}
                                cardOption={{
                                    margin: [8, 3, 0, 3]
                                }}
                            />
                            <CommunityCard
                                round={gameSession?.currentMatch?.round}
                                deck={gameSession.sessionDeck}
                                dealerCard={4}
                                cardWidth={50}
                                gameSession={gameSession}
                                microcard={true}
                                cardOption={{
                                    margin: [8, 3, 0, 3]
                                }}
                            />
                            <CommunityCard
                                round={gameSession?.currentMatch?.round}
                                deck={gameSession.sessionDeck}
                                dealerCard={5}
                                cardWidth={50}
                                gameSession={gameSession}
                                microcard={true}
                                cardOption={{
                                    margin: [8, 3, 0, 3]
                                }}
                            />
                        </Container>
                    </Container>
                    <Container
                        id={'gameinfo_sub'}
                        containerOption={{
                            width: '100%',
                            heightPx: 96,
                            margin: [0, 0, 0, 0],
                            bgColor: 'rgba(0, 0, 0, .5)',
                            borderRadius: 10,
                            float: 'left'
                        }}
                    >
                        <GameDigitalCardClock
                            sdk={sdk}
                        />
                        <GameDigitalCardSessionLimit
                            sdk={sdk}
                        />
                        {((gameSession?.voteList || []).length && gameSession.admin) ? (
                            <Container
                                containerOption={{
                                    widthPx: 96,
                                    heightPx: 72,
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    float: 'left',
                                    lineHeight: 72,
                                    color: '#dd985d',
                                    textAlign: 'center',
                                    borderRight: 'solid #879892',
                                    margin: [16, 8, 0, 4],
                                    borderSize: 2,
                                }}
                            >
                                <IconButton
                                    source={`./data/icon/important.svg`}
                                    buttonOption={{
                                        widthPx: 64,
                                        heightPx: 64,
                                        margin: [0, 0, 0, 0]
                                    }}
                                    onClick={() => gameSession.openRequestView()}
                                />
                            </Container>
                        ) : null}
                        <IconButton
                            buttonOption={{
                                widthPx: 48,
                                heightPx: 48,
                                border: 'none',
                                margin: [24, 16, 0, 16],
                                float: 'right'
                            }}
                            source={'./data/icon/phonerotate.svg'}
                            onClick={() => sdk.appState.switchDigitalCardView()}
                        />
                        <IconButton
                            buttonOption={{
                                widthPx: 48,
                                heightPx: 48,
                                border: 'none',
                                margin: [24, 16, 0, 16],
                                float: 'right'
                            }}
                            source={'./data/icon/help.svg'}
                            onClick={() => {
                                gameSession.openOverlay(
                                    {},
                                    'trainer',
                                    'pokertrainer'
                                )
                            }}
                        />
                        {gameSession?.admin ? (
                            <IconButton
                                buttonOption={{
                                    widthPx: 74,
                                    heightPx: 48,
                                    border: 'none',
                                    margin: [24, 16, 0, 16],
                                    float: 'right'
                                }}
                                source={'./data/icon/admin.svg'}
                                onClick={() => gameSession.openOverlay(
                                    {},
                                    'room',
                                    'roomsetting'
                                )}
                            />
                        ) : null}
                    </Container>
                    <Container
                        id={'cardarea'}
                        containerOption={{
                            position: 'relative',
                            width: '100%',
                            heightPx: 800,
                            float: 'left',
                            margin: [40, 0, 0, 0]
                        }}
                    >
                        <GameDigitalHand
                            sdk={sdk}
                            gameSession={gameSession}
                            hugeOverlappedView={true}
                            containerStatic={{
                                margin: [0, 0, 0, 12]
                            }}
                            containerHand={{
                                margin: [0, 0, 0, 200]
                            }}
                            rotated={true}
                        />
                        {gameSession?.currentActor?.matchData?.folded ?
                            <Container
                                containerOption={{
                                    position: 'absolute',
                                    left: 0,
                                    bottom: 164,
                                    widthPx: 760,
                                    heightPx: 240,
                                    zIndex: 10,
                                    bgColor: `#ca0101`,
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    lineHeight: 240,
                                    fontSize: 64
                                }}
                            >
                                {translateKey('game.text.handview.folded')}
                            </Container>
                            : null}
                        {!gameSession?.currentActor?.matchData?.folded && !gameSession?.currentActor?.playing ?
                            <Container
                                containerOption={{
                                    position: 'absolute',
                                    left: 0,
                                    bottom: 164,
                                    widthPx: 760,
                                    heightPx: 240,
                                    zIndex: 10,
                                    bgColor: `#062860`,
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    lineHeight: 240,
                                    fontSize: 32
                                }}
                            >
                                {translateKey('game.text.handview.waitnextround')}
                            </Container> : null}
                    </Container>

                    <Container
                        id={'gameinfo_footer'}
                        className={showYourTurn ? 'placer-active-digitalcard' : ''}
                        containerOption={{
                            width: '100%',
                            heightPx: 96,
                            margin: [8, 0, 0, 0],
                            borderRadius: 10,
                            float: 'left',
                            fontSize: 32,
                            lineHeight: 96,
                            bgColor: gameSession?.currentActor?.matchData?.winner ? 'rgba(57, 105, 5, .7)' : 'rgba(0, 0, 0, .7)',
                            color: gameSession?.currentActor?.matchData?.winner ? '#fff' : '#fff'

                        }}
                    >
                        {showYourTurn ? (
                            translateKey('text.game.playerhand.yourturn', {}, [])
                        ) : null}
                        {showWinningHand ? (
                            translateKey(gameSession?.currentActor?.matchData?.winner ? gameSession?.currentMatch?.round?.splitpot ? 'text.game.playerhand.splitpot' : 'text.game.playerhand.winner' : 'text.game.playerhand.handtype', {}, [
                                ['$handtype', translateKey(`text.player.hand.${gameSession?.currentActor?.matchData?.handType}`)]
                            ])
                        ) : null}
                    </Container>
                    <Container
                        id={'button_area'}
                        containerOption={{
                            width: '100%',
                            heightPx: 180,
                            margin: [0, 0, 0, 0],
                            bgColor: 'rgba(0, 0, 0, .2)',
                            borderRadius: 10,
                            float: 'left'
                        }}
                    >
                        <ActionButtonRound
                            className={'button_circle_action_heartbeat'}
                            actionColor={'green'}
                            buttonOption={{
                                margin: [20, 20, 0, 20]
                            }}
                            translationKey={'NEXT'}
                            onClick={() => gameSession.nextPointer()}
                            buttonVisibility={gameSession?.ui?.buttonNextPointer}
                        />
                        <ActionButtonRound
                            actionColor={'blue'}
                            buttonOption={{
                                margin: [20, 20, 0, 20]
                            }}
                            translationKey={'game.button.handview.table'}
                            buttonVisibility={gameSession?.ui?.buttonTable}
                            onClick={() => sdk.appState.updateValue('subView', POKERVIEW.TABLE)}
                        />
                        {!gameSession.dealerMode ? <>
                            <ActionButtonRound
                                actionColor={'green'}
                                buttonOption={{
                                    margin: [20, 20, 0, 0]
                                }}
                                translationKey={'game.button.handview.deal'}
                                onClick={() => sdk.getMessageList().nextRound()}
                                buttonVisibility={gameSession?.ui?.buttonDeal}
                            />
                            <ActionButtonRound
                                actionColor={'green'}
                                buttonOption={{
                                    margin: [20, 20, 0, 0]
                                }}
                                translationKey={`game.button.handview.deal.status.${gameSession?.currentMatch?.round?.status || 0}`}
                                onClick={() => gameSession?.currentMatch?.playerDeal()}
                                buttonVisibility={gameSession?.ui?.buttonDealCard}
                                enableDelay={1000}
                            />
                            <ActionButtonRound
                                actionColor={'green'}
                                buttonOption={{
                                    margin: [20, 20, 0, 0]
                                }}
                                translationKey={`game.button.handview.deal.showdown`}
                                onClick={() => gameSession?.currentMatch?.playerDeal()}
                                buttonVisibility={gameSession?.ui?.buttonShowdown}
                                enableDelay={2000}
                            />
                            <ActionButtonRound
                                actionColor={'blue'}
                                buttonOption={{
                                    margin: [20, 20, 0, 0]
                                }}
                                translationKey={'game.button.handview.newgame'}
                                onClick={() => gameSession?.playerNewGame()}
                                disabled={gameSession?.ui?.buttonNewGame !== 2}
                                buttonVisibility={gameSession?.ui?.buttonNewGame}
                            />
                        </> : null}
                        {gameSession?.ui?.buttonAllIn === 2 ? (
                            <ActionButtonRound
                                actionColor={'orange'}
                                buttonOption={{
                                    margin: [20, 0, 0, 32],
                                    float: 'left'
                                }}
                                translationKey={'game.button.handview.allin'}
                                onClick={() => gameSession?.currentMatch?.playerAllIn()}
                                buttonVisibility={gameSession?.ui?.buttonAllIn}
                            />
                        ) : (
                            <ActionButtonRound
                                actionColor={'red'}
                                buttonOption={{
                                    margin: [20, 0, 0, 32],
                                    float: 'left'
                                }}
                                translationKey={'game.button.handview.standup'}
                                onClick={() => gameSession.standUp()}
                                buttonVisibility={gameSession?.ui?.buttonStandup}
                            />
                        )}
                    </Container>
                </Container>
            </Container>
        </Container>
    </Container>;
});

export default GameDigitalCardPortrait;