import type AppMessageHandler from "../messagehandler";

export default function roomPing(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number
): boolean {
    messageHandler.sendMessage(98, {
        actorId: playerId,
        roomToken,
        pingType: 1
    })
    return true;
}