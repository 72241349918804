import {
    observer
} from 'mobx-react-lite';
import {
    translateKey,
    replace1k
} from '../../../../utils/helper';
import {
    List,
    ConfiguratorItem
} from '../../../controls';

const BlindSetting = observer(({
    sdk,
    onNextItem
}: any) => {
    const gameSession = sdk?.appState?.currentSession;

    const buildItemList = [];

    if (gameSession?.admin) {
        const clock = gameSession?.currentMatch?.clock;

        if (clock) {
            buildItemList.push(<ConfiguratorItem
                label={clock.running ?
                    'text.overlay.admin.clock.pause' :
                    'text.overlay.admin.clock.resume'
                }
                icon={clock.running ?
                    'clock-g' :
                    'clock-r'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => {
                    if (gameSession?.currentMatch) {
                        gameSession.currentMatch.adminChangeClockRunning(!clock.running)
                    }
                }}
            />)
        }


        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.admin.blindsetting'}
                onNextItem={() => null}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={'casino-chip'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => gameSession.openOverlaySubmenu('editblind')}
            />
        )

        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.admin.connectclock'}
                onNextItem={() => null}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={'clock-g'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => gameSession.openOverlaySubmenu('connectclock')}
            />
        )

    }


    buildItemList.push(<ConfiguratorItem
        label={`${translateKey('text.overlay.admin.smallblind', {}, [[
            '$value', replace1k(gameSession?.currentMatch?.smallBlind || 0)
        ]])}`}
        itemOption={{
            widthPx: 400,
            heightPx: 64,
            margin: [0, 0, 0, 16],
            color: '#56d4fd'
        }}
        iconImage={'./data/ui/smallblind.svg'}
        iconOption={{
            margin: [20, 8, 0, 0]
        }}
        onClick={() => null}
    />)

    buildItemList.push(<ConfiguratorItem
        label={`${translateKey('text.overlay.admin.bigblind', {}, [[
            '$value', replace1k(gameSession?.currentMatch?.bigBlind || 0)
        ]])}`}
        itemOption={{
            widthPx: 400,
            heightPx: 64,
            margin: [0, 0, 0, 16],
            color: '#56d4fd'
        }}
        iconImage={'./data/ui/bigblind.svg'}
        iconOption={{
            margin: [20, 8, 0, 0]
        }}
        onClick={() => null}
    />)

    return <List
        id={'blindsetting'}
        className={'settinglist'}
        containerOption={{
            widthPx: 420,
            heightPx: 400,
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            float: 'right',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 420,
            heightPx: 64,
            lineHeight: 64,
            color: '#fff',
            fontSize: 24,
            borderSize: 2,
            borderBottom: `solid #fff`,
            whiteSpace: 'nowrap'
        }}
        components={buildItemList}
    />
});

export default BlindSetting;