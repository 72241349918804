import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import {
    getPlayerNameFontsize
} from './helper';

const PokerTablePlayerName = observer(({
    pos,
    player,
    containerOption
}: any) => {
    const renderName = player?.name || '-';

    return <Container
        id={`seat-${pos}-name`}
        containerOption={{
            widthPx: 160,
            heightPx: 16,
            lineHeight: 16,
            color: player?.me === true ? '#1ece1e' : '#fff',
            margin: [10, 0, 0, 0],
            float: 'right',
            textAlign: 'right',
            fontSize: getPlayerNameFontsize((renderName).length),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            ...(containerOption || {})
        }}
    >
        {renderName}
    </Container>
});

export default PokerTablePlayerName;