import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../../controls';

const PlayerEmptySeat = observer(({
    sdk,
    onNextItem
}: any) => {
    const gameSession = sdk?.appState?.currentSession;
    const buildItemList = [
        <ConfiguratorItem
            label={'text.overlay.admin.place'}
            itemOption={{
                widthPx: 400,
                heightPx: 64,
                margin: [0, 0, 0, 16]
            }}
            onClick={() => {
                if (
                    gameSession?.overlay?.overlayObj &&
                    gameSession?.currentMatch
                ) {
                    gameSession.openOverlaySubmenu('placeplayer')
                }
            }}
        />,
        <ConfiguratorItem
            label={gameSession?.isSeatOpen(gameSession?.overlay?.overlayObj?.pos) ?
                'text.overlay.admin.seat.close' :
                'text.overlay.admin.seat.open'}
            icon={gameSession?.isSeatOpen(gameSession?.overlay?.overlayObj?.pos) ? 'unlocked' : 'locked'}
            itemOption={{
                widthPx: 400,
                heightPx: 64,
                margin: [0, 0, 0, 16]
            }}
            onClick={() => {
                if (
                    (gameSession?.overlay?.overlayObj?.pos || gameSession?.overlay?.overlayObj?.pos === 0) &&
                    gameSession?.currentMatch
                ) {
                    gameSession.adminChangeSeatState(gameSession.overlay?.overlayObj?.pos);
                    gameSession.closeOverlay();

                }
            }}
        />
    ]

    return <List
        id={'emptyseat'}
        className={'settinglist'}
        containerOption={{
            widthPx: 420,
            heightPx: 460,
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            float: 'right',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 420,
            heightPx: 64,
            lineHeight: 64,
            color: '#fff',
            fontSize: 24,
            borderSize: 2,
            borderBottom: `solid #fff`,
            whiteSpace: 'nowrap'
        }}
        components={buildItemList}
    />
});

export default PlayerEmptySeat;