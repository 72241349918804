import type AppMessageHandler from "../messagehandler";

export default function actionSendGlobal(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    action: any
): boolean {
    messageHandler.sendMessage(1012, {
        roomToken,
        playerId,
        action
    })
    return true;
}