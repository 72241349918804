import {
    observer
} from 'mobx-react-lite';
import {
    translateKey,
    convertNumberToString
} from '../../../../utils/helper';
import {
    List,
    Container,
    Icon,
    IconButton
} from '../../../controls';

const RequestEntry = ({ sdk, vote }: any) => {
    let title = vote.playerName || '';
    let subtitle = '';
    let icon = '';

    if (vote.type === 'buyin') {
        subtitle = `${translateKey('text.voting.buyin', {}, [
            ['$value', convertNumberToString(vote.data?.value || '0')]
        ])}`
        icon = 'exchange';
    } else if (vote.type === 'sitdown') {
        subtitle = `${translateKey('text.voting.sitdown')}`
        icon = 'poker-cards';
    } else if (vote.type === 'adminrequest') {
        title = `${translateKey('text.voting.adminrequest.title')}`
        subtitle = `${translateKey('text.voting.adminrequest.subtitle')}`
        icon = 'television';
    }

    return <>
        {icon ? (
            <Icon
                iconOption={{
                    float: 'left',
                    widthPx: 64,
                    heightPx: 64,
                    margin: [16, 32, 0, 0]
                }}
                source={`./data/icon/${icon}.svg`}
            />
        ) : null}
        <Container
            containerOption={{
                float: 'left',
                widthPx: 340,
                heightPx: 96,
                textAlign: 'left'
            }}
        >
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 340,
                    heightPx: 48,
                    lineHeight: 48,
                    textAlign: 'left'
                }}
            >
                {title}
            </Container>
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 340,
                    heightPx: 48,
                    lineHeight: 48,
                    fontSize: 18,
                    textAlign: 'left',
                    color: 'rgb(245, 134, 31)',
                    whiteSpace: 'nowrap'
                }}
            >
                {subtitle}
            </Container>
        </Container>
        <IconButton
            source={'./data/ui/yes.svg'}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                float: 'left',
                margin: [16, 8, 0, 8]
            }}
            onClick={() => sdk.getMessageList().adminRequestResult(vote.id, true)}
        />
        <IconButton
            source={'./data/ui/no.svg'}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                float: 'left',
                margin: [16, 8, 0, 8]
            }}
            onClick={() => sdk.getMessageList().adminRequestResult(vote.id, false)}
        />
    </>
}

const RoomRequest = observer(({
    sdk,
    onClose
}: any) => {
    const votings = sdk?.appState?.currentSession?.voteList;

    return <List
        id={'requestlist'}
        vertical={true}
        filterNullComponents={true}
        components={votings.map((vote: any) => <RequestEntry sdk={sdk} vote={vote} />)}

        listOption={{
            widthPx: 600,
            heightPx: 96,
            borderSize: 3,
            borderBottom: `solid #fff`
        }}
        containerOption={{
            margin: [0, 0, 0, 0],
            float: 'left',
            widthPx: 600,
            heightPx: 660,
            whiteSpace: 'nowrap'
        }}
    />
});

export default RoomRequest;