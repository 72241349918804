import {
    makeObservable,
    observable,
    action
} from 'mobx'

class GameUI {
    buttonNewGame: number;
    buttonGlobalBuyIn: number;
    buttonSingleBuyIn: number;
    buttonCheck: number;
    buttonMulti: number;
    buttonDeal: number;
    buttonNextPointer: number;
    buttonAllInDeal: number;
    buttonAllIn: number;
    buttonDealCard: number;
    buttonDealer: number;
    buttonShowdown: number;
    buttonStandup: number;
    buttonTable: number;
    buttonReveal: number;
    buttonIamBack: number;
    buttonHand: number; // Can the player go to the hand

    infoNextRound: number;
    flagOverlayRotated: number;

    flagBankroll: boolean;

    constructor() {
        makeObservable(this, {
            buttonNewGame: observable,
            buttonGlobalBuyIn: observable,
            buttonSingleBuyIn: observable,
            buttonCheck: observable,
            buttonMulti: observable,
            buttonDeal: observable,
            buttonAllInDeal: observable,
            buttonAllIn: observable,
            buttonNextPointer: observable,
            buttonDealCard: observable,
            buttonDealer: observable,
            buttonTable: observable,
            buttonShowdown: observable,
            buttonIamBack: observable,
            buttonStandup: observable,
            buttonReveal: observable,
            buttonHand: observable,
            infoNextRound: observable,
            flagBankroll: observable,
            flagOverlayRotated: observable,
            updateButton: action,
            updateFlag: action
        })
        this.buttonNewGame = 0;
        this.buttonNextPointer = 0;
        this.buttonGlobalBuyIn = 0;
        this.buttonSingleBuyIn = 0;
        this.buttonCheck = 0;
        this.buttonMulti = 0;
        this.buttonDeal = 0;
        this.buttonAllIn = 0;
        this.buttonAllInDeal = 0;
        this.buttonHand = 0;
        this.buttonIamBack = 0;
        this.buttonDealCard = 0;
        this.buttonTable = 0;
        this.buttonDealer = 0;
        this.buttonShowdown = 0;
        this.infoNextRound = 0;
        this.buttonStandup = 0;
        this.buttonReveal = 0;
        this.flagOverlayRotated = 0;
        this.flagBankroll = false;
    }

    updateFlag(flagName: string, value: boolean): void {
        // @ts-ignore
        this[flagName] = value;
    }

    updateButton(buttonName: string, value: any) {
        // @ts-ignore
        this[buttonName] = value;
    }

}

export default GameUI;