import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import {
    GAMELOADER_STEP
} from '../constants/gameloader';
import {
    subscribeEvent
} from './events';
import type AppMessageHandler from './messagehandler';
import {
    uuidv4,
    translateKey
} from '../utils/helper';
import {
    GAMEVIEW
} from '../constants/gameViews';

export default class AppGameLoader {
    public messageHandler: AppMessageHandler;
    public sdk: any;

    public running: boolean;

    public loadId: string;
    public loadStep: number;
    public loadStepInvoked: number;
    public loadInterval: any;
    public loadIntervalTime: number;
    public loadMinTime: number;

    public loadAttempt: number;
    public loadError: number | undefined;

    public subscription: any;

    // public loadReturnTo: number;

    constructor(sdk: any, messageHandler: AppMessageHandler) {
        console.log('### <<< CREATE LOADER >>> ###');
        makeObservable(this, {
            running: observable,
            loadError: observable,
            loadStep: observable,
            startLoading: action,
            forceStartLoading: action,
            finishLoading: action,
            handleConnectionClosed: action,
            finishLoadingWithError: action,
            checkWebsocketConnection: action,
            finishLoadingAndReturn: action,
            isActive: computed,
            hasError: computed,
            loadingName: computed
        })
        this.sdk = sdk;
        this.running = false;
        this.loadStepInvoked = 0;
        this.loadAttempt = 0;
        this.loadError = undefined;
        this.loadStep = GAMELOADER_STEP.CHECK_WEBSOCKET_CONNECTION;
        this.loadInterval = undefined;
        this.loadIntervalTime = 150;
        this.loadMinTime = 0;
        this.messageHandler = messageHandler;
        this.loadId = '';

        // Subscribe to the new gamehandling
        this.subscription = subscribeEvent('connection.closed', (msg: any) => this.handleConnectionClosed(msg?.detail))
    }

    public startLoading(minLoadingTime = 0, force = false): void {
        if(this.running && !force) {
            return;
        }
        console.log('<<<<<<<<<<<<<<<<<<<START LOADING')

        this.running = true;
        this.loadError = undefined;
        this.loadAttempt = 0;
        this.loadId = uuidv4();
        this.loadMinTime = minLoadingTime;
        this.loadStepInvoked = 0; // how often was the nextStep invoked?
        this.loadStep = GAMELOADER_STEP.CHECK_WEBSOCKET_CONNECTION;
        this.loadInterval = setInterval(() => {
            console.log('START WITH INTERVAL')
            this.nextStep();
        }, this.loadIntervalTime);

        console.log('INTERVAL ===== ', this.loadInterval)
    }

    public forceStartLoading(minLoadingTime = 0, force:boolean = false) {
        if(this.running && !force) {
            console.log('Not loading, because already running')
            return;
        }
        console.log('<<<<<<<<<<<<<<<<<<<FORCE START LOADING', minLoadingTime)
        if (this.loadId) {
            this.sdk.appState?.currentSession?.clearConnectionCheck(this.loadId)
            this.sdk.appState?.currentSession?.clearRejoinCheck(this.loadId)

        }
        this.startLoading(minLoadingTime, force);
    }

    public handleConnectionClosed(message: any = {}) {
        const {
            reason,
            // attempt
        } = message;
        console.log('#### CONNECTION CLOSED => ', {
            reason,
            step: this.loadStep,
            stepInvoked: this.loadStepInvoked
        })
        if (this.loadStepInvoked === 0 && this.loadStep === GAMELOADER_STEP.CHECK_WEBSOCKET_CONNECTION) {
            console.log('## ERROR HAPPENS DURING INIT => TRY A RECONNECT')
        } else {
            this.loadError = 404;
            this.loadStep = GAMELOADER_STEP.ERROR;
        }
    }

    public clearInterval(): void {
        console.log('clearInterval', this.loadInterval)
        if (this.loadInterval) {
            clearInterval(this.loadInterval);
            this.loadInterval = undefined;
            console.log('cleared')
        }
    }

    public nextStep(): void {
        this.loadStepInvoked += 1;
        // console.log(`[GAMELOADER] CheckStep ${this.loadStep} - Invoked: ${this.loadStepInvoked}`)

        if (this.loadStepInvoked > 66) {
            console.log('TIMEOUT INVOKED')
            this.finishLoadingAndReturn();
            return;
        }

        switch (this.loadStep) {
            case GAMELOADER_STEP.CHECK_WEBSOCKET_CONNECTION:
                return this.checkWebsocketConnection();
            // Check if we have a gamesession. If we find a session, reconnect
            case GAMELOADER_STEP.CHECK_CURRENT_GAMESESSION:
                const sessionResult = this.sdk.appState.checkGameSession(this.loadId)
                // console.log('checkResult', sessionResult)

                if (sessionResult === 100) {
                } else {
                    this.sdk.appState?.currentSession?.clearConnectionCheck(this.loadId)

                    switch (sessionResult) {
                        // The connection is valid, go back to the game
                        case 200:
                            this.sdk.appState?.currentSession?.onResume();
                            this.finishLoading(GAMEVIEW.GAME);
                            break;
                        // The connection is incorrect.( by the logic <> differente cid ) Invalid credentials. Try a relogin
                        case 401:
                            this.loadStep = GAMELOADER_STEP.CHECK_RELOGIN;
                            break;
                        // TIMEOUT
                        case 408:
                            this.finishLoadingWithError(408);
                            break;
                        // The game is gone
                        case 410:
                            this.finishLoadingAndReturn();
                            break;
                        // The connection is incorrect. If we receive an invalid result remove the session
                        default:
                            // this.finishLoadingAndReturn(true); // Return to the mainmenu
                            this.finishLoadingAndReturn(); // Return to the mainmenu
                            break;
                    }
                }
                break;
            case GAMELOADER_STEP.CHECK_RELOGIN:
                const rejoinResult = this.sdk.appState.tryGameRejoin(this.loadId)
                if (rejoinResult === 100) {
                } else {
                    this.sdk.appState?.currentSession?.clearRejoinCheck(this.loadId, true)
                    if (rejoinResult === 200) {
                        // Relogin accepted, finish the loading
                        this.sdk.appState?.currentSession?.onResume();
                        this.finishLoading(GAMEVIEW.GAME);
                    } else if (rejoinResult === 408) {
                        this.finishLoadingWithError(408);
                    } else {
                        this.finishLoadingAndReturn(); // Return to the mainmenu
                    }
                }
                break;
            case GAMELOADER_STEP.PRE_FINISHED:
                // console.log('PREFINISH', {
                //     invoked: this.loadStepInvoked,
                //     time: this.loadIntervalTime,
                //     minTime: this.loadMinTime
                // })
                if (this.loadStepInvoked * this.loadIntervalTime >= this.loadMinTime || this.loadIntervalTime === 0) {
                    this.finishLoading();
                }
                break;
            case GAMELOADER_STEP.ERROR:
                if (this.loadInterval) {
                    clearInterval(this.loadInterval);
                }
                break;
            default:
                break;
        }
    }

    public checkWebsocketConnection(): void {
        if (this.messageHandler.connected) {
            if (!this.messageHandler.isValidating) {
                if (this.messageHandler.connectionValid) {
                    // Goto the next step and check if a valid gamesession is available
                    this.loadStep = GAMELOADER_STEP.CHECK_CURRENT_GAMESESSION;
                }
            }
        } else {
            if (this.messageHandler.failedAuthorisation) {
                // finish with errorcode 401 - not authorised
                this.finishLoadingWithError(401);
            } else {
                if (!this.messageHandler.connecting) {
                    this.loadAttempt += 1;
                    if (this.loadAttempt < 4) {
                        this.messageHandler.connect(this.loadAttempt);
                    } else {
                        this.finishLoadingWithError(404);
                    }
                }
            }
        }
    }

    // Return to the mainmenu
    public finishLoadingAndReturn(): void {
        console.log('finishLoadingAndReturn')

        // Destroy the session if available
        this.sdk.appState.destroySession();
        this.loadStep = GAMELOADER_STEP.PRE_FINISHED;
    }

    public finishLoading(returnTo: number | undefined = undefined): void {
        console.log('finishLoading', returnTo)
        this.running = false;
        this.loadError = undefined;
        this.loadStep = GAMELOADER_STEP.FINISHED;
        this.clearInterval();

        // Where to go?
        if (returnTo !== undefined) {
            this.sdk.appState.openGameView();
        } else {
            // Check if the view is in the default view
            if (this.sdk.appState.inDefaultView) {
                if (this.sdk?.appState?.user?.hasUser) {
                    this.sdk.appState.openMainMenu();
                }
            } else if (this.sdk.appState.view !== GAMEVIEW.GAME) {
                console.log('be there where you are!')
            } else if (this.sdk.appState.view === GAMEVIEW.GAME) {
                this.sdk.appState.openMainMenu();
            }
        }

    }

    public finishLoadingWithError(errorCode: number): void {
        console.log('finishLoadingWithError', errorCode)
        this.running = false;
        this.loadError = errorCode;
        this.loadStep = GAMELOADER_STEP.ERROR;

        this.clearInterval();
        // TODO: FAILED AUTHORIZATION
    }

    // Computed
    get isActive() {
        return this.running || !!this.loadError;
    }

    get hasError(): boolean {
        return !!this.loadError
    }

    get loadingName(): string | undefined {
        switch (this.loadStep) {
            case GAMELOADER_STEP.CHECK_WEBSOCKET_CONNECTION:
                return translateKey('text.gameloader.connecting')
            case GAMELOADER_STEP.CHECK_CURRENT_GAMESESSION:
                return translateKey('text.gameloader.connecting')
            case GAMELOADER_STEP.CHECK_RELOGIN:
                return translateKey('text.gameloader.connecting')
            case GAMELOADER_STEP.PRE_FINISHED:
                return translateKey('text.gameloader.connecting')
            case GAMELOADER_STEP.ERROR:
                if (this.loadError === 401) {
                    return translateKey('text.gameloader.unauthorized')
                } else if (this.loadError === 404) {
                    return translateKey('text.gameloader.unavailable')
                } else if (this.loadError === 408) {
                    return translateKey('text.gameloader.timeout')
                }
                return translateKey('text.gameloader.error')
            default:
                break;
        }
        return undefined;
    }

}