import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../../controls';

const RoomSetting = observer(({
    sdk,
    onNextItem
}: any) => {
    const gameSession = sdk?.appState?.currentSession;
    const votingNotice = (gameSession?.voteList || []).length && gameSession.admin;

    const buildItemList = [];

    if (gameSession?.admin) {
        // Show Button to extend the demo
        if (gameSession?.demo) {
            buildItemList.push(
                <ConfiguratorItem
                    icon={'vip'}
                    iconOption={{
                        margin: [20, 8, 0, 0]
                    }}
                    label={'text.overlay.admin.demo.extend'}
                    itemOption={{
                        widthPx: 400,
                        heightPx: 64,
                        margin: [0, 0, 0, 16],
                        color: '#e2574c'
                    }}
                    onClick={() => {
                        sdk.appState.showPremiumBox('demo.extend', null)
                        gameSession.closeOverlay();
                    }}
                />
            );
        }


        buildItemList.push(
            <ConfiguratorItem
                icon={gameSession?.adminRoomOpen ? 'unlocked' : 'locked'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                label={gameSession?.adminRoomOpen ?
                    'text.overlay.admin.close.room' :
                    'text.overlay.admin.open.room'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                onClick={() => {
                    if (
                        gameSession
                    ) {
                        gameSession.adminSwitchRoomOpenState()
                    }
                }}
            />
        )

        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.admin.waitingarea'}
                icon={'waitarea'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16],
                    color: '#fff'
                }}
                onClick={() => gameSession.openOverlaySubmenu('roomwaitarea')}
            />
        )

        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.admin.roomrequest'}
                icon={votingNotice ? 'important' : 'important-w'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16],
                    color: votingNotice ? '#e2574c' : '#fff'
                }}
                onClick={() => gameSession.openOverlaySubmenu('roomrequest')}
            />
        )

        buildItemList.push(
            <ConfiguratorItem
                label={gameSession?.tableConfig?.flagHideRoomCode ?
                    'text.overlay.admin.showroomcode' :
                    'text.overlay.admin.hideroomcode'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={gameSession?.tableConfig?.flagHideRoomCode ? 'hidden' : 'visible'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    if (
                        gameSession
                    ) {
                        gameSession.adminHideRoomCode()
                    }
                }}
            />
        )

        if (gameSession?.isDigitalCardMode) {
            buildItemList.push(
                <ConfiguratorItem
                    label={gameSession?.tableConfig?.flagShowBlindNotice ?
                        'text.overlay.admin.hideblindnotice' :
                        'text.overlay.admin.showblindnotice'}
                    itemOption={{
                        widthPx: 400,
                        heightPx: 64,
                        margin: [0, 0, 0, 16]
                    }}
                    icon={gameSession?.tableConfig?.flagShowBlindNotice ? 'visible' : 'hidden'}
                    iconOption={{
                        margin: [20, 8, 0, 0]
                    }}
                    onClick={() => {
                        if (
                            gameSession
                        ) {
                            gameSession.adminSwitchBlindNotice()
                        }
                    }}
                />
            )
        }

        buildItemList.push(
            <ConfiguratorItem
                label={gameSession?.tableConfig?.flagRabbitCards ?
                    'text.overlay.admin.disable.rabbithunt' :
                    'text.overlay.admin.enable.rabbithunt'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                // icon={gameSession?.tableConfig?.flagRabbitCards ? 'visible' : 'hidden'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    if (
                        gameSession
                    ) {
                        gameSession.adminSwitchRabbitHunt()
                    }
                }}
            />
        )

        buildItemList.push(
            <ConfiguratorItem
                label={gameSession?.tableConfig?.flagForbidBuyIn ?
                    `text.overlay.admin.allow.${gameSession?.tableConfig?.buyIn?.type}` :
                    `text.overlay.admin.forbid.${gameSession?.tableConfig?.buyIn?.type}`}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={gameSession?.tableConfig?.flagForbidBuyIn ? 'decline' : 'accept'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    if (
                        gameSession
                    ) {
                        gameSession.adminForbidBuyIn()
                    }
                }}
            />
        )

        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.admin.restartround'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={'refresh'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    sdk?.appState.showMessagebox('adminrestart', 'dialog.admin.restartround', () => {
                        if (
                            gameSession
                        ) {
                            gameSession.adminRestartRound()
                        }
                    }, () => null, true);
                }}
            />
        )
        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.admin.resetmatch'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={'refresh'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    sdk?.appState.showMessagebox('adminreset', 'dialog.admin.resetmatch', () => {
                        if (
                            gameSession
                        ) {
                            gameSession.adminResetMatch()
                        }
                    }, () => null, true);
                }}
            />
        )

        if (gameSession?.currentMatch?.flagAllowSwitchWinDetection) {
            buildItemList.push(
                <ConfiguratorItem
                    label={gameSession?.adminWinDetection ?
                        'text.overlay.admin.disable.windetection' :
                        'text.overlay.admin.enable.windetection'}
                    itemOption={{
                        widthPx: 400,
                        heightPx: 64,
                        margin: [0, 0, 0, 16]
                    }}
                    icon={gameSession?.adminWinDetection ? 'krone-y' : 'krone'}
                    iconOption={{
                        margin: [20, 8, 0, 0]
                    }}
                    onClick={() => {
                        if (
                            gameSession?.currentMatch
                        ) {
                            gameSession.currentMatch.adminChangeWinDetection()
                        }
                    }}
                />
            )
        }
        if (gameSession?.isDigitalCardMode) {
            buildItemList.push(
                <ConfiguratorItem
                    label={gameSession?.tableConfig?.flagUiTable ?
                        'text.overlay.admin.disable.tablebutton' :
                        'text.overlay.admin.enable.tablebutton'}
                    itemOption={{
                        widthPx: 400,
                        heightPx: 64,
                        margin: [0, 0, 0, 16]
                    }}
                    // icon={gameSession?.adminWinDetection ? 'krone-y' : 'krone'}
                    iconOption={{
                        margin: [20, 8, 0, 0]
                    }}
                    onClick={() => {
                        if (
                            gameSession?.currentMatch
                        ) {
                            gameSession.currentMatch.adminChangeTableUi()
                        }
                    }}
                />
            )

            buildItemList.push(
                <ConfiguratorItem
                    label={'text.overlay.admin.missdeal'}
                    itemOption={{
                        widthPx: 400,
                        heightPx: 64,
                        margin: [0, 0, 0, 16]
                    }}
                    iconImage={'./data/ui/missdeal.png'}
                    iconOption={{
                        margin: [20, 8, 0, 0]
                    }}
                    onClick={() => {
                        if (gameSession) {
                            gameSession.adminMissdeal()
                        }
                    }}
                />
            )
        }
    }

    if (gameSession?.tableAdsEnabled && !gameSession?.demo) {
        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.buy.adfree'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16],
                    color: '#e2574c'
                }}
                icon={'noads'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    sdk?.appState.showMessagebox('adfree', 'dialog.table.upgrade.adfree', () => {
                        if (
                            gameSession
                        ) {
                            gameSession.buyAdFree();
                        }
                    }, () => null, true, [], false, {
                        coinValue: 10
                    }, 'coin');
                }}
            />
        )
    }

    if (gameSession.tableConfig.flagDisableHistory !== 1) {
        buildItemList.push(
            <ConfiguratorItem
                label={'text.overlay.history.show'}
                itemOption={{
                    widthPx: 400,
                    heightPx: 64,
                    margin: [0, 0, 0, 16]
                }}
                icon={'history'}
                iconOption={{
                    margin: [20, 8, 0, 0]
                }}
                onClick={() => {
                    sdk?.appState?.openGameHistory();
                }}
            />
        )
    }

    // buildItemList.push(<ConfiguratorItem
    //     label={`${translateKey('text.overlay.admin.smallblind', {}, [[
    //         '$value', gameSession?.currentMatch?.smallBlind || 0
    //     ]])}`}
    //     itemOption={{
    //         widthPx: 400,
    //         heightPx: 64,
    //         margin: [0, 0, 0, 16],
    //         color: '#56d4fd'
    //     }}
    //     onClick={() => null}
    // />)

    // buildItemList.push(<ConfiguratorItem
    //     label={`${translateKey('text.overlay.admin.bigblind', {}, [[
    //         '$value', gameSession?.currentMatch?.bigBlind || 0
    //     ]])}`}
    //     itemOption={{
    //         widthPx: 400,
    //         heightPx: 64,
    //         margin: [0, 0, 0, 16],
    //         color: '#56d4fd'
    //     }}
    //     onClick={() => null}
    // />)

    return <List
        id={'roomsetting'}
        className={'settinglist'}
        containerOption={{
            widthPx: 420,
            heightPx: 460,
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            float: 'right',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 420,
            heightPx: 64,
            lineHeight: 64,
            color: '#fff',
            fontSize: 24,
            borderSize: 2,
            borderBottom: `solid #fff`,
            whiteSpace: 'nowrap'
        }}
        components={buildItemList}
    />
});

export default RoomSetting;