import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import {
    translateKey
} from '../../../../utils/helper';

const PokerWinnerBanner = observer(({
    gameSession,
    bannerLayout
}: any) => {
    const rankCardsType = gameSession?.currentMatch?.round?.rankCardType || undefined;
    const rankCard = gameSession?.currentMatch?.round?.rankCard || [];
    const visible = rankCardsType !== undefined && rankCard.length;
    const bannerText = translateKey(`text.player.hand.${rankCardsType}`)
    let useTop = 70;
    let useLeft = 0;
    let bannerWidth = 800;
    let bannerSizeX = 640;

    if (bannerLayout === 1) {
        useLeft = 140;
        useTop = 86;
        bannerWidth = 640;
    } else if (bannerLayout === 2) {
        bannerWidth = 1000;
        useTop = 80;
        useLeft = 148;
    }

    const bannerHeight = bannerWidth / 6.63;

    return visible ?
        <Container
            containerOption={{
                position: 'absolute',
                widthPx: bannerWidth,
                heightPx: bannerHeight,
                bgImage: `url(./data/ui/banner.png)`,
                bgRepeat: 'no-repeat',
                bgImageSize: 'contain',
                zIndex: 50,
                left: useLeft,
                top: useTop
            }}
        >
            <Container
                containerOption={{
                    textAlign: 'center',
                    widthPx: bannerSizeX,
                    heightPx: bannerHeight,
                    lineHeight: bannerHeight,
                    color: '#fff',
                    fontSize: 32,
                    fontWeight: 'bold',
                    margin: [0, 0, 0, (bannerWidth - bannerSizeX) / 2]
                }}>
                {bannerText}
            </Container>
        </Container> : null;
});


export default PokerWinnerBanner;