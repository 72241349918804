import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const InputFile = observer(({
    id,
    inputOption,
    onChange,
    children
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsInput = useStyles({
        ...(inputOption || {})
    });

    return <>
        <input
            type={'file'}
            name={'file'}
            className={'inputfile'}
            style={propsInput}
            id={id}
            onChange={onChange}
        />
        <label
            htmlFor={id}
        >
            {children ? children : 'Choose'}
        </label>
    </>

});

export default InputFile;