import {
    observer
} from 'mobx-react-lite';
import {
    ImageComponent
} from '../../../controls';

const btnPosition = [
    {
        top: -16,
        left: 80
    },
    {
        top: -16,
        left: 220 //320
    },
    {
        top: -16,
        right: 270 //220
    },
    {
        top: -16,
        right: 80
    },
    {
        bottom: 128,
        right: -16
    },
    {
        bottom: -16,
        right: 80
    },
    {
        bottom: -16,
        right: 220//270
    },
    {
        bottom: -16,
        left: 320 //270
    },
    {
        bottom: -16,
        left: 80
    },
    {
        bottom: 128,
        left: -16
    }
]

const btnPositionTablet = [
    {
        top: -32,
        left: 150
    },
    {
        top: -32,
        left: 290
    },
    {
        top: -32,
        left: 420
    },
    {
        top: -32,
        right: 420
    },
    {
        top: -32,
        right: 290
    },
    {
        bottom: -32,
        right: 150
    },
    {
        bottom: -32,
        right: 290
    },
    {
        bottom: -32,
        right: 420
    },
    {
        bottom: -32,
        left: 420
    },
    {
        bottom: -32,
        left: 290
    }
]

const btnPositionTablet2 = [
    {
        top: -32,
        left: 150 - 44
    },
    {
        top: -32,
        left: 290 - 44
    },
    {
        top: -32,
        left: 420 - 44
    },
    {
        top: -32,
        right: 420 + 44
    },
    {
        top: -32,
        right: 290 + 44
    },
    {
        bottom: -32,
        right: 150 - 44
    },
    {
        bottom: -32,
        right: 290 - 44
    },
    {
        bottom: -32,
        right: 420 - 44
    },
    {
        bottom: -32,
        left: 420 + 44
    },
    {
        bottom: -32,
        left: 290 + 44
    }
]

const PokerTableDealerButton = observer(({
    gameSession,
    isTablet
}: any) => {
    const posDealerButton = gameSession?.currentMatch?.positionD;
    const posNull = gameSession.calculateSeatPosition(0);

    let useDealerPos = posDealerButton - posNull;
    if (useDealerPos < 0) {
        useDealerPos += 10;
    }
    const showButton = useDealerPos >= 0 && useDealerPos <= 9;

    return showButton ? <ImageComponent
        imageOption={{
            position: 'absolute',
            widthPx: 32,
            heightPx: 32,
            ...(isTablet ?
                gameSession.isDigitalCardMode ? btnPositionTablet[useDealerPos] : btnPositionTablet2[useDealerPos] :
                btnPosition[useDealerPos])
        }}
        source={'./data/ui/dealer.png'}
    /> : null;
})

export default PokerTableDealerButton;