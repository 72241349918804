import { useState, useEffect } from 'react';
import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../utils/helper';
import {
    Container,
    IconButton,
    GifImage
} from '../controls/index';

const TutorialDialog = observer(({ sdk }: any) => {
    const {
        appState,
        layoutManager
    } = sdk;
    const dialog = appState?.tutorialDialog;
    const tutorialList = dialog?.tutorial || [];
    const [currentTutorial, setTutorial] = useState(0);

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => {
            if (currentTutorial + 1 < tutorialList.length) {
                setTutorial(currentTutorial + 1)
            } else {
                setTutorial(0);
                appState.closeTutorialDialog()
            }
        }}
        style={{}}
    >
        <div
            id={'gamedialog_tutorial_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_tutorial_content'}
                containerOption={{
                    position: 'relative',
                    widthPx: 640,
                    heightPx: 362,
                    float: 'left',
                    border: 'solid #fff',
                    borderSize: 4,
                    zIndex: 60,
                    bgColor: '#062860',
                    margin: [(750 - 362) / 2, 0, 0, (1334 - 640) / 2]
                }}
            >
                <GifImage
                    imageOption={{
                        widthPx: tutorialList?.[currentTutorial]?.wide ? 640 : 360,
                        heightPx: 360,
                    }}
                    source={tutorialList?.[currentTutorial]?.src}
                />
                <Container
                    containerOption={{
                        textAlign: 'center',
                        position: 'absolute',
                        widthPx: 640,
                        heightPx: 64,
                        bgColor: 'rgb(0,0,0,0.7)',
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: '#fff',
                        bottom: 0
                    }}
                >
                    <Container
                        containerOption={{
                            widthPx: 640,
                            heightPx: 48,
                            lineHeight: 48,
                            fontSize: 24
                        }}
                    >
                        {<div id={'tutorial_dialog_b'} dangerouslySetInnerHTML={{
                            __html: translateKey(tutorialList?.[currentTutorial]?.text)
                        }} />}
                    </Container>
                    <Container
                        containerOption={{
                            widthPx: 640,
                            heightPx: 16,
                            lineHeight: 16,
                            fontSize: 18
                        }}
                    >
                        {currentTutorial + 1}/{tutorialList?.length || 0}
                    </Container>
                </Container>
            </Container>
            <Container
                id={'gamedialog_tutorial_content2'}
                containerOption={{
                    position: 'relative',
                    float: 'left',
                    widthPx: 640,
                    heightPx: 96,
                    margin: [16, 0, 0, (1334 - 640) / 2]
                }}
            >
                <IconButton
                    id={'tutorial.clicker'}
                    source={'./data/ui/yes.svg'}
                    // enableDelay={dialog?.disableTimer ? false : 2500}
                    enableDelay={false}
                    className={'iconbutton_refresh'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [16, 32, 0, 0]
                    }}
                    onClick={(event:any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (currentTutorial + 1 < tutorialList.length) {
                            setTutorial(currentTutorial + 1)
                        } else {
                            setTutorial(0);
                            appState.closeTutorialDialog()
                        }
                    }}
                />
            </Container>
        </div>
    </div > : null;
});

export default TutorialDialog;