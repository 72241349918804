import {
    triggerEvent
} from './events';
import {
    uuidv4
} from '../utils/helper';
import {
    WEBSOCKET_SETTINGS
} from '../constants/websocket';
import type {
    CouchgamesSdk
} from './index';

const timestamp = () => {
    return Math.round(new Date().getTime() / 1000);
}

/**
 * TODO:
 * - Maintenance
 * - AppVersionCode
 * - GameService
 * - ReactApp Type, Version => Buildsteps
 * - createClientToken
 */

interface ConfigWebsocket {
    url: string;
}

const DEFAULT_WEBSOCKETURL: string = 'wss://websocket.couchgames.wtf/';

export default class AppMessageHandler {
    sdk: CouchgamesSdk;
    connection: WebSocket | undefined;
    connectionUrl: string;
    sessionKey: string | undefined;

    connectionError: boolean;
    connectionValid: boolean;
    connectionValidateTimeout: any | undefined;
    connectTimeout: any | undefined;
    connecting: boolean;
    connected: boolean;
    connectionAttempt: number;
    connectionFailedAuthorization: boolean;
    connectionOpened: number | undefined;

    constructor(sdk: CouchgamesSdk, config: ConfigWebsocket) {
        this.sdk = sdk;
        this.connection = undefined;
        this.connectionUrl = config?.url || DEFAULT_WEBSOCKETURL;
        this.sessionKey = undefined;

        this.connectionError = false;
        this.connectionValid = false;
        this.connectionValidateTimeout = undefined;
        this.connectTimeout = undefined;
        // this.wssValidateTimeout = undefined;
        this.connectionAttempt = 0;
        this.connecting = false;
        this.connected = false;
        this.connectionFailedAuthorization = false;
        this.connectionOpened = undefined;

    }

    onPause(): void {
        if(this.connection) {
            console.log('CLOSE CONNECTION')
            this.connection?.close()
        }
    }

    handleMessage(message: any) {
        if (!message) return;
        let rawMessage = undefined;

        try {
            rawMessage = JSON.parse(message);
        } catch (e) { }

        // Only handle the message when a type is presented
        if (rawMessage?.type) {
            const type = parseInt(rawMessage.type, 10);
            switch (type) {
                case 2:
                    if (rawMessage?.maintenance) {
                        this.connectionError = true;
                        //@ts-ignore
                        this.connection.maintenanceMode = true
                        triggerEvent('connection.maintenance', true);
                    } else {
                        const wasError = this.connectionError;
                        this.connectionError = false;
                        this.connectionValid = true;

                        if (this.connectionValidateTimeout) {
                            clearTimeout(this.connectionValidateTimeout);
                            this.connectionValidateTimeout = undefined;
                        }
                        const buildConfig = {
                            gameConfig: rawMessage?.appConfig || {},
                            gameUrl: rawMessage?.gameUrl || '',
                            apiUrl: rawMessage?.apiUrl || '',
                            accountUrl: rawMessage?.gameAccountUrl || '',
                            mediaUrl: rawMessage?.mediaUrl || ''
                        }

                        if(this.sdk) {
                            this.sdk.appConfig = buildConfig;
                            if(this.sdk.xp) {
                                this.sdk.xp.onHandleXP(rawMessage?.xp)
                            }
                            if(this.sdk.appPublic) {
                                this.sdk.appPublic.onHandleData(rawMessage?.public)
                            }
                        }

                        triggerEvent('connection.open', {
                            config: buildConfig,
                            version: rawMessage?.version,
                            versionCode: rawMessage?.versionCode,
                            news: rawMessage?.gameNews || [],
                            wasError
                        })
                    }

                    // this.sdk.callback(CGCallback.onHandleMessage, type, rawMessage)
                    break;
                default:
                    triggerEvent('message.handle', {
                        type,
                        rawMessage
                    })
                    triggerEvent(`message.handle.${type}`, {
                        rawMessage
                    })

                    break;
            }
        }
    }

    get currentVersionCode() {
        let currentAppVersion = process.env.REACT_APP_VERSIONCODE || 'APPVERSION_CODE';
        if (currentAppVersion.includes('APPVERSION_CODE')) return 99999;
        return parseInt(currentAppVersion, 10);
    }

    /**
     * Connect to the CouchgamesWtf Platform
     */
    connect(attempt = 0) {
        if (this.connection) {
            try {
                this.connection.close()
            } catch(e) {
                console.log('Closing not working', e)
            }
            this.connection = undefined;
        }

        // Authentication use serviceName, versionString, versionCode, and app
        this.connection = new WebSocket(`${this.connectionUrl}?s=${process.env.REACT_APP_GAMESERVICE}&v=${this.currentVersionCode}&t=${process.env.REACT_APP_TYPE}&c=${this.createClientToken()}`);
        this.connection.binaryType = 'blob';

        this.connecting = true;
        this.connectionAttempt = attempt;
        this.connectionFailedAuthorization = false;
        const wssLink = this;

        this.connectTimeout = setTimeout(() => {
            wssLink.connection?.close();
            // Trigger callback onConnectionTimeout to indicate that the connection failed, pass the time of connection attempts
            triggerEvent('connection.closed', {
                reason: 'timeout',
                attempt: attempt
            })
        }, WEBSOCKET_SETTINGS.CONNECT_TIMEOUT);

        this.connection.onopen = (event) => {
            if (this.connectTimeout) {
                clearTimeout(this.connectTimeout);
                this.connectTimeout = undefined;
            }

            // Create a validation error
            this.connectionValidateTimeout = setTimeout(() => {
                wssLink.connectionFailedAuthorization = true;
                wssLink.connection?.close();
                console.log('CLOSE!')
            }, WEBSOCKET_SETTINGS.VALIDATION_TIMEOUT);

            this.connected = true;
            this.connectionValid = false;
            this.connecting = false;
            this.connectionOpened = timestamp();

        };

        this.connection.onclose = (event) => {
            console.log('############# CLOSING THE WEBSOCKET SO CONNECTED SHOULD FALSE');
            if (this.connectTimeout) {
                clearTimeout(this.connectTimeout);
                this.connectTimeout = undefined;
            }
            // @ts-ignore
            const isMaintenance = this.connection?.maintenanceMode || false; // TODO
            this.connecting = false;
            this.connected = false;
            this.connectionValid = false;

            this.onClose(isMaintenance ? { code: 99999 } : event);
        };

        this.connection.onerror = (errorEvent) => {
            console.log('#############  ERROR')
            if (this.connectTimeout) {
                clearTimeout(this.connectTimeout);
                this.connectTimeout = undefined;
            }
            this.connecting = false;
            this.connected = false;
            this.connectionValid = false;
            this.onError(errorEvent);
        };

        this.connection.onmessage = (event) => this.handleMessage(event ? event.data : undefined);
    }

    reset() {
        this.connectionFailedAuthorization = false;
    }

    sendMessage(type: number, data: any): boolean {
        if (this.connection && this.connection?.readyState === this.connection?.OPEN) {
            data = {
                type,
                sessionKey: this.sessionKey,
                ...data
            }

            this.connection.send(JSON.stringify(data));

            return true;
        }

        return false;
    }

    createClientToken():string {
        let uuidAuthToken = this.sdk.loadFromStorage('auth')?.token || '';

        if (!uuidAuthToken) {
            uuidAuthToken = uuidv4();
            this.sdk.saveInStorage('auth', {
                token: uuidAuthToken
            });
        }
        return uuidAuthToken;
    }

    onClose(closeEvent: any) {
        console.log('CLOSE', closeEvent, closeEvent.code);
        this.sessionKey = undefined;

        // MAINTENACE RETURN
        if (closeEvent?.code === 99999) {
            return;
        }

        // Show Error
        if (closeEvent?.code === 1006) {
            this.connectionError = true;
        }

        // Trigger callback onConnectionTimeout to indicate that the connection failed, pass the time of connection attempts
        triggerEvent('connection.closed', {
            attempt: this.connectionAttempt,
            reason: 'closed',
            error: this.connectionError
        });
    }

    onError(error: any) {
        console.log('ERROR', error)
        console.log('ERROR MESSAGE', error?.message)
        console.log('ERROR CODE', error?.code)
        this.sessionKey = undefined;
    }

    get authenticated() {
        return this.connection?.readyState === 1 && this.sessionKey;
    }

    get failedAuthorisation() {
        return this.connectionFailedAuthorization;
    }

    get isValidating() {
        return this.connectionValidateTimeout ? true : false;
    }

}