import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ImageComponent
} from '../../../controls';
import {
    getRenderIcon
} from './helper';
import {
    getAvatarImage
} from '../../../../utils/helper';

const PokerTablePlayerWaitingTime = observer(({
    gameSession,
    player
}: any) => {

    const useCurrentPlayer = gameSession?.currentMatch?.round?.currentPlayer;

    const visible = !gameSession?.isDigitalCardMode && useCurrentPlayer === player?.id;

    return visible ?
        <Container
            containerOption={{
                position: 'absolute',
                top: 16,
                left: 12,
                zIndex: 10,
                widthPx: 64,
                heightPx: 64,
                textAlign: 'center',
                lineHeight: 64,
                textShadow: 10,
                textShadowColor: '#000',
                color: gameSession?.currentMatch?.round?.moveTimer?.remainingTime < 10 ? 'red' : '#fff',
                fontWeight: 'bold',
                fontSize: 28
            }}
        >
            {gameSession?.currentMatch?.round?.moveTimer?.timerDisplay}
        </Container> :
        null
})
const getAvatarButton = (folded: any, seatMessage: any) => {
    if (folded) return 'foldbutton';
    if (seatMessage) {
        if (seatMessage?.color === 'red') {
            return 'foldbutton';
        } else if (seatMessage?.color === 'orange') {
            return 'allinbutton';
        } else if (seatMessage?.color === 'yellow') {
            return 'betbutton';
        } else if (seatMessage?.color === 'green') {
            return 'checkbutton';
        }
    }
    return undefined;
}
const getAvatarButtonActionText = (folded: any, seatMessage: any) => {
    if (folded) return './data/ui/action_fold.png';
    if (seatMessage) {
        return `./data/ui/action_${seatMessage?.message}.png`
    }
    return undefined;
}

const PokerTablePlayerAvatar = observer(({
    pos,
    player,
    containerOption,
    avatarBackgroundOption,
    children,
    gameSession,
    isTablet = false,
    iconPosition = 'bottom'
}: any) => {
    const playerFolded = player?.matchData?.folded || false;
    let avatarBackground = getAvatarButton(playerFolded, gameSession?.currentSeatMessage?.get(player?.id));
    let avatarBackgroundActionText = getAvatarButtonActionText(playerFolded, gameSession?.currentSeatMessage?.get(player?.id));

    const renderIcon = getRenderIcon(player?.matchData?.role || gameSession?.currentMatch?.getSeatRole(player.seat) || undefined);
    const avatarImage = getAvatarImage(player?.avatar || undefined);
    let renderSubIcon = null; //player?.blindActor || false;

    if (!player?.active) {
        renderSubIcon = {
            icon: './data/icon/logout.svg'
        }
    } else if (player?.waiting) {
        renderSubIcon = {
            icon: './data/icon/waitingclock.svg'
        }
    } else if (player?.matchData?.assignable === false) {
        renderSubIcon = {
            icon: './data/icon/nocards.svg'
        }
    } else if (player?.blindActor) {
        renderSubIcon = {
            icon: './data/ui/blindmode.png'
        }
    }

    return <Container
        id={`seat-${pos}-avatar`}
        containerOption={{
            position: 'relative',
            widthPx: 90,
            heightPx: 90,
            margin: [12, 4, 0, 0],
            float: 'right',
            ...(avatarBackground ? {
                bgImage: `url('./data/ui/${avatarBackground}.png')`,
                bgRepeat: 'no-repeat',
                bgImageSize: avatarImage?.custom ? 'cover' : 'contain'
            } : {
                bgImage: `url(${avatarImage.src})`,
                borderRadiusPercent: avatarImage.circle ? '50' : undefined,
                bgRepeat: 'no-repeat',
                bgImageSize: avatarImage?.custom ? 'cover' : 'contain'
            }),
            ...(containerOption || {})
        }}
    >
        {avatarBackground ? (
            <Container
                id={`seat-${pos}-avatar2`}
                containerOption={{
                    position: 'absolute',
                    top: 11,
                    left: 11,
                    widthPx: 68,
                    heightPx: 68,
                    bgImage: `url(${avatarImage.src})`,
                    borderRadiusPercent: avatarImage.circle ? '50' : undefined,
                    bgRepeat: 'no-repeat',
                    bgImageSize: 'contain',
                    float: 'right',
                    ...(avatarBackgroundOption || {})
                }}
            >
                {avatarBackgroundActionText ?
                    <ImageComponent
                        imageOption={{
                            position: 'absolute',
                            widthPx: 68,
                            heightPx: 68,
                            left: isTablet ? 8 : 0,
                            bottom: isTablet ? 32 : 0
                        }}
                        source={avatarBackgroundActionText}
                    />
                    : null}

            </Container>
        ) : null}
        {renderIcon ? <ImageComponent
            imageOption={{
                position: 'absolute',
                widthPx: 32,
                right: 0,
                bottom: iconPosition === 'bottom' ? 0 : undefined,
                top: iconPosition === 'top' ? 0 : undefined,
                ...(renderIcon.options || {})
            }}
            source={renderIcon.source}
        /> : null}
        {renderSubIcon ? <ImageComponent
            imageOption={{
                position: 'absolute',
                widthPx: 28,
                heightPx: 28,
                left: 0,
                bottom: 0
            }}
            source={renderSubIcon.icon}
        /> : null}
        {children ?
            children :
            <PokerTablePlayerWaitingTime
                gameSession={gameSession}
                player={player}
            />
        }
    </Container>
});

export default PokerTablePlayerAvatar;