import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react';
import {
    OptionTextbox
} from '../../../controls';
import GameOverlaySaveBar from '../GameOverlaySaveBar';


const PlayerBankroll = observer(({
    sdk,
    onClose
}: any) => {
    const player = sdk?.appState?.currentSession?.overlay?.overlayObj || undefined;
    const [bankroll, setBankroll] = useState(player?.matchData?.bankroll || 0);
    return <>
        <OptionTextbox
            id={'config-bankroll'}
            label={'input.lobby.bankroll'}
            value={bankroll}
            inputType={'number'}
            onInput={(txt: any) => setBankroll(parseInt(txt, 10))}
            containerOption={{
                margin: [16, 0, 0, 140],
                float: 'left'
            }}
        />
        <GameOverlaySaveBar
            onSave={() => {
                if (sdk?.appState?.currentSession?.currentMatch) {
                    sdk.appState.currentSession.currentMatch.adminSaveBankroll(player.id, bankroll);
                }
                onClose()
            }}
            onCancel={() => {
                onClose()
            }}
            containerOption={{
                margin: [460, 0, 0, 0]
            }}
        />
    </>
});

export default PlayerBankroll;