import {
    LANGUAGE,
    SUPPORTED_LANGUAGES
} from '../../constants/language';
import {
    makeObservable,
    observable,
    action
} from 'mobx'
import type {
    CouchgamesSdk
} from '../index';
import {
    MOVE_SENSIBILITY,
    FOLD_SENSIBILITY_VALUE,
    REVEAL_SENSIBILITY_VALUE
} from '../../constants/movesensibility';

export default class AppOptions {
    public language: string;
    public vibration: boolean;
    public backgroundColor: string;
    public fontColor: string;
    public fontSize: number;
    public tutorialWatched: Array<string>;
    public handFoldSensitive: number;
    public handRevealSensitive: number;
    public dummyFoldDisabled: number;
    public dummyRevealDisabled: number;
    private sdk: CouchgamesSdk;

    constructor(sdk: CouchgamesSdk) {
        makeObservable(this, {
            language: observable,
            vibration: observable,
            tutorialWatched: observable,
            handFoldSensitive: observable,
            handRevealSensitive: observable,
            dummyFoldDisabled: observable,
            dummyRevealDisabled: observable,
            switchLanguage: action,
            switchHandRevealSensibility: action,
            switchHandFoldSensibility: action,
            switchRevealSecurity: action,
            saveTutorialWatched: action,
            switchFoldSecurity: action,
            switchVibration: action,
            initOptions: action,
            updateValue: action
        })
        this.sdk = sdk;
        this.language = this.initLanguage(sdk);
        this.vibration = true;
        this.backgroundColor = 'black';
        this.fontColor = 'white';
        this.fontSize = 1;
        this.dummyFoldDisabled = 0;
        this.dummyRevealDisabled = 0;
        this.tutorialWatched = [];
        this.handFoldSensitive = MOVE_SENSIBILITY.DEFAULT;
        this.handRevealSensitive = MOVE_SENSIBILITY.DEFAULT;
        this.initOptions(sdk);
    }

    public saveTutorialWatched(id: string): void {
        if (!this.tutorialWatched.includes(id)) {
            this.tutorialWatched.push(id);
            this.save();
        }
    }

    public hasTutorialWatched(id: string): boolean {
        if (this.tutorialWatched.includes(id)) {
            return true;
        }
        return false;
    }

    public initLanguage(sdk: CouchgamesSdk): string {
        // Try to get the stored language from the storage
        const loadedLanguage = sdk.loadFromStorage('language', true);

        // If we do not find any saved langauges, check the navigator for the language
        if (loadedLanguage) {
            if (SUPPORTED_LANGUAGES[loadedLanguage]) {
                return loadedLanguage;
            }
        } else if (navigator?.language) {
            let languageCode = (navigator.language || 'en').split('-') || [];
            let useLanguage = '';

            // Did we find another language?
            if (languageCode.length > 0) {
                useLanguage = languageCode[0];
            }

            if (useLanguage) {
                useLanguage = useLanguage.toLowerCase();
                if (SUPPORTED_LANGUAGES[useLanguage]) {
                    sdk.saveInStorage('language', useLanguage, true);
                    return useLanguage;
                }
            }
        }
        return LANGUAGE.ENGLISH;
    }

    public initOptions(sdk: CouchgamesSdk): void {
        const loadedData = sdk.loadFromStorage('device', true);

        if (loadedData) {
            this.vibration = loadedData.vibration === false || loadedData.vibration === true ?
                loadedData.vibration :
                true;
            this.backgroundColor = loadedData.bgColor || 'black';
            this.tutorialWatched = loadedData.tutorial || [];
            this.fontColor = loadedData.fntColor || 'white';
            this.fontSize = loadedData.fntSize || 1;
            this.dummyFoldDisabled = loadedData.dummyFoldDisabled || 0;
            this.dummyRevealDisabled = loadedData.dummyRevealDisabled || 0;
            this.handFoldSensitive = loadedData.handFoldSensitive === 0 ? 0 : loadedData.handFoldSensitive || MOVE_SENSIBILITY.DEFAULT;
            this.handRevealSensitive = loadedData.handRevealSensitive === 0 ? 0 : loadedData.handRevealSensitive || MOVE_SENSIBILITY.DEFAULT;
        } else {
            this.save()
        }
    }

    private save(): void {
        this.sdk.saveInStorage('device', {
            vibration: this.vibration,
            fntColor: this.fontColor,
            fntSize: this.fontSize,
            bgColor: this.backgroundColor,
            handFoldSensitive: this.handFoldSensitive,
            handRevealSensitive: this.handRevealSensitive,
            dummyFoldDisabled: this.dummyFoldDisabled,
            dummyRevealDisabled: this.dummyRevealDisabled,
            tutorial: this.tutorialWatched
        }, true)
    }

    public switchLanguage(language: string): void {
        this.language = language;
        this.sdk.saveInStorage('language', language, true);
    }

    public switchFoldSecurity(): void {
        this.dummyFoldDisabled = 1 - this.dummyFoldDisabled
        this.save();
    }

    public switchRevealSecurity(): void {
        this.dummyRevealDisabled = 1 - this.dummyRevealDisabled
        this.save();
    }

    public switchHandRevealSensibility(): void {
        this.handRevealSensitive += 1;
        if (this.handRevealSensitive > MOVE_SENSIBILITY.STRONG) {
            this.handRevealSensitive = MOVE_SENSIBILITY.WEAK
        }
        this.save();
    }

    public switchHandFoldSensibility(): void {
        this.handFoldSensitive += 1;
        if (this.handFoldSensitive > MOVE_SENSIBILITY.STRONG) {
            this.handFoldSensitive = MOVE_SENSIBILITY.WEAK
        }
        this.save();
    }

    public switchVibration(): void {
        this.vibration = !this.vibration;
        this.save();
    }

    public setBackgroundColor(color: string): boolean {
        if (this.backgroundColor !== color) {
            this.backgroundColor = color;
            this.save();
            return true;
        }
        return false;
    }

    public getRevealSensibility(handMode: number): number {
        return REVEAL_SENSIBILITY_VALUE[handMode][this.handRevealSensitive]
    }

    public getFoldSensibility(handMode: number): number {
        return FOLD_SENSIBILITY_VALUE[handMode][this.handFoldSensitive]
    }

    updateValue(valueName: string, value: any): void {
        console.log('UPDATE', valueName, value)
        // @ts-ignore
        this[valueName] = value;

        this.save()
    }

    getValue(valueName: string) {
        // @ts-ignore
        if (this[valueName]) {
            // @ts-ignore
            return this[valueName]
        }
        return 0;
    }
}