import type AppMessageHandler from "../messagehandler";

export default function tableBuyIn(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    seat: number | null,
    value: number | undefined,
    creditToken: string | undefined
): boolean {
    messageHandler.sendMessage(1014, {
        roomToken,
        playerId,
        seat,
        creditToken,
        value
    })
    return true;
}