const TUTORIAL = {
    MODE_100PRO: [
        {
            src: 'call',
            text: 'tutorial.bet',
            wide: true
        },
        {
            src: 'check',
            text: 'tutorial.check',
            wide: true
        }
    ],
    DIGITALCARD: [
        {
            src: 'peek',
            text: 'tutorial.card.peek'
        },
        {
            src: 'fold',
            text: 'tutorial.card.fold'
        },
        {
            src: 'revealone',
            text: 'tutorial.card.revealone'
        },
        {
            src: 'revealboth',
            text: 'tutorial.card.revealall'
        }
    ]
};

export {
    TUTORIAL
}