import {
    observer
} from 'mobx-react-lite';
import {
    useState,
    useEffect
} from 'react';
import {
    currentLanguage,
    translateKey
} from '../../utils/helper';
import {
    List,
    TabArea,
    Container,
    ImageComponent
} from '../controls';
import GameShopItem from './GameShopItem';
const getViewTab = (view: string) => {
    if (view === 'coin') return 1;
    if (view === 'credit') return 0;
    if (view === 'vip') return 3;
    return 0;
}
const GameShop = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;

    const [tab, setTab] = useState(getViewTab(appState?.gamestore?.view || ''));
    const getList = (usedTab: number) => {
        if (usedTab === 0) {
            return appState.shop.creditList;
        } else if (usedTab === 2) {
            return appState.shop.assetList;
        } else if (usedTab === 3) {
            return appState.shop.premiumList;
        }

        return appState.shop.coinList
    }
    useEffect(() => {
        setTab(getViewTab(appState?.gamestore?.view || ''))
    }, [appState?.gamestore?.view])

    const couponCredit = appState.user.getCoupon('credit');

    return appState.gamestore ? <Container
        containerOption={{
            margin: [64, 0, 0, 0],
            position: 'relative'
        }}>
        <TabArea
            id={'shop'}
            width={1200}
            height={660}
            containerOption={{
                // margin: [0,0,0,0]
            }}
            onClickCloseButton={() => {
                sdk.appState.closeGameStore();
            }}
            tabList={[
                translateKey('text.inappstore.tab.chips'),
                translateKey('text.inappstore.tab.coins'),
                translateKey('text.inappstore.tab.assets'),
                translateKey('text.inappstore.tab.vip')
            ]}
            tabColor={[
                ['#f43b28', '#c12d1e'],
                ['#fef161', '#aca556'],
                null,
                ['#35ce39', '#0d7710']
            ]}
            tabIcon={[
                './data/ui/chip.png',
                './data/ui/coin.png',
                './data/icon/poker-cards.svg',
                './data/icon/vip.svg'
            ]}
            disabledButtons={[false, false]}
            activeTab={tab}
            onClickTab={(tabId: any) => setTab(tabId)}
        >
            <List
                // listRef={historyEndRef}
                id={'shop'}
                containerOption={{
                    float: 'left',
                    textAlign: 'left',
                    widthPx: 1160,
                    heightPx: 600,
                    margin: [0, 0, 0, 20],
                    padding: [0, 0, 0, 0],
                    overflowX: 'hidden',
                    overflowY: 'auto'
                }}
                listOption={{
                    position: 'relative',
                    widthPx: 1120,
                    heightPx: 112,
                    borderSize: 2,
                    borderBottom: 'solid #fff',
                    color: '#fff',
                    fontSize: 28,
                    whiteSpace: 'nowrap',
                    margin: [6, 0, 0, 0]
                }}
                components={getList(tab).map((item: any) => <GameShopItem item={item} sdk={sdk} couponCredit={couponCredit} />)}
            />
            {/* {tab === 2 ? <>Hallo C</> : null} */}
            {/* {tab === 1 ? <>Hallo A</> : null} */}
            {/* {tab === 0 ? <>Hallo B</> : null} */}
        </TabArea>
        {tab === 3 ? (
            <ImageComponent
                imageOption={{
                    position: 'absolute',
                    widthPx: 1120,
                    heightPx: 224,
                    top: 420,
                    left: 140,
                    zIndex: 5
                }}
                source={`./data/premiumbanner/premiumbanner_${currentLanguage()}.png`}
            />
        ) : null}

    </Container> : null;
})

export default GameShop;