const GAMEPURCHASE_TYPE = {
    MONEY: 'money',
    DAILYMONEYBONUS: 'dailymoneybonus',
    ADMONEYBONUS: 'admoneybonus',
    COINS: 'coins',
    PREMIUM: 'premium',
    ASSETS: 'assets'
}

export {
    GAMEPURCHASE_TYPE
}