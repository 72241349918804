import { currentLanguage } from "../../utils/helper";
import type AppMessageHandler from "../messagehandler";
import type AppUser from "../stores/user";

export default function roomCreate(
    messageHandler: AppMessageHandler,
    user: AppUser | undefined,
    publicData: any = undefined,
    dealerMode: boolean = false,
    dealerPin: string = '',
    waitingArea: boolean = false
): boolean {
    let additionalData = {};
    if (publicData) {
        additionalData = {
            public: publicData
        }
    }
    messageHandler.sendMessage(1000, {
        language: currentLanguage(),
        clientToken: messageHandler.createClientToken(),
        userToken: user?.token,
        playerName: user?.userData?.name || '',
        playerAvatar: user?.userData?.avatar || 0,
        blindmode: user?.blindMode || false,
        referred: false,
        waitingArea,
        ...(dealerMode ? {
            dealerMode,
            dealerPin
        } : {}),
        ...(additionalData || {})
    })
    return true;
}