import { useState, useEffect } from 'react';
import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../utils/helper';
import {
    Container,
    IconButton,
    InputSlider,
    TextField
} from '../controls/index';

const BuyInDialog = observer(({ sdk }: any) => {
    const {
        appState,
        layoutManager
    } = sdk;
    const dialog = appState?.buyinDialog;
    const dialogChip = dialog?.chipStack || false;

    const dialogRange = Array.isArray(dialog?.stack)
    const dialogMin = dialogRange ? dialog.stack[0] : dialog?.stack || 0;
    const dialogMax = dialogRange ? dialog.stack[1] : dialog?.stack || 0;
    const [buyin, setBuyin] = useState(Array.isArray(dialog?.stack) ? dialog.stack[0] : dialog?.stack || 0);

    useEffect(() => {
        setBuyin(Array.isArray(dialog?.stack) ? dialog.stack[0] : dialog?.stack || 0)
    }, [dialog])

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_buyin_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_buyin_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    zIndex: 60,
                    bgColor: '#062860',
                    margin: [(750 - 362) / 2, 0, 0, (1334 - 640) / 2]
                }}
            >
                <Container
                    id={'gamedialog_buyin_message'}
                    containerOption={{
                        widthPx: 620,
                        heightPx: 96,
                        color: '#fff',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        role: 'alert'
                    }}
                >
                    <div dangerouslySetInnerHTML={{
                        __html: translateKey(dialogChip ?
                            'dialog.chip.range' :
                            dialogRange ? 'dialog.buyin.range' : 'dialog.buyin', {}, [
                            ['$buyinmin', `<span>${dialogMin}</span>`],
                            ['$buyinmax', `<span>${dialogMax}</span>`],
                            ['$min', `<span>${dialogMin}</span>`],
                            ['$max', `<span>${dialogMax}</span>`]
                        ])
                    }} />
                </Container>
                {dialogRange ? (
                    <>
                        <InputSlider
                            sliderMin={dialogMin}
                            sliderMax={dialogMax}
                            inputOption={{
                                margin: [16, 0, 0, 70],
                                heightPx: 40
                            }}
                            onChange={(v: any) => setBuyin(v)}
                            value={buyin}
                        // store={lobbyStore}
                        // storeProperty={'pokerPublicBuyin'}
                        />
                        <Container
                            containerOption={{
                                widthPx: 620,
                                heightPx: 32,
                                color: '#fff',
                                fontSize: 32,
                                float: 'left',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                margin: [32, 0, 32, 10],
                                role: 'alert'
                            }}
                        >
                            <TextField
                                inputType={'number'}
                                onInput={(v: any) => {
                                    setBuyin(parseInt(v, 10))
                                }}
                                value={buyin}
                            />
                        </Container>
                    </>
                ) : null}


                <IconButton
                    source={'./data/ui/no.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    onClick={() => {
                        appState.closeBuyInDialog()
                    }}
                />
                <IconButton
                    source={'./data/ui/yes.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    onClick={() => {
                        if (appState.currentSession) {
                            if (dialogChip) {
                                appState.currentSession?.currentActor?.matchData.setBet(buyin);
                                appState.closeBuyInDialog()
                            } else {
                                appState.currentSession.buyIn(buyin, dialog.seat)
                            }
                        }
                    }}
                />
            </Container>
        </div>
    </div > : null;
});

export default BuyInDialog;