import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ActionButtonRound,
    IconButton
} from '../../../../controls/index';
import CommunityCard from '../../cards/CommunityCard';
import {
    translateKey
} from '../../../../../utils/helper'
import GameDigitalCardClock from './DigitalCardClock'
import GameDigitalCardSessionLimit from './DigitalCardSessionLimit'
import GameDigitalTypeIcon from './DigitalCardTypeIcon'
import GameDigitalHand from './DigitalCardHand'
import {
    POKERVIEW
} from '../../../../../constants/gameViews'
import TutorialNext from '../../tutorial/TutorialNext';

const GameDigitalCardLandscape = observer(({
    sdk,
    gameSession
}: any) => {
    const showWinningHand = gameSession?.currentActor?.matchData?.handType &&
        gameSession?.currentActor?.matchData?.handRevealed === 3 &&
        !gameSession?.currentActor?.matchData?.folded;
    const showYourTurn = !showWinningHand && gameSession?.currentActor?.matchData?.pointerTurn;

    return <>
        <Container
            id={'gameinfo'}
            className={showYourTurn ? 'placer-active-digitalcard' : ''}
            containerOption={{
                width: '100%',
                heightPx: 96,
                margin: [0, 0, 0, 0],
                bgColor: 'rgba(0, 0, 0, .7)',
                borderRadius: 10,
                float: 'left'
            }}
        >
            <IconButton
                buttonOption={{
                    widthPx: 48,
                    heightPx: 48,
                    border: 'none',
                    margin: [24, 0, 0, 16],
                    float: 'left'
                }}
                source={'./data/ui/settings.svg'}
                onClick={() => sdk.appState.openSetting('game')}
            />
            {gameSession?.tableConfig?.flagDisableBlinds !== 1 ? (
                <Container
                    containerOption={{
                        float: 'left',
                        widthPx: 210,
                        heightPx: 72,
                        margin: [16, 10, 0, 5],
                        borderRight: 'solid #879892',
                        borderSize: 2
                    }}
                >
                    <Container
                        containerOption={{
                            widthPx: 200,
                            heightPx: 32,
                            fontSize: 22,
                            fontWeight: 'bold',
                            float: 'left',
                            lineHeight: 32,
                            color: '#fff',
                            textAlign: 'center'
                        }}
                    >
                        {translateKey('game.text.handview.blinds')}
                    </Container>
                    <Container
                        containerOption={{
                            widthPx: 200,
                            heightPx: 32,
                            fontSize: 20,
                            fontWeight: 'bold',
                            float: 'left',
                            lineHeight: 32,
                            color: '#dd985d',
                            textAlign: 'center'
                        }}
                    >
                        {`${gameSession?.currentMatch?.smallBlind || '-'} / ${gameSession?.currentMatch?.bigBlind || '-'}`}
                    </Container>

                </Container>
            ) : null}
            {((gameSession?.voteList || []).length && gameSession.admin) ? (
                <Container
                    containerOption={{
                        float: 'left',
                        widthPx: 128,
                        heightPx: 72,
                        margin: [16, 10, 0, 5],
                        borderRight: 'solid #879892',
                        borderSize: 2
                    }}
                >
                    <IconButton
                        source={`./data/icon/important.svg`}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64
                        }}
                        onClick={() => gameSession.openRequestView()}
                    />
                </Container>
            ) : null}
            <GameDigitalCardClock
                sdk={sdk}
            />
            <GameDigitalCardSessionLimit
                sdk={sdk}
            />
            <GameDigitalTypeIcon sdk={sdk} gameSession={gameSession} />
            <Container
                containerOption={{
                    float: 'right',
                    widthPx: 300,
                    heightPx: 72
                }}
            >
                <CommunityCard
                    round={gameSession?.currentMatch?.round}
                    dealerCard={1}
                    deck={gameSession.sessionDeck}
                    gameSession={gameSession}
                    cardWidth={50}
                    microcard={true}
                    cardOption={{
                        margin: [8, 3, 0, 3]
                    }}
                />
                <CommunityCard
                    round={gameSession?.currentMatch?.round}
                    deck={gameSession.sessionDeck}
                    dealerCard={2}
                    cardWidth={50}
                    gameSession={gameSession}
                    microcard={true}
                    cardOption={{
                        margin: [8, 3, 0, 3]
                    }}
                />
                <CommunityCard
                    round={gameSession?.currentMatch?.round}
                    deck={gameSession.sessionDeck}
                    dealerCard={3}
                    cardWidth={50}
                    gameSession={gameSession}
                    microcard={true}
                    cardOption={{
                        margin: [8, 3, 0, 3]
                    }}
                />
                <CommunityCard
                    round={gameSession?.currentMatch?.round}
                    deck={gameSession.sessionDeck}
                    dealerCard={4}
                    cardWidth={50}
                    gameSession={gameSession}
                    microcard={true}
                    cardOption={{
                        margin: [8, 3, 0, 3]
                    }}
                />
                <CommunityCard
                    round={gameSession?.currentMatch?.round}
                    deck={gameSession.sessionDeck}
                    dealerCard={5}
                    cardWidth={50}
                    gameSession={gameSession}
                    microcard={true}
                    cardOption={{
                        margin: [8, 3, 0, 3]
                    }}
                />
            </Container>
        </Container>
        <Container
            id={'leftcontent'}
            containerOption={{
                position: 'relative',
                widthPx: 770,
                heightPx: 660,
                float: 'left'
            }}
        >
            <Container
                containerOption={{
                    widthPx: 760,
                    heightPx: 580,
                    float: 'left',
                    position: 'relative',
                }}
            >
                <GameDigitalHand sdk={sdk} gameSession={gameSession} />
                {gameSession?.currentActor?.matchData?.folded ?
                    <Container
                        containerOption={{
                            position: 'absolute',
                            left: 0,
                            bottom: 164,
                            widthPx: 760,
                            heightPx: 240,
                            zIndex: 10,
                            bgColor: `#ca0101`,
                            color: '#fff',
                            fontWeight: 'bold',
                            lineHeight: 240,
                            fontSize: 64
                        }}
                    >
                        {translateKey('game.text.handview.folded')}
                    </Container>
                    : null}
                {!gameSession?.currentActor?.matchData?.folded && !gameSession?.currentActor?.playing ?
                    <Container
                        containerOption={{
                            position: 'absolute',
                            left: 0,
                            bottom: 164,
                            widthPx: 760,
                            heightPx: 240,
                            zIndex: 10,
                            bgColor: `#062860`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 32
                        }}
                    >
                        <Container
                            containerOption={{
                                position: 'relative',
                                widthPx: 730,
                                heightPx: 32,
                                color: '#fff',
                                fontWeight: 'bold',
                                margin: [80, 0, 0, 16],
                                fontSize: 32
                            }}
                        >{translateKey('game.text.handview.waitnextround')}</Container>

                    </Container> : null}
            </Container>
            <Container
                id={'gamefooter'}
                className={showYourTurn ? 'placer-active-digitalcard' : ''}
                containerOption={{
                    widthPx: 760,
                    heightPx: 64,
                    margin: [8, 0, 0, 0],
                    borderRadius: 10,
                    float: 'left',
                    fontSize: 32,
                    lineHeight: 64,
                    bgColor: gameSession?.currentActor?.matchData?.winner ? 'rgba(57, 105, 5, .7)' : 'rgba(0, 0, 0, .7)',
                    color: gameSession?.currentActor?.matchData?.winner ? '#fff' : '#fff'

                }}
            >
                {showYourTurn ? (
                    translateKey('text.game.playerhand.yourturn', {}, [])
                ) : null}
                {showWinningHand ? (
                    translateKey(gameSession?.currentActor?.matchData?.winner ? gameSession?.currentMatch?.round?.splitpot ? 'text.game.playerhand.splitpot' : 'text.game.playerhand.winner' : 'text.game.playerhand.handtype', {}, [
                        ['$handtype', translateKey(`text.player.hand.${gameSession?.currentActor?.matchData?.handType}`)]
                    ])
                ) : null}

            </Container>
        </Container>
        <Container
            id={'rightcontent'}
            containerOption={{
                widthPx: 558,
                heightPx: 660,
                float: 'left',
                position: 'relative'
            }}
        >
            <Container
                id={'iconarea'}
                containerOption={{
                    width: '100%',
                    heightPx: 80,
                    float: 'left',
                    margin: [16, 0, 0, 0]
                }}
            >
                <IconButton
                    buttonOption={{
                        widthPx: 48,
                        heightPx: 48,
                        border: 'none',
                        margin: [24, 16, 0, 16],
                        float: 'right'
                    }}
                    source={'./data/icon/phonerotate.svg'}
                    onClick={() => sdk.appState.switchDigitalCardView()}
                />
                <IconButton
                    buttonOption={{
                        widthPx: 48,
                        heightPx: 48,
                        border: 'none',
                        margin: [24, 16, 0, 16],
                        float: 'right'
                    }}
                    source={'./data/icon/help.svg'}
                    onClick={() => {
                        gameSession.openOverlay(
                            {},
                            'trainer',
                            'pokertrainer'
                        )
                    }}
                />
                {gameSession?.admin ? (
                    <IconButton
                        buttonOption={{
                            widthPx: 74,
                            heightPx: 48,
                            border: 'none',
                            margin: [24, 16, 0, 16],
                            float: 'right'
                        }}
                        source={'./data/icon/admin.svg'}
                        onClick={() => gameSession.openOverlay(
                            {},
                            'room',
                            'roomsetting'
                        )}
                    />
                ) : null}
            </Container>
            <Container
                id={'actionarea0'}
                containerOption={{
                    width: '100%',
                    heightPx: 180,
                    float: 'left',
                    margin: [16, 0, 0, 0]
                }}
            >
                <ActionButtonRound
                    actionColor={'orange'}
                    buttonOption={{
                        margin: [8, 32, 0, 32]
                    }}
                    translationKey={'game.button.handview.allin'}
                    onClick={() => gameSession?.currentMatch?.playerAllIn()}
                    buttonVisibility={gameSession?.ui?.buttonAllIn}
                />
            </Container>
            <Container
                id={'actionarea1'}
                containerOption={{
                    width: '100%',
                    heightPx: 180,
                    float: 'left',
                    margin: [0, 0, 0, 0]
                }}
            >
                <ActionButtonRound
                    actionColor={'red'}
                    buttonOption={{
                        margin: [8, 32, 0, 32]
                    }}
                    translationKey={'game.button.handview.standup'}
                    onClick={() => gameSession.standUp()}
                    buttonVisibility={gameSession?.ui?.buttonStandup}
                />
                <ActionButtonRound
                    className={'button_circle_action_heartbeat'}
                    actionColor={'green'}
                    buttonOption={{
                        margin: [8, 32, 0, 32]
                    }}
                    translationKey={'Next'}
                    onClick={() => gameSession.nextPointer()}
                    buttonVisibility={gameSession?.ui?.buttonNextPointer}
                />
            </Container>
            <Container
                id={'actionarea2'}
                containerOption={{
                    width: '100%',
                    heightPx: 180,
                    float: 'left',
                    margin: [0, 0, 0, 0]
                }}
            >
                {!gameSession.dealerMode ? <>
                    <ActionButtonRound
                        actionColor={'green'}
                        buttonOption={{
                            margin: [8, 32, 0, 32]
                        }}
                        translationKey={'game.button.handview.deal'}
                        onClick={() => sdk.getMessageList().nextRound()}
                        buttonVisibility={gameSession?.ui?.buttonDeal}
                    />
                    <ActionButtonRound
                        actionColor={'green'}
                        buttonOption={{
                            margin: [8, 32, 0, 32]
                        }}
                        translationKey={`game.button.handview.deal.status.${gameSession?.currentMatch?.round?.status || 0}`}
                        onClick={() => gameSession?.currentMatch?.playerDeal()}
                        buttonVisibility={gameSession?.ui?.buttonDealCard}
                        enableDelay={1000}
                    />
                    <ActionButtonRound
                        actionColor={'green'}
                        buttonOption={{
                            margin: [8, 32, 0, 32]
                        }}
                        translationKey={`game.button.handview.deal.showdown`}
                        onClick={() => gameSession?.currentMatch?.playerDeal()}
                        buttonVisibility={gameSession?.ui?.buttonShowdown}
                        enableDelay={2000}
                    />
                    <ActionButtonRound
                        actionColor={'blue'}
                        buttonOption={{
                            margin: [8, 16, 0, 0]
                        }}
                        translationKey={'game.button.handview.newgame'}
                        onClick={() => gameSession?.playerNewGame()}
                        disabled={gameSession?.ui?.buttonNewGame !== 2}
                        buttonVisibility={gameSession?.ui?.buttonNewGame}
                    />
                </> : null}


                <ActionButtonRound
                    actionColor={'blue'}
                    buttonOption={{
                        margin: [8, 32, 0, 32]
                    }}
                    translationKey={'game.button.handview.table'}
                    buttonVisibility={gameSession?.ui?.buttonTable}
                    onClick={() => sdk.appState.updateValue('subView', POKERVIEW.TABLE)}
                />
            </Container>
            <TutorialNext
                gameSession={gameSession}
                appState={sdk.appState}
            />
        </Container>

    </>
});

export default GameDigitalCardLandscape;