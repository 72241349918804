import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../../utils/helper';
import {
    Container
} from '../../controls';
import BlindEdit from './blinds/BlindEdit';
import ClockConnect from './blinds/ClockConnect';
import PlayerBankroll from './player/PlayerBankroll';
import PlayerPlace from './player/PlayerPlace';
import RoomWaitArea from './room/RoomWaitArea';
import RoomRequest from './room/RoomRequest';
import TrainerWinningHand from './trainer/TrainerWinningHand';

const GameOverlayMenu = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;
    const submenu = appState?.currentSession?.overlaySubmenu || null;

    const handleClose = () => {
        appState?.currentSession?.openOverlaySubmenu(null)
    }
    
    const getTitle = () => {
        return translateKey(`text.overlay.sub.title.${submenu}`)
    }
    
    return submenu ? <Container
        containerOption={{
            float: 'right',
            widthPx: 640,
            bgColor: 'rgba(0,0,0,0.8)',
            height: '100%',
            borderSize: 4,
            borderLeft: 'solid #555555'
        }}
        onClick={(event: any) => event.stopPropagation()}
    >
        <Container
            containerOption={{
                float: 'right',
                width: '100%',
                heightPx: 48,
                lineHeight: 48,
                bgColor: '#555555',
                fontSize: 20,
                color: '#fff',
                fontWeight: 'bold'
            }}
        >
            {getTitle()}
        </Container>
        <Container
            containerOption={{
                float: 'left',
                widthPx: 600,
                heightPx: 680,
                lineHeight: 48,
                fontSize: 20,
                color: '#fff',
                fontWeight: 'bold',
                margin: [20, 0, 0, 20]
            }}
        >
            {submenu === 'editblind' ? <BlindEdit sdk={sdk} onClose={handleClose} /> : null}
            {submenu === 'connectclock' ? <ClockConnect sdk={sdk} onClose={handleClose} /> : null}
            {submenu === 'placeplayer' ? <PlayerPlace sdk={sdk} onClose={handleClose} /> : null}
            {submenu === 'editbankroll' ? <PlayerBankroll sdk={sdk} onClose={handleClose} /> : null}
            {submenu === 'roomrequest' ? <RoomRequest sdk={sdk} onClose={handleClose} /> : null}
            {submenu === 'roomwaitarea' ? <RoomWaitArea sdk={sdk} onClose={handleClose} /> : null}
            {submenu === 'helpwinninghand' ? <TrainerWinningHand sdk={sdk} onClose={handleClose} /> : null}
        </Container>


    </Container> : null;
});

export default GameOverlayMenu;