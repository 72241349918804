import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import {
    convertNumberToString,
    translateKey
} from '../../../../utils/helper';

const PokerTablePlayerBankroll = observer(({
    pos,
    player,
    containerOption,
    gameSession
}: any) => {
    const renderBankroll = player?.matchData?.bankroll || 0;
    const bankrollVisible = gameSession?.ui?.flagBankroll || false;
    const waiting = player?.waiting === true;

    return bankrollVisible ? <Container
        id={`seat-${pos}-bankroll`}
        containerOption={{
            widthPx: 160,
            heightPx: 16,
            lineHeight: 16,
            color: '#eea262',
            margin: [5, 0, 0, 0],
            float: 'right',
            textAlign: 'right',
            fontWeight: 'bold',
            fontSize: 20,
            ...(containerOption || {})
        }}
    >
        {waiting ?
            translateKey('text.common.waiting') :
            convertNumberToString(renderBankroll)}
    </Container> : null;
});

export default PokerTablePlayerBankroll;