import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import {
    translateKey,
    replace1k
} from '../../../../utils/helper';

const PokerTableBlinds = observer(({
    gameSession,
    containerOption
}: any) => {

    const clock = gameSession?.currentMatch?.clock
    const visible = gameSession.tableConfig.flagDisableBlinds !== 1;
    
    return visible ? <Container
        containerOption={{
            bgColor: '#000',
            widthPx: 192,
            heightPx: 88 + (clock ? 26 : 0),
            lineHeight: 80,
            color: '#fff',
            margin: [32, 0, 0, 0],
            borderCorner: [20, 20, 20, 20],
            ...(containerOption || {})
        }}
        onClick={() => {
            gameSession.openOverlay(
                {},
                'blinds',
                'blindsetting'
            )
        }}
    >
        <Container
            containerOption={{
                widthPx: 192,
                heightPx: 40,
                lineHeight: 40,
                color: '#fff',
                fontWeight: 500,
                fontSize: 20,
                borderBottom: 'solid rgb(200, 200, 200,0.4)',
                borderSize: 2
            }}>
            {translateKey('game.text.handview.blinds', {
                upperSize: true
            })}
        </Container>
        <Container
            containerOption={{
                widthPx: 192,
                heightPx: 40,
                lineHeight: 40,
                fontWeight: 'bold',
                fontSize: 26,
                color: '#ffc99a',
                opacity: 0.7
            }}>
            {`${replace1k(gameSession?.currentMatch?.smallBlind) || '-'} / ${replace1k(gameSession?.currentMatch?.bigBlind) || '-'}`}
        </Container>
        {clock ? (
            <Container
                containerOption={{
                    widthPx: 192,
                    heightPx: 24,
                    lineHeight: 26,
                    fontWeight: 'bold',
                    fontSize: 18,
                    color: '#fff',
                    opacity: 0.7,
                    borderTop: 'solid rgb(200, 200, 200,0.4)',
                    borderSize: 2
                }}>
                {clock.timeString}
            </Container>
        ) : null}
    </Container> : null;
});

export default PokerTableBlinds;