import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react';
import {
    Container,
    IconButton,
    ActionButton,
    ImageComponent
} from '../../components/controls';
import {
    getAvatarImage
} from '../../utils/helper';

const AccountAvatarSelect = observer(({
    sdk,
    onNextItem,
    handleOnBack
}: any) => {
    const [avatar, setAvatar] = useState(null);
    const customAvatar = sdk.appState.user?.userData?.customAvatar;

    return <>
        {[1, 2, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((av: any, i: any) => {
            let useBorder = '';

            if (avatar === av) {
                useBorder = 'solid red';
            } else if (av === sdk.appState.user.userData.avatar) {
                useBorder = 'solid green';
            }

            return <IconButton
                key={i}
                buttonOption={{
                    float: 'left',
                    widthPx: 120,
                    heightPx: 120,
                    borderRadiusPercent: 50,
                    borderSize: 8,
                    margin: [18, 20, 16, 18],
                    border: useBorder
                }}
                source={`./data/avatar/${av}.jpg`}
                onClick={() => {
                    setAvatar(av)
                }}
            />
        })}
        {customAvatar?.status >= 0 ? (
            <Container
                containerOption={{
                    position: 'relative',
                    float: 'left',
                    widthPx: 120,
                    heightPx: 120,
                    margin: [18, 20, 16, 18],
                }}
            >
                <IconButton
                    buttonOption={{
                        widthPx: 120,
                        heightPx: 120,
                        borderRadiusPercent: 50,
                        borderSize: 8,

                        border: sdk.appState.user.userData.avatar === `${customAvatar?.directory}/${customAvatar?.fileUuid}` ?
                            'solid green' :
                            avatar === `${customAvatar?.directory}/${customAvatar?.fileUuid}` ? 'solid red' : ''
                    }}
                    source={customAvatar?.status === 1 ?
                        getAvatarImage(`${customAvatar?.directory}/${customAvatar?.fileUuid}`)?.src :
                        sdk.loadFromStorage('customavatar', true)
                    }
                    onClick={() => {
                        if (customAvatar?.status === 0) {
                            // show a small dialog 
                            sdk.appState.showMessagebox('avatar-upload', 'dialog.avatarupload.waiting', null, null, true, false, false)
                            // The profile picture was not accepted. Please wait
                        } else if (customAvatar?.status === 1) {
                            //@ts-ignore
                            setAvatar(`${customAvatar?.directory}/${customAvatar?.fileUuid}`)
                        } else if (customAvatar?.status === 2) {
                            sdk.appState.showMessagebox('avatar-upload', 'dialog.avatarupload.declined', null, null, true, false, false)
                        }
                    }}
                />
                <ImageComponent
                    source={`./data/icon/${customAvatar?.status === 0 ? 'waiting' : customAvatar?.status === 2 ? 'decline' : 'accept'}.svg`}
                    imageOption={{
                        position: 'absolute',
                        right: 16,
                        bottom: 16,
                        widthPx: 32,
                        heightPx: 32
                    }}
                />
            </Container>
        ) : null}
        {(!customAvatar?.status || customAvatar?.status === 1 || customAvatar?.status === 2) ? (
            <Container
                containerOption={{
                    position: 'relative',
                    float: 'left',
                    widthPx: 120,
                    heightPx: 120,
                    borderRadiusPercent: 50,
                    margin: [18, 20, 16, 18],
                    border: 'solid #06B800',
                    borderSize: 4
                }}
                onClick={() => {
                    if (customAvatar?.status === 1 || customAvatar?.status === 2) {
                        sdk.appState.showMessagebox('avatar-upload', 'dialog.avatarupload.delete', () => {
                            sdk.appState.user.saveAvatar(`${customAvatar?.directory}/${customAvatar?.fileUuid}`, true).then(() => {
                                sdk.appState.user.userData.removeCustomeAvatar()
                                sdk.removeFromStorage('customavatar', true);
                                if (`${customAvatar?.directory}/${customAvatar?.fileUuid}` === avatar) {
                                    setAvatar(null);
                                }
                            });
                        }, () => null, true, false, false)
                    } else {
                        if (sdk.appState.user.premium) {
                            onNextItem('account-upload-avatar')
                        } else {
                            sdk.appState.showPremiumBox('avatar')
                        }
                    }
                }}
            >
                <ImageComponent
                    source={'./data/icon/addpicture.svg'}
                    imageOption={{
                        position: 'absolute',
                        right: 24,
                        bottom: 24,
                        widthPx: 64,
                        heightPx: 64
                    }}
                />
            </Container>
        ) : null}
        <Container
            containerOption={{
                width: '100%',
                heightPx: 80,
                float: 'right',
                margin: [48, 0, 0, 0]
            }}
        >
            <ActionButton
                actionColor={'green'}
                translationKey={'button.save'}
                buttonOption={{
                    widthPx: 192,
                    float: 'right',
                    margin: [12, 16, 0, 0],
                    padding: [0, 0, 0, 0]
                }}
                disabled={avatar === sdk.appState.user.userData.avatar || avatar === null}
                onClick={() => {
                    sdk.appState.user.saveAvatar(avatar).then(() => {
                        handleOnBack();
                    });
                }}
            />
            <ActionButton
                actionColor={'red'}
                buttonOption={{
                    widthPx: 192,
                    float: 'right',
                    margin: [12, 32, 0, 16],
                    padding: [0, 0, 0, 0]
                }}
                translationKey={'button.cancel'}
                onClick={() => handleOnBack()}
            />
        </Container>
    </>
});

export default AccountAvatarSelect;