import type AppMessageHandler from "../messagehandler";

export default function adminEditSetting(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: number,
    data: any
): boolean {
    messageHandler.sendMessage(1063, {
        roomToken,
        playerId,
        ...(data || {})
    })
    return true;
}