import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../../utils/helper';
import {
    Container,
    IconButton
} from '../index';

const Configurator = observer(({
    id,
    containerOption,
    children,
    onBack,
    disableBackButton,
    title
}: any) => {
    const propsContainer = {
        widthPx: 1000,
        heightPx: 740,
        float: 'left',
        margin: [5, 0, 0, 165],
        ...(containerOption || {})
    }

    return <Container
        id={id}
        containerOption={propsContainer}
    >
        <Container
            containerOption={{
                widthPx: 980,
                heightPx: 80,
                float: 'left',
                margin: [20, 0, 0, 10],
                borderCorner: [16, 16, 0, 0],
                bgColor: 'rgba(0, 0, 0, 0.9)'
            }}
        >
            {!disableBackButton ?
                <Container
                    containerOption={{
                        widthPx: 128,
                        heightPx: 78,
                        float: 'left'
                    }}
                    onClick={() => onBack ? onBack() : null}
                >
                    <IconButton
                        id={`btn_setting_back`}
                        source={'./data/ui/backbutton.svg'}
                        buttonOption={{
                            widthPx: 80,
                            heightToWidth: 2.1,
                            margin: [20, 0, 0, 16],
                            float: 'left'
                        }}
                    />
                </Container>
                : null}
            <Container
                containerOption={{
                    widthPx: 800,
                    heightPx: 78,
                    float: 'left',
                    lineHeight: 78,
                    textAlign: 'center',
                    color: '#fff',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                {title ? translateKey(title) : null}
            </Container>
        </Container>
        <Container
            containerOption={{
                widthPx: 980,
                heightPx: 620,
                float: 'left',
                margin: [0, 0, 0, 10],
                borderCorner: [0, 0, 16, 16],
                bgColor: 'rgba(0, 0, 0, 0.6)'
            }}
        >
            {children}
        </Container>
    </Container >
});

export default Configurator;