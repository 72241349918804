import { GAMEPURCHASE_TYPE } from '../../../constants/gameshop';
import { triggerEvent } from '../../events';
const STORE_VALIDATION_URL = 'https://validate.couchgames.wtf/store/poker';
// const appID = 'couchgames.wtf.poker';

const PURCHASES = [
    {
        id: 1001,
        itemId: 'poker.coin.1',
        icon: 'data/ui/taler/poker.coin.1.png',
        store: true,
        type: GAMEPURCHASE_TYPE.COINS,
        value: 20,
        testPrice: '0,99 €'
    },
    {
        id: 1002,
        itemId: 'poker.coin.2',
        icon: 'data/ui/taler/poker.coin.2.png',
        store: true,
        type: GAMEPURCHASE_TYPE.COINS,
        value: 70,
        testPrice: '2,99 €'
    },
    {
        id: 1003,
        itemId: 'poker.coin.3',
        icon: 'data/ui/taler/poker.coin.3.png',
        store: true,
        type: GAMEPURCHASE_TYPE.COINS,
        value: 140,
        testPrice: '4,99 €'
    },
    {
        id: 1004,
        itemId: 'poker.coin.4',
        icon: 'data/ui/taler/poker.coin.4.png',
        store: true,
        type: GAMEPURCHASE_TYPE.COINS,
        value: 380,
        testPrice: '5,99 €'
    },
    {
        id: 1005,
        itemId: 'poker.coin.5',
        icon: 'data/ui/taler/poker.coin.5.png',
        store: true,
        type: GAMEPURCHASE_TYPE.COINS,
        value: 880,
        testPrice: '6,99 €'
    },
    {
        id: 1101,
        itemId: 'poker.credit.1',
        type: GAMEPURCHASE_TYPE.MONEY,
        icon: 'data/ui/chips/poker.credit.1.png',
        value: 1200,
        price: 1,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1102,
        itemId: 'poker.credit.2',
        icon: 'data/ui/chips/poker.credit.2.png',
        type: GAMEPURCHASE_TYPE.MONEY,
        value: 25000,
        price: 20,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1103,
        itemId: 'poker.credit.3',
        icon: 'data/ui/chips/poker.credit.3.png',
        type: GAMEPURCHASE_TYPE.MONEY,
        value: 80000,
        price: 60,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1104,
        itemId: 'poker.credit.4',
        icon: 'data/ui/chips/poker.credit.4.png',
        type: GAMEPURCHASE_TYPE.MONEY,
        value: 275000,
        price: 180,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1105,
        itemId: 'poker.credit.5',
        icon: 'data/ui/chips/poker.credit.5.png',
        type: GAMEPURCHASE_TYPE.MONEY,
        value: 490000,
        price: 300,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1106,
        itemId: 'poker.credit.6',
        icon: 'data/ui/chips/poker.credit.6.png',
        type: GAMEPURCHASE_TYPE.MONEY,
        value: 1210000,
        price: 600,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1107,
        itemId: 'poker.credit.7',
        icon: 'data/ui/chips/poker.credit.7.png',
        type: GAMEPURCHASE_TYPE.MONEY,
        value: 1500000,
        price: 700,
        priceType: 'coin',
        weight: 5
    },
    {
        id: 1110,
        itemId: 'poker.credit.ad',
        icon: 'data/ui/chips/poker.credit.ad.png',
        type: GAMEPURCHASE_TYPE.ADMONEYBONUS,
        bonusId: 'moneyAd',
        value: 10000,
        weight: 4
    },
    {
        id: 1111,
        itemId: 'poker.credit.daily',
        icon: 'data/ui/chips/poker.credit.daily.png',
        type: GAMEPURCHASE_TYPE.DAILYMONEYBONUS,
        bonusId: 'moneyDaily',
        value: 2000,
        weight: 4
    },
    {
        id: 1200,
        itemId: 'poker.vip.1',
        icon: 'data/icon/vip.svg',
        type: GAMEPURCHASE_TYPE.PREMIUM,
        value: 1,
        weight: 20,
        price: 10,
        priceType: 'coin'
    },
    {
        id: 1200,
        itemId: 'poker.vip.30',
        icon: 'data/icon/vip.svg',
        type: GAMEPURCHASE_TYPE.PREMIUM,
        value: 30,
        weight: 20,
        price: 40,
        priceType: 'coin'
    },
    {
        id: 1200,
        itemId: 'poker.vip.360',
        icon: 'data/icon/vip.svg',
        type: GAMEPURCHASE_TYPE.PREMIUM,
        value: 360,
        weight: 20,
        price: 410,
        priceType: 'coin'
    },
    {
        id: 1300,
        itemId: 'poker.deck.3',
        icon: './data/decks/deck3/icon.png',
        translationKey: 'text.settings.cards.fourcolor',
        type: GAMEPURCHASE_TYPE.ASSETS,
        trial: true,
        value: 360,
        weight: 20,
        price: 60,
        priceType: 'coin',
        wide: true
    },
    {
        id: 1301,
        itemId: 'poker.deck.1',
        icon: './data/decks/deck1/icon.png',
        translationKey: 'text.settings.cards.deck1',
        type: GAMEPURCHASE_TYPE.ASSETS,
        trial: true,
        value: 360,
        weight: 20,
        price: 60,
        priceType: 'coin',
        wide: true
    }
];

// {
//     id: 1302,
//     itemId: 'poker.deck.4',
//     icon: './data/icon-deckfourcolor.png',
//     translationKey: 'text.settings.cards.deck1',
//     type: GAMEPURCHASE_TYPE.ASSETS,
//     value: 360,
//     weight: 20,
//     price: 0,
//     priceType: 'coin'
// }
class GameShop {
    public purchasedItem: Map<any, any>
    public currentPurchase: any;
    public CdvPurchase: any; // Store Wrapper
    private sdk: any;

    constructor(sdk: any) {
        this.purchasedItem = new Map();
        this.CdvPurchase = null;
        this.currentPurchase = null;
        this.sdk = sdk;
        console.log('[SHOP] Construct Shop')
        this.onInit();
    }

    public updateItemId(itemId: string): string {
        if (this.CdvPurchase && this.getPlatform() === this.CdvPurchase.Platform.GOOGLE_PLAY) {
            return `gp.${itemId}`
        }
        return itemId;
    }

    public getPlatform(): any {
        if (this.CdvPurchase) {
            if (process.env.REACT_APP_MOBILE_IOS === 'true') {
                return this.CdvPurchase.Platform.APPLE_APPSTORE;
            } else if (process.env.REACT_APP_MOBILE_ANDROID === 'true') {
                return this.CdvPurchase.Platform.GOOGLE_PLAY;
            }
            return this.CdvPurchase.Platform.TEST;
        }
        return null;
    }

    public onInit(): void {
        try {
            // @ts-ignore
            this.CdvPurchase = CdvPurchase;
            this.CdvPurchase.store.validator = STORE_VALIDATION_URL;
        } catch (_e) {
            console.log('[INAPP] ERROR IN LOADING')
        };

        this.loadInApp();
        this.loadPurchasedItems();
    }

    /**
    * try to load all InApp Purchases from the appstore
    */
    loadInApp(): void {

        if (this.CdvPurchase?.store) {
            this.CdvPurchase.store.applicationUsername = () => this.sdk?.appState?.user?.token || null;
            this.CdvPurchase.store.error((error: any) => {
                try {
                    switch (error.code) {
                        case this.CdvPurchase.ErrorCode.PAYMENT_CANCELLED:
                            this.cancelPurchase();
                            break;
                        case this.CdvPurchase.ErrorCode.PAYMENT_INVALID:
                            this.cancelPurchase();
                            break;
                        default:
                            break;
                    }
                } catch (e) { }
            });
            // Create all products in an array
            const productArray: any = [];

            PURCHASES.forEach((item: any) => {
                //@ts-ignore
                if (item.store && !item.disabled) {
                    productArray.push({
                        type: this.CdvPurchase.CONSUMABLE || this.CdvPurchase.store.CONSUMABLE,
                        id: this.updateItemId(item.itemId),
                        platform: this.getPlatform()
                    });
                }
            });

            console.log('REGISTER', JSON.stringify(productArray))
            // Register all products
            try {
                this.CdvPurchase.store.register(productArray);
                // this.CdvPurchase.store.error(function (error: any) {
                // console.log('ERROR IN CODE ' + error.code + ': ' + error.message);
                // });
            } catch (ee) {
                console.log('ERROR STROE', ee)
            }

            try {
                this.CdvPurchase.store.when()
                    .productUpdated((product: any) => console.log('UPDATED', JSON.stringify(product)))
                    .approved((transaction: any) => {
                        //@ts-ignore

                        // transaction['userToken'] = that.sdk?.appState?.user?.token || null;
                        return transaction.verify()
                    })
                    .verified((receipt: any) => {
                        this.finishPurchase(receipt);
                        return receipt.finish();
                    });
            } catch (e) {
                console.log('ERROR STORE', e)
            }
            // productArray.forEach((item: any) => {

            //     console.log('CREATE STORE LISTENER', item)
            //     try {
            //         this.CdvPurchase.store.when(item.id)
            //             // .updated(this.refreshUI)
            //             .approved(this.verifyPurchase.bind(this))
            //             .verified(this.finishPurchase.bind(this))
            //             .cancelled(this.cancelPurchase.bind(this))
            //             .error(this.cancelPurchase.bind(this));

            //     } catch (e) {
            //         console.log('ERROR => ', e)
            //     }

            // });

            try {
                this.CdvPurchase.store.initialize([this.getPlatform()]);
            } catch (e) {
                console.log(e)
            }

            console.log('[INAPP] Registered products ' + productArray.length)
            // Refresh the store after registering all products
            // try {
            //     // this.CdvPurchase.store.refresh();
            // } catch(e) {
            //     console.log('REFRESH NOT WORKING')
            // }

        } else {
            console.log('CANNOT LOAD')
        }

    }

    productId(search: any) {
        // let searchResult = findInArray(purchaseableMoney, deck => {
        //     if (`${deck.deckId}` === search.replace('noclue_deck_', '')) {
        //         return true;
        //     }
        //     return false
        // })

        // if (!searchResult) {
        //     searchResult = findInArray(purchaseableItem, item => {
        //         console.log(`${item.name}`, `${search}`)
        //         if (`${item.name}` === `${search}`) {
        //             return true;
        //         }
        //         return false
        //     })
        // }

        // return searchResult;
        return []
    }

    /**
     * Callback to refresh the UI
     */
    refreshUI(product: any): void {
        console.log('[INAPP] refreshUI', JSON.stringify(product));
        if (product && product.owned === true && process.env.REACT_APP_MOBILE_ANDROID === 'true') {
            try {
                const useProductId = this.productId(product.id);

                if (useProductId) {
                    //@ts-ignore
                    this.purchasedItem.set(useProductId.id, true);
                }
            } catch (e) { }

            console.log('[INAPP] Owned product ', product.id)
        }
    }

    /**
     * Recover all items
     */
    // recoverItems(recoveredCB) {
    //     console.log('[INAPP] RECOVER')
    //     if (this.store) {

    //         try {
    //             this.store.refresh().finished(() => {
    //                 if (process.env.REACT_APP_MOBILE_ANDROID === 'true') {
    //                     console.log('[INAPP] SAVE')
    //                     this.savePurchasedItems();
    //                 }

    //                 recoveredCB();
    //                 console.log(['[INAPP] Recovered'])
    //             });

    //         } catch (E) {
    //             console.log('RECOVER ISSUE', E);
    //         }
    //     } else {
    //         recoveredCB();
    //     }
    // }

    async orderItem(item: any): Promise<void> {
        const that = this;

        return new Promise((resolve: any) => {
            console.log('Start purchasing', item.itemId)
            const useProduct = that.CdvPurchase?.store?.get(`${item.itemId}`, that.getPlatform());
            if (useProduct) {
                useProduct.getOffer().order()

                that.currentPurchase = {
                    ...item,
                    resolve
                }
            } else {
                resolve();
            }
        })

    }

    async trialItem(item: any) {
        let useSpinner = null;

        if (item) {
            useSpinner = this.sdk.appState.createSpinner();

            if (item?.trial) {
                switch (item.type) {
                    case GAMEPURCHASE_TYPE.ASSETS:
                        const getAssetResult = await this.sdk.fetchApi('api', 'trial', {
                            userToken: this.sdk.appState.user.token,
                            id: item.itemId
                        }, 'POST');

                        if (getAssetResult.status === 200) {
                            this.sdk.appState.user.data.updateTrialUsed(getAssetResult.json.trialPurchased)
                            this.sdk.appState.user.data.updateFeature(getAssetResult.json.trialPurchased)
                            this.sdk.appState.user.data.updatePaidToken(getAssetResult.json.paidToken)
                            this.sdk.appState.user.setCardDeck(getAssetResult.json.trialPurchased)
                            this.sdk.appState.showSnackbar(`snackbar.shop.asset`)
                        }

                        break;
                    default:
                        break;
                }
            }
        }

        if (useSpinner) {
            useSpinner.close()
        }

    }

    async purchaseItem(item: any, coupon: any = undefined): Promise<void> {

        console.log('purchase', item, coupon)
        let useSpinner = null;
        let redeemBonus = null;

        if (item) {

            useSpinner = this.sdk.appState.createSpinner(30000);

            switch (item.type) {
                case GAMEPURCHASE_TYPE.ASSETS:
                    const getAssetResult = await this.sdk.fetchApi('api', 'purchase', {
                        userToken: this.sdk.appState.user.token,
                        id: item.itemId
                    }, 'POST');

                    if (getAssetResult.status === 200) {
                        this.sdk.appState.user.data.updatePaidToken(getAssetResult.json.paidToken)
                        this.sdk.appState.user.data.updateCoins(getAssetResult.json.taler)
                        this.sdk.appState.user.data.updateFeature(getAssetResult.json.feature)
                        this.sdk.appState.showSnackbar(`snackbar.shop.asset`)
                    } else if (getAssetResult.status === 404 && getAssetResult?.json?.error === 'NoCoin') {
                        this.sdk.appState.showMessagebox('no-credits', 'dialog.user.coins.notenough', null, null, true, false, false)
                    }

                    break;
                case GAMEPURCHASE_TYPE.MONEY:
                    const getExchangeResult = await this.sdk.fetchApi('api', 'purchase', {
                        userToken: this.sdk.appState.user.token,
                        id: item.itemId,
                        coupon: coupon?.token
                    }, 'POST');

                    if (getExchangeResult.status === 200) {
                        this.sdk.appState.user.data.updateCoins(getExchangeResult.json.taler)
                        this.sdk.appState.user.data.updateCredit(getExchangeResult.json.credit)
                        this.sdk.appState.showSnackbarMoney(getExchangeResult.json.received)
                    } else if (getExchangeResult?.json?.error === 'NoCoin') {
                        this.sdk.appState.showMessagebox('no-credits', 'dialog.user.coins.notenough', null, null, true, false, false)
                    }
                    break;
                case GAMEPURCHASE_TYPE.COINS:
                    await this.orderItem(item);
                    break;
                case GAMEPURCHASE_TYPE.ADMONEYBONUS:
                    // Start Reward Banner
                    const adResult = await this.sdk.appState.showRewardAd();

                    console.log('AD RESULT', adResult)
                    if (adResult === true) {
                        const useAdBonus = this.sdk.appState?.user?.userBoni?.getBoniTime(item.bonusId);
                        const useAdBonusCash = this.sdk.appState?.user?.userBoni?.getBoniCash(item.bonusId);

                        if (useAdBonus === 0) {
                            redeemBonus = await this.sdk.fetchApi('api', 'bonus', {
                                userToken: this.sdk.appState.user.token,
                                bonus: item.bonusId,
                                coupon: coupon?.token
                            }, 'POST');
                        } else {
                            console.log('not possible')
                        }

                        if (redeemBonus?.status === 200) {
                            this.sdk.appState.user.userBoni.updateBonusTimestamp(redeemBonus.json.bonus, redeemBonus.json.timestamp)
                            this.sdk.appState.user.data.updateCredit(redeemBonus.json.credit)
                            this.sdk.appState.showSnackbarMoney(useAdBonusCash)
                        }
                    } else {
                        this.sdk.appState.showMessagebox('no-ads', 'dialog.user.shop.noads', null, null, true, false, false)
                    }
                    break;
                case GAMEPURCHASE_TYPE.DAILYMONEYBONUS:
                    const useBonus = this.sdk.appState?.user?.userBoni?.getBoniTime(item.bonusId);
                    const useBonusCash = this.sdk.appState?.user?.userBoni?.getBoniCash(item.bonusId);
                    if (useBonus === 0) {
                        redeemBonus = await this.sdk.fetchApi('api', 'bonus', {
                            userToken: this.sdk.appState.user.token,
                            bonus: item.bonusId,
                            coupon: coupon?.token
                        }, 'POST');
                    } else {
                        console.log('not possible')
                    }

                    if (redeemBonus?.status === 200) {
                        this.sdk.appState.user.userBoni.updateBonusTimestamp(redeemBonus.json.bonus, redeemBonus.json.timestamp)
                        this.sdk.appState.user.data.updateCredit(redeemBonus.json.credit)
                        this.sdk.appState.showSnackbarMoney(useBonusCash)
                    }
                    break;
                case GAMEPURCHASE_TYPE.PREMIUM:
                    const getPremiumResult = await this.sdk.fetchApi('api', 'premium', {
                        userToken: this.sdk.appState.user.token,
                        premiumId: item.itemId
                    }, 'PUT');

                    if (getPremiumResult.status === 200) {
                        console.log(getPremiumResult)
                        if (!this.sdk.appState.user.premium) {
                            triggerEvent('shop.premium', {
                                paidToken: getPremiumResult.json.paidToken
                            })
                        }
                        const snackbarKey = this.sdk.appState.user.premium ? 'snackbar.shop.vip.extended' : 'snackbar.shop.vip.buy';

                        this.sdk.appState.user.data.updatePaidToken(getPremiumResult.json.paidToken)
                        this.sdk.appState.user.data.updateCoins(getPremiumResult.json.taler)
                        this.sdk.appState.user.data.updatePremium(getPremiumResult.json)

                        const that = this;
                        (getPremiumResult?.json?.feature || []).forEach((feat: string) => {
                            that.sdk.appState.user.data.updateFeature(feat)
                        })

                        this.sdk.appState.showSnackbar(snackbarKey)

                    } else if (getPremiumResult?.json?.error === 'NoCoin') {
                        this.sdk.appState.showMessagebox('no-credits', 'dialog.user.coins.notenough', null, null, true, false, false)
                    }

                    break;
                default:
                    break;
            }
        }

        if (useSpinner) {
            useSpinner.close()
        }
    }

    /**
    * Call the validtator to verify the product
    * @param {*} p 
    */
    verifyPurchase(product: any) {
        product['userToken'] = this.sdk?.appState?.user?.token || null;
        console.log('[INAPP] Try to validate', JSON.stringify(product))
        product.verify();
    }

    /**
     * Finish the purchase of a product
     * @param {} p 
     */
    finishPurchase(receipt: any) {

        console.log('[INAPP] finish', JSON.stringify(receipt))
        // let purchaseResult = false;

        try {
            this.sdk.appState.user.resetLastUpdate();
        } catch (e: any) { }

        try {
            this.currentPurchase.resolve(true);
        } catch (e) {
            console.log(e)
        }

        // if (this.currentPurchase) {
        // this.purchasedItem.set(this.currentPurchase.id, true);
        // this.savePurchasedItems();
        // purchaseResult = true;
        // } else {

        // if (p.transaction && p.transaction.appStoreReceipt) {

        //     const getProductId = this.productId(p.id);

        //     if (getProductId) {

        //         this.purchasedItem.set(getProductId.id, true);
        //         this.savePurchasedItems();
        //     }
        // }

        // }

        // product.finish();

        // try {
        //     this.currentPurchase.resolve(purchaseResult);
        // } catch (e) {
        // }
        // this.currentPurchase = null;
    }

    cancelPurchase() {

        console.log('[INAPP] cancel')
        if (this.currentPurchase) {
            try {
                console.log('[INAPP] FINISH CALLBACK')
                this.currentPurchase.resolve(false);
            } catch (e) {
                console.log('[INAPP] FINISH CALLBACK ERROR')
            }

            this.currentPurchase = null;
        }
    }

    savePurchasedItems() {
        const mapToArray: any = [];
        this.purchasedItem.forEach((_, key) => mapToArray.push(key));
        this.sdk.saveInStorage('shop', { map: mapToArray });
    }

    loadPurchasedItems() {
        const loadItems = this.sdk.loadFromStorage('shop') || undefined;

        if (loadItems && loadItems.map) {
            loadItems.map.forEach((item: any) => this.purchasedItem.set(item, true));
        }
    }

    get creditList() {
        return PURCHASES.filter((item: any) => {
            if (item?.type === GAMEPURCHASE_TYPE.MONEY) return true;
            if (item?.type === GAMEPURCHASE_TYPE.DAILYMONEYBONUS) return true;
            if (item?.type === GAMEPURCHASE_TYPE.ADMONEYBONUS) return true;
            return false;
        }).sort((a, b) => ((a?.weight || 0) - (b?.weight || 0)));
    }

    get coinList() {
        return PURCHASES.filter((item: any) => {
            if (item?.type === GAMEPURCHASE_TYPE.COINS) return true;
            return false;
        }).map((item: any) => {
            const newItemId = this.updateItemId(item.itemId)
            return {
                ...item,
                itemId: newItemId,
                price: this.CdvPurchase?.store?.get(`${newItemId}`, this.getPlatform())?.raw?.price ||
                    this.CdvPurchase?.store?.get(`${newItemId}`, this.getPlatform())?.pricing?.price ||
                    '-'
            }
        })
            .sort((a, b) => ((a?.weight || 0) - (b?.weight || 0)));
    }

    get premiumList() {
        return PURCHASES.filter((item: any) => {
            if (item?.type === GAMEPURCHASE_TYPE.PREMIUM) return true;
            return false;
        }).sort((a, b) => ((a?.weight || 0) - (b?.weight || 0)));
    }
    get assetList() {
        return PURCHASES.filter((item: any) => {
            if (item?.type === GAMEPURCHASE_TYPE.ASSETS) return true;
            return false;
        }).map((item: any) => ({
            ...item,
            purchased: this.sdk.appState.user.data.feature.includes(item.itemId)
        })).sort((a, b) => ((a?.weight || 0) - (b?.weight || 0)));
    }
}

export default GameShop;