import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    IconButton
} from '../../controls';
import PlayerDetail from './player/PlayerDetail';
import PlayerEmptySeat from './player/PlayerEmptySeat';
import BlindSetting from './blinds/BlindSetting';
import RoomSetting from './room/RoomSetting';
import GameOverlayMenu from './GameOverlaySubmenu';
import TrainerHelp from './trainer/TrainerHelp';
import {
    translateKey,
    getAvatarImage
} from '../../../utils/helper';

const GameOverlay = observer(({ sdk }: any) => {
    const {
        appState,
        gameLoader
    } = sdk;
    const gameSession = appState?.currentSession;
    const currentOverlay = gameSession?.overlay || null;
    const overlayOpen = !gameLoader.isActive && currentOverlay !== null;

    const handleOnBack = () => {
        gameSession.openPreviousOverlay();
    }

    const handleOnNext = (data: any) => {
        gameSession.openOverlay(data);
    }

    let overlayTitle = '';
    let overlayTitleOptions: any = {
        upperSize: false
    };
    let overlayImage = {
        src: './data/ui/user/avatar.svg',
        circle: false
    };

    if (currentOverlay) {
        if (currentOverlay.overlayType === 'player') {
            overlayTitle = currentOverlay.overlayObj.name
            overlayTitleOptions = {
                disableTranslation: true
            };
            overlayImage = getAvatarImage(currentOverlay.overlayObj?.avatar)
        } else if (currentOverlay.overlayType === 'blinds') {
            overlayTitle = 'text.overlay.admin.blindsetting'
            overlayTitleOptions = {
                upperSize: true
            };
            overlayImage = {
                src: './data/icon/casino-chip.svg',
                circle: false
            }
        } else if (currentOverlay.overlayType === 'room') {
            overlayTitle = 'text.overlay.admin.blindsetting'
            overlayTitleOptions = {
                upperSize: true
            };
            overlayImage = {
                src: './data/icon/table.svg',
                circle: false
            }
        } else if (currentOverlay.overlayType === 'trainer') {
            overlayImage = {
                src: './data/icon/faq.svg',
                circle: false
            }
            overlayTitle = 'text.overlay.admin.help'
        }
    }
    return overlayOpen ? (
        <div
            id={'gameoverlay'}
            onClick={() => gameSession.closeOverlay()}
            style={{
                zIndex: 53
            }}
        >
            <div
                id={'gameoverlay_content'}
                key={1}
                // style={sdk.layoutManager.calculateCanvas}
                style={{
                    ...sdk.layoutManager.calculateCanvas,
                    transform: gameSession?.ui?.flagOverlayRotated === 1 ? 'rotate(180deg)' : undefined
                }}
            >
                <Container
                    containerOption={{
                        position: 'relative',
                        float: 'right',
                        widthPx: 420,
                        bgColor: 'rgba(0,0,0,0.8)',
                        height: '100%',
                        borderSize: 4,
                        borderRight: 'solid #555555',
                        borderLeft: 'solid #555555'
                    }}
                    onClick={(event: any) => event.stopPropagation()}
                >
                    <Container
                        containerOption={{
                            float: 'right',
                            width: '100%',
                            heightPx: 48,
                            lineHeight: 48,
                            bgColor: '#555555',
                            fontSize: 20,
                            color: '#fff',
                            fontWeight: 'bold'
                        }}
                    >
                        {overlayTitleOptions?.disableTranslation ? overlayTitle : translateKey(overlayTitle, overlayTitleOptions)}
                    </Container>
                    <Container
                        id={`overlay-image`}
                        containerOption={{
                            position: 'relative',
                            widthPx: 160,
                            heightPx: 160,
                            margin: [16, 130, 16, 0],
                            float: 'right',
                            bgImage: `url(${overlayImage.src})`,
                            borderRadiusPercent: overlayImage.circle ? '50' : undefined,
                            bgRepeat: 'no-repeat',
                            bgImageSize: 'contain'
                        }}
                    />
                    {gameSession?.currentActor?.tv ? (
                        <IconButton
                            buttonOption={{
                                position: 'absolute',
                                right: 16,
                                top: 60,
                                widthPx: 48,
                                heightPx: 48
                            }}
                            source={'./data/icon/phonerotate.svg'}
                            onClick={() => {

                                gameSession?.ui?.updateFlag('flagOverlayRotated', 1 - (gameSession?.ui?.flagOverlayRotated || 0))
                            }}
                        />
                    ) : null}
                    {gameSession?.sessionClock?.timerDisplay ? (
                        <Container
                            containerOption={{
                                position: 'absolute',
                                right: 0,
                                top: 180,
                                widthPx: 128,
                                heightPx: 64,
                                lineHeight: 64,
                                fontSize: 24,
                                color: '#fff'
                            }}
                        >
                            {gameSession.sessionClock.timerDisplay}
                        </Container>
                    ) : null}
                    <Container
                        containerOption={{
                            float: 'right',
                            width: '100%',
                            heightPx: 48,
                            lineHeight: 48,
                            bgColor: '#484848',
                            fontSize: 20,
                            color: '#fff',
                            fontWeight: 'bold'
                        }}
                    >
                        {translateKey('text.overlay.menu')}
                    </Container>
                    {currentOverlay.overlayView === 'emptyseat' ? <PlayerEmptySeat sdk={sdk} onNextItem={handleOnNext} /> : null}
                    {currentOverlay.overlayView === 'playerdetail' ? <PlayerDetail sdk={sdk} onNextItem={handleOnNext} /> : null}
                    {currentOverlay.overlayView === 'blindsetting' ? <BlindSetting sdk={sdk} onNextItem={handleOnNext} /> : null}
                    {currentOverlay.overlayView === 'roomsetting' ? <RoomSetting sdk={sdk} onNextItem={handleOnNext} /> : null}
                    {currentOverlay.overlayView === 'pokertrainer' ? <TrainerHelp sdk={sdk} onNextItem={handleOnNext} /> : null}
                </Container>
                <GameOverlayMenu sdk={sdk} />
            </div>
        </div>

    ) : null

});

export default GameOverlay;