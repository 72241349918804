import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../controls';

const SettingTvLayout = observer(({
    sdk,
    onNextItem
}: any) => {
    const currentSession = sdk?.appState?.currentSession;

    return <List
        className={'settinglist'}
        containerOption={{
            widthPx: 930,
            heightPx: 600,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left'
        }}
        listOption={{
            widthPx: 930,
            heightPx: 100,
            lineHeight: 100,
            color: '#fff',
            fontSize: 28,
            borderSize: 2,
            borderBottom: `solid #fff`
        }}
        components={[
            <ConfiguratorItem
                label={'checkbox.tvmode.tablet'}
                onClick={() => {
                    currentSession.setTvMode('tablet')
                    sdk.appState.closeSetting()
                }}
            />,
            <ConfiguratorItem
                label={'checkbox.tvmode.tv'}
                onClick={() => {
                    currentSession.setTvMode('tv')
                    sdk.appState.closeSetting()
                }}
            />
        ]}
    />
});

export default SettingTvLayout;