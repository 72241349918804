const LANGUAGE:any = {
    GERMAN: 'de',
    ENGLISH: 'en',
    SPAIN: 'es'
}

const SUPPORTED_LANGUAGES:any = {
    'de': true,
    'en': true,
    'es': true
}

export {
    LANGUAGE,
    SUPPORTED_LANGUAGES
}