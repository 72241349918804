import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ImageComponent
} from '../../../controls';
import {
    getRenderIcon
} from './helper';

const PokerTablePlayerEmpty = observer(({
    pos,
    containerOption,
    gameSession
}: any) => {
    const isAllowed = gameSession.tableSeatState?.[pos] === 1;
    const renderIcon = getRenderIcon(gameSession?.currentMatch?.getSeatRole(pos) || undefined);
    return <Container
        id={`seat-${pos}-avatar`}
        containerOption={{
            position: 'relative',
            widthPx: 90,
            heightPx: 90,
            lineHeight: 90,
            margin: [12, 4, 0, 0],
            border: isAllowed ? 'solid rgb(255,255,255)' : 'solid rgb(255,0,0)',
            borderSize: 4,
            borderRadiusPercent: '50',
            bgRepeat: 'no-repeat',
            bgImageSize: 'contain',
            color: isAllowed ? '#fff' : '#ff0000',
            fontWeight: 'bold',
            opacity: 0.5,
            fontSize: 32,
            ...(containerOption || {})
        }}
        onClick={() => {
            if (gameSession.ui.buttonSingleBuyIn === 2) {
                gameSession.joinMatch(pos)
            } else if (gameSession.ui.buttonDealer === 2) {
                gameSession.openOverlay(
                    {
                        pos
                    },
                    'seat',
                    'emptyseat'
                )
            }
        }}
    >
        {isAllowed ? `${pos + 1}` : 'X'}
        {renderIcon ? <ImageComponent
            imageOption={{
                position: 'absolute',
                widthPx: 32,
                right: 0,
                bottom: 0,
                ...(renderIcon.options || {})
            }}
            source={renderIcon.source}
        /> : null}
    </Container >
});

export default PokerTablePlayerEmpty;