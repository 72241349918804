import {
    makeObservable,
    observable,
    action,
    computed,
    runInAction
} from 'mobx'
import type {
    CouchgamesSdk
} from '../index';
import {
    timestampMs
} from '../../utils/helper';

export default class PublicList {
    private sdk: CouchgamesSdk;
    public roomList: Map<string, any>;
    public filter: number;
    public lastRequest: number;

    constructor(sdk: CouchgamesSdk) {
        makeObservable(this, {
            roomList: observable,
            filter: observable,
            handleGameList: action,
            gameList: computed
        });
        this.sdk = sdk;
        this.roomList = new Map<string, any>();
        this.filter = 0;
        this.lastRequest = 0;
    }

    public async requestGameList(timeCheck: number = 30000): Promise<boolean> {
        if (timeCheck > 0) {
            if ((timestampMs() - this.lastRequest) >= timeCheck) {
            } else {
                return false;
            }
        }

        this.lastRequest = timestampMs();

        const useSpinner = this.sdk.appState.createSpinner();
        const result = await this.sdk.fetchApi('api', 'session', {}, 'GET')

        if (result?.status === 200) {
            const answer = result?.json;
            this.handleGameList(JSON.stringify(answer) !== '{}' ? answer : [])
        }

        useSpinner.close();
        // https://gamingapi.couchgames.wtf/poker/session/?service=pokerappdev
        return true;
    }

    public handleGameList(list: any): void {

        const roomToRemove: Map<string, boolean> = new Map();

        // #####################################################
        // Fill the list with all actors to remove
        this.roomList.forEach((_v, code: string): any => {
            roomToRemove.set(code, true);
        });

        list.forEach((entry: any) => {
            const that = this;
            if (roomToRemove.has(entry.code)) {
                roomToRemove.delete(entry.code);
            }

            runInAction(() => {
                if (entry?.config) {
                    if (that.roomList.has(entry.code)) {
                        const updRoom: any = that.roomList.get(entry.code);
                        if (updRoom.buyinMax !== entry.config.buyinMax) {
                            updRoom.buyinMax = entry.config.buyinMax;
                        }
                        if (updRoom.buyinMin !== entry.config.buyinMin) {
                            updRoom.buyinMin = entry.config.buyinMin;
                        }
                        if (updRoom.player !== entry.config.player) {
                            updRoom.player = entry.config.player;
                        }
                        if (updRoom.maxPlayer !== entry.config.maxPlayer) {
                            updRoom.maxPlayer = entry.config.maxPlayer;
                        }
                        if (updRoom.sb !== entry.config.sb) {
                            updRoom.sb = entry.config.sb;
                        }
                        if (updRoom.bb !== entry.config.bb) {
                            updRoom.bb = entry.config.bb;
                        }
                    } else {
                        that.roomList.set(entry.code, {
                            ...entry.config,
                            code: entry.code
                        })
                    }
                }
            })
        })

        console.log(roomToRemove)
        if (roomToRemove.size) {
            roomToRemove.forEach((_v, code: string): any => {
                runInAction(() => {
                    this.roomList.delete(code);
                });
            });

        }
    }

    get gameList(): any {
        const bList: any = [];
        this.roomList.forEach((obj) => {
            bList.push(obj)
        })

        return bList.sort((a: any, b: any) => {
            if (this.filter === 0) {
                return a.buyinMin - b.buyinMin;
            }
            return 1;
        });
    }
}