import {
    observer
} from 'mobx-react-lite';
import PokerTableView from '../table/PokerTableView';
import TutorialDragAndDrop from '../tutorial/TutorialDragAndDrop'
import PokerHandButtons from '../hand/PokerHandButtons';
import PokerChip from '../hand/PokerChip';
import {
    ActionButtonRound
} from '../../../controls/index';
import {
    translateKey
} from '../../../../utils/helper';

const GameOnlineView = observer(({
    sdk
}: any) => {
    const gameSession = sdk?.appState?.currentSession;
    const allowShowFold = gameSession?.tableConfig?.flagAllowShowFold === 1; // Was hatte ich
    const showStaticHand = gameSession?.currentActor?.matchData?.handRevealed === 3 && !gameSession?.currentActor?.matchData?.folded;
    const showStaticFoldedHand = ((gameSession?.currentActor?.matchData?.folded || gameSession?.currentActor?.matchData?.prefolded) && allowShowFold);

    return <>
        <PokerTableView
            sdk={sdk}
        />
        <PokerHandButtons
            gameSession={gameSession}
        />
        <TutorialDragAndDrop
            gameSession={gameSession}
            appState={sdk?.appState}
        />
        {/* {showStaticFoldedHand ?
            <PokerHandStaticFold
                cardWidth={148}
                hugeOverlappedView={false}
                gameSession={gameSession}
                containerOption={{
                    position: 'absolute',
                    margin: [5, 10, 0, 0],
                    left: 0,
                    bottom: 0,
                    zIndex: 1,
                }}
            />
            : showStaticHand ? <PokerHandStatic
                cardWidth={148}
                gameSession={gameSession}
                containerOption={{
                    position: 'absolute',
                    margin: [5, 10, 0, 0],
                    left: 0,
                    bottom: 0,
                    zIndex: 1,
                }}
            /> : <PokerHand
                gameSession={gameSession}
                // zoomOnTap={1.2}
                onFold={() => sdk.appState.showSecurityBox('dialog.game.security.fold', () => sdk.getMessageList().fold(), 'dummyFoldDisabled')}
                leftMargin={48}
            />} */}
        {/* {(gameSession?.currentActor?.matchData?.folded || gameSession?.currentActor?.matchData?.prefolded) ?
            <Container
                containerOption={{
                    position: 'absolute',
                    left: 16,
                    bottom: 80,
                    widthPx: showStaticFoldedHand ? 312 : 200,
                    heightPx: 80,
                    zIndex: 10,
                    bgColor: `#ca0101`,
                    color: '#fff',
                    fontWeight: 'bold',
                    lineHeight: 80,
                    fontSize: 48
                }}
            >
                {translateKey('game.text.handview.folded')}
            </Container>
            : null} */}
        <PokerChip
            gameSession={gameSession}
        />
        <ActionButtonRound
            actionColor={'green'}
            translationKey={'game.button.handview.newgame'}
            buttonOption={{
                position: 'absolute',
                right: 16,
                bottom: 32,
                zIndex: 1,
                widthPx: 96,
                heightPx: 96,
                fontSize: 24,
                outlineBorderSize: 2,
            }}
            buttonVisibility={gameSession?.ui?.buttonNewGame}
            onClick={() => sdk.getMessageList().startMatch()}
        />
        <ActionButtonRound
            actionColor={'blue'}
            buttonOption={{
                position: 'absolute',
                right: 16,
                bottom: 32,
                zIndex: 1,
                widthPx: 96,
                heightPx: 96,
                fontSize: 24,
                outlineBorderSize: 2,
                bgImage: 'url(./data/clock.png)',
                bgRepeat: 'no-repeat',
                bgImageSize: 'contain',
                color: '#0096fe'
            }}
            enableDelay={false}
            buttonVisibility={gameSession?.ui?.infoNextRound}
            onClick={() => null}
        >
            {gameSession?.currentMatch?.roundTimer?.timerDisplay || `00`}
        </ActionButtonRound>
        <ActionButtonRound
            actionColor={'green'}
            translationKey={'game.button.handview.deal'}
            buttonOption={{
                position: 'absolute',
                right: 16,
                bottom: 32,
                zIndex: 1,
                widthPx: 96,
                heightPx: 96,
                fontSize: 24,
                outlineBorderSize: 2,
            }}
            buttonVisibility={gameSession?.ui?.buttonDeal}
            onClick={() => sdk.getMessageList().nextRound()}
        />
        <ActionButtonRound
            actionColor={'green'}
            translationKey={gameSession?.currentMatch?.round?.status === 3 ?
                'game.button.handview.deal.showdown' :
                'game.button.handview.deal'
            }
            enableDelay={3000}
            buttonOption={{
                position: 'absolute',
                right: 16,
                bottom: 32,
                zIndex: 1,
                widthPx: 96,
                heightPx: 96,
                fontSize: 22,
                outlineBorderSize: 2,
            }}
            buttonVisibility={gameSession?.ui?.buttonAllInDeal}
            onClick={() => sdk.getMessageList().allInDeal()}
        />

    </>
})

export default GameOnlineView;