import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../controls';

const SettingDevice = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;

    return <List
        className={'settinglist'}
        containerOption={{
            widthPx: 930,
            heightPx: 600,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left'
        }
        }
        listOption={{
            widthPx: 930,
            heightPx: 100,
            lineHeight: 100,
            color: '#fff',
            fontSize: 28,
            borderSize: 2,
            borderBottom: `solid #fff`
        }}
        components={
            [
                <ConfiguratorItem
                    label={appState.options.vibration ? 'text.settings.vibration.on' : 'text.settings.vibration.off'}
                    onClick={() => appState.options.switchVibration()}
                    icon={'vibrate'}
                />,
                <ConfiguratorItem
                    label={`text.settings.reveal.sensibility`}
                    labelSplitValue={appState.options.handRevealSensitive}
                    onClick={() => appState.options.switchHandRevealSensibility()}
                    icon={'sensitive'}
                />,
                <ConfiguratorItem
                    label={`text.settings.fold.sensibility`}
                    labelSplitValue={appState.options.handFoldSensitive}
                    onClick={() => appState.options.switchHandFoldSensibility()}
                    icon={'sensitive'}
                />,
                <ConfiguratorItem
                    label={`text.settings.security.fold`}
                    labelSplitValue={appState.options.dummyFoldDisabled}
                    onClick={() => appState.options.switchFoldSecurity()}
                    icon={appState.options.dummyFoldDisabled ? 'security0' : 'security1'}
                />,
                <ConfiguratorItem
                    label={`text.settings.security.reveal`}
                    labelSplitValue={appState.options.dummyRevealDisabled}
                    onClick={() => appState.options.switchRevealSecurity()}
                    icon={appState.options.dummyRevealDisabled ? 'security0' : 'security1'}
                />
            ]}
    />
});

export default SettingDevice;