import {
    currentLanguage,
    translateKey
} from './helper';
const PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=<pck_name>';
const APPSTORE_URL = 'https://apps.apple.com/app/<store-NAME>/id<store-ID>';

function canShare() {
    if (process.env.REACT_APP_TYPE === 'android') {
        return window && window.plugins && window.plugins.socialsharing;
    } else if(process.env.REACT_APP_TYPE === 'ios') {
        return true;
    }
    return true;
}

function onShare(shareType: string, shareOptions: any = {}) {
    let message: any = undefined;
    const gameUrl = shareOptions?.shareUrl || 'https://bullets.poker/links';

    switch (shareType) {
        case 'invite':
            const inviteSplit = (translateKey('text.common.invite') || '|').split('|');
            
            message = {
                title: inviteSplit[0],
                text:  inviteSplit[1],
                url: gameUrl
            };
            message.text = message.text.replace('$name', shareOptions?.playerName || '');
            message.text = message.text.replace('$tablecode', shareOptions?.roomCode || '');
            break;
        default:
            break;
    }

    if (message) {
        if (process.env.REACT_APP_TYPE === 'android') {
            const options = {
                message: message?.text || '', // not supported on some apps (Facebook, Instagram)
                subject: message?.title || '', // fi. for email
                url: message?.url || ''
            };

            const onSuccess = function (result: any) {
                console.log("[SHARE] Share completed? " + result.completed); // On Android apps mostly return false even while it's true
            };

            const onError = function (msg: any) {
                console.log("[SHARE] Sharing failed with message: " + msg);
            };

            if (window && window.plugins && window.plugins.socialsharing) {
                window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
            }
        } else {
            const messageTitle = message?.title || '';
            const messageText = message?.text || '';
            const messageUrl = message?.url || '';

            try {
                navigator.share(
                    {
                        title: messageTitle,
                        text: messageText,
                        url: messageUrl
                    }
                );
            } catch (e) {
            }
        }
    }
}

function rateAppPossible() {
    return process.env.REACT_APP_TYPE === 'ios' || process.env.REACT_APP_TYPE === 'android';
}


function onRateApp() {
    if (process.env.REACT_APP_TYPE === 'ios' && process.env.REACT_APP_MOBILE_IOS === 'true') {
        try {
            window.couchgames.requestReview(() => {
            }, () => null);
        } catch (e) {
        }
    }
    if (process.env.REACT_APP_TYPE === 'android' && process.env.REACT_APP_MOBILE_ANDROID === 'true') return openAndroidApp();
    return null;
}


const openSupport = () => {
    try {
        window.open(process.env.REACT_APP_SUPPORT);
    } catch (e) { }
}

const openImprint = () => {
    if (currentLanguage() === 'de') {
        window.open('https://couchgames.wtf/impressum/?lang=de');
    } else {
        window.open('https://couchgames.wtf/imprint/?lang=en');
    }
}

const openAboutUs = () => {
    if (currentLanguage() === 'de') {
        window.open('https://couchgames.wtf/about/?lang=de');
    } else {
        window.open('https://couchgames.wtf/about-us/');
    }
}

function openAndroidApp(appId: string | undefined = process.env.REACT_APP_PLAYSTORE_ID) {
    if (appId) {
        window.open(PLAYSTORE_URL.replace('<pck_name>', appId));
    }
    return true;
}

function openIosApp(appId: string | undefined = process.env.REACT_APP_APPSTORE_ID, appName: string | undefined = process.env.REACT_APP_APPSTORE_NAME) {
    if (appId && appName) {
        window.open(APPSTORE_URL
            .replace('<store-ID>', appId)
            .replace('<store-NAME>', appName)
        )
    }
    return true;
}

export {
    openImprint,
    openAboutUs,
    openAndroidApp,
    openIosApp,
    openSupport,
    onRateApp,
    rateAppPossible,
    canShare,
    onShare
}