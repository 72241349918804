import {
    observer
} from 'mobx-react-lite';
import {
    translateKey
} from '../../../utils/helper'
import getSdk from '../../../api'

const Label = observer(({
    id,
    value,
    labelOption
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const propsLabel = useStyles({
        float: 'left',
        widthPx: 440,
        heightPx: 60,
        lineHeight: 60,
        color: '#c1c1c1',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'left',
        ...(labelOption || {})
    });

    return <div
        id={id}
        style={propsLabel}
    >
        {value}
    </div>
});

export default Label;