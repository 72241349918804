import {
    observer
} from 'mobx-react-lite';
import {
    Container
} from '../../../controls';
import PokerTablePlayerAvatar from '../player/PlayerAvatar';
import PokerTablePlayerBet from '../player/PlayerBet';
import PokerTablePlayerHand from '../player/PlayerHand';
import PokerTablePlayerBubble from '../player/PlayerBubble';
import PokerTablePlayerBankroll from '../player/PlayerBankroll';
import PokerTablePlayerName from '../player/PlayerName';
import PokerTablePlayerKrone from '../player/PlayerKrone';
import PokerTablePlayerEmpty from '../player/PlayerEmpty';

const PokerTablePlayer = observer(({
    pos,
    gameSession
}: any) => {
    const usePos = gameSession.calculateSeatPosition(pos);
    const gameSeats = gameSession.seats;
    const player = gameSeats[usePos];
    const seatVisible = !!player || false;
    const isCurrentPlayer = (!gameSession?.isDigitalCardMode &&
        gameSession?.currentMatch?.round?.currentPlayer === player?.id) || (gameSession?.isDigitalCardMode && player?.matchData?.pointerTurn);
    const forceHandReveal = !gameSession?.isDigitalCardMode && player?.me;
    const addMarginTop = (gameSession?.isDigitalCardMode && [0, 1, 2, 3].includes(pos) ? 0 : 0);
    const addMarginTopEmpty = addMarginTop || (!gameSession?.isDigitalCardMode && [5, 6, 7, 8].includes(pos) ? 40 : 0)
    const seatSelectable = gameSession?.tableConfig?.flagAutoSitDown === 0;
    // const emptyPlayerVisible = (seatSelectable && !gameSession?.currentActor?.sitting) || (!gameSession?.currentActor?.sitting && !gameSession?.tvMode);
    const emptyPlayerVisible = true;
    let opacity = 1.0;

    if (seatVisible) {
        if (!player.active) {
            opacity = 0.3;
        }
    }
    return seatVisible ? <Container
        id={`seat-${usePos}`}
        className={isCurrentPlayer ? 'placer-active' : undefined}
        containerOption={{
            position: 'relative',
            float: 'left',
            widthPx: 200,
            heightPx: 200,
            opacity,
            margin: [10 + addMarginTop, 10, 10, 10],
            borderRadius: isCurrentPlayer ? 16 : undefined
        }}
        onClick={() => {
            if (seatVisible) {
                gameSession.openOverlay(
                    player,
                    'player',
                    'playerdetail'
                )
            }
        }}
    >
        <PokerTablePlayerBet
            pos={usePos}
            origPos={pos}
            player={player}
            gameSession={gameSession}
            position={'top'}
            visibleTop={[5, 6, 7, 8]}
        />
        <PokerTablePlayerAvatar
            pos={usePos}
            gameSession={gameSession}
            player={player}
            iconPosition={'bottom'}
        />
        {/* <PokerTablePlayerBubble
            pos={usePos}
            player={player}
            gameSession={gameSession}
        /> */}
        <PokerTablePlayerKrone
            pos={usePos}
            player={player}
            imageOption={{
                top: [5, 6, 7, 8].includes(pos) ? 30 : -10
            }}
        />
        <PokerTablePlayerHand
            pos={usePos}
            player={player}
            containerOption={undefined}
            cardWidth={42}
            featHandReveal={forceHandReveal}
            cardOption={{
                margin: [0, 0, 0, 2]
            }}
            gameSession={gameSession}
        />
        <PokerTablePlayerName
            pos={usePos}
            player={player}
        />
        <PokerTablePlayerBankroll
            pos={usePos}
            player={player}
            gameSession={gameSession}
        />
        <PokerTablePlayerBet
            pos={usePos}
            origPos={pos}
            gameSession={gameSession}
            player={player}
            visibleBottom={[0, 1, 2, 3, 4, 9]}
        />
    </Container> : <Container
        id={`seat-empty-${usePos}`}
        containerOption={{
            position: 'relative',
            float: 'left',
            widthPx: 200,
            heightPx: 200,
            margin: [10, 10, 10, 10]
        }}
    >
        {emptyPlayerVisible ? (
            <PokerTablePlayerEmpty
                pos={usePos}
                containerOption={{
                    float: 'right',
                    margin: [10 + addMarginTopEmpty, 0, 0, 0]
                }}
                gameSession={gameSession}
            />
        ) : null}

    </Container>;
})

export default PokerTablePlayer;