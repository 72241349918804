import {
    observer
} from 'mobx-react-lite';
import PokerTableView from '../table/PokerTableView';
import {
    POKERVIEW
} from '../../../../constants/gameViews'
import {
    ActionButtonRound
} from '../../../controls/index';
import GameDigitalCardLandscape from './DigitalCard/DigitalCardLandscape';
import GameDigitalCardPortrait from './DigitalCard/DigitalCardPortrait';
import GameDealerButton from '../dealer/GameDealerButton';

const GameDigitalCardView = observer(({
    sdk
}: any) => {
    const {
        appState
    } = sdk;
    const currentSession = appState?.currentSession;

    return <>
        {appState?.subView === POKERVIEW.TABLE || !currentSession?.currentActor?.sitting ?
            <>
                <PokerTableView
                    sdk={sdk}
                />
                <GameDealerButton gameSession={currentSession} />
                <ActionButtonRound
                    actionColor={'blue'}
                    buttonOption={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        widthPx: 96,
                        heightPx: 96,
                        margin: [90, 18, 0, 0],
                        outlineBorderSize: 2,
                        borderSize: 4,
                        bgImage: 'url(./data/ui/mode2.png)',
                        bgRepeat: 'no-repeat',
                        bgImageSize: 'contain'
                    }}
                    buttonVisibility={currentSession?.ui?.buttonHand}
                    onClick={() => {
                        if (currentSession?.currentActor?.seat !== null) {
                            if (currentSession?.currentActor?.sitting) {
                                appState.updateValue('subView', POKERVIEW.HAND)
                            } else {
                                if(currentSession?.tableConfig?.flagAutoSitDown) {
                                    appState.showMessagebox(
                                        'dialog.sitdown',
                                        'dialog.sitdown',
                                        () => currentSession?.joinMatch(),
                                        () => null,
                                        false
                                    )
                                } else {
                                    appState.showMessagebox(
                                        'dialog.noseat',
                                        'dialog.noseat',
                                        null,
                                        null,
                                        true
                                    )
                                }
                            }
                        } else {
                            appState.showMessagebox(
                                'dialog.noseat',
                                'dialog.noseat',
                                null,
                                null,
                                true
                            )
                        }
                    }}
                />
            </> : null}
        {(appState?.subView === POKERVIEW.HAND && currentSession?.currentActor?.sitting) ?
            (<>{
                sdk.appState.digitalCardView === 0 ? (
                    <GameDigitalCardLandscape sdk={sdk} gameSession={currentSession} />
                ) : (
                    <GameDigitalCardPortrait sdk={sdk} gameSession={currentSession} />
                )
            }</>)
            : null}
    </>
})

export default GameDigitalCardView;