import {
    observer
} from 'mobx-react-lite';
import {
    translateKey,
    convertNumberToString
} from '../../utils/helper';
import {
    Container,
    IconButton,
    ImageComponent,
    ActionButton,
    Icon,
    Checkbox
} from '../controls/index';

const DialogDoNotShow = observer(({ sdk, storeProperty }: any) => {
    return <Container
        containerOption={{
            widthPx: 340,
            heightPx: 80,
            float: 'left',
            margin: [96, 48, 0, 160],
            padding: undefined,
            lineHeight: 80,
            bgColor: '#fff',
            borderRadius: 16
        }}
    >
        <Checkbox
            id={'checkbox_security'}
            label={'dialog.game.security.dontshowagain'}
            checkboxNameAddition={'red'}
            containerOption={{
                widthPx: 310,
                margin: [16, 0, 0, 16]
            }}
            labelOption={{
                widthPx: 240,
                color: '#d81b0f'
            }}
            store={sdk.appState.options}
            storeProperty={storeProperty}
        />
    </Container>
});

const DialogComponent = observer(({ sdk }: any) => {
    const {
        appState,
        layoutManager
    } = sdk;
    const dialog = appState?.currentDialog;
    const dialogVisible = dialog && !appState?.currentSession?.demoExpired
    const errorDialog = dialog?.type === 'error';
    // const warningDialog = dialog?.type === 'warning';
    const coinDialog = dialog?.type === 'coin';
    const creditDialog = dialog?.type === 'nocredits';
    const premiumDialog = dialog?.type === 'premium' || dialog?.type === 'expire';
    const securityDialog = dialog?.type === 'security';
    const isPreview = dialog?.type === 'preview';
    const onBuyInInfo = dialog?.config?.onBuyIn || null;

    const getBackgroundColor = (type: string) => {
        switch (type) {
            case 'warning':
                return '#ff1e1e';
            case 'error':
                return '#FC6E6E';
            case 'nocredits':
                return '#FC6E6E';
            case 'premium':
                return '#17900e';
            case 'security':
                return '#9b140c';
            default:
                break;
        }
        return '#062860';
    }

    return dialogVisible ? <div
        id={appState?.rotatedView ? 'gamedialog_rotated' : 'gamedialog'}
        onClick={() => dialog.closeOutside ? appState.closeDialog() : null}
        style={appState?.rotatedView ? {
            transform: 'rotate(-90deg)'
        } : {}}
    >
        <div
            id={'gamedialog_content'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'dialog_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    bgColor: getBackgroundColor(dialog.type),
                    margin: [(750 - 362) / 2, 0, 0, (1334 - 640) / 2]
                }}
            >
                <Container
                    id={'dialog_message'}
                    containerOption={{
                        widthPx: 620,
                        heightPx: 160,
                        color: '#fff',
                        // fontSize: 24,
                        fontSize: dialog?.config?.fontSize || 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        role: 'alert'
                    }}
                >
                    {isPreview ? <>
                        <ImageComponent
                            imageOption={{
                                widthPx: dialog.previewWidth || 180,
                                heightPx: dialog.previewHeight || 64,
                                // ...(dialog.previewOption || {})
                            }}
                            source={dialog.preview}
                        />
                    </> : (<>
                        {dialog.text ? translateKey(dialog.text, undefined, dialog?.replacements || []) : dialog.text}
                    </>)}
                </Container>
                {errorDialog || (!dialog.btnYes && !dialog.btnNo) ?
                    <IconButton
                        source={'./data/ui/yes.svg'}
                        buttonOption={{
                            widthPx: 96,
                            heightPx: 96,
                            float: 'left',
                            margin: [0, 75, 0, 150]
                        }}
                        onClick={() => appState.closeDialog()}
                    />
                    : <>
                        {onBuyInInfo ? (
                            <ActionButton
                                actionColor={'orange'}
                                buttonOption={{
                                    float: 'left',
                                    widthPx: 480,
                                    heightToWidth: 7.2,
                                    margin: [0, 75, 0, 75],
                                    padding: undefined,
                                    lineHeight: 48
                                }}
                                onClick={() => {
                                    if(onBuyInInfo?.credits) {
                                        try {
                                            appState?.currentSession?.buyIn(onBuyInInfo.credits, onBuyInInfo.seat)
                                        } catch(e) {}
                                    }
                                }}
                            >
                                <Icon
                                    source={'./data/icon/join.svg'}
                                    iconOption={{
                                        widthPx: 40,
                                        heightPx: 40,
                                        float: 'left'
                                    }}
                                />
                                {translateKey('dialog.buyin.notenough.buyinwith', {}, [
                                    ['$chips', convertNumberToString(onBuyInInfo?.credits || 0)]
                                ])}
                            </ActionButton>
                        ) : null}
                        {creditDialog ? <ActionButton
                            actionColor={'green'}
                            buttonOption={{
                                float: 'left',
                                margin: [onBuyInInfo ? 16 : 0, 75, 0, 75],
                                padding: undefined,
                                lineHeight: 62,
                                fontSize: 18
                            }}
                            onClick={() => appState.openGameStore({
                                view: 'credits',
                                from: 'session'
                            })}
                        >
                            {translateKey('dialog.buyin.notenough.buychips')}
                        </ActionButton> : null}
                        {premiumDialog ? <ActionButton
                            actionColor={'green'}
                            buttonOption={{
                                float: 'left',
                                margin: [0, 75, 0, 75],
                                padding: undefined,
                                lineHeight: 62
                            }}
                            onClick={() => appState.openGameStore({
                                view: 'vip',
                                from: dialog?.openLocation !== undefined ? dialog.openLocation : 'account'
                            })}
                        >
                            <Icon
                                source={'./data/icon/vip.svg'}
                                iconOption={{
                                    widthPx: 48,
                                    heightPx: 48,
                                    float: 'left'
                                }}
                            />Premium
                        </ActionButton>
                            : null}
                        {coinDialog ? (
                            <ActionButton
                                actionColor={'yellow'}
                                buttonOption={{
                                    float: 'left',
                                    margin: [0, 75, 0, 75],
                                    padding: undefined,
                                    lineHeight: 62
                                }}
                                onClick={() => {
                                    if (dialog.btnYes) {
                                        dialog.btnYes();
                                    }
                                    appState.closeDialog();
                                }}
                            >
                                <Icon
                                    source={'./data/ui/coin.png'}
                                    iconOption={{
                                        widthPx: 48,
                                        heightPx: 48,
                                        float: 'left'
                                    }}
                                />
                                {dialog?.config?.coinValue || ''}
                            </ActionButton>
                        ) : null}
                        {dialog.btnYes && !coinDialog ? <IconButton
                            source={'./data/ui/yes.svg'}
                            buttonOption={{
                                widthPx: 96,
                                heightPx: 96,
                                float: 'left',
                                margin: [0, 75, 0, (premiumDialog || creditDialog) ? 75 : 150]
                            }}
                            onClick={() => {
                                if (dialog.btnYes) {
                                    dialog.btnYes();
                                }
                                appState.closeDialog();
                            }}
                        /> : null}
                        {dialog.btnNo ? <IconButton
                            source={'./data/ui/no.svg'}
                            buttonOption={{
                                widthPx: 96,
                                heightPx: 96,
                                float: 'left',
                                margin: [onBuyInInfo ? 16 : 0, 0, 0, 75]
                            }}
                            onClick={() => {
                                if (dialog.btnNo) {
                                    dialog.btnNo();
                                }
                                appState.closeDialog();
                            }}
                        /> : null}
                        {securityDialog && dialog?.storeProperty ? <DialogDoNotShow sdk={sdk} storeProperty={dialog?.storeProperty} /> : null}
                    </>}
            </Container>
        </div>
    </div> : null;
});

export default DialogComponent;