import { useRef, useEffect, useCallback } from 'react';
import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    TextField,
    IconButton
} from '../../../controls';
import {
    radianToDegrees,
    distance,
    translateKey
} from '../../../../utils/helper';
import getSdk from '../../../../api';
const innerClickDistance = 0.4;
const outerClickDistance = 0.92;
let chipSliderDown: any = false;

const delayChipInput = (currentActor: any, realRadien: any) => {
    currentActor.matchData.updateSlider(realRadien)
}

const getActionFontColor = (action: string) => {
    if (action === 'check' || action === 'call') {
        return '#48bf53';
    } else if (action === 'allin') {
        return '#ff1e1e'
    } else if (action === 'deal') {
        return '#0096fe'
    } else if (action === 'raise' || action === 'bet' || action === 'reraise') {
        return '#df830b'
    }
    return '#000'
}

const PokerChipSlider = observer(({
    gameSession,
    skin
}: any) => {
    const currentActor = gameSession?.currentActor;

    return <IconButton
        id={'chipslider'}
        source={`./data/ui/chip/skin${skin}/chip_slider.png`}
        onDragStart={(event: any) => {
            event.preventDefault()
            event.stopPropagation()
        }}
        onMouseDown={(event: any) => {
            if (document.getElementById('chipslider')) {
                //@ts-ignore
                document.getElementById('chipslider').style.pointerEvents = 'none'
                chipSliderDown = true;
            }
            event.preventDefault();
        }}
        draggable={true}
        buttonOption={{
            position: 'absolute',
            widthPx: 40,
            heightPx: 40,
            left: 120 + 90 * Math.sin((currentActor?.matchData?.betSliderAngle || 0) * Math.PI / 180),
            top: 120 + 90 * -Math.cos((currentActor?.matchData?.betSliderAngle || 0) * Math.PI / 180)
        }}
    />
});

const PokerChip = observer(({
    skin = 1,
    gameSession
}: any) => {
    const chipRef: any = useRef(null);
    const currentActor = gameSession?.currentActor;
    const visible = gameSession?.ui?.buttonMulti === 2;

    const multiUpdate = currentActor?.matchData?.multiAction;

    const chipText = multiUpdate?.text ?
        translateKey(`multibutton.action.${multiUpdate.text}`) :
        '';

    const chipImage = (skin: number, preflop: boolean, raise: boolean = false) => {
        return `url(./data/ui/chip/skin${skin}/chip_${raise === true ? 'raise' : preflop ? 'bet_preflop' : 'bet_postflop'}.png)`;
    }

    const useMouseMoveHandler = (event: any) => {
        if (chipSliderDown && event.target.id === 'pokerchip-container') {
            const clientRect = event.target.getBoundingClientRect();
            const offsetX = event?.offsetX || event.clientX - clientRect.left;
            const offsetY = event?.offsetY || event.clientY - clientRect.top;

            const boxWidth = chipRef?.current?.offsetWidth || 0;
            const boxHeight = chipRef?.current?.offsetHeight || 0;

            const boxMiddleX = boxWidth / 2;
            const boxMiddleY = boxHeight / 2;

            const dx = offsetX - boxMiddleX;
            const dy = offsetY - boxMiddleY;
            const angle = Math.atan2(dy, dx);

            const radien = radianToDegrees(angle);
            const realRadien = radien < 0 ? 180 + (180 + radien) : radien;

            delayChipInput(currentActor, realRadien)

        }
    };

    const useClickHandler = (event: any) => {
        const clientRect = event.target.getBoundingClientRect();
        const offsetX = event?.offsetX || event.clientX - clientRect.left;
        const offsetY = event?.offsetY || event.clientY - clientRect.top;

        const boxWidth = chipRef?.current?.offsetWidth || 0;
        const boxHeight = chipRef?.current?.offsetHeight || 0;

        const boxMiddleX = boxWidth / 2;
        const boxMiddleY = boxHeight / 2;

        const dx = offsetX - boxMiddleX;
        const dy = offsetY - boxMiddleY;
        const angle = Math.atan2(dy, dx);

        const radien = radianToDegrees(angle);
        const realRadien = radien < 0 ? 180 + (180 + radien) : radien;
        const useDistance = distance(offsetX, offsetY, boxMiddleX, boxMiddleY);
        const useDistancePercent = useDistance / boxMiddleX;

        if (useDistancePercent >= innerClickDistance && useDistancePercent <= outerClickDistance) {
            if (realRadien >= 65 && realRadien < 115) {
                if (currentActor?.matchData?.isRaiseChip) {
                    currentActor.matchData.setRaise(3);
                } else {
                    if (gameSession?.currentMatch?.preFlop) {
                        currentActor.matchData.setBet(gameSession?.currentMatch?.bigBlind * 3);
                    } else {
                        currentActor.matchData.setPercentBet(66, gameSession?.currentMatch?.round?.pot, gameSession?.currentMatch?.bigBlind);
                    }
                }
            } else if (realRadien >= 10 && realRadien < 65) {
                if (currentActor?.matchData?.isRaiseChip) {
                    currentActor.matchData.setRaise(5);
                } else {
                    if (gameSession?.currentMatch?.preFlop) {
                        currentActor.matchData.setBet(gameSession?.currentMatch?.bigBlind * 5);
                    } else {
                        currentActor.matchData.setPercentBet(75, gameSession?.currentMatch?.round?.pot, gameSession?.currentMatch?.bigBlind);
                    }
                }
            } else if (realRadien < 10 || realRadien >= 320) {
                currentActor.matchData.updateBet(gameSession?.currentMatch?.round, gameSession?.currentMatch?.smallBlind);
            } else if (realRadien > 115 && realRadien <= 170) {
                if (currentActor?.matchData?.isRaiseChip) {
                    currentActor.matchData.setRaise(2);
                } else {
                    if (gameSession?.currentMatch?.preFlop) {
                        currentActor.matchData.setBet(gameSession?.currentMatch?.bigBlind * 2);
                    } else {
                        currentActor.matchData.setPercentBet(33, gameSession?.currentMatch?.round?.pot, gameSession?.currentMatch?.bigBlind);
                    }
                }
            } else if (realRadien > 170 && realRadien <= 220) {
                currentActor.matchData.updateBet(gameSession?.currentMatch?.round, -gameSession?.currentMatch?.smallBlind);
            } else {
                currentActor.matchData.updateSlider(realRadien)
            }
        }
    }

    const useDragStart = (event: any) => {
        gameSession.updateDragging(true)
        chipRef.current.classList.add('hide');

        const clientRect = event.target.getBoundingClientRect();
        const offsetX = (event.clientX - clientRect.left);
        const offsetY = (event.clientY - clientRect.top)
        event.stopPropagation();
        event.dataTransfer.setDragImage(chipRef.current, offsetX, offsetY);
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData('text/plain', `useActionClickHandler|${+ multiUpdate.visible ? multiUpdate.action : 'null'}`)
    }

    const useDragEnd = (event: any) => {
        gameSession.updateDragging(false)
        chipRef.current.classList.remove('hide');
    }

    useEffect(() => {
        const mouseEndEvent = process.env.REACT_APP_MOBILE === 'true' || ('ontouchend' in window) ? 'touchend' : 'mouseup';
        const upHandler = (event: any) => {
            chipSliderDown = false;
            if (document.getElementById('chipslider')) {
                //@ts-ignore
                document.getElementById('chipslider').style.pointerEvents = 'auto'
            }
        }
        window.addEventListener(mouseEndEvent, upHandler);
        return () => {
            window.removeEventListener(mouseEndEvent, upHandler);
        }
    }, [])

    return visible ? <Container
        id={'pokerchip'}
        containerOption={{
            position: 'absolute',
            margin: [5, 10, 0, 0],
            widthPx: 280,
            heightPx: 280,
            right: 0,
            bottom: 0,
            zIndex: 1,
            bgImage: chipImage(skin, gameSession?.currentMatch?.preFlop || false, currentActor?.matchData?.isRaiseChip),
            bgRepeat: 'no-repeat',
            bgImageSize: 'contain',
        }}
        draggable={true}
        onClick={useClickHandler}
        onDragStart={useDragStart}
        onDragEnd={useDragEnd}
        onMouseMove={useMouseMoveHandler}
        containerRef={chipRef}
    >
        <Container
            containerOption={{
                // padding: [4, 4, 4, 4],
                widthPx: 120,
                margin: [100, 0, 0, 80],
                fontSize: chipText.length < 6 ? 26 : 22,
                fontWeight: 'bold',
                bgColor: 'transparent',
                lineHeight: 28,
                heightPx: 28,
                color: getActionFontColor(multiUpdate?.action)
                // opacity: multiUpdate?.visible ? 1.0 : 0.4
            }}
        >
            <label htmlFor={'chip_value'} onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
                try {
                    getSdk().appState.openChipStackDialog(
                        multiUpdate?.minimum || 0,
                        multiUpdate?.maximum || 0
                    )
                } catch (e) { }
            }}>{chipText}</label>
        </Container>
        {/* 24 => 1.000.000 */}
        {/* 28 => 999.999 */}
        {/* 32 => 99.999 */}
        <TextField
            id={'chip_value'}
            // value={multiUpdate?.value || ''}
            // value={currentActor?.matchData?.useBet}
            value={multiUpdate?.showValue}
            inputType={'number'}
            inputOption={{
                padding: [4, 4, 4, 4],
                // margin: [88, 0, 0, 0],
                fontSize: 32,
                fontWeight: 'bold',
                bgColor: 'transparent',
                lineHeight: 30,
                widthPx: 120,
                heightPx: 30,
                color: getActionFontColor(multiUpdate?.action),
                opacity: multiUpdate?.visible ? 1.0 : 0.4
            }}
            onInput={(nmb: any) => {
                currentActor?.matchData?.setBet(parseInt(nmb, 10))
            }}
            onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
                try {
                    getSdk().appState.openChipStackDialog(
                        multiUpdate?.minimum || 0,
                        multiUpdate?.maximum || 0
                    )
                } catch (e) { }
            }}
        />
        <PokerChipSlider
            gameSession={gameSession}
            skin={skin}
        />
    </Container> : null;
})


export default PokerChip;