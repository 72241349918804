import {
    observer
} from 'mobx-react-lite';
import {
    List,
    ConfiguratorItem
} from '../../controls';
import {
    LANGUAGE 
} from '../../../constants/language';

const SettingLanguage = observer(({ sdk }: any) => {
    const {
        appState,
    //     gameLoader
    } = sdk;

    return <List
        className={'settinglist'}
        containerOption={{
            widthPx: 930,
            heightPx: 600,
            margin: [0, 0, 0, 20],
            padding: [0, 0, 0, 0],
            float: 'left'
        }}
        listOption={{
            widthPx: 930,
            heightPx: 100,
            lineHeight: 100,
            color: '#fff',
            fontSize: 28,
            borderSize: 2,
            borderBottom: `solid #fff`
        }}
        components={[
            <ConfiguratorItem
                label={'text.settings.english'}
                onClick={() => appState.options.switchLanguage(LANGUAGE.ENGLISH)}
                icon={'english'}
            />,
            <ConfiguratorItem
                label={'text.settings.german'}
                onClick={() => appState.options.switchLanguage(LANGUAGE.GERMAN)}
                icon={'german'}
            />,
            <ConfiguratorItem
                label={'text.settings.spain'}
                onClick={() => appState.options.switchLanguage(LANGUAGE.SPAIN)}
                icon={'spain'}
            />
        ]}
    />
});

export default SettingLanguage;