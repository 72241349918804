import {
    observer
} from 'mobx-react-lite';
import {
    translateKey
} from '../../../utils/helper'
import getSdk from '../../../api'

const Header = observer(({
    id,
    headOption,
    label
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsHeader = useStyles({
        widthPx: 240,
        heightPx: 48,
        color: '#fff',
        margin: [9, 0, 0, 0],
        float: 'left',
        textAlign: 'left',
        fontSize: 28,
        fontWeight: 'bold',
        ...(headOption || {})
    });

    return <div id={id} style={propsHeader}>{label? translateKey(label) : label}</div>
});

export default Header;