import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './package/dragdrop';
import { getLocalStorage } from './utils/storagehelper'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/fonts.css';
import App from './App';

declare global {
  interface Window { cordova: any; screenLocker:any; couchgames:any; plugins:any; }
}

window.cordova = window.cordova || false;
window.screenLocker = window.screenLocker || false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// root.render(<App />);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
const startApp = () => {
  console.log('##APP Start running')
  root.render(<App />);

  // Only for android
  //@ts-ignore
  if (process.env.REACT_APP_MOBILE_ANDROID === "true" && window?.screenLocker) {
    //@ts-ignore
    window.screenLocker.lock(() => {
      console.log('[SCREEN] locked')
    }, () => {
      console.log('[SCREEN][ERROR] locked error')
    });
  } else {
    console.log('NO')
  }
}


if (!window?.cordova) {
  console.log('RUNNING WITHOUT CORDOVA')
  startApp()
} else {
  console.log('RUNNIN WITH CORDOVA')
  document.addEventListener('deviceready', startApp, false)
}