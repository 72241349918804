import {
    observer
} from 'mobx-react-lite';
import {
    getAvatarImage,
    translateKey
} from '../../../../utils/helper';
import {
    List,
    Container,
    Icon,
    IconButton
} from '../../../controls';

const PlayerEntry = ({ sdk, player, gameSession, seat }: any) => {

    const actor = gameSession.actorList.get(player)
    const useAvatar = getAvatarImage(actor?.avatar || undefined);

    return <>
        <Icon
            iconOption={{
                float: 'left',
                widthPx: 64,
                heightPx: 64,
                margin: [16, 32, 0, 0],
                borderRadiusPercent: useAvatar?.circle ? '50' : undefined
            }}
            source={useAvatar?.src}
        />
        <Container
            containerOption={{
                float: 'left',
                widthPx: 340,
                heightPx: 96,
                textAlign: 'left'
            }}
        >
            <Container
                containerOption={{
                    float: 'left',
                    widthPx: 340,
                    heightPx: 96,
                    lineHeight: 96,
                    textAlign: 'left'
                }}
            >
                {actor?.name || ''}
            </Container>
        </Container>
        <IconButton
            source={'./data/icon/plus.svg'}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                float: 'left',
                margin: [16, 8, 0, 8]
            }}
            onClick={() => {
                if (actor) {
                    gameSession.currentMatch.adminPlacePlayer(player, seat);
                    gameSession.closeOverlay();
                }
            }}
        />
    </>
}

const PlayerPlace = observer(({
    sdk,
    onClose
}: any) => {
    const gameSession = sdk.appState.currentSession;
    const seatPos = gameSession.overlay.overlayObj.pos;
    
    const listPlayer = gameSession?.allActor?.filter((actor: any) => actor.sitting === false && actor.me === false).map((actor: any) => actor.id);
    return <>
        <Container
            containerOption={{
                heightPx: 60,
                widthPx: 600,
                float: 'left',
                textAlign: 'center'
            }}
        >
            {translateKey('text.overlay.admin.subhead.playerplace', {}, [
                ['$seat', `${seatPos}`]
            ])}
        </Container>
        <List
            id={'requestlist'}
            vertical={true}
            filterNullComponents={true}
            components={listPlayer.map((plId: number) => <PlayerEntry sdk={sdk} player={plId} seat={seatPos} gameSession={gameSession} />)}
            listOption={{
                widthPx: 600,
                heightPx: 96,
                borderSize: 3,
                borderBottom: `solid #fff`
            }}
            containerOption={{
                margin: [0, 0, 0, 0],
                float: 'left',
                widthPx: 600,
                heightPx: 600,
                whiteSpace: 'nowrap',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
        />
    </>
});

export default PlayerPlace;