import {
    observer
} from 'mobx-react-lite';
import {
    translateKey
} from '../../../utils/helper'
import getSdk from '../../../api'

const RadioButton = observer(({
    id,
    containerOption,
    buttonOption,
    labelOption,
    label,
    value,
    store,
    storeProperty,
    fixSelected,
    onSelect,
    children,
    selected = false
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const useLabelText = label ? translateKey(label) : label;
    const useFixSelectedValue = fixSelected ? 1 : 0
    const radioButtonEnabled = (fixSelected === true || fixSelected === false) ? useFixSelectedValue : (store && storeProperty ?
        store[storeProperty] === value ? 1 : 0 :
        selected ? 1 : 0);

    const propsContainer = useStyles({
        widthPx: 320,
        heightPx: 60,
        lineHeight: 60,
        color: '#c1c1c1',
        fontWeight: 'bold',
        ...(containerOption || {})
    })
    const propsButton = useStyles({
        float: 'left',
        bgImage: `url(./data/ui/radiobutton${radioButtonEnabled}.svg)`,
        bgRepeat: 'no-repeat',
        bgImageSize: 'contain',
        border: 'none',
        widthPx: 48,
        heightPx: 48,
        ...(buttonOption || {})
    });

    const propsLabel = useStyles({
        widthPx: 240,
        heightPx: 48,
        lineHeight: 48,
        float: 'left',
        textAlign: 'left',
        padding: [0, 0, 0, 16],
        fontSize: 24,
        ...(labelOption || {})
    })

    const handleRadioButtonClick = () => {
        if(fixSelected === true || fixSelected === false) return;

        if (store?.updateValue && storeProperty) {
            store.updateValue(storeProperty, value)
        } else {
            if (onSelect) {
                onSelect(value)
            }
        }
    }

    return <div id={`${id}-container`} style={propsContainer}>
        <button
            id={`${id}-button`}
            style={propsButton}
            onClick={handleRadioButtonClick}
        />
        <div
            id={`${id}-label`}
            style={propsLabel}
            onClick={handleRadioButtonClick}
        >
            {children ? children : useLabelText}
        </div>
    </div>
});

export default RadioButton;