import {
    observer
} from 'mobx-react-lite';
import {
    canShare,
    onShare
} from '../../../../utils/helperfunction';
import { translateKey } from '../../../../utils/helper';
import {
    TabArea,
    Container,
    ActionButton,
    Icon,
    ActionButtonRound
} from '../../../controls';
import LobbyMenuMode from './LobbyMenuMode';
import LobbyMenuSettings from './LobbyMenuSettings';
import LobbyMenuWaitingArea from './LobbyMenuWaitingArea'
import type {
    CouchgamesSdk
} from '../../../../api/index'

const LobbyMenu = observer(({
    sdk
}: { sdk: CouchgamesSdk }) => {
    const currentSession = sdk?.appState?.currentSession;
    const tab = sdk?.appState?.currentSession?.roomLobby?.currentTab || 0;
    const isAdmin = currentSession?.admin;

    return <>
        <Container
            id={'lobbyarea-top'}
            containerOption={{
                widthPx: 1334,
                heightPx: 64,
                float: 'left'
            }}
        />
        <TabArea
            id={'lobbyarea'}
            width={1000}
            height={500}
            tabList={isAdmin ? [
                'text.lobby.tab.modus',
                'text.lobby.tab.setting',
                `text.lobby.tab.waitingarea`
            ] : [
                `text.lobby.tab.waitingarea`
            ]}
            disabledButtons={isAdmin ? [
                (currentSession?.running || currentSession?.roomLobby?.tabModeDisabled === 1) ? true : false,
                currentSession?.roomLobby?.isNoModeSelected,
                currentSession?.running ? false : true
            ] : [false]}
            activeTab={isAdmin ? tab : 0}
            onClickTab={(tabId: any) => currentSession?.roomLobby?.updateValue('currentTab', tabId)}
        >
            {isAdmin && tab === 0 ? <LobbyMenuMode sdk={sdk} /> : null}
            {isAdmin && tab === 1 ? <LobbyMenuSettings sdk={sdk} /> : null}
            {!isAdmin || tab === 2 ? <LobbyMenuWaitingArea sdk={sdk} /> : null}
            <Container
                id={'info'}
                containerOption={{
                    widthPx: 1000,
                    heightPx: 40,
                    lineHeight: 40,
                    float: 'left',
                    fontSize: 18,
                    fontWeight: 'bold',
                    borderTop: 'solid #383c3a',
                    borderSize: 2
                }}
            >
                {translateKey(`text.lobby.help.tab.${tab + 1}`)}
            </Container>
        </TabArea>
        <Container
            id={'lobbyarea-right'}
            containerOption={{
                widthPx: 160,
                heightPx: 500,
                float: 'left'
            }}
        >
            <Container
                id={'lobbyarea-roomcode'}
                containerOption={{
                    bgColor: '#000',
                    lineHeight: 80,
                    color: '#fff',
                    fontWeight: 'bold',
                    borderCorner: [16, 0, 0, 16],
                    widthPx: 128,
                    heightPx: 80,
                    float: 'right',
                    opacity: 0.7,
                    fontSize: 28
                }}
            >
                {currentSession?.roomCode}
            </Container>
            {(currentSession?.running && canShare()) ? (
                <ActionButtonRound
                    id={'sharebutton'}
                    actionColor={'blue'}
                    onClick={() => onShare('invite', {
                        playerName: sdk?.appState?.user?.userData?.name || '',
                        roomCode: currentSession?.roomCode || ''
                    })}
                    buttonOption={{
                        float: 'right',
                        margin: [32, 8, 0, 0],
                        widthPx: 112,
                        heightPx: 112
                    }}
                >
                    <Icon
                        source={'./data/icon/share.svg'}
                        iconOption={{
                            widthPx: 60,
                            heightPx: 60,
                            margin: [0, 0, 0, 20]
                        }}
                    />
                </ActionButtonRound>
            ) : null}
            {(currentSession?.running && currentSession?.tableAdsEnabled) ? (
                <ActionButtonRound
                    id={'adfreeround'}
                    actionColor={'green'}
                    onClick={() => {
                        sdk?.appState.showMessagebox('adfree', 'dialog.table.upgrade.adfree', () => {
                            if (
                                currentSession
                            ) {
                                currentSession.buyAdFree();
                            }
                        }, () => null, true, [], false, {
                            coinValue: 10
                        }, 'coin');
                    }}
                    buttonOption={{
                        float: 'right',
                        margin: [48, 16, 0, 0],
                        widthPx: 96,
                        heightPx: 96
                    }}
                >
                    <Icon
                        source={'./data/icon/noads.svg'}
                        iconOption={{
                            widthPx: 60,
                            heightPx: 60,
                            margin: [0, 0, 0, 12]
                        }}
                    />
                </ActionButtonRound>
            ) : null}
        </Container>
        <Container
            id={'lobbyarea-bottom'}
            containerOption={{
                widthPx: 1334,
                heightPx: 160,
                float: 'left',
                margin: [16, 0, 0, 0]
            }}
        >
            {isAdmin ?
                <>
                    <ActionButton
                        id={'button.lobby.close'}
                        translationKey={currentSession?.running ? 'button.lobby.close' : 'button.back'}
                        actionColor={'red'}
                        buttonOption={{
                            margin: [0, 32, 0, 0],
                            padding: [0, 8, 0, 8],
                            lineHeight: 48,
                            widthPx: 256,
                            textAlign: 'left'
                        }}
                        icon={currentSession?.running ? './data/icon/clear.svg' : './data/icon/backblack.svg'}
                        onClick={() => {
                            sdk.appState.destroySession();
                            sdk.appState.openMainMenu();
                        }}
                    />
                    {currentSession?.running ? (
                        <ActionButton
                            id={'button.lobby.start'}
                            translationKey={'button.lobby.start'}
                            actionColor={'green'}
                            buttonOption={{
                                padding: [0, 8, 0, 8],
                                lineHeight: 48,
                                widthPx: 256,
                                textAlign: 'left'
                            }}
                            icon={'./data/icon/play-button.svg'}
                            disabled={!currentSession?.roomLobby?.validate}
                            enableDelay={800}
                            // onClick={() => console.log(currentSession?.roomLobby?.config)}
                            onClick={() => sdk.getMessageList().createTable(currentSession?.roomLobby?.config)}
                        />
                    ) : null}
                    {!currentSession?.running && tab !== 0 ? (
                        <ActionButton
                            id={'button.lobby.create'}
                            translationKey={'button.lobby.create'}
                            disabled={currentSession?.running}
                            actionColor={'green'}
                            icon={'./data/icon/create.svg'}
                            buttonOption={{
                                padding: [0, 8, 0, 8],
                                lineHeight: 48,
                                widthPx: 256,
                                textAlign: 'left'
                            }}
                            enableDelay={800}
                            onClick={() => {
                                currentSession?.createGame(
                                    currentSession?.roomLobby?.publicInfo,
                                    !!currentSession?.roomLobby?.flagDealerMode,
                                    `${currentSession?.roomLobby?.dealerModePin || ''}`,
                                    !!currentSession?.roomLobby?.flagWaitingArea
                                )
                            }}
                        />
                    ) : null}
                </>
                : (
                    <ActionButton
                        id={'button.lobby.leave'}
                        translationKey={'button.lobby.leave'}
                        actionColor={'red'}
                        buttonOption={{
                            widthPx: 240,
                            margin: [0, 32, 0, 0]
                        }}
                        onClick={() => {
                            sdk.appState.leaveGame()
                        }}
                    />
                )}


        </Container>
    </>
});

export default LobbyMenu;