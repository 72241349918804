import {
    observer
} from 'mobx-react-lite';
import LoadingSpinner from './dialog/LoadingSpinner';
import {
    ActionButton
} from './controls'

const LoaderComponent = observer(({ sdk }: any) => {
    const { gameLoader } = sdk;
    return gameLoader.isActive ? <>
        <LoadingSpinner
            sdk={sdk}
            label={gameLoader.loadingName}
        >
            {gameLoader.hasError ?
                <ActionButton
                    buttonOption={{
                        float: 'left',
                        textAlign: 'center',
                        margin: [16, 0, 0, 28],
                        padding: [16, 16, 16, 16],
                        fontSize: 32,
                        fontWeight: 'bold',
                        lineHeight: 32,
                        widthPx: 256,
                        borderRadius: 8
                    }}
                    translationKey={'button.gameloader.reconnect'}
                    onClick={() => gameLoader.forceStartLoading(0, true)}
                />
                : null}

        </LoadingSpinner>

    </> : null;
});

export default LoaderComponent;

// const LoaderComponent = observer(({ sdk }: any) => {
//     const { gameLoader } = sdk;
//      {/* <LoadingSpinner sdk={sdk} /> */}
//     return gameLoader.isActive ? <>Aktiv</> : null;
// });