import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ActionButton,
    ImageComponent
} from '../../controls'
import AccountSetup from '../../account/AccountSetup';

const StartMenu = observer(({ sdk }: any) => {
    const accountOpen = sdk?.appState?.cgMaskOpen || false;
    return <>
        {accountOpen ? (
            <>
                <ImageComponent
                    id={'mainmenu_logo'}
                    imageOption={{
                        widthPx: 320,
                        margin: [40, 0, 0, 64]
                    }}
                    source={'./data/gamelogo_optimized.png'}
                />
                <Container
                    id={'accountbox'}
                    containerOption={{
                        float: 'left',
                        widthPx: 820,
                        heightPx: 400,
                        margin: [32, 0, 0, 264]
                    }}
                >
                    <AccountSetup
                        sdk={sdk}
                        createAccount={true}
                    />
                </Container>
            </>
        ) : (
            <>
                <ImageComponent
                    id={'mainmenu_logo'}
                    imageOption={{
                        widthPx: 800,
                        margin: [40, 0, 0, 64]
                    }}
                    source={'./data/gamelogo_optimized.png'}
                />
                <Container
                    id={'optionbox'}
                    containerOption={{
                        float: 'left',
                        widthPx: 820,
                        heightPx: 110,
                        margin: [64, 0, 64, 264]
                    }}
                >
                    <ActionButton
                        id={'btn_guest'}
                        icon={`./data/ui/website.png`}
                        actionColor={'blue'}
                        translationKey={'button.start.playwithcg'}
                        buttonOption={{
                            widthPx: 720,
                            heightToWidth: 8.24,
                            lineHeight: 48,
                            fontSize: 28
                        }}
                        onClick={() => sdk?.appState?.openCgMask()}
                    />
                </Container>
            </>
        )}
        <Container
            id={'optionbox'}
            containerOption={{
                float: 'left',
                widthPx: 820,
                heightPx: 110,
                margin: [0, 0, 0, 264]
            }}
        >
            <ActionButton
                id={'btn_guest'}
                actionColor={'green'}
                icon={`./data/icon/guest.svg`}
                translationKey={'button.account.guest'}
                buttonOption={{
                    widthPx: 800,
                    heightToWidth: 8.24,
                    lineHeight: 48,
                    fontSize: 28
                }}
                onClick={() => sdk.appState.user.createUser().then((r: any) => {
                    if (r === true) {
                        sdk.appState.openMainMenu();
                    }
                })}
            />
        </Container>
    </>
});

export default StartMenu;