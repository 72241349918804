import {
    observer
} from 'mobx-react-lite';
import { toJS } from 'mobx'
import {
    Container
} from '../../../controls';
import {
    getAvatarImage
} from '../../../../utils/helper';

const LobbyMenuWaitingArea = observer(({
    sdk
}: any) => {
    const currentSession = sdk.appState?.currentSession;
    const actorList = currentSession?.allActor || [];

    return <Container
        containerOption={{
            widthPx: 810,
            heightPx: 412,
            overflowY: 'auto',
            overflowX: 'hidden',
            margin: [0, 0, 0, 14 + 95]
        }}>
        {actorList.map((actor: any, i:number): any => {
            const avatar = getAvatarImage(actor.avatar);
            return <Container
                key={`waiting-actor-${actor.id}-${i}`}
                containerOption={{
                    position: 'relative',
                    float: 'left',
                    widthPx: 400,
                    heightPx: 80
                }}
            >
                <Container
                    containerOption={{
                        position: 'relative',
                        widthPx: 64,
                        heightPx: 64,
                        bgImage: `url(${avatar.src})`,
                        borderRadiusPercent: avatar.circle ? '50' : undefined,
                        bgRepeat: 'no-repeat',
                        bgImageSize: 'contain',
                        margin: [8, 0, 0, 16],
                        float: 'left'
                    }}
                >
                </Container>
                <Container
                    containerOption={{
                        textAlign: 'left',
                        widthPx: 300,
                        heightPx: 32,
                        lineHeight: 32,
                        margin: [8, 0, 0, 16],
                        float: 'left',
                        fontSize: 24,
                        fontWeight: 'bold',
                        color: '#FCAA67'
                    }}
                >
                    {actor.name}
                </Container>
                <Container
                    containerOption={{
                        textAlign: 'left',
                        widthPx: 300,
                        heightPx: 32,
                        lineHeight: 32,
                        margin: [0, 0, 0, 16],
                        float: 'left',
                        fontSize: 24,
                        fontWeight: '900',
                        color: '#fff'
                    }}
                >
                    -
                </Container>
            </Container>
        })}
    </Container>
});

export default LobbyMenuWaitingArea;