import type AppMessageHandler from "../messagehandler";

export default function tableUpgrade(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: any,
    paidToken: string
): boolean {
    messageHandler.sendMessage(1030, {
        roomToken,
        playerId,
        paidToken
    })
    return true;
}