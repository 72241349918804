import {
    observer
} from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import {
    TabArea,
    Container,
    ActionButton,
    IconButton,
    List,
    Icon
} from '../../controls';
import {
    replace1k,
    translateKey
} from '../../../utils/helper';

import JoinMenu from './JoinMenu';

const GameListEntry = observer(({
    entry,
    sdk
}: any) => {
    const {
        code,
        buyinMin,
        buyinMax,
        sb,
        bb,
        player,
        maxPlayer
    } = entry;
    return <>
        <Container
            containerOption={{
                widthPx: 140,
                heightPx: 50,
                lineHeight: 50,
                float: 'left',
                color: '#fff',
                fontSize: 38,
                margin: [25, 0, 0, 0],
                borderSize: 2,
                borderRight: 'solid #fff'
            }}
        >
            <span>{code}</span>
        </Container>
        <Container
            className={'gamelist-entry'}
            containerOption={{
                float: 'left',
                widthPx: 580,
                margin: [25, 0, 0, 16],
                heightPx: 50,
                lineHeight: 50,
                borderSize: 2,
                borderRight: 'solid #fff'
            }}
        >
            <div dangerouslySetInnerHTML={{
                __html: translateKey('text.lobby.serverlist.entry', {}, [
                    ['$min', `<span>${replace1k(buyinMin)}</span>`],
                    ['$max', `<span>${replace1k(buyinMax)}</span>`],
                    ['$sb', `<span>${replace1k(sb)}</span>`],
                    ['$bb', `<span>${replace1k(bb)}</span>`]
                ])
            }} />
        </Container>
        <Container
            className={'gamelist-entry'}
            containerOption={{
                float: 'left',
                widthPx: 120,
                margin: [25, 0, 0, 0],
                heightPx: 50,
                lineHeight: 50,
                borderSize: 2,
                borderRight: 'solid #fff'
            }}
        >
            <Icon
                source={'./data/ui/user.svg'}
                iconOption={{
                    widthPx: 32,
                    heightPx: 32,
                    float: 'left',
                    margin: [10, 4, 0, 12]
                }}
            />
            <span>{player}/{maxPlayer}</span>
        </Container>
        <IconButton
            source={'./data/icon/play.svg'}
            buttonOption={{
                widthPx: 70,
                heightPx: 70,
                float: 'left',
                margin: [15, 0, 0, 20]
            }}
            enableDelay={3500}
            onClick={() => {
                // Check if the user has enough money
                if (sdk.appState.user?.hasCredit(buyinMin)) {
                    sdk.appState.joinGame(undefined, code)
                } else {
                    sdk.appState.showMessagebox('no-credits', 'dialog.user.credits.notenough', null, null, true, false, false)
                }
            }}
        />
    </>
});

const GameList = observer(({
    sdk
}: any) => {
    const useGameList = sdk.appState.publicList.gameList;

    return useGameList.length ? <List
        id={'gamelist'}
        containerOption={{
            widthPx: 980,
            heightPx: 440,
            float: 'left',
            margin: [0, 0, 0, 10],
            overflowY: 'auto',
            overflowX: 'hidden'
        }}
        listOption={{
            widthPx: 980,
            heightPx: 100,
            // lineHeight: 48,
            fontSize: 28,
            fontWeight: 'bold',
            color: '#f5861f',
            borderSize: 2,
            borderBottom: 'solid #fff'
        }}
        components={useGameList.map((data: any) => <GameListEntry entry={data} sdk={sdk} />)}
    /> : <Container
        containerOption={{
            widthPx: 800,
            heightPx: 96,
            lineHeight: 96,
            margin: [172, 0, 0, 90],
            textAlign: 'center',
            float: 'left',
            fontWeight: 'bold',
            fontSize: 24
        }}
    >
        {translateKey('text.lobby.serverlist.noroom')}
    </Container>
});

const JoinMenuList = observer(({
    sdk
}: any) => {

    useEffect(() => {
        if (sdk?.appState?.publicList && tab === 0) {
            // only request every 5 minutes if necessaty
            sdk.appState.publicList.requestGameList()
        }
        // eslint-disable-next-line
    }, []);

    const [tab, setTab] = useState(sdk.appState.joinMenuTabDefault || 0);
    const onRequestList = () => {
        sdk.appState.publicList.requestGameList(0);
    }

    return <>
        <Container
            id={'joinlist-top'}
            containerOption={{
                widthPx: 1334,
                heightPx: 64,
                float: 'left'
            }}
        />
        <TabArea
            id={'joinlist'}
            width={1000}
            height={500}
            tabList={[
                `text.joinmenu.tab.public`,
                `text.joinmenu.tab.private`
            ]}
            disabledButtons={[false, false]}
            activeTab={tab}
            onClickTab={(tabId: any) => {
                sdk.appState.publicList.requestGameList();
                setTab(tabId)
            }}
        >
            {tab === 1 ? <JoinMenu sdk={sdk} embedded={true} /> : null}
            {tab === 0 ? <GameList sdk={sdk} /> : null}
        </TabArea>
        <Container
            id={'joinlist-right'}
            containerOption={{
                widthPx: 160,
                heightPx: 500,
                float: 'left'
            }}
        >
            {tab === 0 ? (
                <IconButton
                    source={'./data/icon/refresh.svg'}
                    className={'iconbutton_refresh'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'left',
                        margin: [0, 75, 0, 75]
                    }}
                    enableDelay={3500}
                    onClick={() => onRequestList()}
                />
            ) : null}
        </Container>
        <Container
            id={'joinlist-bottom'}
            containerOption={{
                widthPx: 1334,
                heightPx: 160,
                float: 'left',
                margin: [16, 0, 0, 0]
            }}
        >
            <ActionButton
                id={'button.back'}
                translationKey={'button.back'}
                actionColor={'red'}
                buttonOption={{
                    widthPx: 256,
                    margin: [16, 32, 0, 160],
                    padding: [0, 8, 0, 8],
                    lineHeight: 48,
                    float: 'left'
                }}
                icon={'./data/icon/backblack.svg'}
                onClick={() => {
                    sdk.appState.openMainMenu();
                }}
            />
        </Container>
    </>
});


export default JoinMenuList;