import {
    observer
} from 'mobx-react-lite';
import { translateKey } from '../../../../utils/helper';
import {
    Container,
    ActionButton
} from '../../../controls';

const PokerTableIamBack = observer(({
    appState,
    gameSession
}: any) => {
    const visible = gameSession.ui.buttonIamBack === 2;

    return visible ? <Container
        containerOption={{
            position: 'absolute',
            widthPx: 640,
            heightPx: 96,
            zIndex: 52,
            left: 140,
            top: 70
        }}
    ><ActionButton
            id={'btn_iamback'}
            actionColor={'green'}
            translationKey={'button.game.menu.iamback'}
            buttonOption={{
                widthPx: 480,
                heightToWidth: 8.24,
                lineHeight: 16,
                fontSize: 28,
                position: 'relative',
                margin: [80, 0, 0, 0]
            }}
            onClick={() => gameSession?.currentMatch?.playerIsBack()}
        />
    </Container> : null;
});


export default PokerTableIamBack;