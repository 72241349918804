import {
    makeObservable,
    observable,
    action,
    computed,
    toJS
} from 'mobx'
import {
    PokerDeck
} from '../../constants/cards';
import {
    ACCESSIBILITY_MODE
} from '../../constants/accessibility';
import AppAccount from './account';
import type {
    CouchgamesSdk
} from '../index';
import AppUserBoni from './userboni';
import {
    timestampMs,
    getAvatarImage,
    convertNumberToString,
    translateKey
    // convertXpString
} from '../../utils/helper';
import getSdk from '../index';

class AppUserDetails {
    public name: string;
    public avatar: string | number | undefined;
    public customAvatar: any;
    public premium: any;
    public paidToken: any;
    public credits: number;
    public coins: number;
    public created: number; // When does the user was created
    public info: any; // The whole userinfo
    public feature: any;
    public trialUsed: any;
    public coupon: any;

    constructor(defaultValues: any = undefined) {
        makeObservable(this, {
            name: observable,
            credits: observable,
            coupon: observable,
            trialUsed: observable,
            coins: observable,
            paidToken: observable,
            avatar: observable,
            feature: observable,
            customAvatar: observable,
            premium: observable,
            info: observable,
            created: observable,
            updateName: action,
            updateUser: action,
            updateAvatar: action,
            updateCoins: action,
            updateFeature: action,
            updateTrialUsed: action,
            updatePremium: action,
            updateCredit: action,
            updatePaidToken: action,
            loadUser: action,
            level: computed,
            statistic: computed,
            levelXp: computed,
            premiumExpire: computed,
            avatarImage: computed,
            userCreated: computed
        })

        this.name = defaultValues?.name || '';
        this.credits = 0;
        this.coins = 0;
        this.avatar = defaultValues?.avatar || undefined;
        this.info = undefined;
        this.created = 0;
        this.premium = null;
        this.feature = [];
        this.trialUsed = [];
        this.coupon = [];
        this.paidToken = null;
    }

    public updatePaidToken(token: string): void {
        this.paidToken = token;
    }

    public updateCoins(coins: number): void {
        this.coins = coins;
    }

    public updateTrialUsed(feature: string): void {
        if (!this.trialUsed.includes(feature)) {
            this.trialUsed.push(feature)
        }
    }

    public updateFeature(feature: string): void {
        if (!this.feature.includes(feature)) {
            this.feature.push(feature)
        }
    }

    public updatePremium(data: any): void {
        if (this.premium) {
            this.premium['expire'] = data.expire;
        } else {
            this.premium = {
                expire: data.expire
            }
        }

        if (data.coupon) {
            if (JSON.stringify(data.coupon) !== JSON.stringify(this.coupon)) {
                this.coupon = data.coupon;
            }
        } else {
            this.coupon = [];
        }
    }

    public updateCredit(credits: number): void {
        this.credits = credits;
    }

    public updateName(name: string) {
        this.name = name;
    }

    public removeCustomeAvatar(): void {
        if (typeof this.avatar === 'string') {
            this.avatar = undefined;
        }
        this.customAvatar = undefined;
    }

    public updateAvatar(avatar: any) {
        this.avatar = avatar;
    }

    public createUser(message: any): void {
        this.name = message?.username || undefined;
        this.avatar = message?.avatar || 0;
        this.created = message?.datadate || undefined;
        this.info = {
            ...(message || {}),
            usertoken: undefined,
            username: undefined
        }
    }

    public loadUser(readUser: any): void {
        console.log('LOAD USER', readUser)
        this.name = readUser.name || this.name;
        this.avatar = readUser.avatar || this.avatar;
        this.customAvatar = readUser.customAvatar || this.customAvatar;
        this.created = readUser.created || this.created;
        this.info = readUser.info || this.info;
    }

    public updateUser(data: any): any {
        let shouldSave = false;
        let checkFeature = false;

        if (data) {

            if (data.usedtrial) {
                if (JSON.stringify(data.usedtrial) !== JSON.stringify(this.trialUsed)) {
                    this.trialUsed = data.usedtrial;
                }
            }

            if (data.coupon) {
                if (JSON.stringify(data.coupon) !== JSON.stringify(this.coupon)) {
                    this.coupon = data.coupon;
                }
            } else {
                this.coupon = [];
            }

            if (data?.customAvatar) {
                if (data.customAvatar.status === 1 && this.customAvatar?.status !== data.customAvatar.status) {
                    getSdk().appState.showMessagebox('dialog.avatar', 'dialog.vip.avatar.granted', null, null, true, [])
                    shouldSave = true;
                }
            }

            this.paidToken = data?.paidToken || this.paidToken;

            if (data.feature) {
                if (JSON.stringify(data.feature) !== JSON.stringify(this.feature)) {
                    this.feature = data?.feature || this.feature;
                    checkFeature = true;
                }
            }

            this.name = data?.username ? data.username : this.name;
            this.credits = data?.credit || data?.credit === 0 ? data.credit : this.credits;
            this.coins = data?.taler || data?.taler === 0 ? data.taler : this.coins;
            this.customAvatar = data?.customAvatar;
            this.avatar = data?.avatar ? data.avatar : 0;
            this.premium = data?.premium || null;
            this.info = {
                ...data,
                usertoken: undefined,
                username: undefined,
                avatar: undefined,
                receivedMs: timestampMs()
            }

        }
        return {
            shouldSave,
            checkFeature
        };
    }

    public hasTrialUsed(feature: string): boolean {
        if (this.trialUsed.includes(feature)) {
            return true;
        }
        return false;
    }

    get level() {
        return getSdk().xp.getLevel(this.info?.experience || 0);
    }

    get levelXp() {
        return getSdk().xp.getLevelExperience(this.info?.experience || 0);
    }

    get avatarImage() {
        return getAvatarImage(this.avatar === 0 ? undefined : this.avatar) || {
            src: '',
            circle: false
        };
    }

    get userCreated() {
        return this.info?.datadate;
    }

    get creditLabel() {
        return convertNumberToString(this.credits);
    }

    get coinLabel() {
        return convertNumberToString(this.coins);
    }

    get premiumExpire() {
        if (this.premium) {
            const d = new Date(this.premium.expire * 1000)
            return translateKey('text.userdetail.vip.expire', {}, [
                ['$date', `${d.toLocaleDateString('de-de')} - ${d.toLocaleTimeString('de-de')}`]
            ])
        }
        return '-'
    }
    get statistic() {
        const useInfo = toJS(this.info) || {};
        return [
            ['gamepublic', useInfo?.gamepublic?.statistic || {}],
            ['gameprivate', useInfo?.gameprivate?.statistic || {}]
        ]
    }

}

export default class AppUser {

    public sdk: CouchgamesSdk;
    public account: AppAccount;

    public accessibilityMode: number;

    public token: string | undefined;
    public lastRequestUser: number;
    public doubletteUser: any;
    public userData: any;
    public userBoni: any;
    public userTmp: any;
    public userDeck: any;

    constructor(sdk: CouchgamesSdk) {
        makeObservable(this, {
            token: observable,
            userData: observable,
            userBoni: observable,
            userDeck: observable,
            userTmp: observable,
            doubletteUser: observable,
            createUser: action,
            activateAccountAndConnect: action,
            loadUser: action,
            setCardDeck: action,
            resetLastUpdate: action,
            handleCreditUpdate: action,
            handleCoinUpdate: action,
            fetchTmpUser: action,
            saveAvatar: action,
            hasUser: computed,
            premium: computed,
            deckList: computed,
            data: computed
        })

        this.sdk = sdk;
        this.token = undefined;
        this.doubletteUser = undefined;
        this.userTmp = undefined;
        this.accessibilityMode = 0;
        this.lastRequestUser = 0;
        this.userBoni = new AppUserBoni();
        this.userData = new AppUserDetails();
        this.account = new AppAccount(sdk);
        this.userDeck = 'poker.deck.2';
        this.loadUser();
    }

    public resetLastUpdate(): void {
        this.lastRequestUser = 0;
    }

    public getCoupon(type: string): any {
        return this.data.coupon.filter((item: any) => item.type === type)
            .map((item: any) => item)
            .sort((a: any, b: any) => a.multiplicator - b.multiplicator)
            .pop();
    }

    public setCardDeck(deck: any): void {
        this.userDeck = deck;
        this.saveUser();
    }

    public handleCreditUpdate(credits: number): void {
        this.userData.updateCredit(credits);
    }

    public handleCoinUpdate(coins: number): void {
        this.userData.updateCoins(coins);
    }

    public async activateAccountAndCreateUser(): Promise<boolean> {
        const activationResult = await this.account.activate();
        if (activationResult) {
            if (await this.createUser(activationResult) === true) {
                return true;
            }
        }
        return false;
    }

    public async activateAccountAndConnect(overwriteAccount: any = false, overwriteWithUserToken: any = undefined, forceOverwrite: boolean = false): Promise<boolean> {
        const useSpinner = this.sdk.appState.createSpinner();
        const activationResult = overwriteAccount ?
            this.account.accountToken :
            await this.account.activate(true);

        if (activationResult) {
            const connectData = await this.sdk.fetchApi('api', 'user', {
                action: 'account',
                accountToken: activationResult,
                userToken: this.token,
                overwriteWithUserToken: overwriteWithUserToken || (forceOverwrite ? this.token : undefined)
            });

            try {
                useSpinner.close();
            } catch (e) { }

            if (connectData.status === 201 || connectData.status === 200) {
                if (overwriteWithUserToken) {
                    await this.fetchUser(false, overwriteWithUserToken);
                    this.token = overwriteWithUserToken;
                }
                return true;
            } else if (connectData.status === 409) {
                this.doubletteUser = connectData.json;
                this.account.setFormStep(3);
                return false;
            }
        }
        try {
            useSpinner.close();
        } catch (e) { }
        return false;
    }

    public updateUserName(name: string): void {
        this.userData.updateName(name);
        this.saveUser();
    }

    public async saveAvatar(avatar: string | number | undefined, removeAvatar: boolean = false): Promise<any> {
        const useSpinner = this.sdk.appState.createSpinner();
        const updateAvatar = await this.sdk.fetchApi('api', 'user', {
            userToken: this.token,
            action: 'avatar',
            avatar: avatar || null,
            avatarAction: removeAvatar ? 'delete' : 'update'
        }, 'POST');

        if (updateAvatar?.status === 200) {
            this.userData.updateAvatar(avatar);
            this.saveUser();
        } else {
            getSdk().appState.showErrorMessage('dialog.rename.issue', 'dialog.account.error.request', true)
        }

        useSpinner.close();
        return true;
    }

    public async fetchTmpUser(data: any): Promise<any> {
        this.userTmp = new AppUserDetails(data);
        const useSpinner = this.sdk.appState.createSpinner();

        // const getData = await this.sdk.fetchApi('api', 'user', {
        //     userId: data.id
        // }, 'GET');

        // if (getData?.status === 200) {
        //     this.userTmp.updateUser(getData.json);
        // }

        useSpinner.close();
    }

    public async fetchUserWithOwnTime(time: number): Promise<any> {
        return this.fetchUser(true, undefined, time)
    }

    public async fetchUser(timeCheck: boolean = true, useToken: any = undefined, requestTime: number = 0): Promise<any> {
        const useSpinner = this.sdk.appState.createSpinner();

        if (this.token) {
            if (timeCheck) {
                if ((timestampMs() - this.lastRequestUser) >= (requestTime ? requestTime : parseInt(process.env?.REACT_APP_FETCH_USER_MAX || '10000', 10))) {
                } else {
                    useSpinner.close();
                    return true;
                }
            }

            this.lastRequestUser = timestampMs();

            const getData = await this.sdk.fetchApi('api', 'user', {
                userToken: useToken || this.token
            }, 'GET');

            if (getData?.status === 200) {
                this.userBoni.update(getData.json)
                const updateUserResult = this.userData.updateUser(getData.json);

                if (updateUserResult.checkFeature) {
                    if (this.userDeck) {
                        const lookForDeck = this.deckList.find((item: any) => item.id === this.userDeck);
                        if (lookForDeck) {
                            if (!lookForDeck.selectable) {
                                this.setCardDeck('poker.deck.2')
                            }
                        }
                    }
                }
                if (updateUserResult.shouldSave) {
                    this.saveUser();
                }
            } else if (getData?.status === 410) {
                useSpinner.close();
                return this.sdk.appState.removeUser();
            }
        }
        useSpinner.close();
        return true;
    }

    public async deleteUserFromApi(): Promise<any> {
        const useSpinner = this.sdk.appState.createSpinner();
        const logoutData = await this.sdk.fetchApi('api', 'user', {
            userToken: this.token,
            delete: true
        }, 'DELETE');

        if (logoutData?.status === 200) {
            useSpinner.close();
            return true;
        }
        useSpinner.close();
        return false;
    }

    public async logoutUser(): Promise<any> {
        const useSpinner = this.sdk.appState.createSpinner();
        const logoutData = await this.sdk.fetchApi('api', 'user', {
            userToken: this.token
        }, 'DELETE');

        if (logoutData?.status === 200) {
            useSpinner.close();
            return true;
        }
        useSpinner.close();
        return false;
    }

    public async createUser(connectedAccount: any): Promise<any> {
        const useSpinner = this.sdk.appState.createSpinner();
        const putData = await this.sdk.fetchApi('api', 'user', {
            accountToken: connectedAccount,
            dev: process.env.REACT_APP_DEVMODE === 'true' ? true : undefined,
            userAccessibility: this.accessibilityMode === ACCESSIBILITY_MODE.ACCESSIBILITY ?
                1 :
                undefined
        }, 'PUT');

        useSpinner.close();

        // We created the user
        if (putData?.status === 200 || putData?.status === 201) {
            const message = putData.json;

            this.token = message?.usertoken || undefined;
            this.userData.createUser(message);

            // Save the userdata
            this.saveUser();

            return true;
        }
        return false;
    }

    public async renameUser(userNameNew: string): Promise<any> {
        const userNameOld = this.userData.name;
        const useSpinner = this.sdk.appState.createSpinner();
        const updateName = await this.sdk.fetchApi('api', 'user', {
            userToken: this.token,
            action: 'rename',
            userNameNew,
            userNameOld
        }, 'POST');

        useSpinner.close();

        if (updateName?.status === 201) {
            const message = updateName.json;
            if (message?.username) {
                this.updateUserName(message.username)
                getSdk().appState.showMessagebox('dialog.account.name.changed', 'dialog.account.name.changed', null, null, true, [
                    ['$name', message.username]
                ])
            }
        } else {
            let useErrorText = 'dialog.account.error.request';

            if (updateName?.json?.error === 'AlreadyExist') {
                useErrorText = 'dialog.account.error.name.exist';
            }

            if (updateName?.json?.error === 'ChangeLimit') {
                useErrorText = 'dialog.account.error.name.limitation';
            }

            getSdk().appState.showErrorMessage('dialog.rename.issue', useErrorText, true)
        }

        return true;
    }

    public deleteUser() {
        this.sdk.saveToUserStorage('user', {
            accessibility: this.accessibilityMode
        });
    }

    public saveUser() {
        this.sdk.saveToUserStorage('user', {
            accessibility: this.accessibilityMode,
            name: this.userData.name,
            token: this.token,
            avatar: this.userData.avatar,
            customAvatar: this.userData?.customAvatar || null,
            created: this.userData.created,
            account: this.account.store,
            info: this.userData.info,
            deck: this.userDeck
        });
    }

    public async loadUser() {
        const readUser = await this.sdk.loadFromUserStorage('user');
        console.log('###############', readUser)
        if (readUser) {
            this.accessibilityMode = readUser.accessibility;
            this.token = readUser.token || this.token;
            this.userDeck = readUser.deck || this.userDeck;
            this.userData.loadUser(readUser);
            this.account.load(readUser?.account)
        }
    }

    public hasCredit(credits: number): boolean {
        return this.userData?.credits >= credits || false;
    }

    get hasUser() {
        if (this.token) return true;
        return false;
    }

    get deckList() {
        const deck: any = [];
        Object.entries(PokerDeck).forEach(([key, value]: any) => {
            deck.push({
                id: key,
                data: value,
                icon: `./data/decks/${value[0]}/icon.png`,
                selectable: value[3] === false || this.data.feature.includes(key),
                checked: key === this.userDeck
            })
        })
        return deck;
    }

    get blindMode() {
        return this.accessibilityMode === ACCESSIBILITY_MODE.ACCESSIBILITY;
    }

    get premium() {
        return this.userData?.premium;
    }

    get hasAccount() {
        return this.account.loggedIn;
    }

    get data() {
        return this.userData;
    }

}