const MATCH_EVENT:any = {
    NEW_ROUND: 'NEW_ROUND',
    NEW_ROUND_STATUS: 'NEW_ROUND_STATUS',
    NEW_BLINDS: 'NEW_BLINDS',
    YOU_ARE: 'YOU_ARE',
    NOW_SITTING: 'NOW_SITTING',
    SPLITPOT: 'SPLITPOT',
    MATCHWINNER: 'MATCHWINNER',
    ADFREE: 'ADFREE',
    INITMATCH: 'INITMATCH',
    YOURTURN: 'YOURTURN'
}

export {
    MATCH_EVENT
}