import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'

class GamePokerTimer {
    public remainingTime: number;
    public timeOut: any;

    constructor() {
        makeObservable(this, {
            remainingTime: observable,
            update: action,
            updateTime: action,
            timerDisplay: computed
        });
        this.remainingTime = 0;
        this.timeOut = null;
    }

    public update(time: number): void {
        this.remainingTime = time;
        if (this.remainingTime < 0) {
            this.remainingTime = 0;
        } else {
            this.createTimer();
        }
    }

    public updateTime(): void {
        this.remainingTime -= 1;
        if (this.remainingTime < 0) {
            this.remainingTime = 0;
            if (this.timeOut) {
                clearTimeout(this.timeOut)
            }
        } else {
            this.createTimer();
        }
    }

    public createTimer(): void {
        if (this.timeOut) {
            clearTimeout(this.timeOut)
        }
        const that = this;
        this.timeOut = setTimeout(() => {
            that.updateTime()
        }, 1000)
    }

    public destroy(): void {
        if (this.timeOut) {
            clearTimeout(this.timeOut)
        }
    }

    get timerDisplay(): string {
        return `00${this.remainingTime}`.slice(-2)
    }

}

export default GamePokerTimer;