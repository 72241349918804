import type AppMessageHandler from "../messagehandler";

export default function tableUpgradeAdfree(
    messageHandler: AppMessageHandler,
    roomToken: any,
    playerId: any,
    token: string
): boolean {
    messageHandler.sendMessage(1029, {
        roomToken,
        playerId,
        token
    })
    return true;
}