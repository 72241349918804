import { useEffect, useState } from 'react';
import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'
const DEFAULT_ICONBUTTON_TIMEOUT = 800;

const IconButton = observer(({
    id,
    buttonOption,
    className,
    onClick,
    draggable,
    source,
    openWebsite,
    onMouseMove,
    onDragStart,
    enableDelay = DEFAULT_ICONBUTTON_TIMEOUT,
    onMouseDown,
    onMouseUp
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const [disabledComponent, setDisabled] = useState(null);

    const propsButton = useStyles({
        bgImage: `url(${source})`,
        bgImageSize: 'cover',
        bgRepeat: 'no-repeat',
        widthPx: 64,
        heightPx: 64,
        ...(buttonOption || {})
    });

    return <button
        id={id}
        className={className}
        style={propsButton}
        onMouseMove={onMouseMove}
        disabled={!!disabledComponent}
        onTouchMove={onMouseMove}
        draggable={draggable}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onDragStart={onDragStart}
        onClick={(event) => {
            if (onClick) {
                onClick(event)
            }
            if (openWebsite) {
                window.open(openWebsite);
            }

            if(enableDelay) {
                const addTimeout = setTimeout(() => {
                    //@ts-ignore
                    setDisabled(null);
                }, enableDelay)
    
                //@ts-ignore
                setDisabled(addTimeout);
            }
        }}
    />
});

export default IconButton;