import {
    observer
} from 'mobx-react-lite';
import {
    useEffect
} from 'react';
import {
    openAndroidApp,
    openIosApp
} from '../../../utils/helperfunction';
import {
    ActionButton,
    List,
    ImageComponent,
    IconButton
} from '../../controls'


const MainMenuWeb = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;
    const btnOption = {
        padding: [0, 8, 0, 8],
        lineHeight: 48,
        widthPx: 256,
        textAlign: 'center'
    }

    return <>
        <ImageComponent
            id={'mainmenu_logo'}
            imageOption={{
                widthPx: 800,
                margin: [96, 0, 0, 64]
            }}
            className={'bounce-top'}
            source={'./data/gamelogo_optimized.png'}
            onClick={() => {
                try {
                    //@ts-ignore
                    document.getElementById('mainmenu_logo').classList.remove("bounce-top");

                } catch (e) { }
                setTimeout(() => {
                    try {
                        //@ts-ignore
                        document.getElementById('mainmenu_logo').classList.add("bounce-top");
                    } catch (e) { }
                }, 10)
            }}
        />
        <List
            id={'mainmenu_buttons'}
            components={[

                <ImageComponent
                    source={'./data/badge/googleplay.png'}
                    imageOption={{
                        widthPx: 280,
                        margin: [6, 0, 0, 0]
                    }}
                    onClick={() => openAndroidApp()}
                />,
                <ActionButton
                    id={'button.mainmenu.tv'}
                    translationKey={'button.mainmenu.tv'}
                    actionColor={'green'}
                    icon={'./data/icon/tvblack.svg'}
                    buttonOption={btnOption}
                    onClick={() => appState.openJoinMenuTv('tv')}
                />,
                <ActionButton
                    id={'button.mainmenu.tablet'}
                    translationKey={'button.mainmenu.tablet'}
                    actionColor={'blue'}
                    icon={'./data/icon/tablet2.svg'}
                    buttonOption={btnOption}
                    onClick={() => appState.openJoinMenuTv('tablet')}
                />,
                <ImageComponent
                    source={'./data/badge/appstore.svg'}
                    imageOption={{
                        widthPx: 256,
                        margin: [6, 0, 0, 0]
                    }}
                    onClick={() => openIosApp()}
                />
            ]}
            listOption={{
                widthPx: 295
            }}
            containerOption={{
                margin: [64, 0, 0, 85],
                float: 'left',
                widthPx: 1334
            }}
        />
        <List
            id={'mainmenu_linklist'}
            components={[
                <IconButton
                    id={'btn_website'}
                    source={'./data/ui/website.png'}
                    openWebsite={process.env.REACT_APP_WEBSITE}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_tiktok'}
                    source={'./data/ui/tiktok.png'}
                    openWebsite={process.env.REACT_APP_TIKTOK}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_twitter'}
                    source={'./data/ui/twitter.png'}
                    openWebsite={process.env.REACT_APP_TWITTER}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_facebook'}
                    source={'./data/ui/facebook.png'}
                    openWebsite={process.env.REACT_APP_FACEBOOK}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_instagram'}
                    source={'./data/ui/instagram.png'}
                    openWebsite={process.env.REACT_APP_INSTAGRAM}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                <IconButton
                    id={'btn_contact'}
                    source={'./data/icon/contact.svg'}
                    openWebsite={process.env.REACT_APP_SUPPORT}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />,
                <IconButton
                    id={'btn_instagram'}
                    source={'./data/ui/settings.svg'}
                    onClick={() => appState.openSetting('common')}
                    buttonOption={{
                        margin: [8, 0, 0, 8]
                    }}
                />
            ]}
            listOption={{
                widthPx: 80,
                heightPx: 80,
                lineHeight: 80
            }}
            containerOption={{
                float: 'left',
                widthPx: 1334,
                heightPx: 80,
                margin: [80, 0, 0, 0]
            }}
        />
    </>
});

export default MainMenuWeb;