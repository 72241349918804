import {
    observer
} from 'mobx-react-lite';
import {
    translateKey,
    unixTime
} from '../../../utils/helper'
import getSdk from '../../../api'

const Checkbox = observer(({
    id,
    disableClick,
    containerOption,
    buttonOption,
    labelOption,
    label,
    store,
    onChange,
    storeProperty,
    checkboxUnixtime,
    checkboxNameAddition = '',
    value,
    children
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const useLabelText = label ? translateKey(label) : label;
    const checkboxState = store && storeProperty ?
        store[storeProperty] : value;

    const propsContainer = useStyles({
        widthPx: 440,
        heightPx: 60,
        lineHeight: 60,
        color: '#c1c1c1',
        fontSize: 20,
        fontWeight: 'bold',
        ...(containerOption || {})
    })
    const propsButton = useStyles({
        float: 'left',
        bgImage: `url(./data/ui/checkbox${checkboxNameAddition}${checkboxState ? '1' : '0'}.svg)`,
        bgRepeat: 'no-repeat',
        bgImageSize: 'contain',
        border: 'none',
        widthPx: 48,
        heightPx: 48,
        ...(buttonOption || {})
    });

    const propsLabel = useStyles({
        widthPx: 360,
        heightPx: 48,
        lineHeight: 48,
        float: 'left',
        textAlign: 'left',
        padding: [0, 0, 0, 16],
        ...(labelOption || {})
    })

    const handleCheckboxSwitch = (event: any) => {
        if (disableClick) {
            return;
        }
        event.stopPropagation();
        if (store?.updateValue && storeProperty) {
            store.updateValue(storeProperty, checkboxUnixtime ?
                store[storeProperty] ? 0 : unixTime() :
                1 - store[storeProperty])
        } else if(onChange) {
            onChange()
        }
    }

    return <div id={`${id}-container`} style={propsContainer}>
        <button
            id={`${id}-button`}
            style={propsButton}
            onClick={handleCheckboxSwitch}
        />
        <div
            id={`${id}-label`}
            style={propsLabel}
            onClick={handleCheckboxSwitch}
        >
            {useLabelText}
        </div>
        {children ? children : null}
    </div>
});

export default Checkbox;