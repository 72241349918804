import {
    observer
} from 'mobx-react-lite';
import { convertNumberToString, translateKey } from '../../utils/helper';
import {
    Container,
    Icon
} from '../controls';

const SnackbarComponent = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;
    const snackbar = appState?.snackbar;

    const getSnackbarIcon = (type: any) => {
        if (type === 'demo') {
            return './data/icon/clock.svg';
        }
        if (type === 'money') {
            return './data/ui/chip.png';
        }
        if (type === 'coins') {
            return './data/ui/coin.png';
        }
        if (type === 'warning') {
            return './data/icon/important.svg';
        }
        return './data/ui/info.svg';

    }

    const getSnackbarText = (data: any) => {
        if (data?.type === 'demo') {
            return translateKey('snackbar.demo')
        }

        if (data?.type === 'invite') {
            return translateKey('snackbar.invite')
        }

        if (data?.type === 'money') {
            return `+ ${convertNumberToString(data.money)}`;
        }
        if (data?.type === 'coins') {
            return `+ ${data.coins}`;
        }

        return translateKey(data?.message, {}, data?.replacements || []);
    }

    return snackbar ?
        <Container
            containerOption={{
                position: 'fixed',
                widthPx: 480,
                heightPx: snackbar?.type === 'demo' ? 28 : 64,
                lineHeight: snackbar?.type === 'demo' ? 28 : 64,
                textAlign: 'center',
                fontSize: snackbar?.type === 'demo' ? 24 : 32,
                fontWeight: 'bold',
                bgColor: snackbar.type === 'warning' ? '#fff' : '#dde4ff',
                top: snackbar?.type === 'demo' ? 0 : 8,
                leftPercent: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                borderSize: snackbar?.type === 'demo' ? 4 : 8,
                border: `solid ${snackbar.type === 'warning' ? '#e2574c' : '#051d78'}`,
                borderRadius: 50,
                color: snackbar.type === 'warning' ? '#e2574c' : '#051d78'
            }}
            onClick={() => {
                if (snackbar?.onClick) {
                    snackbar.onClick();
                }
                appState.hideSnackbar();
            }}
        >
            {snackbar?.type !== 'invite' ? (
                <Icon
                    iconOption={{
                        widthPx: snackbar?.type === 'demo' ? 24 : 48,
                        heightPx: snackbar?.type === 'demo' ? 24 : 48,
                        margin: [snackbar?.type === 'demo' ? 4 : 8, 0, 0, 16],
                        float: 'left'
                    }}
                    source={getSnackbarIcon(snackbar.type)}
                />
            ) : null}

            {getSnackbarText(snackbar)}
        </Container>
        : null;
});

export default SnackbarComponent;