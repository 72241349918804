import {
    observer
} from 'mobx-react-lite';
import {
    useEffect
} from 'react';
import GameComponent from './game/GameComponent';
import Setting from './settings/Setting';
import DialogComponent from './dialog/DialogComponent';
import ExpireComponent from './dialog/ExpireComponent';
import SnackbarComponent from './snackbar/SnackbarComponent'
import BuyInDialog from './dialog/BuyInDialog';
import TutorialDialog from './dialog/TutorialDialog';
import LoaderComponent from './LoaderComponent';
import GameOverlay from './game/overlay/GameOverlay';
import LoadingSpinner from './dialog/LoadingSpinner';
import GameShop from './shop/GameShop';

const GameCanvas = observer(({ sdk }: any) => {
    const { layoutManager } = sdk;

    useEffect(() => {
        // Update the layoutmanager to fit the margin of the client
        layoutManager.updateScreen(window);
    }, []);


    return <>
        <div
            id={'gamecanvas'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <LoaderComponent sdk={sdk} />
            <GameShop sdk={sdk} />
            <Setting sdk={sdk} />
            <GameComponent sdk={sdk} />
        </div>
        <LoadingSpinner sdk={sdk} defaultSpinner={true} />
        <GameOverlay sdk={sdk} />
        <DialogComponent sdk={sdk} />
        <ExpireComponent sdk={sdk} />
        <BuyInDialog sdk={sdk} />
        <TutorialDialog sdk={sdk} />
        <SnackbarComponent sdk={sdk} /> 
    </>
});

export default GameCanvas;