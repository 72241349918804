import React, { useRef, useEffect, useCallback } from 'react';
import {
    observer
} from 'mobx-react-lite';
import { toJS } from 'mobx';
import PokerCard from '../cards/PokerCard';
import {
    Container
} from '../../../controls';
import getSdk from '../../../../api';
import {
    radianToDegrees,
    distance,
    translateKey
} from '../../../../utils/helper';

let cardMovement: any = undefined;

const PokerHand = observer(({
    gameSession,
    zoomOnTap,
    onFold,
    onReveal,
    cardWidth = 160,
    leftMargin = 0,
    containerOption,
    rotated = false
}: any) => {

    const containerRef = useRef();
    const cardWidthDiff = cardWidth / 160;

    let cardRefFrontA = useRef();
    let cardRefFrontB = useRef();
    let cardRefBackA = useRef();
    let cardRefBackB = useRef();

    const marginLeftCard = gameSession?.isDigitalCardMode ? 32 : 16;
    const usePlayerHand = gameSession?.currentActor?.matchData?.hand || null;
    const usePlayerHandRevealed = gameSession?.currentActor?.matchData?.handRevealed;
    const leftHandRevealed = usePlayerHandRevealed === 1 || usePlayerHandRevealed === 3;
    const rightHandRevealed = usePlayerHandRevealed === 2 || usePlayerHandRevealed === 3;
    const handVisible = !!gameSession?.currentMatch?.round &&
        gameSession?.currentActor?.matchData?.joined &&
        usePlayerHand?.length &&
        !gameSession?.currentActor?.matchData?.folded &&
        !gameSession?.currentActor?.matchData?.prefolded;

    const percentToFold = getSdk()?.appState?.options?.getFoldSensibility(gameSession?.currentMatch?.handMode || 0) || 0.75;
    const percentToReveal = getSdk()?.appState?.options?.getRevealSensibility(gameSession?.currentMatch?.handMode || 0) || 0.75;
    // const featureReveal = gameSession?.ui?.buttonReveal === 2 && !!onReveal;


    const calculateDirection = (startX: number, startY: number, lastX: number, lastY: number): any => {
        const dx = lastX - startX;
        const dy = lastY - startY;
        const angle = Math.atan2(dy, dx);
        const radien = radianToDegrees(angle);
        const realRadien = radien < 0 ? 180 + (180 + radien) : radien;
        const realDistance = distance(lastX, lastY, startX, startY);
        if (realRadien >= 315 || realRadien < 45) {
            return {
                direction: rotated ? 'down' : 'right',
                distance: realDistance
            }
        } else if (realRadien >= 45 && realRadien < 135) {
            return {
                direction: rotated ? 'left' : 'down',
                distance: realDistance
            }
        } else if (realRadien >= 135 && realRadien < 225) {
            return {
                direction: rotated ? 'top' : 'left',
                distance: realDistance
            }
        }
        return {
            direction: rotated ? 'right' : 'top',
            distance: realDistance
        }
    }

    const handleMouseDown = useCallback((event: any) => {
        event.stopPropagation();

        const useLastX = event.clientX || event.touches[0].clientX;

        cardMovement = {
            startX: event.clientX || event.touches[0].clientX,
            startY: event.clientY || event.touches[0].clientY,
            lastX: useLastX,
            lastY: event.clientY || event.touches[0].clientY,
            reveal: null,
            fold: false,
            rotate: 0,
            movedY: 0,
            clipPath: [
                leftHandRevealed ? '' : `circle(0px at 0 0)`,
                rightHandRevealed ? '' : `circle(0px at 0 0)`
            ]
        }

        // Update elements
        if (containerRef?.current) {
            //@ts-ignore
            containerRef.current.style.transform = zoomOnTap ? `scale(${zoomOnTap})` : ''
        }

    }, [leftHandRevealed, rightHandRevealed, zoomOnTap])

    const handleMouseMove = useCallback((event: any) => {

        if (cardMovement && cardMovement?.startX !== -1 && cardMovement?.startY !== -1) {
            cardMovement.lastX = event.clientX || event.touches?.[0].clientX;
            cardMovement.lastY = event.clientY || event.touches?.[0].clientY;
            cardMovement.movedY = cardMovement.startY - cardMovement.lastY;
            cardMovement.rotate = 0;
            cardMovement.reveal = null;
            cardMovement.fold = false;

            //@ts-ignore
            const useCardWidth = parseInt(cardRefBackB.current.style.width.replace('px', ''), 10);
            //@ts-ignore
            const useCardHeight = parseInt(cardRefBackB.current.style.height.replace('px', ''), 10);
            const movement = calculateDirection(cardMovement.startX, cardMovement.startY, cardMovement.lastX, cardMovement.lastY)

            // TOP => fold
            if (movement.direction === 'top') {
                let foldState = movement.distance / (useCardHeight * percentToFold);

                if (foldState > 1.0) {
                    foldState = 1.0;
                    cardMovement.fold = true;
                }
                foldState = foldState * 2.0
                cardMovement.clipPath = [
                    leftHandRevealed ? '' : `circle(0px at 0 0)`,
                    rightHandRevealed ? '' : `circle(0px at 0 0)`
                ];

                // Update elements
                if (containerRef?.current) {
                    //@ts-ignore
                    containerRef.current.style.transform = `${zoomOnTap ? `scale(${zoomOnTap}) ` : ''}translateY(${movement.distance * -1}px)`
                    //@ts-ignore
                    containerRef.current.style.filter = `blur(${foldState}px)`
                }
                if (cardRefFrontA?.current) {
                    //@ts-ignore
                    cardRefFrontA.current.style.transform = ``
                    //@ts-ignore
                    cardRefFrontA.current.style.clipPath = cardMovement.clipPath[0]

                }
                if (cardRefFrontB?.current) {
                    //@ts-ignore
                    cardRefFrontB.current.style.transform = ``
                    //@ts-ignore
                    cardRefFrontB.current.style.clipPath = cardMovement.clipPath[1]
                }
                if (cardRefBackA?.current) {
                    //@ts-ignore
                    cardRefBackA.current.style.transform = ``
                }
                if (cardRefBackB?.current) {
                    //@ts-ignore
                    cardRefBackB.current.style.transform = ``
                }
            } else {
                const calculated = (movement.distance / ((movement.direction === 'down' ? useCardHeight : (useCardWidth / 2)) * 0.25))
                const calculatedClipPath = `circle(${movement.distance}px at 0 0)`;
                let revealState = movement.distance / (useCardHeight * percentToReveal);
                if (revealState > 1.0) {
                    revealState = 1.0;
                }

                cardMovement.rotate = 5 * (calculated > 1 ? 1 : calculated)


                switch (movement.direction) {
                    case 'left':
                        if (!leftHandRevealed) {
                            cardMovement.clipPath = [
                                calculatedClipPath,
                                rightHandRevealed ? '' : `circle(0px at 0 0)`
                            ];
                            if (revealState === 1.0) {
                                cardMovement.reveal = 1;
                            }
                        }

                        break;
                    case 'right':
                        if (!rightHandRevealed) {
                            cardMovement.clipPath = [
                                leftHandRevealed ? '' : `circle(0px at 0 0)`,
                                calculatedClipPath
                            ];
                            if (revealState === 1.0) {
                                cardMovement.reveal = 2;
                            }
                        }

                        break;
                    default: // down
                        cardMovement.clipPath = [
                            leftHandRevealed ? '' : calculatedClipPath,
                            rightHandRevealed ? '' : calculatedClipPath
                        ];
                        if (revealState === 1.0) {
                            cardMovement.reveal = 3;
                        }
                        break;
                }

                // Update elements
                if (containerRef?.current) {
                    //@ts-ignore
                    containerRef.current.style.transform = zoomOnTap ? `scale(${zoomOnTap}) ` : ''
                }
                if (cardRefFrontA?.current) {
                    //@ts-ignore
                    cardRefFrontA.current.style.transform = `rotate(${cardMovement.rotate * -1}deg)`
                    //@ts-ignore
                    cardRefFrontA.current.style.clipPath = cardMovement.clipPath[0];
                    if (revealState === 1.0 && (movement.direction === 'left' || movement.direction === 'down')) {
                        //@ts-ignore
                        // cardRefFrontA.current.style.filter = `blur(2px)`
                        //@ts-ignore
                        // cardRefFrontA.current.style.border = `2px solid red`
                    }
                }
                if (cardRefBackA?.current) {
                    //@ts-ignore
                    cardRefBackA.current.style.transform = `rotate(${cardMovement.rotate * -1}deg)`
                }
                if (cardRefFrontB?.current) {
                    //@ts-ignore
                    cardRefFrontB.current.style.transform = `rotate(${cardMovement.rotate}deg)`
                    //@ts-ignore
                    cardRefFrontB.current.style.clipPath = cardMovement.clipPath[1];
                    if (revealState === 1.0 && (movement.direction === 'right' || movement.direction === 'down')) {
                        //@ts-ignore
                        // cardRefFrontB.current.style.filter = `brightness(2)`
                    }
                }
                if (cardRefBackB?.current) {
                    //@ts-ignore
                    cardRefBackB.current.style.transform = `rotate(${cardMovement.rotate}deg)`
                }
            }

        }
    }, [leftHandRevealed, rightHandRevealed, percentToFold, percentToReveal, zoomOnTap])

    const handleMouseUp = useCallback((event: any) => {
        if (cardMovement) {
            if (containerRef?.current) {
                //@ts-ignore
                containerRef.current.style.transform = ''
                //@ts-ignore
                containerRef.current.style.filter = 'none';
            }
            if (cardRefFrontA?.current) {
                //@ts-ignore
                cardRefFrontA.current.style.clipPath = leftHandRevealed ? '' : `circle(0px at 0 0)`
                //@ts-ignore
                cardRefFrontA.current.style.transform = ``
                //@ts-ignore
                cardRefFrontA.current.style.filter = 'none';
            }
            if (cardRefFrontB?.current) {
                //@ts-ignore
                cardRefFrontB.current.style.clipPath = rightHandRevealed ? '' : `circle(0px at 0 0)`
                //@ts-ignore
                cardRefFrontB.current.style.transform = ``
                //@ts-ignore
                cardRefFrontB.current.style.filter = 'none';
            }
            if (cardRefBackA?.current) {
                //@ts-ignore
                cardRefBackA.current.style.transform = ``
            }
            if (cardRefBackB?.current) {
                //@ts-ignore
                cardRefBackB.current.style.transform = ``
            }

            if (cardMovement.reveal !== null && gameSession?.currentMatch) {
                gameSession.currentMatch.playerReveal(cardMovement.reveal)
            } else if (cardMovement.fold && gameSession?.currentMatch) {
                if (gameSession.currentMatch.playerFold() === true) {
                    //@ts-ignore
                    containerRef.current.classList.add('slide-up');

                    setTimeout(() => {
                        if (containerRef?.current) {
                            //@ts-ignore
                            containerRef.current.classList.remove('slide-up');
                        }
                    }, 3000)
                }
            }

        }

        cardMovement = undefined;
    }, [leftHandRevealed, rightHandRevealed, gameSession?.currentMatch])


    useEffect(() => {
        const mouseEndEvent = process.env.REACT_APP_MOBILE === 'true' || ('ontouchend' in window) ? 'touchend' : 'mouseup';
        const mouseMoveEvent = process.env.REACT_APP_MOBILE === 'true' || ('ontouchmove' in window) ? 'touchmove' : 'mousemove';
        window.addEventListener(mouseEndEvent, handleMouseUp);
        window.addEventListener(mouseMoveEvent, handleMouseMove);
        return () => {
            window.removeEventListener(mouseEndEvent, handleMouseUp);
            window.removeEventListener(mouseMoveEvent, handleMouseMove);
        }
    }, [handleMouseUp, handleMouseMove])

    return handVisible ? <Container
        containerOption={{
            position: 'absolute',
            margin: [5, 10, 0, leftMargin],
            widthPx: 192 * cardWidthDiff,
            heightPx: 256 * cardWidthDiff,
            left: 0,
            bottom: 0,
            zIndex: 1,
            ...(containerOption || {})
        }}
        onMouseDown={handleMouseDown}
        containerRef={containerRef}
    >
        {(usePlayerHand || []).map((card: any, i: number) => {
            return <React.Fragment key={`playercard-back-${i}`}>
                <PokerCard
                    cardWidth={cardWidth}
                    card={null}
                    deck={gameSession.sessionDeck}
                    draggable={false}
                    cardOption={{
                        margin: [2, 0, 0, i === 0 ? 0 : marginLeftCard],
                        position: 'absolute',
                        pointerEvents: 'none',
                        draggable: false,
                        left: i > 0 ? 14 * cardWidthDiff : 0,
                        zIndex: 1 + (i * 2)
                    }}
                    cardRef={i === 0 ? cardRefBackA : cardRefBackB}
                />
                <PokerCard
                    cardWidth={cardWidth}
                    card={toJS(card)}
                    deck={gameSession.sessionDeck}
                    draggable={false}
                    cardRevealed={true}
                    cardOption={{
                        margin: [2, 0, 0, i === 0 ? 0 : marginLeftCard],
                        pointerEvents: 'none',
                        position: 'absolute',
                        draggable: false,
                        left: i > 0 ? 14 * cardWidthDiff : 0,
                        zIndex: 1 + (i * 2),
                        clipPath: usePlayerHandRevealed === i + 1 ? '' : 'circle(0px at 0 0)'
                    }}
                    cardRef={i === 0 ? cardRefFrontA : cardRefFrontB}
                />
            </React.Fragment>
        })}
        <Container
            id={'toucharea'}
            containerOption={{
                width: '100%',
                height: '100%',
                zIndex: 4
            }}
            draggable={false}
            onMouseDown={handleMouseDown}
        />
    </Container> : null;
})

export default PokerHand;