import {
    observer
} from 'mobx-react-lite';
import MainMenu from './menu/MainMenu';
import MainMenuWeb from './menu/MainMenuWeb';
import JoinMenu from './menu/JoinMenu';
import JoinMenuList from './menu/JoinMenuList';
import LobbyMenu from './ingame/lobby/LobbyMenu';
import StartMenu from './menu/StartMenu';
import GameOnlineView from './ingame/views/GameOnlineView';
import GameDigitalCardView from './ingame/views/GameDigitalCardView';
import {
    GAMEVIEW,
    POKERVIEW
} from '../../constants/gameViews'
import PokerTableView from './ingame/table/PokerTableView';
import PokerTabletView from './ingame/tablet/PokerTabletView';

const GameComponent = observer(({ sdk }: any) => {
    const {
        gameLoader,
        appState
    } = sdk;
    return gameLoader.isActive || appState?.settingView !== null || appState?.gamestore ? null : <>
        {appState?.view === GAMEVIEW.STARTVIEW ? <StartMenu sdk={sdk} /> : null}
        {appState?.view === GAMEVIEW.MAINMENU && process.env.REACT_APP_GAMEWEBVIEW !== 'true' ? <MainMenu sdk={sdk} /> : null}
        {appState?.view === GAMEVIEW.MAINMENU && process.env.REACT_APP_GAMEWEBVIEW === 'true' ? <MainMenuWeb sdk={sdk} /> : null}
        {appState?.view === GAMEVIEW.JOINMENU_ACTOR ? <JoinMenuList sdk={sdk} /> : null}
        {appState?.view === GAMEVIEW.JOINMENU_TV ? <JoinMenu sdk={sdk} tv={true} /> : null}
        {appState?.view === GAMEVIEW.GAME ?
            <>
                {appState?.subView === POKERVIEW.LOBBY ?
                    <LobbyMenu sdk={sdk} /> :
                    appState?.currentSession?.currentActor?.tv ? <>
                        {appState?.currentSession?.tvMode === 'tv' ?
                            <PokerTableView sdk={sdk} /> :
                            <PokerTabletView sdk={sdk} />}
                    </> : <>
                        {appState?.currentSession?.isDigitalCardMode ?
                            <GameDigitalCardView sdk={sdk} /> :
                            <GameOnlineView sdk={sdk} />}
                    </>}
            </> : null}
    </>
});

export default GameComponent;