import {
    observer
} from 'mobx-react-lite';
import {
    ImageComponent
} from '../../../controls';

const PokerTablePlayerKrone = observer(({
    pos,
    player,
    imageOption
}: {
    player: any,
    pos: number,
    imageOption: any
}) => {
    const renderWinner = player?.matchData?.winner || false;

    return renderWinner ? <ImageComponent
        id={`seat-${pos}-krone`}
        imageOption={{
            position: 'absolute',
            widthPx: 62,
            heightPx: 35,
            top: 20,
            right: 16,
            ...(imageOption || {})
        }}
        source={'./data/ui/krone.png'}
    /> : null
});

export default PokerTablePlayerKrone;