import {
    makeObservable,
    observable,
    action,
    computed,
    toJS
} from 'mobx'
import {
    subscribeEvent,
} from '../../events'
import {
    timestampMs,
    uuidv4,
    convertWinnerInfo,
    playVibration
} from '../../../utils/helper';
import LobbyState from '../lobbystate';
import GameActor from './gameactor';
import GameMatch from './gamematch';
import type AppState from '../appstate';
import {
    GAMEVIEW,
    POKERVIEW
} from '../../../constants/gameViews'
import {
    PokerDeck
} from '../../../constants/cards';
import {
    GAMEACTION
} from '../../../constants/gameaction'
import { CouchgamesSdk } from '../..';
import GameUI from './gameui';
import {
    MATCH_EVENT
} from '../../../constants/matchevent';
import GameSessionClock from './gamesessionclock';

const CHECK_CONNECTION_REJOIN_TIMEOUT = 2000;
const DEFAULT_SEAT = 6;
let viewedNextPointer = 0;
export default class GameSession {
    private sdk: CouchgamesSdk;
    private subscription: any;
    private shareNote: any;
    private sessionId: string;
    private sessionCreator: boolean;
    private lastSessionUpdateId: number;

    public appState: AppState;
    public dragging: boolean;
    public sessionClock: GameSessionClock | null;
    public demo: boolean;
    public demoExpired: boolean;
    public dealerMode: boolean;

    public roomCode: string | undefined; // roomcode will be set after creation
    public roomToken: string | undefined; // do we need this here?
    public roomLobby: LobbyState | undefined; // the lobby state
    public tvMode: string | undefined;

    public running: boolean;
    public connectionCheck: any;
    public rejoinCheck: any;

    public stage: string | null;

    public currentActor: GameActor | undefined;
    public actorList: Map<number, GameActor>;
    public actorLeavedList: Map<number, any>;

    public adminRights: boolean;
    public adminWinDetection: boolean;
    public adminRoomOpen: boolean;

    public tableSeats: Array<number | null>;
    public tableSeatState: Array<number>;
    public tableConfig: any;
    public tableStatus: number;
    public tableVotes: Array<any>;

    public tableAdsEnabled: boolean;
    public timeClient: number;
    public timeServer: number;

    public currentMatch: GameMatch | null;
    public currentSeatMessage: Map<number, any>;

    public ui: GameUI | null;

    public overlayObject: any;
    public overlayType: string | null;
    public overlaySubmenu: string | null;
    public overlayView: string | null;
    public overlayHistory: Array<any>;

    public vipUpgrade: boolean = false;

    public history: Array<any>;

    constructor(sdk: CouchgamesSdk, appState: AppState, code: string | undefined = undefined, creator: boolean = false) {
        makeObservable(this, {
            overlayObject: observable,
            overlayType: observable,
            overlaySubmenu: observable,
            overlayView: observable,
            overlayHistory: observable,
            demo: observable,
            demoExpired: observable,
            tableVotes: observable,
            adminWinDetection: observable,
            dealerMode: observable,
            adminRoomOpen: observable,
            roomCode: observable,
            dragging: observable,
            tvMode: observable,
            history: observable,
            roomToken: observable,
            tableAdsEnabled: observable,
            actorList: observable,
            tableSeats: observable,
            tableSeatState: observable,
            timeServer: observable,
            tableConfig: observable,
            running: observable,
            currentSeatMessage: observable,
            handleGameMessageJoin: action,
            handleGameMessageAdminEditResponse: action,
            handleGameMessageSelectionUpdate: action,
            updateRoomInfo: action,
            openOverlay: action,
            updateHistory: action,
            updateVotes: action,
            closeOverlay: action,
            updateDragging: action,
            handleGameMessageUpdate: action,
            openOverlaySubmenu: action,
            setExpired: action,
            updateVisibility: action,
            joinGame: action,
            setTvMode: action,
            adminRights: observable,
            currentMatch: observable,
            ui: observable,
            currentActor: observable,
            tableStatus: observable,
            isDigitalCardMode: computed,
            isRabbitHunt: computed,
            admin: computed,
            overlay: computed,
            seats: computed,
            voteList: computed,
            tablet: computed,
            allActor: computed
        })
        this.shareNote = null;
        this.sessionClock = null;
        this.lastSessionUpdateId = 0;
        this.overlayObject = null;
        this.overlayType = null;
        this.overlayView = null;
        this.history = [];
        this.tableVotes = [];
        this.overlaySubmenu = null;
        this.overlayHistory = [];
        this.sessionId = uuidv4();
        this.sdk = sdk;
        this.appState = appState;
        this.tvMode = undefined;
        this.dealerMode = false;
        this.running = false;
        this.demo = false;
        this.demoExpired = false;
        this.roomCode = code ? code.toUpperCase() : code;
        this.roomToken = undefined
        this.roomLobby = new LobbyState(
            creator && appState.mainmenuView === 1 ? 1 : 0,
            creator && appState.mainmenuView === 2 ? 1 : 0
        );
        this.tableSeats = Array(10).fill(null);
        this.tableSeatState = Array(10).fill(1);
        this.adminRights = false;
        this.adminWinDetection = false;
        this.adminRoomOpen = false;
        this.tableAdsEnabled = false;
        this.dragging = false;
        this.actorList = new Map();
        this.actorLeavedList = new Map();
        this.tableConfig = undefined;
        this.stage = null;
        this.tableStatus = 0;
        this.timeClient = timestampMs();
        this.timeServer = 0;
        this.currentMatch = null;
        this.ui = new GameUI();
        this.currentSeatMessage = new Map();
        this.connectionCheck = undefined;
        this.rejoinCheck = undefined;
        this.sessionCreator = creator;

        // Subscribe to the new gamehandling
        this.subscription = [
            subscribeEvent('message.handle', (msg: any) => this.handleGameMessage(msg?.detail?.type, msg?.detail?.rawMessage)),
            subscribeEvent('shop.premium', (msg: any) => this.handlePremiumPurchased(msg?.detail?.paidToken))
        ]
    }

    setExpired(): void {
        this.demoExpired = !this.demoExpired;
    }

    onPause(): void {
        console.log('SESSION PAUSE')
        this.roomLobby?.saveInCache(this.roomCode);
    }

    onResume(): void {
        console.log('SESSION RESUME')
        if (this.roomCode) {
            this.roomLobby?.loadFromCache(this.roomCode);
        }
    }

    openRequestView(): void {
        this.openOverlay(
            {},
            'room',
            'roomsetting'
        )
        this.openOverlaySubmenu('roomrequest')
    }

    showVotingNotice(): void {
        if (this.admin) {
            this.sdk.appState.showSnackbarWarning('snackbar.voting.request', () => {
                this.openOverlay(
                    {},
                    'room',
                    'roomsetting'
                )
                this.openOverlaySubmenu('roomrequest')
            });
        }
    }

    updateVotes(votes: any): void {
        const compareA = this.tableVotes.join('-');
        const compareB = votes.join('-');
        if (compareA !== compareB) {
            this.tableVotes = votes;

            // Only show the notice, when the valuze b is longer than a
            if (compareB.length > compareA.length) {
                this.showVotingNotice();
            }
        }
    }

    updateHistory(history: any): void {
        this.history = [];

        // GAMEACTION
        (history || []).forEach((entry: any, i: number) => {
            const useAction = entry[4];

            if (useAction === GAMEACTION.ACTION_NEWROUND) {
                if (i !== 0) {
                    this.history.push({
                        action: GAMEACTION.ACTION_EMPTY_ROW,
                        playerId: 0,
                        value: 0,
                        value2: 0
                    })
                }
                this.history.push({
                    action: GAMEACTION.ACTION_ROUND_INFO,
                    roundId: entry[2],
                    value: entry[5]
                })
            } else if (useAction === GAMEACTION.ACTION_SMALLBLIND) {
                this.history.push({
                    action: GAMEACTION.ACTION_DEALER_DEALSCARD,
                    playerId: 0,
                    value: 0,
                    value2: 0
                })
                this.history.push({
                    id: entry[0],
                    matchId: entry[1],
                    roundId: entry[2],
                    playerId: entry[3],
                    action: entry[4],
                    value: entry[5],
                    value2: entry[6]
                })
            } else if (useAction === GAMEACTION.ACTION_WINNER) {
                const winnerInfo = convertWinnerInfo(entry[6]);

                this.history.push({
                    id: entry[0],
                    matchId: entry[1],
                    roundId: entry[2],
                    playerId: entry[3],
                    action: entry[4],
                    value: entry[5],
                    value2: winnerInfo?.cards
                })
            } else if (useAction === GAMEACTION.ACTION_BLIND_REVEALED) {
                // TODO
            } else {
                this.history.push({
                    id: entry[0],
                    matchId: entry[1],
                    roundId: entry[2],
                    playerId: entry[3],
                    action: entry[4],
                    value: entry[5],
                    value2: entry[6]
                })
            }
        })

    }

    get tv() {
        return this.tvMode === 'tv' && this.currentActor?.tv;
    }

    get tablet() {
        return this.tvMode === 'tablet' && this.currentActor?.tv;
    }

    setTvMode(mode: string): void {
        if (this.tvMode !== mode) {
            this.tvMode = mode;
            this.saveSession();
        }
    }

    updateRoomInfo(useSession: any): void {
        this.roomToken = useSession.roomToken;
        this.tvMode = useSession.tvMode;
    }

    updateDragging(newState: boolean): void {
        this.dragging = newState;
    }

    handleGameMessageAdminEditResponse(message: any): void {
        const {
            result
        } = message;

        switch (result?.action) {
            case 'windetection':
                if (result.flag === false || result.flag === true) {
                    this.adminWinDetection = result.flag;
                }
                break;
            case 'openstate':
                if (result.flag === false || result.flag === true) {
                    this.adminRoomOpen = result.flag;
                }
                break;
            default:
                break;
        }
    }

    handleGameMessageAdminRequest(message: any): void {
        if (message?.voteInitiator && message?.voteId && message?.status === 'requested') {
            if (this?.admin) {
                let useRequesterName = '"tv device"'
                if (this.actorList?.has(message.voteInitiator)) {
                    useRequesterName = this.actorList?.get(message.voteInitiator)?.name || '-';
                }
                this.sdk.appState.showMessagebox(`adminrequest-${message.voteId}`, 'dialog.voting.adminrequest', () => {
                    this.sdk.getMessageList().adminRequestResult(message.voteId)
                }, () => null, false, [
                    ['$player', `${useRequesterName}`]
                ]);
            }
        }
    }

    handlePremiumPurchased(paidToken: string): void {
        if (paidToken && !this.vipUpgrade && this.sessionRunning && this.tableConfig?.demo === true && this.admin) {
            this.vipUpgrade = true;
            this.sdk.getMessageList().upgrade(paidToken)
        }
    }

    handleGameMessage(type: number | undefined, message: any): void {
        if (!type) return;
        if (!message) return;

        switch (type) {
            case 3:
                console.log('ERROR CAUSE', message)
                if (message?.errorCause === 1001) {
                    if (this.rejoinCheck) {
                        this.rejoinCheck.result = 410;
                    }
                }
                break;
            case 2001:
                this.handleGameMessageJoin(message);
                break;
            case 2106:
                console.log('<UPDATE>', message)
                this.handleGameMessageUpdate(message);
                break;
            case 2107:
                this.handleGameMessageSelectionUpdate(message);
                break;
            case 2112:
                if (message.notice === 'autoaction') {
                    this.sdk.appState.showSnackbar('snackbar.notice.autoaction', [], 5000)
                }
                break;
            case 2113:
                this.updateHistory(message?.history || []);
                break;
            case 2151:
                this.updateVotes(message?.votes || []);
                break;
            case 2160:
                this.handleGameMessageAdminEditResponse(message);
                break;
            case 2180:
                this.handleGameMessageAdminRequest(message);
                break;
            case 2190:
                this.sdk.appState.showSnackbar('snackbar.notice.clock.connected', [], 5000)
                break;
            case 2200:
                this.currentActor?.updateAdminAuth(message.auth)
                if (message.granted) {
                    this.adminRights = true;
                    this.sdk.appState.showMessagebox('adminaccessgranted', 'dialog.admin.access.received', null, null, true);
                }
                break;
            case 2208: // ads upgraded
                this.sdk.appState.showSnackbar('snackbar.notice.adfree', [], 5000)
                break;
            case 2209: // table upgraded
                this.sdk.appState.showSnackbar('snackbar.demo.upgraded', [], 5000)
                break;
            case 2210: // The expire message
                if (message?.expireMessage === 1) {
                    this.sdk.appState.showMessagebox('expiredemo', this.admin ?
                        'dialog.expire.demo.creator' :
                        'dialog.expire.demo.player',
                        () => null,
                        null,
                        true,
                        [],
                        false,
                        {
                            fontSize: 24,
                            openLocation: 'session'
                        },
                        this.admin ? 'expire' : 'message'
                    );
                } else if (message?.expireMessage === 2) {
                    this.sdk.appState.showMessagebox('expiredemo', this.admin ?
                        'dialog.gameover.demo.creator' :
                        'dialog.gameover.demo.player',
                        () => null,
                        null,
                        true,
                        [],
                        false,
                        {
                            fontSize: 24
                        },
                        this.admin ? 'expire' : 'message'
                    );
                } else if (message?.expireMessage === 3) {
                    this.sdk.appState.showMessagebox('expire5', 'dialog.expire.30minutes', null, null, true, [], false, {
                        fontSize: 24
                    });
                }
                break;
            case 2300:
                break;
            case 2500: // Trigger dialog
                if (message?.dialogText) {
                    this.sdk.appState.showMessagebox('sessiontext', message.dialogText, null, null, message?.dialogCloseOutside, [], false, message?.dialogConfig, message?.dialogType);
                }
                break;
            case 2501: // Trigger snackbar
                if (message?.snackbarText) {
                    this.appState.showSnackbar(message.snackbarText);
                }
                break;
            case 99:
                if (this.connectionCheck) {
                    this.connectionCheck.result = message.sessionValid;
                }

                break;
            default:
        }
    }

    handleGameMessageJoin({ actorId, actorName, actorTv, actorRole, roomCode, roomToken }: any): void {

        if (this.roomCode === roomCode && this.rejoinCheck) {
            this.rejoinCheck.result = 200;
        }

        this.roomCode = roomCode;
        this.roomToken = roomToken;

        const myActor = new GameActor(
            actorId,
            actorName,
            actorRole,
            this.sdk?.appState?.user?.userData?.avatar || null,
            actorTv,
            true,
            false
        );

        this.actorList.set(
            actorId,
            myActor
        )
        myActor.initAsPlayer();


        if (this.currentMatch) {
            this.currentMatch.handleActorJoin();
        }
        this.currentActor = myActor;
        this.running = true;
        this.saveSession();
    }

    openPreviousOverlay(): void {
        if (this.overlayHistory.length > 1) {
            const useHistory = this.overlayHistory.slice(0, -1)
            if (useHistory.length) {
                this.openOverlay(
                    this.overlayObject,
                    useHistory[useHistory.length - 1].overlayType,
                    useHistory[useHistory.length - 1].overlayName,
                    useHistory
                )
                return;
            }
        }
        this.closeOverlay();
    }

    openOverlaySubmenu(overlaySubmenu: string | null): void {
        if (this.overlaySubmenu === overlaySubmenu) {
            this.overlaySubmenu = null;
        } else {
            this.overlaySubmenu = overlaySubmenu;
        }
    }

    openOverlay(obj: any, overlayType: string, overlayName: string, overwriteHistory: any = undefined): void {
        if (overlayName !== this.overlayView) {
            this.overlayObject = obj;
            this.overlayType = overlayType;
            this.overlayView = overlayName;
            this.overlaySubmenu = null;
            if (overwriteHistory) {
                this.overlayHistory = overwriteHistory;
            } else {
                this.overlayHistory.push({
                    overlayType,
                    overlayName
                });
            }
        }
    }

    closeOverlay(): void {
        this.overlayView = null;
        this.overlayType = null;
        this.overlayObject = null;
        this.overlayHistory = [];
    }

    get overlay() {
        if (this.overlayView && this.overlayType && this.overlayObject) {
            return {
                overlayView: this.overlayView,
                overlayType: this.overlayType,
                overlayObj: this.overlayObject
            }
        }
        return null;
    }


    handleGameMessageSelectionUpdate({ seats, actor }: any): void {
        (actor || []).forEach((pl: any) => {
            if (this.actorList.has(pl.playerId)) {
                // update
                this.actorList.get(pl.playerId)?.update(pl);
            }
        })

        if (this.tableSeats.join('-') !== seats.join('-')) {
            this.tableSeats = seats;
        }
    }

    /**
     * Trigger a new round
     */
    triggerEventNewRound(): void {
        // check if we should show a banner (SB, BB)
        if (this.currentActor && this.isDigitalCardMode && this.currentMatch && this.tableConfig?.flagShowBlindNotice === 1) {
            let useMessage = '';

            if (this.currentActor?.matchData?.isSmallBlind && this.currentActor?.matchData?.isDealer) {
                useMessage = 'game.note.handview.dealer.smallblind';
            } else if (this.currentActor?.matchData?.isSmallBlind) {
                useMessage = 'game.note.handview.smallblind';
            } else if (this.currentActor?.matchData?.isBigBlind && this.currentActor?.matchData?.isDealer) {
                useMessage = 'game.note.handview.dealer.bigblind';
            } else if (this.currentActor?.matchData?.isBigBlind) {
                useMessage = 'game.note.handview.bigblind';
            } else if (this.currentActor?.matchData?.isDealer) {
                useMessage = 'game.note.handview.dealer';
            }

            if (useMessage) {
                this.appState.showMessagebox('actor-notice', useMessage, null, null, true, [
                    ['$smallblind_value', this.currentMatch?.smallBlind || 0],
                    ['$bigblind_value', this.currentMatch?.bigBlind || 0]
                ], true);
            }
        }

        // play the vibration
        playVibration();
    }

    triggerEvents(events: any) {
        (events || []).forEach((event: any) => {
            switch (event.id) {
                case MATCH_EVENT.INITMATCH:
                    if (this.roomLobby) {
                        this.roomLobby.currentTab = 2;
                    }
                    break;
                case MATCH_EVENT.NOW_SITTING:
                    if (this.isDigitalCardMode) {
                        this.appState.updateValue('subView', POKERVIEW.HAND)
                    }
                    break;
                // Splitpot
                case MATCH_EVENT.SPLITPOT:
                    if (this.appState.subView === POKERVIEW.HAND) {
                        this.appState.showSnackbar('snackbar.notice.splitpot');
                    }
                    break;
                // Who wins?
                case MATCH_EVENT.MATCHWINNER:
                    if (this.appState.subView === POKERVIEW.HAND && event.value !== this.currentActor?.id && this.actorList.has(event.value)) {
                        this.appState.showSnackbar('snackbar.notice.matchwinner', [
                            ['$player', this.actorList.get(event.value)?.name || '']
                        ]);
                    }
                    break;
                // We got a new round
                case MATCH_EVENT.NEW_ROUND:
                    this.triggerEventNewRound();
                    break;
                case MATCH_EVENT.YOURTURN:
                    // play the vibration
                    playVibration();
                    break;
                // We got a new round status
                case MATCH_EVENT.NEW_ROUND_STATUS:
                    // update the chip
                    if (this.currentActor) {
                        this.currentActor.onNewRoundStatus(this.sdk)
                    }
                    break;
                // You are playing now
                case MATCH_EVENT.YOU_ARE:
                    // update the chip
                    if (this.currentActor) {
                        this.currentActor.onYouAre(this)
                    }
                    // Play a vibration
                    playVibration();
                    break;
                case MATCH_EVENT.NEW_BLINDS:
                    this.appState.showMessagebox('blindincrease', 'text.game.banner.blindincrease', null, null, true, [
                        ['$smallblind', `${event.value?.smallBlind}`],
                        ['$bigblind', `${event.value?.bigBlind}`]
                    ])
                    break;
                default:
                    break;
            }
        })
    }

    handleGameMessageUpdate({ id, stage, time, admin, actor, dealer, actorLeaved, table, life, ads }: any): void {

        if (this.lastSessionUpdateId >= id) {
            return;
        }

        // #####################################################
        // Be sure that only new messages will be handled
        this.lastSessionUpdateId = id;
        this.dealerMode = table?.dealerMode || false;

        let matchEvents: any = [];

        this.currentSeatMessage.clear();

        // #####################################################
        // Prepare
        // #####################################################
        const actorToRemove: Map<number, boolean> = new Map();

        // #####################################################
        // Fill the list with all actors to remove
        this.actorList.forEach((_v, key: number): any => {
            actorToRemove.set(key, true);
        });

        // #####################################################
        // Does I have admin rights?
        this.adminRights = !!admin.list.find((actorId: number) => actorId === this.me?.id)

        if (this.adminRights) {
            this.adminWinDetection = admin.windetection;
            this.adminRoomOpen = admin.open;
        }

        // #####################################################
        // First message, tell him that he should show the waiting area instead of the creating mode
        if ((id === 1 || id === 2) && table?.status === 0 && this.adminRights) {
            matchEvents.push({
                id: MATCH_EVENT.INITMATCH
            })
        }


        // #####################################################
        this.updateVotes(table.votes)

        // #####################################################
        // # UPDATE ACTORS
        actor.forEach((pl: any) => {
            // Delete the player from the list
            actorToRemove.delete(pl.playerId);

            if (this.actorList.has(pl.playerId)) {
                // update
                if (this.actorList.get(pl.playerId)?.update(pl)) {
                    if (this.actorList.get(pl.playerId)?.me) {
                        matchEvents.push({
                            id: MATCH_EVENT.NOW_SITTING
                        })
                    }
                }
            } else {
                const newActor = new GameActor(
                    pl.playerId,
                    pl.name,
                    1,
                    pl.avatar,
                    null,
                    pl.active,
                    pl.blind || false
                );

                newActor.update(pl);

                this.actorList.set(
                    pl.playerId,
                    newActor
                )
            }
        })

        dealer.forEach((dealer: any) => {
            if (this.currentActor && this.currentActor.id === dealer.playerId) {
                this.currentActor.update({
                    ...dealer,
                    role: 5
                });
            }
        })

        // Add leaved actors to the list
        this.actorLeavedList.clear();

        // add all leaved player
        actorLeaved.forEach((pl: any) => {
            // Delete the player from the list
            if (this.actorList.has(pl.playerId)) {
                // update
                this.actorList.get(pl.playerId)?.update(undefined, true);
            } else {
                this.actorLeavedList.set(pl.playerId, pl)
            }
        });

        // #####################################################
        // # REMOVE OLD UNUSED ACTORS
        actorToRemove.forEach((_v, key: number): any => {
            this.actorList.delete(key);
        });

        // #####################################################
        // Update the tableSeats
        if (this.tableSeats.join('-') !== table.seats.join('-')) {
            this.tableSeats = table.seats;
        }

        if (this.tableSeatState.join('-') !== table.seatsAllowed.join('-')) {
            this.tableSeatState = table.seatsAllowed;
        }

        // this.tableHistory = table?.history || []; Do not add history here
        this.tableConfig = table?.config || {};
        this.tableStatus = table?.status || 0;
        this.stage = stage;

        // Check if the demo is active
        // life: {
        //     born: this.roomCreated,
        //     lifetime: this.roomLifetime,
        //     remaining: (this.roomCreated + this.roomLifetime) - timestamp(),
        //     demo: this.roomTable?.tableConfig?.demo
        //   },
        if (life) {
            if (this.sessionClock === null) {
                this.sessionClock = new GameSessionClock();
            }
            this.sessionClock.update(life.remaining)
            this.demo = life?.demo || false;
            this.demoExpired = life?.expired || false;
        }

        // #####################################################
        // Set the timestamps
        this.timeClient = timestampMs();
        this.timeServer = time;

        // #####################################################
        // Update Matchinfo
        if (table?.match) {
            if (this.currentMatch) {
                matchEvents = [
                    ...matchEvents,
                    ...this.currentMatch.update(table.match)
                ]
            } else {
                this.currentMatch = new GameMatch(table.match);
                matchEvents = [
                    ...matchEvents,
                    ...this.currentMatch.update(table.match)
                ]
            }

            this.actorList.forEach((actor: GameActor): any => {
                const playerMatchData: any = table?.match?.player?.find((pl: any) => pl.playerId === actor.id);
                const playerHand: any = table?.match?.round?.hand?.find((data: any) => data[0] === actor.id);
                const usePlayerHand = playerHand?.[1] === null ?
                    null :
                    playerHand?.[1] || [];
                const usePlayerHandRevealed = playerHand?.[2] || 0;

                actor.updateMatch(playerMatchData, {
                    hand: usePlayerHand,
                    revealed: usePlayerHandRevealed
                }, table?.match)

                // Update seatMessage
                if (playerMatchData) {
                    if (this.currentMatch?.round && !this.currentMatch?.round?.finished) {

                        // this.currentSeatMessage.set(playerMatchData.playerId, {
                        //     message: 'button.game.fold',
                        //     color: 'red'
                        // })

                        if (playerMatchData.allIn) {
                            this.currentSeatMessage.set(playerMatchData.playerId, {
                                message: 'allin',
                                color: 'orange'
                            })
                        } else if (playerMatchData.folded) {
                            this.currentSeatMessage.set(playerMatchData.playerId, {
                                message: 'fold',
                                color: 'red'
                            })
                        } else if (playerMatchData.lastAction !== 0) {
                            this.currentSeatMessage.set(playerMatchData.playerId, {
                                message: 'fold',
                                color: 'red'
                            })
                            let useMessage = '';
                            let useBubble = '';
                            switch (playerMatchData.lastAction) {
                                case GAMEACTION.ACTION_CALL:
                                    useMessage = 'call';
                                    useBubble = 'green';
                                    break;
                                case GAMEACTION.ACTION_BET:
                                    useMessage = 'bet';
                                    useBubble = 'yellow';
                                    break;
                                case GAMEACTION.ACTION_RAISE:
                                    useMessage = 'raise';
                                    useBubble = 'yellow';
                                    break;
                                case GAMEACTION.ACTION_RERAISE:
                                    useMessage = 'reraise';
                                    useBubble = 'yellow';
                                    break;
                                case GAMEACTION.ACTION_DEAL:
                                    // useMessage = (this.currentMatch?.isShowDown && this.isDigitalCardMode) ?
                                    //     'button.game.showdown' :
                                    //     'button.game.deal'
                                    useBubble = 'green';
                                    break;
                                case GAMEACTION.ACTION_FOLD:
                                    useMessage = 'fold';
                                    useBubble = 'red';
                                    break;
                                case GAMEACTION.ACTION_CHECK:
                                    useMessage = 'check';
                                    useBubble = 'green';
                                    break;
                                default:
                                    break;
                            }

                            if (useMessage) {
                                this.currentSeatMessage.set(playerMatchData.playerId, {
                                    message: useMessage,
                                    color: useBubble
                                })
                            }
                        }
                        // TODO
                        // else if (this.currentMatch?.isDigitalCardMode && round?.dealPushed?.find(checkPl => checkPl === pl.playerId)) {
                        //     writeSeatMessage(pl.playerId, isShowDown ?
                        //         'button.game.showdown' :
                        //         'button.game.check'
                        //         , 'green');
                        // }
                    } else {
                        if (this.isDigitalCardMode) {
                            if (playerMatchData.folded) {
                                this.currentSeatMessage.set(playerMatchData.playerId, {
                                    message: 'button.game.fold',
                                    color: 'red'
                                })
                            }

                            // TODO -> NEXT
                        } else {
                            if (playerMatchData.folded) {
                                this.currentSeatMessage.set(playerMatchData.playerId, {
                                    message: 'button.game.fold',
                                    color: 'red'
                                })
                            }
                        }
                    }
                }
            });

            // Check if the player is currently playing
            if (this.currentMatch?.round && !this.currentMatch?.round?.finished) {
                if (this.currentMatch.round.currentPlayer && !this.currentMatch.round.roundEndPause) {
                    if (this.currentMatch.round.currentPlayer === this.currentActor?.id) {
                        this.currentSeatMessage.set(this.currentMatch.round.currentPlayer, {
                            message: 'text.game.bubble.yourturn',
                            color: 'blue'
                        })
                    } else {
                        this.currentSeatMessage.set(this.currentMatch.round.currentPlayer, {
                            message: '...',
                            color: 'blue'
                        })
                    }
                }
            }

        } else {
            if (this.currentMatch) {
                this.currentMatch.destroy();
                this.currentMatch = null;
            }
        }

        // Decide where to go
        if (this.tableStatus === 0) {
            // We are in the lobby
            this.appState.view = GAMEVIEW.GAME;
            this.appState.subView = POKERVIEW.LOBBY;
        } else if (this.tableStatus > 0) {
            if (
                (this.appState.view === GAMEVIEW.GAME &&
                    this.appState.subView === POKERVIEW.LOBBY) ||
                this.appState.view !== GAMEVIEW.GAME
            ) {
                this.appState.view = GAMEVIEW.GAME;
                this.appState.subView = POKERVIEW.TABLE;
            }
            if (!this.tvMode && !this.currentActor?.isGameDealer) {
                this.sdk.appState.openTutorialDialog(true);
            }
        }

        // Ads enabled
        if (this.tableAdsEnabled && ads === false) {
            matchEvents.push(MATCH_EVENT.ADFREE)
        }

        this.tableAdsEnabled = ads || false;

        this.updateVisibility();

        // Trigger all events
        if (matchEvents.length) {
            this.triggerEvents(matchEvents);
        }

        // Make a note after 1 minute
        if (!this.shareNote && this.tableConfig?.private === false) {
            const that = this;
            this.shareNote = true;

            setTimeout(() => {
                try {
                    that.appState.showSnackBarShare();
                } catch (e) { }
            }, 60 * 1000)
        }

    }

    checkView(subview: number): number {
        if (subview === POKERVIEW.LOBBY) {
            if (this.tableStatus > 0) return POKERVIEW.TABLE;
        }
        return subview;
    }

    joinMatch(seat: number | null) {

        const isSitDown: boolean = this.tableConfig?.buyIn?.type === 'sitdown';
        const buyInAllowed = !this.currentActor?.sitting;

        if (this?.tableConfig?.flagForbidBuyIn === 1) {
            this.sdk.appState.showMessagebox('buyindisabled', isSitDown ? 'dialog.sitdown.disabled' : 'dialog.buyin.disabled', () => null, null, true)
            return;
        }

        if (!buyInAllowed) {
            return;
        }

        if (isSitDown) {
            this.sdk.getMessageList().sitDown(seat)
        } else {
            this.openBuyIn(seat);
        }
    }

    // BuyIn is only allowed if the player has no seat
    openBuyIn(seat: number | null) {
        const useBuyInStack = this.tableConfig?.buyIn?.stack;

        if (Array.isArray(useBuyInStack)) {
            this.sdk.appState.openBuyInDialog(seat, useBuyInStack)
        } else {
            this.sdk.appState.openBuyInDialog(seat, useBuyInStack)
        }
    }

    async buyAdFree(): Promise<void> {
        const useSpinner = this.sdk.appState.createSpinner();

        const result = await this.sdk.fetchApi('api', 'cointoken', {
            userToken: this.sdk.appState.user?.token,
            value: 10
        }, 'POST')

        if (result?.status === 201) {
            const {
                transaction
            } = result.json;

            if (transaction) {
                this.sdk?.getMessageList().upgradeAdFree(transaction.hash)
                this.sdk?.appState?.user?.handleCoinUpdate(transaction.taler)
            }
        }

        // After finishing, close the spinner
        useSpinner.close();

    }

    /**
     * 
     * @param seat -1 is random
     * @param cash 
     */
    async buyIn(cash: any, seat: number | null = null): Promise<void> { // d

        // BuyIN custom benötigt keine Erstellung eines Tokens
        // BuyIN credit benötigt einen Token
        const useSpinner = this.sdk.appState.createSpinner();
        let useValue = undefined;
        let useCreditToken = undefined;

        if (this.tableConfig?.buyIn?.mode === 'custom') {
            useValue = parseInt(cash, 10);
        } else if (this.tableConfig?.buyIn?.mode === 'credit') {
            const result = await this.sdk.fetchApi('api', 'credittoken', {
                userToken: this.sdk.appState.user?.token,
                value: parseInt(cash, 10),
                type: 'CREDIT'
            }, 'POST')

            // Do we got a token?
            if (result?.status === 201) {
                const {
                    transaction
                } = result.json;

                if (transaction) {
                    useCreditToken = transaction.hash;
                    this.sdk?.appState?.user?.handleCreditUpdate(transaction.credits)
                }
            } else {
                const getBuyInInfo = this.tableConfig?.buyIn?.stack || null;
                const getUserMaxCredit = result?.json?.credits || 0;
                let useBuyInStack = null;

                if (getUserMaxCredit > 0 && Array.isArray(getBuyInInfo)) {
                    if (getUserMaxCredit >= getBuyInInfo[0] && getUserMaxCredit <= getBuyInInfo[1]) {
                        useBuyInStack = getUserMaxCredit;
                    }
                }

                this.sdk.appState.showMessagebox(
                    'no-credits',
                    'dialog.user.credits.notenough',
                    null,
                    () => null,
                    true, // outside
                    [],
                    false,
                    {
                        onBuyIn: useBuyInStack ? {
                            seat,
                            credits: useBuyInStack
                        } : undefined
                    },
                    'nocredits'
                );
            }
        }

        if (useValue || useCreditToken) {
            this.sdk.getMessageList().buyIn(seat, useValue, useCreditToken)
        }

        this.sdk.appState.closeBuyInDialog();

        // After finishing, close the spinner
        useSpinner.close();
    }

    updateVisibility(): void {
        const isRoundFinished = this.currentMatch?.round?.finished;
        const disableActorInteraction: boolean | undefined = this.currentActor?.id !== this.currentMatch?.round?.currentPlayer ||
            isRoundFinished ||
            this.currentMatch?.round?.roundEndPause;

        this.ui?.updateFlag('flagBankroll', this.isDigitalCardMode ? false : true)
        this.ui?.updateButton('buttonNewGame', 0);
        this.ui?.updateButton('buttonIamBack', 0);
        this.ui?.updateButton('buttonTable', 0);
        this.ui?.updateButton('buttonNextPointer', 0);
        this.ui?.updateButton('buttonGlobalBuyIn', 0);
        this.ui?.updateButton('buttonSingleBuyIn', 0);
        this.ui?.updateButton('buttonCheck', 0);
        this.ui?.updateButton('buttonDeal', 0);
        this.ui?.updateButton('buttonAllInDeal', 0);
        this.ui?.updateButton('buttonAllIn', 0);
        this.ui?.updateButton('buttonDealCard', 0);
        this.ui?.updateButton('buttonShowdown', 0);
        this.ui?.updateButton('buttonHand', 0);
        this.ui?.updateButton('infoNextRound', 0);
        this.ui?.updateButton('buttonMulti', 0);
        this.ui?.updateButton('buttonStandup', 0);
        this.ui?.updateButton('buttonReveal', 0);
        this.ui?.updateButton('buttonDealer', 0);

        if (this.currentActor && this.currentActor.isGameDealer) {
            this.ui?.updateButton('buttonDealer', 2);
        }

        // Check Button
        if (
            !this.isDigitalCardMode &&
            this.currentMatch?.round?.bet === this.currentActor?.matchData?.bet &&
            !this.currentMatch?.round?.allInShowdown
        ) {
            this.ui?.updateButton('buttonCheck', disableActorInteraction ? 1 : 2);
        }

        // Multibutton
        if (
            !this.isDigitalCardMode &&
            !this.currentMatch?.round?.allInShowdown
        ) {
            this.ui?.updateButton('buttonMulti', disableActorInteraction ? 1 : 2);
        }

        // AllIn Deal
        if (this.currentMatch?.round?.allInDealVisible && !isRoundFinished) {
            this.ui?.updateButton('buttonAllInDeal', 2);
        }

        // Deal and DealCard
        if (
            (this.currentMatch?.status === 0 || (this.currentMatch?.status === 1 && !this.currentMatch?.round) || isRoundFinished) &&
            !this.tableConfig?.autostartRound &&
            (this.currentActor?.matchData?.joined || this.currentActor?.isGameDealer)
        ) {
            const disableDefaultDeal = (this.currentMatch?.nextVotes || []).includes(this.currentActor?.id);
            this.ui?.updateButton('buttonDeal', disableDefaultDeal ? 1 : 2);
        } else if (this.isDigitalCardMode) {
            const showdownButton = this.currentMatch?.round?.status === 3 || false;
            let disabledDeal = isRoundFinished ||
                (!this.currentActor?.playing && !this.currentActor?.matchData?.isDealer) ||
                this.currentActor?.matchData?.dealPushed ||
                // (this.currentActor?.playing && this.currentActor?.matchData?.allIn) ||
                this.currentActor?.isGameDealer ||
                (this.currentMatch?.dealerMissing === 0 && !this.currentActor?.matchData?.isDealer);

            const hideDealButton = !this.currentActor?.matchData?.isDealer && this.currentMatch?.dealerMissing === 0;

            if (!hideDealButton) {
                if (showdownButton) {
                    this.ui?.updateButton('buttonShowdown', disabledDeal ? 1 : 2);
                } else {
                    this.ui?.updateButton('buttonDealCard', disabledDeal ? 1 : 2);
                }
            }
        }

        if (!isRoundFinished && this.currentActor?.matchData?.pointerTurn) {
            this.ui?.updateButton('buttonNextPointer', 2);
            this.ui?.updateButton('buttonAllIn', 2);
        }

        if (isRoundFinished && this.tableConfig?.autostartRound && this.currentMatch?.roundTimer) {
            this.ui?.updateButton('infoNextRound', 2);
        }

        // Is the standup button active?
        if (this.currentActor?.seat || this.currentActor?.seat === 0) {
            this.ui?.updateButton('buttonStandup', (this.currentActor.playing && !this.currentMatch?.round?.finished) ?
                1 :
                2
            );
        } else {
            this.ui?.updateButton('buttonStandup', 0);
        }

        // if (this.currentActor?.noresponse) {
        //     console.log('UNRESPONSE')
        //     this.ui?.updateButton('buttonIamBack', 2);
        // }

        // Reveal
        this.ui?.updateButton('buttonReveal',
            (this.currentActor?.matchData?.folded ||
                !this.currentActor?.matchData?.hand?.length ||
                !this.currentActor?.matchData?.joined ||
                this.currentActor?.matchData?.handRevealed === 3) ? 1 : 2
        );

        // Hand button can be pushed
        if (this.isDigitalCardMode && !this.currentActor?.isGameDealer) {
            // this.isAutoSitdown
            this.ui?.updateButton('buttonHand', this.currentActor?.sitting ?
                2 :
                this.currentMatch ? 2 : 1
            );
        }

        // Table Button?
        this.ui?.updateButton('buttonTable', this.isDigitalCardMode && (this.tableConfig?.flagUiTable === 1 || this.admin) ? 2 : 0);

        // Show BuyIn?
        const buyInPossible = !this.currentActor?.sitting && !this.currentActor?.seat && !this.currentActor?.isGameDealer;

        if (this.tableConfig?.flagAutoSitDown) {
            // Only Global BuyIn
            this.ui?.updateButton('buttonGlobalBuyIn', buyInPossible ? 2 : 0);
        } else {
            // Only Single BuyIn
            this.ui?.updateButton('buttonSingleBuyIn', buyInPossible ? 2 : 0);
        }

        // Only the admin or the dealer can start a new game
        if (this.currentMatch?.running === false && this.admin) {
            this.ui?.updateButton('buttonNewGame', 2);
        }
    }

    adminConnectClock(roomCode: string): void {
        if (this.admin && roomCode?.length === 4) {
            this.sdk.getMessageList().adminConnectClock(roomCode);
        }
    }
    // The tv / tablet should be able to request admin access
    adminRequest(): void {
        if (this.currentActor?.tv) {
            this.sdk.getMessageList().adminRequest();
        }
    }

    rabbitHunt(value: number): void {
        this.sdk.getMessageList().adminHunt(value);
    }

    adminMissdeal(force: boolean = false): void {
        if (this.admin && this.currentMatch?.isMissdealPossible) {
            if (!force) {
                const that = this;
                this.sdk.appState.showSecurityBox('dialog.security.missdeal', () => that.adminMissdeal(true), null)
                return;
            }

            this.sdk.getMessageList().adminEdit('missdeal', {});
        }
    }

    adminRestartRound(): void {
        this.sdk.getMessageList().adminEdit('restartround', {});
    }

    adminResetMatch(): void {
        this.sdk.getMessageList().adminEdit('reset', {});
    }

    adminForbidBuyIn(): boolean {
        this.sdk.getMessageList().adminEdit('forbidbuyin', {});
        return true;
    }

    adminHideRoomCode(): boolean {
        this.sdk.getMessageList().adminEdit('showroomcode', {});
        return true;
    }

    adminSwitchRabbitHunt(): boolean {
        this.sdk.getMessageList().adminEdit('rabbithunt', {});
        return true;
    }

    adminSwitchBlindNotice(): boolean {
        this.sdk.getMessageList().adminEdit('blindnotice', {});
        return true;
    }

    adminSwitchRoomOpenState(): boolean {
        this.sdk.getMessageList().adminEdit('openstate', {
            open: !this.adminRoomOpen
        });
        return true;
    }

    adminForcePlayerStandup(playerId: number): boolean {
        this.sdk.getMessageList().adminEdit('forcestandup', {
            playerId
        });
        return true;
    }

    // The player will standup from the game
    standUp(): void {
        this.sdk.getMessageList().standUp();
    }

    nextPointer(): void {
        viewedNextPointer += 1;

        if (viewedNextPointer > 3) {
            try {
                this.sdk.appState?.tutorialCompleted('nextbutton')
            } catch (e) { }
        }

        this.sdk.getMessageList().nextPointer();
    }

    createGame(publicData: any = undefined, dealerMode: boolean = false, dealerPin: string, waitingArea: boolean = false): void {
        if (publicData) {
            if (!this.sdk.appState.user?.hasCredit(publicData.buyinMin || 0)) {
                this.sdk.appState.showMessagebox('no-credits', 'dialog.user.credits.notenough', null, null, true, false, false);
                return;
            }
        }

        this.sdk.getMessageList().createRoom(publicData, dealerMode, dealerPin, waitingArea)
    }

    joinGame(tvMode: string | undefined, rejoin: boolean, quickgame: boolean = false, dealer: string | null = null): boolean {
        this.tvMode = tvMode;
        this.sdk.getMessageList().joinRoom(
            this.roomCode,
            tvMode,
            rejoin === true && this.roomToken ? this.roomToken : undefined,
            false,
            quickgame,
            dealer
        )

        return true;
    }

    playerNewGame(): void {
        this.sdk.getMessageList().startMatch();
    }

    getRejoinGame(loadId: string): number | undefined {
        if (this.rejoinCheck?.id === loadId) {
            return this.rejoinCheck.result;
        }
        return this.rejoinCheck?.id ? 404 : undefined;
    }

    rejoinGame(loadId: string): number {
        const checker = {
            id: loadId,
            result: 100,
            timer: setTimeout(() => {
                checker.result = 408
            }, CHECK_CONNECTION_REJOIN_TIMEOUT)
        };

        this.joinGame(this.tvMode, true);
        this.rejoinCheck = checker;

        return 100;
    }

    clearConnectionCheck(loadId: string, force: boolean = false): void {
        if (this.connectionCheck?.id === loadId || force) {
            if (this.connectionCheck?.timer) {
                clearTimeout(this.connectionCheck?.timer);
            }
            this.connectionCheck = undefined;
        }
    }

    clearRejoinCheck(loadId: string, force: boolean = false) {
        if (this.rejoinCheck && this.rejoinCheck.id === loadId) {
            if (this.rejoinCheck?.timer) {
                clearTimeout(this.rejoinCheck.timer);
            }
            this.rejoinCheck = undefined;
        }
    }

    getConnectionCheck(loadId: string): number | undefined {
        if (this.connectionCheck?.id === loadId) {
            return this.connectionCheck.result;
        }
        return undefined;
    }

    checkConnection(loadId: string, actorId: number | null): number {
        const checker = {
            id: loadId,
            result: 100,
            timer: setTimeout(() => {
                checker.result = 408
            }, CHECK_CONNECTION_REJOIN_TIMEOUT)
        };
        this.connectionCheck = checker;
        this.sdk.getMessageList().pingRoom(
            actorId || this.currentActor?.id
        );
        return 100;
    }

    saveSession(): void {
        const createdSession = {
            actorId: this.currentActor?.id,
            name: this.currentActor?.name,
            tvMode: this.tvMode,
            code: this.roomCode,
            sessionCreated: Date.now(),
            roomToken: this.roomToken || ''
        };

        console.log('Save the session to the storage', createdSession)
        this.sdk.saveInStorage('lastgame', createdSession)
    }

    isSeatOpen(seat: number) {
        if (seat || seat === 0) {
            if (seat >= 0 && seat <= 9) {
                return this.tableSeatState[seat] === 1;
            }
        }
        return false;
    }

    adminChangeSeatState(seat: number): boolean {
        if (this.sessionRunning && seat >= 0 && seat <= 9) {
            this.sdk.getMessageList().adminEdit('seatstate', {
                seat,
                seatState: 1 - this.tableSeatState[seat]
            });
            return true;
        }
        return false;
    }

    destroySessionClock() {
        if (this.sessionClock) {
            this.sessionClock.destroy();
            this.sessionClock = null;
        }
    }

    destroy(sendLeaveMessage: boolean = true, clearStorage: boolean = true): void {
        console.log('####SESSION### Destroy', sendLeaveMessage, clearStorage)
        // 1.) Send a leave message to the game
        if (this.running && sendLeaveMessage) {
            this.sdk.getMessageList().leaveRoom();
        }

        // Remove connection check
        this.clearConnectionCheck('', true);
        this.clearRejoinCheck('', true);
        this.destroySessionClock();

        // 2.) Remove it from storage
        if (clearStorage) {
            this.sdk.removeFromStorage('lastgame');
        }

        // 3.) Unsubscribe 
        if (this.subscription) {
            this.subscription.forEach((sub: any) => sub.unsubscribe());
        }
    }

    public calculateSeatPosition(position: number): number {
        if (!this.isDigitalCardMode && this.currentActor?.sitting) {
            const pDistance = (this.currentActor?.seat || 0) - DEFAULT_SEAT;
            const p2 = position + pDistance;
            if (p2 > 9) {
                return p2 - 10;
            }
            if (p2 < 0) {
                return 10 + p2;
            }
            return p2;
        }
        return position;
    }

    get sessionRunning() {
        return this.running;
    }

    get sitdownEnabled() {
        return this.tableConfig?.buyIn?.type === 'sitdown';
    }

    get isRabbitHunt() {
        return this.tableConfig?.flagRabbitCards === 1;
    }

    get voteList() {
        return toJS(this.tableVotes).map(vote => ({
            ...vote,
            playerName: this.actorList.get(vote.playerId)?.name || ''
        }));
    }

    get sessionDeck() {
        // userdeck cannot be aplied if tvmode is enabled
        const userDeck = this.tvMode ? undefined : this.sdk.appState?.user?.userDeck;
        // Return the user setting if no deck is forced
        return PokerDeck[this.tableConfig?.feature?.globalDeck ||
            userDeck ||
            'poker.deck.2'];
    }

    // Get the current Server time
    get currentServerTimeSec() {
        const t = this.timeClient !== 0 ?
            Math.floor((timestampMs() - this.timeClient) / 1000) :
            0;
        return this.timeServer + t;
    }

    get me() {
        return this.currentActor;
    }

    get admin() {
        return ((this.adminRights && this.currentActor?.auth) || (!this.running && this.sessionCreator)) && this.tableConfig?.flagDisableAdmin !== 1;
    }

    get allActor() {
        const actors: any = [];
        this.actorList.forEach((value: any): any => {
            actors.push(value);
        });
        return actors;
    }

    get seats() {
        const buildSeats: any = [];

        this.tableSeats.forEach(seatPlayer => {
            if (seatPlayer) {
                buildSeats.push(this.actorList.get(seatPlayer));
            } else {
                buildSeats.push(null)
            }
        })
        return buildSeats;
    }

    // TODO: actorLEAVED
    public getActor(id: number): GameActor | undefined {
        return this.actorList.get(id) || undefined;
    }

    public getActorLeaved(id: number): any {
        return this.actorLeavedList.get(id) || undefined;
    }

    get isDigitalCardMode() {
        return this.tableConfig?.handMode === 1;
    }

    get isAutoSitdown() {
        return this.tableConfig?.flagAutoSitDown === 1;
    }
}