const STATISTIC_LIST = {
    fold: {
        type: 'INCREASE',
        default: '0'
    },
    handType: {
        type: 'HIGHEST',
        default: '-',
        txtWithValue: 'text.player.hand.'
    },
    playedHand: {
        type: 'INCREASE',
        default: '0'
    },
    roundWon: {
        type: 'INCREASE',
        default: '0'
    },
    cashWon: {
        type: 'INCREASE',
        default: '0'
    },
    cashHighestWin: {
        type: 'HIGHEST',
        default: '0'
    }
}

export {
    STATISTIC_LIST
}