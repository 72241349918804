import {
    MATCH_ROLE
} from '../../../../constants/matchrole';

const getRenderIcon = (actorRole: number | undefined) => {

    // if (actorRole === MATCH_ROLE.DEALER) return {
    //     source: './data/ui/dealer.png',
    //     options: {
    //         heightToWidth: 1
    //     }
    // }
    
    if (actorRole === MATCH_ROLE.DEALER_BIGBLIND) return {
        source: './data/ui/bigblind.svg',
        // source: './data/ui/dealer_bigblind.png',
        options: {
            heightToWidth: 1
        }
    }

    if (actorRole === MATCH_ROLE.DEALER_SMALLBLIND) return {
        source: './data/ui/smallblind.svg',
        // source: './data/ui/dealer_smallblind.png',
        options: {
            heightToWidth: 1
        }
    }
    
    if (actorRole === MATCH_ROLE.SMALLBLIND) return {
        source: './data/ui/smallblind.svg',
        options: {
            heightToWidth: 1.7
        }
    }
    if (actorRole === MATCH_ROLE.BIGBLIND) return {
        source: './data/ui/bigblind.svg',
        options: {
            heightToWidth: 1.7
        }
    }
    return undefined;
}

const getPlayerNameFontsize = (length:number) => {
    if (length <= 10) {
        return 20;
    } else if (length <= 15) {
        return 18;
    } else if (length <= 19) {
        return 16;
    } else {
        return 14;
    }
}

export {
    getRenderIcon,
    getPlayerNameFontsize
}