import React from 'react';
import './css/app.css';
import './css/animation.css';
import MobileLayout from './components/GameCanvas';
import getSdk from './api';

function App() {
  return (
    <div className="App">
      <MobileLayout sdk={getSdk()} />
    </div>
  );
}

export default App;
