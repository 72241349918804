import {
    observer
} from 'mobx-react-lite';
import {
    translateKey
} from '../../../utils/helper'
import getSdk from '../../../api'

const OptionTextbox = observer(({
    id,
    inputOption,
    containerOption,
    headOption,
    label,
    inputType = 'text',
    autoFocus = false,
    disabled = false,
    store = undefined,
    storeProperty = undefined,
    upperCase = false,
    placeholder = '',
    maxLength = 64,
    value = '',
    onInput
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const useValue = store && storeProperty ?
        store[storeProperty] : value;

    const propsContainer = useStyles({
        widthPx: 320,
        heightToWidth: 2.8,
        lineHeight: 60,
        color: '#c1c1c1',
        fontWeight: 'bold',
        bgImage: 'url(./data/ui/optioninput.svg)',
        bgRepeat: 'no-repeat',
        bgImageSize: 'contain',
        ...(containerOption || {})
    })

    const propsInput = useStyles({
        color: '#666',
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: 32,
        widthPx: 280,
        tabIndex: -1,
        ...(inputOption || {})
    });

    const propsHeader = useStyles({
        color: '#fff',
        heightPx: 42,
        lineHeight: 42,
        fontSize: 24,
        fontWeight: 'bold',
        ...(headOption || {})
    })
    return <div
        id={id}
        style={propsContainer}
    >
        <label>
            <div
                id={`${id}-header`}
                style={propsHeader}
            >{label ? translateKey(label) : label}</div>
            <input
                id={`${id}-input`}
                className={'emptyinput'}
                type={inputType}
                value={useValue}
                style={propsInput}
                autoFocus={autoFocus}
                placeholder={placeholder ? translateKey(placeholder) : placeholder}
                maxLength={maxLength}
                disabled={disabled}
                onInput={(event: any) => {
                    let useInput = (event.target.value || '');
                    if (upperCase) {
                        useInput = useInput.toUpperCase();
                    }
                    // Update Targetvalue
                    if (useInput !== event.target.value) {
                        event.target.innerHTML = useInput;
                        event.target.value = useInput;
                    }

                    // Update the statepath
                    if (store?.updateValue && storeProperty) {
                        store.updateValue(storeProperty, useInput)
                    }

                    // Trigger the onInput callback if necessary
                    if (onInput) {
                        onInput(useInput);
                    }
                }}
            />
        </label>
    </div>
});

export default OptionTextbox;