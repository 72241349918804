import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    List,
    Header,
    TextField,
    ActionButton,
    Checkbox
} from '../controls'
import {
    convertNumberToString
} from '../../utils/helper';

const AccountSetup = observer(({
    sdk,
    createAccount = false,
    setupOption,
    handleOnBack = undefined
}: any) => {
    const user = sdk.appState.user;
    const userAccount = user.account;
    const renderList = [];

    // render the header
    renderList.push(<Header
        headOption={{
            widthPx: 580,
            heightPx: 72,
            fontSize: 30,
            lineHeight: 72,
            textAlign: 'center'
        }}
        label={`header.account.step.${userAccount.formStep}`}
    />);

    switch (userAccount.formStep) {
        case 0:
            renderList.push(<TextField
                id={'accountmail'}
                inputOption={{
                    widthPx: 320
                }}
                maxLength={120}
                placeholder={'text.common.email'}
                store={userAccount}
                storeProperty={'formMail1'}
            />)
            renderList.push(<>
                <ActionButton
                    id={'btn_login'}
                    translationKey={'button.account.login'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 240,
                        margin: [16, 0, 0, 50]
                    }}
                    disabled={!userAccount.mailFormValid}
                    actionColor={'green'}
                    onClick={() => {
                        userAccount.login().then((r: any) => console.log(r))
                    }}
                />
                <ActionButton
                    id={'btn_register'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 240,
                        margin: [16, 0, 0, 20]
                    }}
                    actionColor={'blue'}
                    translationKey={'button.account.register'}
                    disabled={!userAccount.mailFormValid}
                    onClick={() => userAccount.setFormStep(2)}
                />
            </>)
            break;
        case 1:
            renderList.push(<TextField
                id={'activationcode'}
                inputOption={{
                    widthPx: 320
                }}
                maxLength={6}
                upperCase={true}
                placeholder={'account.activationcode'}
                store={userAccount}
                storeProperty={'formActivationCode'}
            />)
            renderList.push(<>
                <ActionButton
                    id={'btn_back'}
                    translationKey={'button.account.back'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 240,
                        margin: [16, 0, 0, 50],
                        padding: undefined
                    }}
                    actionColor={'red'}
                    onClick={() => {
                        userAccount.leaveActivation()
                    }}
                />
                <ActionButton
                    id={'btn_activate'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 240,
                        margin: [16, 0, 0, 20],
                        padding: undefined
                    }}
                    actionColor={'green'}
                    translationKey={'button.account.activate'}
                    onClick={() => createAccount ?
                        user.activateAccountAndCreateUser().then((success: any) => {
                            if (success) {
                                sdk.appState.openMainMenu();
                            }
                        }) :
                        user.activateAccountAndConnect().then((success: any) => {
                            if (success) {
                                handleOnBack();
                            }
                        })
                    }
                />
            </>)
            break;
        // REGISTERVIEW
        case 2:
            renderList.push(<TextField
                id={'accountmail2'}
                inputOption={{
                    widthPx: 320
                }}
                maxLength={120}
                placeholder={'text.common.email.repeat'}
                store={userAccount}
                storeProperty={'formMail2'}
            />)
            renderList.push(<Checkbox
                id={'checkbox_agb'}
                store={userAccount}
                storeProperty={'formAgb'}
                label={'button.account.agb'}
                checkboxUnixtime={true}
                containerOption={{
                    widthPx: 540,
                    margin: [16, 0, 0, 32]
                }}
                labelOption={{
                    widthPx: 460
                }}
            />)
            renderList.push(<Checkbox
                id={'checkbox_newsletter'}
                store={userAccount}
                storeProperty={'formNewsletter'}
                checkboxUnixtime={true}
                label={'button.account.newsletter'}
                containerOption={{
                    widthPx: 540,
                    margin: [16, 0, 0, 32]
                }}
                labelOption={{
                    widthPx: 460
                }}
            />)
            renderList.push(<>
                <ActionButton
                    id={'btn_back'}
                    translationKey={'button.account.back'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 164,
                        margin: [8, 6, 0, 32],
                        padding: undefined
                    }}
                    actionColor={'red'}
                    onClick={() => {
                        userAccount.leaveRegistration()
                    }}
                />
                <ActionButton
                    id={'btn_agb'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 164,
                        margin: [8, 6, 0, 12],
                        padding: undefined
                    }}
                    actionColor={'blue'}
                    translationKey={'button.account.agb'}
                    disabled={!userAccount.mailFormValid}
                    onClick={() => null}
                />
                <ActionButton
                    id={'btn_register'}
                    buttonOption={{
                        float: 'left',
                        widthPx: 164,
                        margin: [8, 6, 0, 12],
                        padding: undefined
                    }}
                    actionColor={'green'}
                    translationKey={'button.account.register'}
                    onClick={() => {
                        userAccount.register().then((rv: any) => {
                            if (rv.error) {
                                sdk.appState.showErrorMessage('registererror', rv.errorMessage, true)
                            }
                        })
                    }}
                />
            </>)
            break;
        // FOUND A DOUBLETTE - SELECT THE RIGHT ACCOUNT
        case 3:
            renderList.push(<ActionButton
                id={'btn_useoldaccount'}
                buttonOption={{
                    float: 'left',
                    widthPx: 512,
                    fontSize: 22,
                    heightPx: 80,
                    heightToWidth: undefined,
                    margin: [16, 6, 0, 40],
                    padding: undefined
                }}
                actionColor={'blue'}
                onClick={() => user.activateAccountAndConnect(true, user.doubletteUser.userToken, true).then((result: any) => {
                    if (result) {
                        // SAVE
                        user.saveUser();
                        if (handleOnBack) {
                            handleOnBack()
                        }
                    }
                })}
            >
                {`${user.doubletteUser.name} - ${convertNumberToString(user.doubletteUser.credits)} (${user.doubletteUser.created})`}
            </ActionButton>)
            renderList.push(<ActionButton
                id={'btn_newaccount'}
                buttonOption={{
                    float: 'left',
                    widthPx: 512,
                    fontSize: 22,
                    heightPx: 80,
                    heightToWidth: undefined,
                    margin: [32, 6, 0, 40],
                    padding: undefined
                }}
                actionColor={'green'}
                onClick={() => user.activateAccountAndConnect(true, undefined, true).then((result: any) => {
                    if (result) {
                        // SAVE
                        user.saveUser();
                        if (handleOnBack) {
                            handleOnBack()
                        }
                    }
                })}
            >
                {`${user.data.name} - ${convertNumberToString(user.data.credits)} (${user.data.created})`}
            </ActionButton>)
            break;
        default:
            break;
    }

    return <Container
        containerOption={{
            widthPx: 600,
            heightPx: 360,
            float: 'left',
            margin: [0, 0, 0, 100],
            bgColor: 'rgba(0, 0, 0, .3)',
            ...(setupOption || {})
        }}
    >
        <List
            id={'account-list'}
            listOption={{
                widthPx: 580,
                heightPx: renderList.length > 3 ?
                    72 :
                    96,
                lineHeight: renderList.length > 3 ?
                    72 :
                    96
            }}
            containerOption={{
                float: 'left',
                widthPx: 580,
                margin: [0, 0, 0, 10]
            }}
            components={renderList}
        >

        </List>
    </Container>
});

export default AccountSetup;