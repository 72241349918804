import { useEffect } from 'react';
import {
    observer
} from 'mobx-react-lite';
import { toJS } from 'mobx';
import {
    List
} from '../../controls';
import { GAMEACTION } from '../../../constants/gameaction';
import {
    translateKey,
    convertTimestampToTime,
    convertCurrency,
    convertDealerCard,
    convertBigCardField
} from '../../../utils/helper';
import {
    PokerCardMicro
} from '../../game/ingame/cards/PokerCard';

const GameHistoryEntry = ({ entry, actor, deck }: any) => {
    const {
        action,
        playerId,
        value,
        value2,
        roundId,
        id
    } = entry;
    let buildText: any = ''
    const name = actor?.name || '-';

    switch (action) {
        case GAMEACTION.ACTION_EMPTY_ROW:
            buildText = '---------------------------';
            break;
        case GAMEACTION.ACTION_ROUND_INFO:
            buildText = translateKey('text.game.history.newround', {}, [
                ['$round', `${roundId}`],
                ['$time', `${convertTimestampToTime(value * 1000)}`]
            ])
            break;
        case GAMEACTION.ACTION_DEALER_DEALSCARD:
            buildText = translateKey('text.game.dealer.gives.pocket.cards');
            break;
        case GAMEACTION.ACTION_SMALLBLIND:
            buildText = translateKey('text.game.player.sets.small.blind', {}, [
                ['$smallblind_value', `<span>${convertCurrency(value)}</span>`],
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_JOIN:
            buildText = translateKey('text.game.player.join', null, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_LEAVE:
            buildText = translateKey('text.game.player.leave', null, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_BIGBLIND:
            buildText = translateKey('text.game.player.sets.big.blind', {}, [
                ['$bigblind_value', `<span>${convertCurrency(value)}</span>`],
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_FOLD:
            buildText = translateKey('text.game.player.folds', null, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_BET:
            buildText = translateKey('text.game.player.bets', {}, [
                ['$player', `${name}`],
                ['$bet_value', `<span>${convertCurrency(value)}</span>`]
            ])
            break;
        case GAMEACTION.ACTION_CALL:
            buildText = translateKey('text.game.player.calls', {}, [
                ['$player', `${name}`],
                ['$call_value', `<span>${convertCurrency(value)}</span>`],
                ['$total', `<span>${convertCurrency(value2)}</span>`]
            ])
            break;
        case GAMEACTION.ACTION_RAISE:
            buildText = translateKey('text.game.player.raises', {}, [
                ['$player', `${name}`],
                ['$raise_value', `<span>${convertCurrency(value)}</span>`],
                ['$total', `<span>${convertCurrency(value2)}</span>`]
            ]);
            break;
        case GAMEACTION.ACTION_RERAISE:
            buildText = translateKey('text.game.player.reraises', {}, [
                ['$player', `${name}`],
                ['$raise_value', `<span>${convertCurrency(value)}</span>`],
                ['$total', `<span>${convertCurrency(value2)}</span>`]
            ])
            break;
        case GAMEACTION.ACTION_CHECK:
            buildText = translateKey('text.game.player.checks', null, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_ALLIN:
            buildText = translateKey('text.game.player.allin', {}, [
                ['$player', `${name}`],
                ['$allin_value', value === 0 ? '' : `<span>${convertCurrency(value)}</span>`],
                ['$total', value2 === 0 ? '' : `<span>${convertCurrency(value2)}</span>`]
            ])
            break;
        case GAMEACTION.ACTION_BANKRUPT:
            buildText = translateKey('text.game.player.bankrupt', {}, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_MATCH_WINNER:
            buildText = translateKey('text.game.match.winner', {}, [
                ['$player', `${name}`]
            ]);
            break;
        case GAMEACTION.ACTION_WINNER_TYPE:
            buildText = translateKey('text.player.winner.log', null, [
                ['$type', `<span>${translateKey(`text.player.hand.${value}`)}</span>`]
            ]);
            break;
        case GAMEACTION.ACTION_WINNER:
            buildText = translateKey('text.game.player.wins', {}, [
                ['$player', `${name}`],
                ['$win_value', `<span>${convertCurrency(value)}</span>`]
            ]);
            if (value2?.length === 2) {
                buildText = <>
                    {(value2 || []).map((card: any, i: number) => <PokerCardMicro
                        key={`card-${id}-${i}`}
                        cardWidth={20}
                        card={card}
                        deck={deck}
                        cardOption={{
                            margin: [0, 4, 0, i === 0 ? 0 : 4]
                        }}
                    />)}
                    <div style={{ float: 'left' }} dangerouslySetInnerHTML={{ __html: buildText }} />
                </>
            }
            break;
        case GAMEACTION.ACTION_BUYIN:
            buildText = translateKey('text.game.player.buyin', {}, [
                ['$player', `${name}`],
                ['$value', `<span>${convertCurrency(value)}</span>`]
            ])
            break;
        case GAMEACTION.ACTION_FLOP:
            const flopCards = convertDealerCard(value) || [];

            if (flopCards.length === 3) {
                buildText = <>
                    <div style={{ float: 'left' }}>{translateKey('text.game.dealer.deals.flop')}</div>
                    {flopCards.map((card: any, i: any) => <PokerCardMicro
                        key={`card-${id}-${i}`}
                        cardWidth={20}
                        card={card}
                        deck={deck}
                        cardOption={{
                            margin: [0, 4, 0, i === 0 ? 8 : 4]
                        }}
                    />)}

                </>
            }

            break;
        case GAMEACTION.ACTION_TURN:
            const turnCards = convertDealerCard(value) || [];

            if (turnCards.length === 1) {
                buildText = <>
                    <div style={{ float: 'left' }}>{translateKey('text.game.dealer.deals.turn')}</div>
                    {turnCards.map((card: any, i: any) => <PokerCardMicro
                        key={`card-turn-${id}-${i}`}
                        cardWidth={20}
                        card={card}
                        deck={deck}
                        cardOption={{
                            margin: [0, 4, 0, i === 0 ? 8 : 4]
                        }}
                    />)}

                </>
            }
            break;
        case GAMEACTION.ACTION_RIVER:
            const riverCards = convertDealerCard(value) || [];

            if (riverCards.length === 1) {
                buildText = <>
                    <div style={{ float: 'left' }}>{translateKey('text.game.dealer.deals.river')}</div>
                    {riverCards.map((card: any, i: any) => <PokerCardMicro
                        key={`card-river-${id}-${i}`}
                        cardWidth={20}
                        card={card}
                        deck={deck}
                        cardOption={{
                            margin: [0, 4, 0, i === 0 ? 8 : 4]
                        }}
                    />)}

                </>
            }
            break;
        case GAMEACTION.ACTION_SITDOWN:
            buildText = translateKey('text.game.match.sitdown', {}, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_SITUP:
            buildText = translateKey('text.game.match.standup', {}, [
                ['$player', `${name}`]
            ])
            break;
        case GAMEACTION.ACTION_REVEALCARD:
            const revealCards = convertDealerCard(value) || [];

            if (revealCards.length) {
                const rndList = [];

                if (value2 === 1) {
                    rndList.push(0);
                    rndList.push(null);
                } else if (value2 === 2) {
                    rndList.push(null);
                    rndList.push(0);
                } else {
                    rndList.push(0);
                    rndList.push(1);
                }

                buildText = <>
                    <div style={{ float: 'left' }}>{translateKey('text.game.player.revealed', null, [
                        ['$player', name]
                    ])}</div>
                    {rndList.map((card: any, i: any) => <PokerCardMicro
                        key={`card-${id}-${i}`}
                        cardWidth={20}
                        deck={deck}
                        cardRevealed={revealCards[card] !== null}
                        card={revealCards[card]}
                        cardOption={{
                            margin: [0, 5, 0, i == 0 ? 10 : 0]
                        }}
                    />)}

                </>
            }

            break;
        case GAMEACTION.ACTION_BEST_CARDS:
            const useBestCards = convertBigCardField(value);
            if (useBestCards?.cards?.length === 5) {
                buildText = <>
                    {useBestCards.cards.map((card: any, i: any) => <PokerCardMicro
                        key={`card-river-${id}-${i}`}
                        cardWidth={20}
                        card={card}
                        deck={deck}
                        cardOption={{
                            margin: [0, 5, 0, 0]
                        }}
                    />)}
                    <div style={{ float: 'left' }}>{translateKey(`text.player.hand.${useBestCards.type}`)}</div>
                </>
            }

            break;
        case GAMEACTION.ACTION_BANKROLL_UPDATE:
            buildText = translateKey('text.game.player.bankroll.update', null, [
                ['$player', `${name}`],
                ['$value', `<span>${convertCurrency(value)}</span>`]
            ])
            break;
        case GAMEACTION.ACTION_KICKED:
            buildText = translateKey('text.game.player.kicked', null, [
                ['$player', `${name}`]
            ])
            break;
        default:
            break;
    }

    return typeof buildText === 'string' ?
        <div dangerouslySetInnerHTML={{ __html: buildText }} /> :
        buildText

}

const GameHistory = observer(({ sdk }: any) => {
    const {
        appState
    } = sdk;
    const currentHistory = appState?.currentSession?.history || [];

    useEffect(() => {
        if (appState?.currentSession?.history?.length) {
            //@ts-ignore
            const ge = document.getElementById(`gamehistory`);
            if (ge) {
                ge.scrollTop = 999999999;
            }
        }

    }, [appState?.currentSession?.history?.length])


    return currentHistory.length ? <>
        <List
            // listRef={historyEndRef}
            id={'gamehistory'}
            containerOption={{
                float: 'left',
                textAlign: 'left',
                widthPx: 930,
                heightPx: 600,
                margin: [0, 0, 0, 20],
                padding: [0, 0, 0, 0],
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
            listOption={{
                widthPx: 930,
                lineHeight: 34,
                heightPx: 34,
                whiteSpace: 'nowrap',
                color: '#fff',
                fontSize: 26,
                margin: [0, 0, 0, 12]
            }}
            components={currentHistory.map((entry: any, i: number) => <GameHistoryEntry key={`history-${i}`} deck={appState?.currentSession?.sessionDeck} actor={appState?.currentSession?.getActor(entry.playerId) || appState?.currentSession?.getActorLeaved(entry.playerId)} entry={toJS(entry)} />)}
        />
    </>
        : null;
});

export default GameHistory;