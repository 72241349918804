import { useEffect, useState } from 'react';
import {
    translateKey
} from '../../../utils/helper'
import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'
import {
    Icon
} from '../index'
const DEFAULT_BUTTON_TIMEOUT = 800;

const ActionButton = observer(({
    children,
    id,
    actionColor,
    actionType,
    labelValue,
    onClick,
    disabled,
    icon,
    translationKey,
    enableDelay = DEFAULT_BUTTON_TIMEOUT,
    buttonOption
}: any) => {
    useEffect(() => {
        return () => {
            if (!!disabledComponent) {
                clearTimeout(disabledComponent)
            }
        }
    }, [])
    const [disabledComponent, setDisabled] = useState(null);

    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles;
    const renderValue = translationKey ? translateKey(translationKey) : children;

    let useBorderColor = '#fff';

    if (actionColor === 'green') {
        useBorderColor = '#48bf53';
    } else if (actionColor === 'red') {
        useBorderColor = '#900D09'
    } else if (actionColor === 'blue') {
        useBorderColor = '#0096fe'
    } else if (actionColor === 'orange') {
        useBorderColor = '#df830b'
    } else if (actionColor === 'black') {
        useBorderColor = '#000'
    } else if (actionColor === 'yellow') {
        useBorderColor = '#f9de00'
    }

    return <button
        id={id}
        disabled={!!disabledComponent || disabled}
        onClick={(e) => {
            if (onClick) {
                onClick(e)
            }
            if (enableDelay) {
                const addTimeout = setTimeout(() => {
                    //@ts-ignore
                    setDisabled(null);
                }, enableDelay)

                //@ts-ignore
                setDisabled(addTimeout);
            }

        }}
        style={useStyles({
            textAlign: 'center',
            padding: [labelValue ? 0 : 16, 16, 16, 16],
            fontSize: 24,
            fontWeight: 'bold',
            widthPx: 240,
            heightToWidth: 3.24,
            bgColor: '#fff',
            borderRadius: 24,
            bgRepeat: 'no-repeat',
            bgImageSize: 'contain',
            borderSize: 8,
            border: `solid ${useBorderColor}`,
            outlineBorder: '#fff',
            outlineBorderSize: 4,
            ...(buttonOption || {})
        })}
        className={actionType === 'alert' ? 'actionbutton_red' : 'actionbutton'}
    >
        {icon ? <Icon source={icon} iconOption={{
            float: 'left',
            widthPx: 32,
            heightPx: 32,
            margin: [8, 8, 0, 8]
        }} /> : null}
        {labelValue ? (<>
            {renderValue}<br />
            <span style={useStyles({
                fontSize: 20
            })}>{labelValue}</span>
        </>) : (<>{renderValue}</>)}
    </button>;
});

export default ActionButton;